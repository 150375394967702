<div class="auth-main">


    <div class="container">
        <h2 class="loginTitle">{{'citizen-registration-details.sign-up-as-legal' | translate}} </h2>


        <div class="registrationSteps" *ngIf="step1">
            <div class="steps active">
                <div class="stepBox ">
                    <div class="d-flex  align-items-center">
                        <i class="stepIcon fa fa-phone"></i>
                        <p>{{'mobile-verification' | translate}}</p>
                    </div>
                </div>
                <i class="fa fa-caret-right stepforword"></i>
            </div>

            <div class="steps mx-3" *ngIf="step1clear && !step2clear" (click)="gotostep2()">
                <div class="stepBox">
                    <div class="d-flex  align-items-center">
                        <i class="stepIcon fa fa-phone"></i>
                        <p> {{'legal-entity-details' | translate}} </p>
                    </div>
                </div>
                <i class="fa fa-caret-right stepforword"></i>
            </div>
            <div class="steps mx-3" *ngIf="step1clear && step2clear" (click)="gotostep3()">
                <div class="stepBox">
                    <div class="d-flex  align-items-center">
                        <i class="stepIcon fa fa-phone"></i>
                        <p> {{'legal-entity-details' | translate}} </p>
                    </div>
                </div>
                <i class="fa fa-caret-right stepforword"></i>
            </div>
            <div class="steps mx-3" *ngIf="!step1clear">
                <div class="stepBox">
                    <div class="d-flex  align-items-center">
                        <i class="stepIcon fa fa-phone"></i>
                        <p> {{'legal-entity-details' | translate}} </p>
                    </div>
                </div>
                <i class="fa fa-caret-right stepforword"></i>
            </div>

            <div class="steps mx-3">
                <div class="stepBox">
                    <div class="d-flex  align-items-center">
                        <i class="stepIcon fa fa-phone"></i>
                        <p> {{'kyc-detail' | translate}}</p>
                    </div>
                </div>
                <i class="fa fa-caret-right stepforword"></i>

            </div>
            <div class="steps">
                <div class="stepBox">
                    <div class="d-flex  align-items-center">
                        <i class="stepIcon fa fa-phone"></i>
                        <p>{{'demographic-details' | translate}} </p>
                    </div>
                </div>

            </div>
        </div>
        <div class="registrationSteps" *ngIf="step2">
            <div class="steps ">
                <div class="stepBox ">
                    <div class="d-flex  align-items-center">
                        <i class="stepIcon fa fa-phone"></i>
                        <p>{{'mobile-verification' | translate}}</p>
                    </div>
                    <button (click)="goBack(2)" class="stepgoback">{{'goback'|translate}}</button>
                </div>
                <i class="fa fa-caret-right stepforword"></i>
            </div>
            <div class="steps active mx-3">
                <div class="stepBox">
                    <div class="d-flex  align-items-center">
                        <i class="stepIcon fa fa-phone"></i>
                        <p>{{'legal-entity-details' | translate}} </p>
                    </div>
                </div>
                <i class="fa fa-caret-right stepforword"></i>
            </div>

            <div class="steps mx-3" *ngIf="step2clear" (click)="gotostep3()">
                <div class="stepBox">
                    <div class="d-flex  align-items-center">
                        <i class="stepIcon fa fa-phone"></i>
                        <p> {{'kyc-detail' | translate}}</p>
                    </div>
                </div>
                <i class="fa fa-caret-right stepforword"></i>
            </div>
            <div class="steps mx-3" *ngIf="!step2clear">
                <div class="stepBox">
                    <div class="d-flex  align-items-center">
                        <i class="stepIcon fa fa-phone"></i>
                        <p> {{'kyc-detail' | translate}}</p>
                    </div>
                </div>
                <i class="fa fa-caret-right stepforword"></i>
            </div>

            <div class="steps">
                <div class="stepBox">
                    <div class="d-flex  align-items-center">
                        <i class="stepIcon fa fa-phone"></i>
                        <p> {{'demographic-details' | translate}}</p>
                    </div>
                </div>

            </div>
        </div>
        <div class="registrationSteps" *ngIf="step3">
            <div class="steps ">
                <div class="stepBox ">
                    <div class="d-flex  align-items-center">
                        <i class="stepIcon fa fa-phone"></i>
                        <p>{{'mobile-verification' | translate}}</p>
                    </div>
                    <button (click)="goBack(2)" class="stepgoback">{{'goback'|translate}}</button>
                </div>
                <i class="fa fa-caret-right stepforword"></i>
            </div>
            <div class="steps active mx-3">
                <div class="stepBox">
                    <div class="d-flex  align-items-center">
                        <i class="stepIcon fa fa-phone"></i>
                        <p>{{'legal-entity-details' | translate}} </p>
                    </div>
                    <button (click)="goBack(3)" class="stepgoback">{{'goback'|translate}}</button>
                </div>
                <i class="fa fa-caret-right stepforword"></i>

            </div>

            <div class="steps mx-3" *ngIf="step3clear" (click)="gotostep4()">
                <div class="stepBox">
                    <div class="d-flex  align-items-center">
                        <i class="stepIcon fa fa-phone"></i>
                        <p> {{'kyc-detail' | translate}}</p>
                    </div>
                </div>
                <i class="fa fa-caret-right stepforword"></i>
            </div>
            <div class="steps mx-3" *ngIf="!step3clear">
                <div class="stepBox">
                    <div class="d-flex  align-items-center">
                        <i class="stepIcon fa fa-phone"></i>
                        <p> {{'kyc-detail' | translate}}</p>
                    </div>
                </div>
                <i class="fa fa-caret-right stepforword"></i>
            </div>

            <div class="steps">
                <div class="stepBox">
                    <div class="d-flex  align-items-center">
                        <i class="stepIcon fa fa-phone"></i>
                        <p> {{'demographic-details' | translate}}</p>
                    </div>
                </div>

            </div>
        </div>
        <div class="registrationSteps" *ngIf="step4">
            <div class="steps ">
                <div class="stepBox ">
                    <div class="d-flex  align-items-center">
                        <i class="stepIcon fa fa-phone"></i>
                        <p> {{'mobile-verification' | translate}}</p>
                    </div>
                    <i class="fa fa-caret-right stepforword"></i>
                </div>

            </div>
            <div class="steps mx-3">
                <div class="stepBox">
                    <div class="d-flex  align-items-center">
                        <i class="stepIcon fa fa-phone"></i>
                        <p> {{'legal-entity-details' | translate}} </p>
                    </div>
                    <button (click)="goBack(4)" class="stepgoback">{{'goback'|translate}}</button>
                </div>
                <i class="fa fa-caret-right stepforword"></i>
            </div>
            <div class="steps active mx-3">
                <div class="stepBox">
                    <div class="d-flex  align-items-center">
                        <i class="stepIcon fa fa-phone"></i>
                        <p> {{'kyc-detail' | translate}}</p>
                    </div>
                </div>
                <i class="fa fa-caret-right stepforword"></i>
            </div>

            <div class="steps" *ngIf="step4clear" (click)="gotostep5()">
                <div class="stepBox">
                    <div class="d-flex  align-items-center">
                        <i class="stepIcon fa fa-phone"></i>
                        <p>{{'demographic-details' | translate}} </p>
                    </div>
                </div>
            </div>
            <div class="steps" *ngIf="!step4clear">
                <div class="stepBox">
                    <div class="d-flex  align-items-center">
                        <i class="stepIcon fa fa-phone"></i>
                        <p>{{'demographic-details' | translate}} </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="registrationSteps" *ngIf="step5">
            <div class="steps ">
                <div class="stepBox ">
                    <div class="d-flex  align-items-center">
                        <i class="stepIcon fa fa-phone"></i>
                        <p>{{'mobile-verification' | translate}}</p>
                    </div>
                    <i class="fa fa-caret-right stepforword"></i>
                </div>

            </div>
            <div class="steps mx-3">
                <div class="stepBox">
                    <div class="d-flex  align-items-center">
                        <i class="stepIcon fa fa-phone"></i>
                        <p>{{'legal-entity-details' | translate}}</p>
                    </div>
                </div>
                <i class="fa fa-caret-right stepforword"></i>

            </div>
            <div class="steps mx-3">
                <div class="stepBox">
                    <div class="d-flex  align-items-center">
                        <i class="stepIcon fa fa-phone"></i>
                        <p> {{'kyc-detail' | translate}}</p>
                    </div>
                    <button (click)="goBack(5)" class="stepgoback">{{'goback'|translate}}</button>
                </div>
                <i class="fa fa-caret-right stepforword"></i>
            </div>
            <div class="steps active">
                <div class="stepBox">
                    <div class="d-flex  align-items-center">
                        <i class="stepIcon fa fa-phone"></i>
                        <p> {{'demographic-details' | translate}}</p>
                    </div>
                </div>

            </div>
        </div>




        <lib-alertmessage></lib-alertmessage>
        <div class="card bg-transparent" *ngIf="step1">

            <div class="card-body p-0 pl-3 pr-3">
                <div class="row">
                    <div class="col-xl-5 col-lg-6 col-md-5 col-xs-12 col-sm-12 logInForms bg-white radius4 p-5">
                        <form (ngSubmit)="verifyOtp()">
                            <!-- <h3 class="card-title">{{'citizen-registration-details.sign-up-as-legal' | translate}} </h3> -->
                            <div class="row">
                                <div class="col-12">
                                    <div class="from-group mb-3">
                                         <label class="w-100">{{'enter-authorized-person-mobile-number' | translate}}
                                            <span style="color: red;">*</span></label>
                                    <div class="input-group">
                                        <div class="input-group-append">
                                            <span class="input-group-text"><i class="fa fa-phone"></i></span>
                                        </div>
                                        <input type="text" name="mobileNo" [(ngModel)]="mobileNo"
                                            class="form-control input_user"
                                            placeholder="{{'enter-mobile-number' | translate}}" pattern="^[6-9]\d{9}$"
                                            minlength="10" maxlength="10" autocomplete="off"
                                            [appCopyPastValidation]="true">
                                  </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row align-items-center mt-2 mb-2">
                                <div class="col-5">
                                    <div class="input-group" >
                                        <img src="{{captchaImageBase64Img}}" height="40" />
                                        <!-- <button (click)="getStartupCaptcha()">REFRESH CAPTCHA</button> -->
                                    </div>
                                </div>
                                <div class="col-2 text-center">
                                    <img src="assets/images/refresh_image.png" height="26px" 
                                        (click)="getStartupCaptcha()" />
                                </div>
                                <div class="col-5">
                                    <div class="input-group ">
                                        <input type="text" name="captchaStr" [(ngModel)]="captchaStr" required
                                            class="form-control input_user pl-3"
                                            placeholder="{{'enter-captcha' | translate}}" minlength="4" maxlength="4"
                                            [appCopyPastValidation]="true" autocomplete="off">
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-end">
                                <!-- <div class="col-12"> -->
                                  <div class="col-md-6 input-group-prepend">
                                        <span *ngIf="counter != 0">
                                            <span style="font-weight: 400; font-size: 13px;">
                                                {{'regenerate_otp' | translate}} 
                                            </span> &nbsp;
                                            {{counter | formatTime}}</span>
                                        <a *ngIf="counter == 0 && !otpTouched" class="btn btn-primary btn-block"
                                            (click)="generateOtp()" (keyup.enter)="generateOtp()">{{'get-otp' |
                                            translate}}</a>
                                        <a *ngIf="counter == 0 && otpTouched" class="btn btn-secondary btn-block"
                                            (click)="generateOtp()" (keyup.enter)="generateOtp()">{{'resend-otp' |
                                            translate}}</a>

                                            
                                    </div>
                                <!-- </div> -->
                            </div>

                            <div class=" mt-3" style="background-color: #ffffff4a!important;" *ngIf="otpGenerated">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="input-group mb-1">
                                            <label class="w-100">{{'enter-otp' | translate}}</label>
                                            <input type="text" name="mobile-otp" minlength="6" maxlength="6"
                                                class="form-control input_user" [(ngModel)]="otp"
                                                [appCopyPastValidation]="true" autocomplete="off">
                                        </div>
                                         <p style="font-weight: 400; font-size: 13px;text-align: right;">( {{'otpValid'| translate}} )</p>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="otpGenerated && step1">
                                <div class="row mt-3">
                                    <div class="col-md-6">
                                        <button class="btn btn-primary btn-block" (click)="verifyOtp()">{{'submit' |
                                            translate}}</button>
                                    </div>
                                    <div class="col-md-6">
                                        <button *ngIf="counter == 0" class="btn btn-danger btn-block"
                                            (click)="reset()">{{'reset' | translate}}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-xl-7 col-lg-6 col-md-7 col-xs-12 col-sm-12 hidden-xs   align-self-center p-5">
                        <div class="alert alert-primary p-2 mb-3" role="alert">
                            <div class="d-flex">
                                <i class="fa fa-info-circle mr-1"></i>
                                <p *ngIf="lan==0">Authorized person means a person approved or assigned by legal entity to perform a
                                    specific type of duty or duties.

                                    Enter Authorized Person Mobile Phone Number and Captcha (in the Format Shown) and
                                    Click “get OTP” to Confirm It. An OTP Verification Code Will Be Sent to Authorized
                                    Person Phone via SMS. Copy the OTP Code From the SMS and Enter Into OTP Box Then,
                                    Click the “submit” Button.
                                </p>
                                <p *ngIf="lan==1">प्राधिकृत व्यक्ति का अर्थ है किसी कार्य को करने के लिए कानूनी इकाई द्वारा अनुमोदित या नियुक्त किया गया व्यक्ति
                                    विशिष्ट प्रकार का कर्तव्य या कर्त्तव्य।

                                    अधिकृत व्यक्ति का मोबाइल फोन नंबर और कैप्चा (दिखाए गए प्रारूप में) दर्ज करें, इसकी पुष्टि करने के लिए “ओटीपी प्राप्त करें” पर क्लिक करें। अधिकृत को एक ओटीपी सत्यापन कोड भेजा जाएगा
                                    एसएमएस के जरिए व्यक्ति का फोन। एसएमएस से ओटीपी कोड कॉपी करें और ओटीपी बॉक्स में दर्ज करें,
                                    "सबमिट" बटन पर क्लिक करें।
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="card-footer text-right" *ngIf="otpGenerated && step1">
                    <button mat-flat-button style="float: left;" *ngIf="counter == 0" (click)="reset()">{{'reset' | translate}}</button>
                    <button mat-flat-button color="primary" style="float: right;" class="mr-2" (click)="verifyOtp()">
                        {{'submit' | translate}}
                    </button>
                </div> -->

        </div>


        <div class="card mb-3" *ngIf="step2">
            <form (ngSubmit)="juristicDetailHtml.form.valid && fetchCompanyDetails()" #juristicDetailHtml="ngForm"
                novalidate>
                <div class="card-body">
                    <h3 class="card-title">{{'citizen-profile-details.register-legal-person' | translate}}</h3>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{'citizen-profile-details.citizen-juristic-service-details.Select GST/CIN Type'
                                    |
                                    translate}} <span style="color: red;">*</span></label>
                                <select class="form-control"
                                    [ngClass]="{ 'is-invalid': juristicDetailHtml.submitted && companyIdType.invalid }"
                                    required name="companyIdType" #companyIdType="ngModel"
                                    [(ngModel)]="juristicPersonProfileModel.companyIdType.id">
                                    <option [value]="0" disabled hidden>
                                        {{'citizen-profile-details.citizen-juristic-service-details.companyIdType' |
                                        translate}}</option>
                                    <option *ngFor="let companyIdType of companyIdTypes" [ngValue]="companyIdType.id">
                                        {{ lan==1?companyIdType.companyIdTypeNameHi:companyIdType.companyIdTypeNameEn }}
                                    </option>

                                </select>

                                <div *ngIf="juristicDetailHtml.submitted && companyIdType.invalid"
                                    class="invalid-feedback">
                                    <div *ngIf="companyIdType.errors?.required">
                                        {{'citizen-profile-details.citizen-juristic-service-details.organization-type' |
                                        translate}}</div>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="juristicPersonProfileModel.companyIdType?.id == 1 ">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>{{'citizen-profile-details.citizen-juristic-service-details.GSTIN-No' |
                                        translate}} <span style="color: red;">*</span> </label>
                                    <input pattern="^[0-9]{2}[A-Z]{4,5}[0-9]{4,5}[A-Z0-9]{4}$"
                                         required
                                        name="companyIdNo" #companyIdNo="ngModel"
                                        [(ngModel)]="juristicPersonProfileModel.companyIdNo" type="text"
                                        (contextmenu)="false;" class="form-control" maxlength="40" AlphaNumeric
                                        autocomplete="off" [appCopyPastValidation]="true">

                                    <div *ngIf="juristicDetailHtml.submitted && companyIdNo.invalid"
                                        class="invalid-feedback">
                                        <div *ngIf="companyIdNo.errors?.required">
                                            {{'citizen-profile-details.citizen-juristic-service-details.GSTIN-No' |
                                            translate}}</div>
                                    </div>
                                    <div *ngIf="companyIdNo.touched && companyIdNo.invalid" class="invalid-feedback">
                                        <div *ngIf="companyIdNo.errors?.pattern">
                                            {{'citizen-profile-details.citizen-juristic-service-details.GSTIN-No-validation'
                                            |
                                            translate}}</div>
                                    </div>

                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="juristicPersonProfileModel.companyIdType?.id == 2 ">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>{{'citizen-profile-details.citizen-juristic-service-details.Company-PAN-No' |
                                        translate}} <span style="color: red;">*</span> </label>
                                    <input pattern="^[A-Z]{5}[0-9]{4}[A-Z]{1}$" required name="companyIdNo"
                                        #companyIdNo="ngModel" [(ngModel)]="juristicPersonProfileModel.companyIdNo"
                                        type="text" (contextmenu)="false;" class="form-control" maxlength="10"
                                        AlphaNumeric oninput="this.value = this.value.toUpperCase()" autocomplete="off"
                                        [appCopyPastValidation]="true" placeholder="{{'citizen-profile-details.citizen-juristic-service-details.Company-PAN-No'|translate}}">

                                    <div *ngIf="juristicDetailHtml.submitted && companyIdNo.invalid"
                                        class="invalid-feedback">
                                        <div *ngIf="companyIdNo.errors?.required">
                                            {{'citizen-profile-details.citizen-juristic-service-details.pan-no' |
                                            translate}}</div>
                                    </div>
                                    <div *ngIf="companyIdNo.touched && companyIdNo.invalid" class="invalid-feedback">
                                        <div *ngIf="companyIdNo.errors?.pattern">
                                            {{'citizen-profile-details.citizen-juristic-service-details.pan-no-validation'
                                            |
                                            translate}}</div>
                                    </div>

                                </div>
                                <div class="form-group">
                                        <div class="form-group">
                                            <label>{{'kyc-details.pan-dob'|translate}}</label>
                                            <input type="date" class="form-control" [disabled]="disabledPanFullName" placeholder="dd/mm/yyyy"
                                            [(ngModel)]="panDob" #dob="ngModel" id="dob" name="dob" >
                                        </div>
                                </div>
    
                                <div class="form-group">
                                    <label>{{'kyc-details.pan-full-name'|translate}}</label>
                                    <input [disabled]="disabledPanFullName" type="text" class="form-control"
                                        (contextmenu)="false;" placeholder="{{'kyc-details.enter-full-name' |translate }}"
                                        [(ngModel)]="panFullName"  maxlength="100" #fullName="ngModel" id="fullName" name="fullName"
                                        oninput="this.value = this.value.toUpperCase()">
                                </div>

                            </div>
                        </ng-container>

                        <ng-container *ngIf="juristicPersonProfileModel.companyIdType?.id == 3 ">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>{{'citizen-profile-details.citizen-juristic-service-details.CIN-No' |
                                        translate}} <span style="color: red;">*</span> </label>
                                    <input
                                        pattern="^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$"
                                        required name="companyIdNo" #companyIdNo="ngModel"
                                        [(ngModel)]="juristicPersonProfileModel.companyIdNo" type="text"
                                        (contextmenu)="false;" class="form-control" maxlength="40" AlphaNumeric
                                        autocomplete="off" [appCopyPastValidation]="true">
                                    <!-- <input pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$" required name="companyIdNo" #companyIdNo="ngModel" [(ngModel)]="juristicPersonProfileModel.companyIdNo" type="text"  class="form-control">   -->

                                    <div *ngIf="juristicDetailHtml.submitted && companyIdNo.invalid"
                                        class="invalid-feedback">
                                        <div *ngIf="companyIdNo.errors?.required">
                                            {{'citizen-profile-details.citizen-juristic-service-details.CIN-No' |
                                            translate}}</div>
                                    </div>
                                    <div *ngIf="companyIdNo.touched && companyIdNo.invalid" class="invalid-feedback">
                                        <div *ngIf="companyIdNo.errors?.pattern">
                                            {{'citizen-profile-details.citizen-juristic-service-details.CIN-No-validation'
                                            |
                                            translate}}</div>
                                    </div>

                                </div>
                            </div>
                        </ng-container>
                    </div>


                </div>
                <div class="card-footer">
                    <div class="row">
                        <div class="col-lg-12 text-center">
                            <button mat-flat-button style="float: left;" (click)="goBack(2)">{{'goback' |
                                translate}}</button>
                            <button type="submit" mat-raised-button class="btn btn-primary"
                                style="float: right;">{{'submit' |
                                translate}}</button>
                        </div>
                    </div>
                </div>

            </form>
        </div>

        <div class="card mb-3" *ngIf="step3">
            <form (ngSubmit)="juristicDetailHtml1.form.valid && onSubmitJuristicDetail(juristicDetailHtml1.form)"
                #juristicDetailHtml1="ngForm" novalidate>
                <div class="card-body">
                    <!-- <h3 class="card-title">{{'citizen-profile-details.register-legal-person' | translate}}</h3> -->
                    <div class="d-flex companydetails mb-3">
                        <span class="building-icon mr-3">
                            <img src="assets/images/building.png" height="100" alt="Building Icon">
                        </span>
                        <div class="">
                            <ng-container *ngIf="juristicPersonProfileModel.companyIdType?.id == 1 ">
                                <small>{{'citizen-profile-details.citizen-juristic-service-details.Company-Name' |
                                    translate}}</small>
                                <h4 class="text-primary" name="companyName" id="compName" #companyName="ngModel"
                                    [(ngModel)]="juristicPersonProfileModel.companyName">{{legalNameOfBusiness}}</h4>
                            </ng-container>
                            <ng-container *ngIf="juristicPersonProfileModel.companyIdType?.id == 2 ">
                                <small>{{'citizen-profile-details.citizen-juristic-service-details.Company-Name' |
                                    translate}}</small>
                                <h4 class="text-primary" name="companyName" id="compName" #companyName="ngModel"
                                    [(ngModel)]="juristicPersonProfileModel.companyName">{{panData?.nameonCard}}</h4>
                            </ng-container>
                            <ng-container *ngIf="juristicPersonProfileModel.companyIdType?.id == 3 ">
                                <small>{{'citizen-profile-details.citizen-juristic-service-details.Company-Name' |
                                    translate}}</small>
                                <h4 class="text-primary" name="companyName" id="compName" #companyName="ngModel"
                                    [(ngModel)]="juristicPersonProfileModel.companyName">{{cinData?.companyName}}</h4>
                            </ng-container>


                            <ng-container *ngIf="juristicPersonProfileModel.companyIdType?.id == 1 ">
                                <small>{{'citizen-profile-details.citizen-juristic-service-details.Company-GST-No' |
                                    translate}}</small>
                                <p> <b><span name="gstNumber" id="gstNumber" #companyIdNo="ngModel"
                                            [(ngModel)]="juristicPersonProfileModel.companyIdNo">{{gstData?.gstin}}</span>
                                    </b></p>
                            </ng-container>
                            <ng-container *ngIf="juristicPersonProfileModel.companyIdType?.id == 2 ">
                                <small>{{'citizen-profile-details.citizen-juristic-service-details.Company-PAN-No' |
                                    translate}}</small>
                                <p> <b><span name="gstNumber" id="gstNumber" #companyIdNo="ngModel"
                                            [(ngModel)]="juristicPersonProfileModel.companyIdNo">{{panData?.pan}}</span>
                                    </b></p>
                            </ng-container>
                            <ng-container *ngIf="juristicPersonProfileModel.companyIdType?.id == 3 ">
                                <small>{{'citizen-profile-details.citizen-juristic-service-details.Company-CIN-No' |
                                    translate}}</small>
                                <p> <b><span name="cinNumber" id="cinNumber" #companyIdNo="ngModel"
                                            [(ngModel)]="juristicPersonProfileModel.companyIdNo">{{cinData?.cin}}</span>
                                    </b></p>
                            </ng-container>

                        </div>
                    </div>

                    <ng-container *ngIf="juristicPersonProfileModel.companyIdType?.id == 1 ">
                        <div class="bg-light p-3 companyotherdetails">
                            <div class="row">
                                <div class="col-md-3">
                                    <label><small>{{'citizen-profile-details.citizen-juristic-service-details.legal-Name-Business'
                                            |
                                            translate}} </small> </label>
                                    <p><b>{{legalNameOfBusiness}}</b> </p>
                                </div>
                                <div class="col-md-3">
                                    <label><small>{{'citizen-profile-details.citizen-juristic-service-details.principal-Place-Business'
                                            | translate}} </small> </label>
                                    <p><b>{{gstData?.location}}</b> </p>
                                </div>
                                <div class="col-md-3">
                                    <label><small>{{'citizen-profile-details.citizen-juristic-service-details.additional-Place-Business'
                                            | translate}} </small> </label>
                                    <p><b>{{gstData?.streetCode}}</b> </p>
                                </div>

                                <div class="col-md-3">
                                    <label><small>{{'citizen-profile-details.citizen-juristic-service-details.state-jurisdiction'
                                            |
                                            translate}} </small> </label>
                                    <p><b>{{gstData?.stateJurisdiction}} </b> </p>
                                </div>



                                <div class="col-md-3">
                                    <label><small>{{'citizen-profile-details.citizen-juristic-service-details.center-Jurisdiction'
                                            |
                                            translate}}</small> </label>
                                    <p><b>{{gstData?.centreJurisdiction}}</b> </p>
                                </div>
                                <div class="col-md-3">
                                    <label><small>{{'citizen-profile-details.citizen-juristic-service-details.registration-date'
                                            |
                                            translate}} </small> </label>
                                    <p><b>{{gstData?.registrationDate}}</b> </p>
                                </div>
                                <div class="col-md-3">
                                    <label><small>{{'citizen-profile-details.citizen-juristic-service-details.constitution-of-Business'
                                            | translate}} </small> </label>
                                    <p><b>{{gstData?.constitutionOfBusiness}}</b> </p>
                                </div>
                                <div class="col-md-3">
                                    <label><small>{{'citizen-profile-details.citizen-juristic-service-details.taxpayerType'
                                            |
                                            translate}} </small> </label>
                                    <p><b>{{gstData?.taxPayerType}}</b> </p>
                                </div>
                                <div class="col-md-3">
                                    <label><small>{{'citizen-profile-details.citizen-juristic-service-details.GSTIN-status'
                                            |
                                            translate}} </small> </label>
                                    <p><b>{{gstData?.status}}</b> </p>
                                </div>
                                <div class="col-md-9">
                                    <label><small> {{'address' | translate}} </small> </label>
                                    <p><b>{{gstData?.streetName}} {{gstData?.location}} </b> </p>
                                </div>



                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="juristicPersonProfileModel.companyIdType?.id == 2 ">
                        <div class="bg-light p-3 companyotherdetails">
                            <div class="row">
                                <div class="col-md-3">
                                    <label><small>{{'citizen-profile-details.citizen-juristic-service-details.registration-number'
                                            |
                                            translate}} </small><span
                                            style="color: red;">*</span> </label>
                                    <input required name="registrationNumber" #registrationNumber="ngModel"
                                        [(ngModel)]="juristicPersonProfileModel.registrationNo" type="text"
                                        [ngClass]="{ 'is-invalid': juristicDetailHtml1.submitted && registrationNumber.invalid }"
                                        class="form-control" [appCopyPastValidation]="true" maxlength="60" (contextmenu)="false;">
                                        <div *ngIf="registrationNumber.invalid && juristicDetailHtml1.submitted"
                                        class="invalid-feedback">
                                        <div *ngIf="registrationNumber.errors?.required">
                                            {{'required' | translate}}</div>
                                    </div>
                                    <!-- <p><b>{{cinData?.cin}}</b> </p> -->
                                </div>
                                <div class="col-md-3">
                                    <label><small>{{'citizen-profile-details.citizen-juristic-service-details.Company-Name'
                                            |
                                            translate}} </small> <span
                                            style="color: red;">*</span></label>
                                    <input required name="companyName" #companyName="ngModel"
                                        [(ngModel)]="juristicPersonProfileModel.companyName" type="text"
                                        [ngClass]="{ 'is-invalid': juristicDetailHtml1.submitted && companyName.invalid }"
                                        class="form-control" [appCopyPastValidation]="true" maxlength="250" (contextmenu)="false;">
                                        <div *ngIf="companyName.invalid && juristicDetailHtml1.submitted"
                                        class="invalid-feedback">
                                        <div *ngIf="companyName.errors?.required">
                                            {{'required' | translate}}</div>
                                    </div>
                                </div> <!-- <p><b>{{cinData?.companyName}}</b> </p> -->
                                <div class="col-md-3">
                                    <label><small>{{'citizen-profile-details.citizen-juristic-service-details.company-status'
                                            |
                                            translate}}</small><span
                                            style="color: red;">*</span> </label>
                                    <input required name="companyStatus" #companyStatus="ngModel"
                                    [ngClass]="{ 'is-invalid': juristicDetailHtml1.submitted && companyStatus.invalid }"
                                        [(ngModel)]="juristicPersonProfileModel.companyStatus" type="text"
                                        class="form-control" [appCopyPastValidation]="true" maxlength="20" (contextmenu)="false;">
                                        <div *ngIf="companyStatus.invalid && juristicDetailHtml1.submitted"
                                        class="invalid-feedback">
                                        <div *ngIf="companyStatus.errors?.required">
                                            {{'required' | translate}}</div>
                                    </div>
                                    <!-- <p><b>{{cinData?.companyStatus}}</b> </p> -->
                                </div>
                                <!-- <div class="col-md-3"> 
                                    <label><small>{{'citizen-profile-details.citizen-contact-details.e-mail' |
                                            translate}}
                                        </small> </label>
                                    <input required name="companyEmail" #companyEmail="ngModel"
                                        [(ngModel)]="juristicPersonProfileModel.companyEmail" type="text" class="form-control"
                                        [appCopyPastValidation]="true" (contextmenu)="false;" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" maxlength="60"
                                        [ngClass]="{ 'is-invalid': juristicDetailHtml.submitted && companyEmail.invalid }">
                                   
                                    <div *ngIf="companyEmail.touched && companyEmail.invalid" class="invalid-feedback-1">
                                        <div *ngIf="companyEmail.errors?.pattern">
                                            {{'citizen-profile-details.citizen-contact-details.mail-validate' | translate}}
                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-md-3">
                                    <label><small>{{'citizen-profile-details.citizen-juristic-service-details.incorporationDate'
                                            |
                                            translate}} </small><span
                                            style="color: red;">*</span> </label>
                                    <input required name="dateOfIncorp" #dateOfIncorp="ngModel"
                                    [ngClass]="{ 'is-invalid': juristicDetailHtml1.submitted && dateOfIncorp.invalid }"
                                        [(ngModel)]="juristicPersonProfileModel.dateOfIncorp" type="date"
                                        class="form-control" [appCopyPastValidation]="true">
                                        <div *ngIf="dateOfIncorp.invalid && juristicDetailHtml1.submitted"
                                        class="invalid-feedback">
                                        <div *ngIf="dateOfIncorp.errors?.required">
                                            {{'required' | translate}}</div>
                                    </div>
                                    <!-- <p><b>{{cinData?.incorporationDate}}</b> </p> -->
                                </div>
                                <div class="col-md-3">
                                    <label><small>{{'citizen-profile-details.citizen-juristic-service-details.registeredAddress'
                                            |
                                            translate}} </small><span
                                            style="color: red;">*</span> </label>
                                    <input required name="registeredAddress" #registeredAddress="ngModel"
                                    [ngClass]="{ 'is-invalid': juristicDetailHtml1.submitted && registeredAddress.invalid }"
                                        [(ngModel)]="juristicPersonProfileModel.registeredAddress" type="text"
                                        class="form-control" [appCopyPastValidation]="true" maxlength="500" (contextmenu)="false;">
                                        <div *ngIf="registeredAddress.invalid && juristicDetailHtml1.submitted"
                                        class="invalid-feedback">
                                        <div *ngIf="registeredAddress.errors?.required">
                                            {{'required' | translate}}</div>
                                    </div>
                                    <!-- <p><b>{{cinData?.registeredAddress}}</b> </p> -->
                                </div>
                                <div class="col-md-3">
                                    <label><small>{{'citizen-profile-details.citizen-juristic-service-details.registeredContactNo'
                                            |
                                            translate}} </small><span
                                            style="color: red;">*</span> </label>
                                    <input required name="registeredContactNo" #registeredContactNo="ngModel"
                                    [ngClass]="{ 'is-invalid': juristicDetailHtml1.submitted && registeredContactNo.invalid }"
                                        [(ngModel)]="juristicPersonProfileModel.companyPhoneNo" type="number"
                                        class="form-control" [appCopyPastValidation]="true" maxlength="10" (contextmenu)="false;" pattern="^[6-9]\d{9}$">
                                    <!-- <p><b>{{cinData?.registeredContactNo}}</b> </p> -->
                                    <div *ngIf="registeredContactNo.invalid && juristicDetailHtml1.submitted"
                                    class="invalid-feedback">
                                    <div *ngIf="registeredContactNo.errors?.required">
                                        {{'required' | translate}}</div>
                                </div>
                                </div>
                                
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="juristicPersonProfileModel.companyIdType?.id == 3 ">
                        <div class="bg-light p-3 companyotherdetails">
                            <div class="row">
                                <div class="col-md-3">
                                    <label><small>{{'citizen-profile-details.citizen-juristic-service-details.registration-number'
                                            |
                                            translate}} </small> </label>
                                    <p><b>{{cinData?.cin}}</b> </p>
                                </div>
                                <div class="col-md-3">
                                    <label><small>{{'citizen-profile-details.citizen-juristic-service-details.Company-Name'
                                            |
                                            translate}} </small> </label>
                                    <p><b>{{cinData?.companyName}}</b> </p>
                                </div>
                                <div class="col-md-3">
                                    <label><small>{{'citizen-profile-details.citizen-juristic-service-details.company-status'
                                            |
                                            translate}}</small> </label>
                                    <p><b>{{cinData?.companyStatus}}</b> </p>
                                </div>
                                <div class="col-md-3">
                                    <label><small>{{'citizen-profile-details.citizen-contact-details.e-mail' |
                                            translate}}
                                        </small> </label>
                                    <p><b>{{cinData?.email}}</b> </p>
                                </div>
                                <div class="col-md-3">
                                    <label><small>{{'citizen-profile-details.citizen-juristic-service-details.financialAuditStatus'
                                            |
                                            translate}} </small> </label>
                                    <p><b>{{cinData?.financialAuditStatus}}</b> </p>
                                </div>
                                <div class="col-md-3">
                                    <label><small>{{'citizen-profile-details.citizen-juristic-service-details.incorporationDate'
                                            |
                                            translate}} </small> </label>
                                    <p><b>{{cinData?.incorporationDate}}</b> </p>
                                </div>
                                <div class="col-md-3">
                                    <label><small>{{'citizen-profile-details.citizen-juristic-service-details.registeredAddress'
                                            |
                                            translate}} </small> </label>
                                    <p><b>{{cinData?.registeredAddress}}</b> </p>
                                </div>
                                <div class="col-md-3">
                                    <label><small>{{'citizen-profile-details.citizen-juristic-service-details.registeredContactNo'
                                            |
                                            translate}} </small> </label>
                                    <p><b>{{cinData?.registeredContactNo}}</b> </p>
                                </div>
                                <div class="col-md-3">
                                    <label><small>{{'citizen-profile-details.citizen-juristic-service-details.rocCode' |
                                            translate}} </small> </label>
                                    <p><b>{{cinData?.rocCode}}</b> </p>
                                </div>
                                <div class="col-md-9">
                                    <label><small> {{'address' | translate}} </small> </label>
                                    <p><b>{{gstData?.streetName}} {{gstData?.location}} </b> </p>
                                </div>

                            </div>
                        </div>
                    </ng-container>


                    <div class="bg-light p-2 pb-3 companyotherdetails mt-1">
                        <div class="row">
                            <div class="col-md-3">
                                <label><small> {{'legalEntityType' | translate}}</small> <span
                                        style="color: red;">*</span></label>
                                <ng-select (change)="onChangeLegalEntityType($event)" name="legalEntityTypeReferenceId"
                                    placeholder="{{'selectlegalEntityType' | translate}}" required
                                    #legalEntityTypeReferenceId="ngModel"
                                    [(ngModel)]="juristicPersonProfileModel.legalEntityTypeReferenceId"
                                    [ngClass]="{ 'is-invalid': juristicDetailHtml1.submitted && legalEntityTypeReferenceId.invalid }">
                                    <!-- <option selected disabled value="">{{'selectlegalEntityType' | translate}}</ng-option> -->
                                    <ng-option *ngFor="let legal of legalEntityTypesList" [value]="legal.id">{{lan == 0
                                        ? legal?.legalEntityNameEn : legal?.legalEntityNameHi}}</ng-option>
                                </ng-select>
                                <div *ngIf="legalEntityTypeReferenceId.invalid && juristicDetailHtml1.submitted"
                                    class="invalid-feedback">
                                    <div *ngIf="legalEntityTypeReferenceId.errors?.required">
                                        {{'required' | translate}}</div>
                                </div>
                            </div>
                            <div class="col-md-3" *ngIf="bankDiv">
                                <label><small> {{'bankName' | translate}} </small>
                                    <span style="color: red;">*</span></label>
                                <ng-select name="bankId" placeholder="{{'selectbankName' | translate}}" required
                                    #bankId="ngModel" [(ngModel)]="juristicPersonProfileModel.bankId"
                                    [ngClass]="{ 'is-invalid': juristicDetailHtml1.submitted && bankId.invalid }">
                                    <!-- <ng-option selected disabled  value="">{{'selectbankName' | translate}}</ng-option> -->
                                    <ng-option *ngFor="let bankList of bankMasterList"
                                        [value]="bankList.id">{{bankList.bankName}} </ng-option>
                                </ng-select>
                                <div *ngIf="bankId.invalid && juristicDetailHtml1.submitted" class="invalid-feedback">
                                    <div *ngIf="bankId.errors?.required">
                                        {{'required' | translate}}</div>
                                </div>
                            </div>
                            <div class="col-md-3" *ngIf="depDiv">
                                <label><small> {{'departmentName' | translate}} </small>
                                    <span style="color: red;">*</span></label>
                                <ng-select name="departmentId" placeholder="{{'selectDepartmentName' | translate}}" required
                                    #departmentId="ngModel" [(ngModel)]="juristicPersonProfileModel.departmentId"
                                    [ngClass]="{ 'is-invalid': juristicDetailHtml1.submitted && bankId.invalid }">
                                    <!-- <ng-option selected disabled  value="">{{'selectbankName' | translate}}</ng-option> -->
                                    <ng-option *ngFor="let depList of departmentList"
                                        [value]="depList.id">{{lan==0?depList.departmentName:depList?.departmentNameHi}} </ng-option>
                                </ng-select>
                                <div *ngIf="departmentId.invalid && juristicDetailHtml1.submitted" class="invalid-feedback">
                                    <div *ngIf="departmentId.errors?.required">
                                        {{'required' | translate}}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="w-100 mt-3"></div>

                    <fieldset class="legend-section">
                        <legend class="sidebar__widget-title">
                            {{'citizen-profile-details.citizen-juristic-service-details.company-authperson-details' |
                            translate}}
                        </legend>

                        <div class="row">
                            <div class="col-xl-3 col-sm-6">
                                <div class="form-group">
                                    <label>{{'citizen-profile-details.citizen-juristic-service-details.authorized-person-name'
                                        | translate}}
                                        <span style="color: red;">*</span>
                                    </label>

                                    <input type="text" class="form-control " name="authorizedPersonName" required
                                        #authorizedPersonName="ngModel"
                                        placeholder="{{'citizen-profile-details.citizen-juristic-service-details.authorized-person-name'| translate}}"
                                        [(ngModel)]="juristicPersonProfileModel.authorizedPersonName"
                                        class="form-control" maxlength="60" autocomplete="off"
                                        [appCopyPastValidation]="true" alpha
                                        [ngClass]="{ 'is-invalid': juristicDetailHtml1.submitted && authorizedPersonName.invalid }">

                                    <div *ngIf="authorizedPersonName.invalid && juristicDetailHtml1.submitted"
                                        class="invalid-feedback">
                                        <div *ngIf="authorizedPersonName.errors?.required">
                                            {{'required' | translate}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-sm-6" *ngIf="depDiv">
                                <div class="form-group">
                                    <label>{{'citizen-profile-details.citizen-juristic-service-details.authorized-person-designation'
                                        | translate}}
                                        <span style="color: red;">*</span>
                                    </label>
                            
                                    <input type="text" class="form-control " name="authorizedPersonDesignation" required
                                        #authorizedPersonDesignation="ngModel"
                                        placeholder="{{'citizen-profile-details.citizen-juristic-service-details.authorized-person-designation'| translate}}"
                                        [(ngModel)]="juristicPersonProfileModel.authorizedPersonDesignation" class="form-control" maxlength="60"
                                        autocomplete="off" [appCopyPastValidation]="true" alpha
                                        [ngClass]="{ 'is-invalid': juristicDetailHtml1.submitted && authorizedPersonDesignation.invalid }">
                            
                                    <div *ngIf="authorizedPersonDesignation.invalid && juristicDetailHtml1.submitted" class="invalid-feedback">
                                        <div *ngIf="authorizedPersonDesignation.errors?.required">
                                            {{'required' | translate}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-sm-6" *ngIf="depDiv">
                                <div class="form-group">
                                    <label>{{'citizen-profile-details.citizen-juristic-service-details.office-email'
                                        | translate}}
                                        <span style="color: red;">*</span>
                                    </label>
                                    <input minlength="1" maxlength="100" type="email" class="form-control" name="authorizedPersonEmail" required
                                        (contextmenu)="false;" [appCopyPastValidation]="true" #authorizedPersonEmail="ngModel"
                                        pattern="^[a-zA-Z0-9_.+-]+@(([a-z]+.gov|nic)).in$" 
                                        placeholder="{{'citizen-profile-details.citizen-juristic-service-details.office-email' | translate}}"
                                        [(ngModel)]="juristicPersonProfileModel.authorizedPersonEmail"
                                        [ngClass]="{'is-invalid': juristicDetailHtml1.submitted && authorizedPersonEmail.invalid}">
                                    
                                    <div *ngIf="authorizedPersonEmail.invalid && juristicDetailHtml1.submitted" class="invalid-feedback">
                                        <div *ngIf="authorizedPersonEmail.errors?.required">{{'mandatory' | translate}}</div>
                            
                                        <div *ngIf="authorizedPersonEmail.errors?.pattern">{{'allow-mp-gov-in' | translate}}</div>
                                    </div>
                                </div>
                            </div>











                            <div class="col-xl-3 col-sm-6">
                                <div class="form-group">
                                    <label>
                                        {{'citizen-profile-details.citizen-juristic-service-details.upload-document-authorization'
                                        | translate}} <span style="color: red;">*</span>
                                    </label>


                                    <ng-template #popContent>{{'file-note' | translate}}</ng-template>
                                    <i class="fa fa-info-circle" [ngbPopover]="popContent"
                                        triggers="mouseenter:mouseleave"></i>


                                    <input type="file" id='documentPath' required name="documentPath" accept=".pdf"
                                        (change)="fileUploadEvent($event, 1)" #documentPath="ngModel"
                                        [ngClass]="{ 'is-invalid': juristicDetailHtml1.submitted && documentPath.invalid }"
                                        [(ngModel)]="juristicPersonProfileModel.documentPath" class="form-control ">
                                                                    
                                    <div *ngIf="step3clear" style="overflow-x: auto;">
                                        <small>{{juristicPersonProfileModel.documentName?juristicPersonProfileModel.documentName:''}}</small>
                                    </div>
                                    <div *ngIf="documentPath.invalid && juristicDetailHtml1.submitted "
                                        class="invalid-feedback">
                                        <div *ngIf="documentPath.errors?.required">
                                            {{'required' | translate}} </div>
                                    </div>

                                </div>
                            </div>
                            <!-- <div class="col-xl-4 col-md-6">
                                <div class="form-group">
                                    <label>{{'citizen-profile-details.citizen-juristic-service-details.upload-document-undertaking'
                                        | translate}} <span style="color: red;">*</span> </label>
                                   
                                    <ng-template #popContent>{{'file-note' | translate}}</ng-template>
                                    <i class="fa fa-info-circle" [ngbPopover]="popContent"
                                        triggers="mouseenter:mouseleave"></i>
                                    <input type="file" id='undertakingDocumentPath' required
                                        name="undertakingDocumentPath" accept=".pdf"
                                        (change)="fileUploadEvent($event, 2)"
                                        [ngClass]="{ 'is-invalid': juristicDetailHtml1.submitted && undertakingDocumentPath.invalid }"
                                        #undertakingDocumentPath="ngModel"
                                        [(ngModel)]="juristicPersonProfileModel.undertakingDocumentPath"
                                        class="form-control ">
                                    <div *ngIf="step3clear" style="overflow-x: auto;">
                                        <small>{{juristicPersonProfileModel.undertakingDocumentName?juristicPersonProfileModel.undertakingDocumentName:''}}</small>
                                    </div>
                                    <div *ngIf="undertakingDocumentPath.invalid && juristicDetailHtml1.submitted "
                                        class="invalid-feedback">
                                        <div *ngIf="undertakingDocumentPath.errors?.required">
                                            {{'required' | translate}} </div>
                                    </div>

                                </div>
                            </div> -->
                              <div class="col-xl-4 col-md-6">
                                <div class="form-group">
                                    <a style="text-decoration: underline; color: blue;" (click)="pdfView()">
                                        {{'citizen-profile-details.citizen-juristic-service-details.download-document-authorization'
                                        | translate}}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="card-footer">
                    <div class="row">
                        <div class="col-lg-12 text-center">
                            <button mat-flat-button style="float: left;" (click)="goBack(3)">{{'goback' |
                                translate}}</button>
                            <button type="submit" mat-raised-button class="btn btn-primary"
                                style="float: right;">{{'submit'
                                | translate}}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>







        <div *ngIf="step4">
            <app-kyc-details (verifyAdharOtpOut)="verifyAdharOtpOut($event)" (verifyPanOut)="verifyPanOut($event)"
                [kycSource]="kycSource" [kycObject]="kycObject">
            </app-kyc-details>
            <button mat-raised-button (click)="goBack(4)" class="mt-2">{{'goback' | translate}}</button>
        </div>
        <div *ngIf="step5">
            <app-demographic-details [kycSource]="kycSource" [kycObject]="kycObject"
                [juristicPersonProfileModel]="juristicPersonProfileModel" [mobileNo]="mobileNo" [citizenType]="citizenType"
                [isIndian]="1" (demographicDetailsSaveOut)="demographicDetailsSaveOut($event)">
            </app-demographic-details>
            <button mat-flat-button class="mt-2" (click)="goBack(5)">{{'goback' | translate}}</button>
        </div>

    </div>




</div>
<ngx-ui-loader></ngx-ui-loader>