import { DeedTypeBean } from "./deed-type-bean";

export class DeedInstrumentBean {

  id: number;
  instrumentCode: string;
  instrumentName: string;
  instrumentNameEn: string;
  instrumentNameHi: string;
  instrumentDescEn: string;
  instrumentDescHi: string;
  deedTypeId: DeedTypeBean;
  status: boolean;
  propReqFlag: boolean;
  propValReqFlag: boolean;
  upkarFlag: boolean;
  municipalFlagNpv: boolean;
  principalStampDutyType: string;
  principalStampDuty: number;
  municipalDuty: number;
  janpadDuty: number;
  upkar: number;
  minStampDuty: number;
  maxStampduty: number;
  registrationFeeType: string;
  registrationFee: number;
  minRegistrationFee: number;
  maxRegistrationFee: number;
  conditionDetails: string;
  instrumentCondition: string;
  registrationOptionalCompulsory: string;
  createdBy: number;
  createdDate: Date;
  modifiedBy: number;
  modifiedDate: Date;
  addPropertyBttn: boolean;
  addPropertyApplicable: boolean;
  instCancellationFlag: boolean = false;
  natureOfInstrument: string
  isMovableProp: boolean;
  bookTypeId: string;
  stepperLableNameEn: string;
  stepperLableNameHi: string;
  paymentDetailsFlag: boolean;
  particularDetailsFlag: boolean;
  firstParty: number;
  secondParty: number;
  multipleParty: number;
  deedDraftingReqFlag: boolean;

  isDutyMovableProp: boolean;



  recitalFlag: number;
  mutationFeesFlag: number;
  registrationOptionalCompulsoryHi: string;
  principalStampDutyTypeHi: string;
  cyberTehsilFlag: boolean;
  isAssistedBkp: number;
  isCompApplicable: number;
  isAssisted: number;
  isNonAssisted: number;
  instrumentId: number; //for representative case only
  ifscCode: string;
}
