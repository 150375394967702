import { Component, OnInit, OnDestroy } from '@angular/core';
import { SrOfficeDisplayWebSocketService } from './sr-office-display-websocket.service'; // Update with the correct path
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { TranslateService } from '@ngx-translate/core';
import { SrOfficeDisplayService } from './sr-office-display.service'; // Update with the correct path
import { AppSetting, AppSettingService } from 'projects/common-lib/src/lib/helper-lib/app.setting'; // Update with the correct path
import { Demography } from 'projects/common-lib/src/lib/models/citizen.login.model/citizen.profile.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'projects/common-lib/src/lib/helper-lib/services/common.service';

@Component({
  selector: 'app-sr-office-display',
  templateUrl: './sr-office-display.component.html',
  styleUrls: ['./sr-office-display.component.scss']
})
export class SrOfficeDisplayComponent implements OnInit, OnDestroy {
  lan: number;
  srOfficeDemoId: number;
  srOfficeList: Demography[] = [];
  makerList: any[];
  checkerList: any[];
  waitList: any[];
  districtNameEn: string;
  districtNameHi: string;
  srofficeNameEn: string;
  srofficeNameHi: string;
  date: string;
  qrCode: string;
  langOptionFlag: number = 1;
  private igrsServicesUrl: string;
  env: string;
   private settings!: AppSetting;

  constructor(
    private SrOfficeDisplayWebSocketService: SrOfficeDisplayWebSocketService,
    private srOfficeDisplayService: SrOfficeDisplayService,
    private appSettingService: AppSettingService,
    private translateService: TranslateHEService,
    private translate: TranslateService,
      private ngxService: NgxUiLoaderService,
      public commonService: CommonService
  ) {
    this.translateService.currentApprovalStageMessage.subscribe(msg => {
      this.lan = msg;
      this.translate.use(msg == 1 ? 'hi' : 'en');
    });
  }

  ngOnInit(): void {
 
    this.newClassAdd();
    this.loadSROffices();

    this.appSettingService.getSetting().subscribe((settings: { igrs_services_url: string }) => {
      this.igrsServicesUrl = settings.igrs_services_url;

      // Proceed with existing selected SR Office if any
      let srOfficeDemoId = Number(localStorage.getItem("selectedSROffice"));
  
      if (srOfficeDemoId != undefined && srOfficeDemoId != 0 && !Number.isNaN(srOfficeDemoId)) {
             
        this.srOfficeDemoId = srOfficeDemoId;
        this.onChangeSrOffice();
         
      }
    });
     this.settings = this.commonService.getAppSetting();
 this.env = this.settings.env;
  }

  ngOnDestroy(): void {
    this.SrOfficeDisplayWebSocketService.close();
  }

  loadSROffices() {
    this.srOfficeDisplayService.getSROffices().subscribe((res: { responseData: Demography[]; }) => {
      this.srOfficeList = res.responseData;
    });
  }

  onChangeSrOffice() {
    this.ngxService.startLoader('loaderSROffice');

    if (this.srOfficeDemoId != undefined && this.srOfficeDemoId != 0 && !Number.isNaN(this.srOfficeDemoId)) {
      localStorage.setItem("selectedSROffice", this.srOfficeDemoId + "");
    
      const wsUrl = this.igrsServicesUrl.replace(/^http/, 'ws') + "common/srDisplayData";
      this.SrOfficeDisplayWebSocketService.connect(wsUrl, this.srOfficeDemoId);
    
      this.SrOfficeDisplayWebSocketService.messages$.subscribe(res => {

        this.qrCode = 'data:image/png;base64,' + res.qrCode;
        this.date = res.date;
        this.srofficeNameEn = res.srofficeNameEn;
        this.srofficeNameHi = res.srofficeNameHi;
        this.districtNameEn = res.districtNameEn;
        this.districtNameHi = res.districtNameHi;
        this.makerList = res.makerList;
        this.checkerList = res.checkerList;
        this.waitList = res.waitList;
        this.ngxService.stopLoader('loaderSROffice');
      });
    }   
  }

  changeLanguageToHindi(lan: any) {
    this.translate.use("hi");
    this.langOptionFlag = 1;
    this.lan = lan;
    sessionStorage.setItem("selectedLanguage", "hi");
    localStorage.setItem("selectedLanguage", "hi");
    this.translateService.updateApprovalMessage(lan);
  }

  changeLanguageToEnglish(lan: any) {
    this.translate.use("en");
    this.langOptionFlag = 0;
    this.lan = lan;
    sessionStorage.setItem("selectedLanguage", "en");
    localStorage.setItem("selectedLanguage", "en");
    this.translateService.updateApprovalMessage(lan);
  }

  newClassAdd() {
    const bodyTag = document.body;
    bodyTag.classList.add("SROfficeMobile");
  }
}
