<div class="position-relative">
    <div class="fid-header"></div>
    <div class="breadcrumb-content">
        <div class="container">
            <div class="d-flex justify-content-between">
                <h2>
                     {{'detailsPages.intVideoKYC' | translate}}
                </h2>
                <!-- <button mat-button class="btn btn-primary" id="sticky_Div">
                   
                    {{'detailsPages.processForIntVideoKYC' | translate}}
                </button> -->
            </div>
        </div>
    </div>

    <div class="container mb-3">
        <mat-card class="example-card">
            <mat-card-title></mat-card-title>
            <mat-card-content class="mat-card-content">
                <div class="row">
                    <div class="col-md-6">
                        <mat-accordion class="example-headers-align">
                            <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div *ngIf="lan == 0">What is Interactive Video KYC?</div>
                                        <div *ngIf="lan == 1">इंटरएक्टिव वीडियो केवाईसी क्या है?</div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="p-3">
                                    <div *ngIf="lan == 0">
                                        <p>
                                            Interactive Video refers to a process where verification is
                                            conducted through a video call with interaction between the party and the
                                            verifying authority (Sub Registrar).
                                        </p>
                                        <p>
                                            Based on the selected nature of the document / instrument, the system will
                                            display this option to a party during the initiation of the Registry.
                                        </p>
                                    </div>
                                    <div *ngIf="lan == 1">
                                        <p>
                                            इंटरएक्टिव वीडियो एक ऐसी प्रक्रिया को संदर्भित करता है जहां पक्षकारो
                                            और सत्यापन करने वाले प्राधिकारी (उप पंजीयक) के बीच वीडियो कॉल के माध्यम से
                                            सत्यापन किया जाता है ।
                                        </p>
                                        <p>
                                            दस्तावेज़ / इंस्ट्रूमेंट की चयनित प्रकृति के आधार पर, सिस्टम पक्षकारो को यह
                                            विकल्प प्रदर्शित करेगा।
                                        </p>
                                    </div>
                                </div>
                            </mat-expansion-panel>

                            <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div *ngIf="lan == 0">Who can do interactive videos KYC?</div>
                                        <div *ngIf="lan == 1">
                                            इंटरएक्टिव वीडियो केवाईसी कौन कर सकता है?
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="p-3">
                                    <div *ngIf="lan == 0">
                                        <p>
                                            In Sampada 2.0, below are the conditions to conduct Interactive
                                            Video for users:
                                        </p>
                                        <ol>
                                            <li>
                                                The nature of the document / instrument should allow for
                                                Interactive Video KYC.
                                            </li>
                                            <li>
                                                If a property transaction is involved in the selected document /
                                                instrument, then fully detailed information about the property should be
                                                available from the custodian department (CLR/UADD/PNRD).
                                            </li>
                                            <li>
                                                All party persons should be registered in the portal through Aadhaar
                                                e-KYC.
                                            </li>
                                            <li>
                                                In an initiated registry document, there should not be more than 10
                                                party persons.
                                            </li>
                                        </ol>
                                    </div>
                                    <div *ngIf="lan == 1">
                                        <p>
                                            सम्पदा 2.0 में, उपयोगकर्ताओं के लिए इंटरएक्टिव वीडियो आयोजित करने की
                                            शर्तें नीचे दी गई हैं:
                                        </p>
                                        <ol>
                                            <li>
                                                दस्तावेज़ / इंस्ट्रूमेंट के लिए इंटरैक्टिव वीडियो की अनुमति होनी
                                                चाहिए।
                                            </li>
                                            <li>
                                                यदि चयनित दस्तावेज़ / इंस्ट्रूमेंट में संपत्ति का लेनदेन शामिल है, तो
                                                संपत्ति के बारे में पूरी विस्तृत जानकारी कस्टोडियन विभाग
                                                (सीएलआर/यूएडीडी/पीएनआरडी) से उपलब्ध होनी चाहिए।
                                            </li>
                                            <li>
                                                सभी पक्षकारो के व्यक्तियों को आधार ई-केवाईसी के माध्यम से पोर्टल में
                                                पंजीकृत होना चाहिए।
                                            </li>
                                            <li>
                                                रजिस्ट्री दस्तावेज़ में 10 से अधिक पक्षकार नहीं होने चाहिए।
                                            </li>

                                        </ol>
                                    </div>
                                </div>
                            </mat-expansion-panel>

                            <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div *ngIf="lan == 0">Pre-requisite for Non Interactive Video KYC</div>
                                        <div *ngIf="lan == 1">नॉन इंटरएक्टिव वीडियो केवाईसी के लिए पूर्व-आवश्यकता
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="p-3">
                                    <div *ngIf="lan == 0">
                                        <p>To do Interactive Video follow below steps : </p>
                                        <ol>
                                            <li>
                                                Click on the Non Interactive Video link received via SMS,
                                                Email, or WhatsApp, user will be directed to the SAMPADA 2.0
                                                Interactive Video KYC welcome page.

                                            </li>
                                            <li>
                                                Select the language (Hindi / English).
                                            </li>
                                            <li>Accept the term and conditions.</li>
                                            <li>
                                                Click on the 'Proceed' button.
                                            </li>
                                            <li>
                                                Enter the OTP to validate the mobile.
                                            </li>
                                            <li>
                                                The system will ask for permission to use the camera, microphone, and
                                                location. Grant the permissions to proceed.
                                            </li>
                                            <li>
                                                Join video virtual meeting as an individual, group, or all
                                            </li>
                                            <li>
                                                Click on the 'Ok' button.
                                            </li>
                                            <li>
                                                Sub Registrar authenticated the parties by sending an Aadhaar-based OTP
                                                and a question. They also marked the party as recognized or unrecognized
                                                based on the ID card displayed by the party during the 
                                                Interactive Video Conference.
                                            </li>
                                            <li>
                                                Sub Registrar will take a decision on the document based on the document
                                                and information presented by the party. The decision can be to cancel
                                                the slot if all parties were not present in the interactive
                                                video conference, hold, return, refuse, impound, or proceed with the
                                                registry. Once the decision is made, the party will receive
                                                notifications on their SMS, email, and WhatsApp. And the 
                                                Interactive Video Conference call will end.
                                            </li>

                                        </ol>
                                    </div>
                                    <div *ngIf="lan == 1">
                                        <p>सम्पदा 2.0 इंटरएक्टिव वीडियो केवाईसी स्वागत पृष्ठ पर निर्देशित किया
                                            जाएगा ।</p>
                                        <ol>
                                            <li>
                                                भाषा (हिंदी/अंग्रेजी) का चयन करे।

                                            </li>
                                            <li>
                                                मोबाइल को वेरिफाई करने के लिए ओटीपी दर्ज करे।
                                            </li>
                                            <li>
                                                सिस्टम कैमरा, माइक्रोफ़ोन और लोकेशन का उपयोग करने की अनुमति मांगेग। आगे
                                                बढ़ने के लिए अनुमति दें।
                                            </li>
                                            <li>
                                                बैठक को इंडिविजुअल, ग्रुप या सभी में ज्वाइन करे।
                                            </li>
                                            <li>
                                                'ओके' बटन पर क्लिक करें.
                                            </li>
                                            <li>
                                                उप पंजीयक आधार - ओटीपी और प्रश्न भेजकर पक्षकारो को प्रमाणित किया। और
                                                इंटरएक्टिव वीडियो कांफ्रेंस के दौरान पक्षकारो द्वारा प्रदर्शित
                                                आईडी कार्ड के आधार पर पक्षकारो को मान्यता प्राप्त या गैर-मान्यता प्राप्त
                                                के रूप में भी चिह्नित करेगा।
                                            </li>
                                            <li>
                                                उप पंजीयक पक्षकारो द्वारा प्रस्तुत दस्तावेज़ और जानकारी के आधार पर
                                                दस्तावेज़ पर निर्णय लेगा। यदि सभी पक्षकारो इंटरएक्टिव वीडियो
                                                कांफ्रेंस में उपस्थित नहीं थे तो स्लॉट को रद्द करना, होल्ड , रिटर्न ,
                                                रिफ्यूज्ड, इम्पाउंड या रजिस्ट्री को आगे बढ़ने का निर्णय लेगा एक बार
                                                निर्णय हो जाने के बाद, पक्षकार को उनके एसएमएस, ईमेल और व्हाट्सएप पर
                                                सूचनाएं प्राप्त होंगी।
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </mat-expansion-panel>



                        </mat-accordion>
                    </div>
                    <div class="col-md-6">
                        <div class="process_img">
                            <img src="assets/images/EnglishFaclessInteractiveVideoConference.png" alt="PaymentWallet"
                                style="width: 100%" *ngIf="lan == 0" />
                            <img src="assets/images/HindiFacelessInteactiveVideoConference.png" alt="PaymentWallet"
                                style="width: 100%" *ngIf="lan == 1" />
                        </div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>