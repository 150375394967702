import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IUserDetailModel, Citizen, User, CitizenForgot } from "../../../models";
import jwt_decode from "jwt-decode";
import { ResultModel } from "../../../models/api.result.model/result.model";
import { map } from "rxjs/operators";
import moment from "moment";
import { BehaviorSubject, Observable } from "rxjs";
import { AppSetting, AppSettingService } from "../../app.setting";
import { CustomHttpClient } from "../../httpclient/custom.httpclient";
import { TranslateService } from "@ngx-translate/core";
import { CitizenUserProfileDetails } from "../../../models/citizen.login.model/citizen.profile.model";
import { Router } from "@angular/router";
import { AESEncryptDecryptService } from 'projects/common-lib/src/lib/helper-lib/services/aesencrypt-decrypt.service';

@Injectable({ providedIn: "root" })
export class CitizenAuthenticationService {
    private settings!: AppSetting;
    private currentUserSubject: BehaviorSubject<IUserDetailModel>;
    public ud!: IUserDetailModel;
    isUserLoggedIn: boolean = false;
    state: number = 1;
    display: any;
    public timerInterval: any;
    seconds: number = 0;

    constructor(private appSettingsService: AppSettingService, private router: Router, private httpClient: HttpClient, private customeHttpClient: CustomHttpClient, private translate: TranslateService, private _AESEncryptDecryptService: AESEncryptDecryptService) {
        this.currentUserSubject = new BehaviorSubject<IUserDetailModel>(null as any);
        this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
            this.settings = settings;
        });
    }
    public get currentUserValue(): IUserDetailModel {
        return this.currentUserSubject.value;
    }


    getLoginCaptcha() {
        return this.httpClient.get(this.settings.igrs_gateway_url + "common/captcha?type=login-user", { responseType: 'json' });
        //  return this.customeHttpClient.Get(this.settings.igrs_gateway_url + "common/captcha?type=login-user", { responseType: 'json' });
    }
    login(credential: Citizen, lang: string) {
        let encVal = this._AESEncryptDecryptService.encryptInAngular(JSON.stringify(credential));
        return this.customeHttpClient
            .PostApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/authenticate_citizen_user",
                encVal, lang
            ).pipe(
                map((result: any) => {
                    if (result && result.status == 'true') {
                        let decVal = this._AESEncryptDecryptService.decryptInAngular(result.data.responseData);
                        result.data.responseData = JSON.parse(decVal);
                        this.setSession(result);
                    }
                    return result;
                })
            );
    }

    setSession(authResult: any) {

        const token = authResult.token;
        let today: any = Date.now();
        this.isUserLoggedIn = true
        sessionStorage.setItem("token", token);
        sessionStorage.setItem("loginType", "citizen");
        sessionStorage.setItem('isUserLoggedIn', "true");
        const authData: any = jwt_decode(token);
        sessionStorage.setItem("tokenTime", today);
        const expiresAt = moment().add(authData.exp, "second");
        sessionStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()));
        this.currentUserSubject.next(this.setCurrentUserSubject(authResult.data));
    }


    private setCurrentUserSubject(data: any): IUserDetailModel {


        sessionStorage.removeItem("authData");
        sessionStorage.removeItem("featureList");
        sessionStorage.setItem("featureList", JSON.stringify(data.responseData.featureList));
        if (data.responseData != null) {
            this.ud = {
                User_Id: data.responseData.id,
                UserName: data.responseData.userName,
                FullNameHi: data.responseData.nameHi,
                FullNameEn: data.responseData.nameEn,
                LoginType: "citizen"
            };
            sessionStorage.setItem("authData", JSON.stringify(data.responseData));
        }
        // else if (data.responseData.citizen != null) {
        //     
        //     if (this.translate.currentLang == "hi") {
        //         this.ud = {
        //             User_Id: data.responseData.id,
        //             UserName: data.responseData.userName,
        //             FullName: data.responseData.firstNameHi,
        //         };
        //     }
        //     if (this.translate.currentLang == "en") {
        //         this.ud = {
        //             User_Id: data.responseData.id,
        //             UserName: data.responseData.userName,
        //             FullName: data.responseData.firstNameEn,
        //         };
        //     }
        //     sessionStorage.setItem("authData", JSON.stringify(data.responseData.citizen));
        // }
        return this.ud;
    }

    getprofileUsingToken(token: any) {

        const tokenDecoded: any = jwt_decode(token);
        var udata = JSON.parse(sessionStorage.getItem('authData') || '{}');
        if (udata.userName == tokenDecoded.sub) {
            let userNameEn = udata.firstNameEn + " ";
            if (udata.middleNameEn != null) {
                userNameEn += udata.middleNameEn + " ";
            }
            if (udata.lastNameEn != null) {
                userNameEn += udata.lastNameEn + " ";
            }
            let userNameHi = udata.firstNameHi + " ";
            if (udata.middleNameHi != null) {
                userNameHi += udata.middleNameHi + " ";
            }
            if (udata.lastNameHi != null) {
                userNameHi += udata.lastNameHi + " ";
            }

            this.ud = {
                User_Id: udata.id,
                UserName: udata.userName,
                FullNameEn: userNameEn,
                FullNameHi: userNameHi
            };
            this.currentUserSubject.next(this.ud)
        }
        else {
            // this.logout();
            this.logout().subscribe(res => {
                res.status ? console.log("User logged out successfully!") : console.log("Some error during log out.");
            });
        }
    }

    logout() {

        let user: User = {};
        user.userId = this.currentUserValue?.User_Id;
        user.userType = 'CITIZEN';
        return this.customeHttpClient
            .PostApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/logoutUser",
                user, sessionStorage.getItem("selectedLanguage")
            ).pipe(
                map((result: any) => {
                    console.log(result);

                    if (result && result.status == 'true') {
                        this.isUserLoggedIn = false;
                        this.clearsessionStorage();
                        if (window.parent.location.href.includes("admin") || window.parent.location.href.includes("select-office")) {
                            this.router.navigate(['alogin']);
                        } else if (window.parent.location.href.includes("citizen")) {
                            this.router.navigate(['clogin']);
                        } else {
                            this.router.navigate(['clogin']);
                        }
                    }
                    return result;
                },
                    (error: any) => {
                        return error;
                    })
            );
    }

    private getApplicationBaseName(): string {
        return this.settings.baseName;
    }

    public clearsessionStorage() {
        let routeUrl = '';
        if (sessionStorage.getItem('loginType') != undefined && sessionStorage.getItem('loginType') != null && sessionStorage.getItem('loginType') != "citizen") {

            routeUrl = '/clogin'
        } else {
            routeUrl = '/clogin';
        }
        if (sessionStorage.length > 0) {
            sessionStorage.removeItem('isUserLoggedIn');
            sessionStorage.removeItem("loginType");
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("token_data");
            sessionStorage.removeItem("expires_at");
            sessionStorage.removeItem("ud");
            sessionStorage.removeItem("authData");
            sessionStorage.removeItem("featureList");
        }
        if (window.parent.sessionStorage.length > 0) {
            window.parent.sessionStorage.removeItem('isUserLoggedIn');
            sessionStorage.removeItem("loginType");
            window.parent.sessionStorage.removeItem("token");
            window.parent.sessionStorage.removeItem("token_data");
            window.parent.sessionStorage.removeItem("expires_at");
            window.parent.sessionStorage.removeItem("ud");
            window.parent.sessionStorage.removeItem("authData");
            window.parent.sessionStorage.removeItem("featureList");
        }
        this.currentUserSubject.next(null as any);
        this.router.navigate([routeUrl]);
    }

    tokenExpire() {
        this.logout();
    }


    // forgotPassword(credential: Citizen) {
    //     return this.httpClient
    //         .post<ResultModel<any>>(
    //             this.settings.igrs_gateway_url + "common/citizen/forget_citizen_user_password?username=" + credential.userName,
    //             null
    //         ).pipe(map((result: any) => {
    //             
    //             
    //             return result;
    //         })
    //         );
    // }


    citizenProfile(token: string) {
        //
        return this.customeHttpClient
            .PostApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/get_citizen_user_profile",
                token, "En"
            ).pipe(
                map((result: any) => {


                    if (result && result.status == 'true') {
                        this.setSession(result);
                    }
                    return result;
                })
            );
    }

    viewCitizenProfile() {
        //
        return this.customeHttpClient
            .GetApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "citizen/get_citizen_user_profile", null
            ).pipe(
                map((result: any) => {
                    if (result && result.responseStatus == 'true') {
                        let decVal = this._AESEncryptDecryptService.decryptInAngular(result.responseData);
                        result.responseData = JSON.parse(decVal);
                        this.setCurrentUserSubject(result);
                        return result;
                    } else {
                        return result;
                    }
                })
            );
    }


    getUserProfileDetailsByMobileAndUserId(params:any,pathurl:string) {
        //
        return this.customeHttpClient
            .PostApiToken<ResultModel<any>>(
                this.settings.igrs_gateway_url+pathurl+"/getUserProfileDetailsByMobileAndUserId", params
            ).pipe(
                map((result: any) => {

                    if (result && result.responseStatus == 'true') {
                       // this.setCurrentUserSubject(result);
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }


    // getUserAadhaarDetails(param: string) {
    //     return this.customeHttpClient
    //         .GetApi<ResultModel<any>>(
    //             this.settings.igrs_gateway_url + "citizen/get_aadhaar_txn_details", param
    //         ).pipe(
    //             map((result: any) => {
    //                 if (result && result.responseStatus == 'true') {

    //                     return result;
    //                 } else {
    //                     return result;
    //                 }

    //             })
    //         );
    // }

    getUserAadhaarDetailsForCommon(param: string) {

        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/get_aadhaar_txn_details?refTxn=" + param
            ).pipe(
                map((result: any) => {

                    if (result && result.responseStatus == 'true') {
                        let decVal = this._AESEncryptDecryptService.decryptInAngular(result.responseData);
                        result.responseData = JSON.parse(decVal);
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }

    getUserPanDetails(param: any) {
        //
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/get_pan_details?refTxn=" + param
            ).pipe(
                map((result: any) => {

                    if (result && result.responseStatus == 'true') {

                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }

    getDivision() {
        //
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/get_demography_by_parent" + "?parent=" + this.state
            ).pipe(
                map((result: any) => {


                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }

    getDistrict() {
        //
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/get_demography_by_type" + "?type=3"
            ).pipe(
                map((result: any) => {


                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }

    getBlocks(disId: number) {
        //
        return this.customeHttpClient
            .Get<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/get_demography_by_parent" + "?parent=" + disId
            ).pipe(
                map((result: any) => {


                    if (result && result.responseStatus == 'true') {
                        return result;
                    } else {
                        return result;
                    }

                })
            );
    }

    getOtp(citizen: Citizen) {
        let encVal = this._AESEncryptDecryptService.encryptInAngular(JSON.stringify(citizen));
        return this.customeHttpClient.Post<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/generate_otp_for_registration", encVal
        ).pipe(
            map((result: any) => {
                ;

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    getOnlyOtp(citizen: Citizen) {
        let encVal = this._AESEncryptDecryptService.encryptInAngular(JSON.stringify(citizen));
        return this.customeHttpClient.Post<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/generate_otp", encVal
        ).pipe(
            map((result: any) => {
                ;

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    getOtpForRegisteredUser(citizen: Citizen) {
        let encVal = this._AESEncryptDecryptService.encryptInAngular(JSON.stringify(citizen));
        return this.customeHttpClient.Post<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/generate_otp_for_registered_user", encVal
        ).pipe(
            map((result: any) => {
                ;

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    getOtpForQuickReg(citizen: Citizen): Observable<any> {
        let encVal = this._AESEncryptDecryptService.encryptInAngular(JSON.stringify(citizen));
        return this.customeHttpClient.Post<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/generate_otp_for_quick_registration", encVal
        ).pipe(
            map((result: any) => {
                ;

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    getOtpForQuickRegForEmail(citizen: Citizen): Observable<any> {
        let encVal = this._AESEncryptDecryptService.encryptInAngular(JSON.stringify(citizen));
        return this.customeHttpClient.Post<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/generate_otp_for_user_on_email", encVal
        ).pipe(
            map((result: any) => {
                ;

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    verifyOtpForQuickRegForEmail(email: string, otp: string, mobileno: string) {
        return this.customeHttpClient.Get<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/verify_otp_for_user_on_email?email=" + email + "&mobileno=" + mobileno + "&usertype=citizen&otp=" + otp
        ).pipe(
            map((result: any) => {
                ;

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    //  getOtpForForget(mobileNo:string):Observable<any>{
    //     return this.customeHttpClient.Get<ResultModel<any>>(
    //          this.settings.igrs_gateway_url + "common/generate_otp_for_user?username="+mobileNo+"&usertype=citizen"
    //      ).pipe(
    //          map((result: any) => {
    //              ;

    //              if (result && result.responseStatus == 'true') {
    //                  return result;
    //              } else {
    //                  return result;
    //              }

    //          })
    //      );
    //  }

    //  verifyOtpForForget(userName:string, otp:string, captchaId:string, captchaStr:string){
    //     return this.customeHttpClient.Get<ResultModel<any>>(
    //          this.settings.igrs_gateway_url + "common/verify_otp_for_user?username="+userName+"&usertype=citizen&otp="+otp+"&captchaid="+captchaId+"&captchastr="+captchaStr
    //      ).pipe(
    //          map((result: any) => {
    //              ;

    //              if (result && result.responseStatus == 'true') {
    //                  return result;
    //              } else {
    //                  return result;
    //              }

    //          })
    //      );
    //  }

    getOtpGLpublic(citizen: Citizen) {
        let encVal = this._AESEncryptDecryptService.encryptInAngular(JSON.stringify(citizen));
        return this.customeHttpClient.Post<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/generate_otp_for_gl_public_review", encVal
        ).pipe(
            map((result: any) => {
                ;

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }


    verifyOtp(mobileNo: string, otp: string): Observable<any> {
        return this.customeHttpClient.Get<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/verify_otp_for_registration?mobile_no=" + mobileNo + "&otp=" + otp
        ).pipe(
            map((result: any) => {
                ;

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    checkAvailabilityOfUserName(userName: string): Observable<any> {
        return this.customeHttpClient.Get<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/citizen_user_name_exists?username=" + userName
        ).pipe(
            map((result: any) => {
                ;

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }
    checkAvailabilityOfEmail(email: string): Observable<any> {
        return this.customeHttpClient.Get<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/citizen_user_email_exists?email_id=" + email
        ).pipe(
            map((result: any) => {
                ;

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    updateCitizenProfile(userProfile: CitizenUserProfileDetails): Observable<any> {
        let httpHeaders = new HttpHeaders({
            "Content-Type": "application/json",
            "Accept-Language": sessionStorage.getItem("selectedLanguage") || ""
        });
        return this.customeHttpClient.Post<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/save_citizen_user_profile", userProfile, { headers: httpHeaders }
        ).pipe(
            map((result: any) => {
                if (result) {
                    let decVal = this._AESEncryptDecryptService.decryptInAngular(result.responseData);
                    result.responseData = JSON.parse(decVal); 
                    return result;
                }
            },
                (error: any) => {
                    return error;
                })
        );
    }

    saveJuristicPersonDetail(juristicProfile: CitizenUserProfileDetails) {
        let httpHeaders = new HttpHeaders({
            "Content-Type": "application/json",
            "Accept-Language": sessionStorage.getItem("selectedLanguage") || ""
        });
        return this.customeHttpClient.Post<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/save_juristic_person_profile", juristicProfile, { headers: httpHeaders }
        ).pipe(
            map((result: any) => {
                if (result) {
                    let decVal = this._AESEncryptDecryptService.decryptInAngular(result.responseData);
                    result.responseData = JSON.parse(decVal); 
                    return result;
                }
            },
                (error: any) => {
                    return error;
                }
            )
        );
    }

    //      updateCitizenProfile(userProfile:CitizenUserProfileDetails) {
    //       ;
    //      return this.customeHttpClient
    //       .PostApiToken<ResultModel<any>>(
    //           this.settings.igrs_gateway_url + "save_citizen_user_profile",
    //           userProfile
    //       ).pipe(
    //             map((result:any)=>{
    //               if (result){
    //                       return result;
    //               }
    //             },
    //             (error:any)=>{
    //                 
    //                 return error;
    //             }
    //             )
    //       );
    //   }

    getOtpForForget(citizen: Citizen, lang: string) {
        let encVal = this._AESEncryptDecryptService.encryptInAngular(JSON.stringify(citizen));
        return this.customeHttpClient.Post<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/generate_otp_for_user", encVal
        ).pipe(
            map((result: any) => {
                if (result) {
                    return result;
                }
            },
                (error: any) => {

                    return error;
                }
            )
        );
        // pipe(
        //     map((result: any) => {
        //         ;

        //         if (result && result.responseStatus == 'true') {
        //             return result;
        //         } else {
        //             return result;
        //         }

        //     })
        // );
    }

    verifyOtpForForget(userName: string, userId: number, otp: string, captchaId: string, captchaStr: string, status: string) {
        return this.customeHttpClient.Get<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/verify_otp_for_user?username=" + userName + "&user_id=" + userId + "&usertype=citizen&otp=" + otp + "&captchaid=" + captchaId + "&captchastr=" + captchaStr + "&status=" + status
        ).pipe(
            map((result: any) => {
                ;

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    //  submitForgetPassword(userName:string, password:string, confPassword:string){
    //     return this.customeHttpClient.Get<ResultModel<any>>(
    //          this.settings.igrs_gateway_url + "common/reset_password_for_user?username="+userName+"&usertype=citizen&newpassword="+password+"&confirmpassword="+confPassword
    //      ).pipe(
    //          map((result: any) => {
    //              ;
    //              
    //              if (result && result.responseStatus == 'true') {
    //                  return result;
    //              } else {
    //                  return result;
    //              }

    //          })
    //      );
    //  }

    generateOtpForUser(citizen: Citizen) {//citizen
        let encVal = this._AESEncryptDecryptService.encryptInAngular(JSON.stringify(citizen));
        return this.customeHttpClient.Post<ResultModel<any>>(
            //  this.settings.igrs_gateway_url + "common/generate_otp_for_user?username="+mobile+"&usertype=citizen"
            this.settings.igrs_gateway_url + "common/generate_otp_for_user_login", encVal
        ).pipe(
            map((result: any) => {
                ;

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    loginMobileWithOTP(credential: Citizen, lang: string) {
        let encVal = this._AESEncryptDecryptService.encryptInAngular(JSON.stringify(credential));
        return this.customeHttpClient
            .PostApi<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/authenticate_citizen_user_by_mobile",
                encVal, lang
            ).pipe(
                map((result: any) => {

                    if (result && result.status == 'true') {
                        // let responseSTR = JSON.stringify(result);
                        // let responseJSON = JSON.parse(responseSTR);
                        // console.log("responseJSON.data", responseJSON.data.responseData.isFullProfile);
                        // if (responseJSON.data.responseData.isFullProfile == 1)
                        let decVal = this._AESEncryptDecryptService.decryptInAngular(result.data.responseData);
                        result.data.responseData = JSON.parse(decVal);
                        this.setSession(result);
                    }
                    return result;
                })
            );
    }

    checkAadhaarAlreadyExist(kycUniqueToken: any): Observable<any> {
        return this.customeHttpClient.Post<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/citizen_user_aadhaar_exists",
            kycUniqueToken
        ).pipe(
            map((result: any) => {
                ;

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    checkPanAlreadyExist(panNumber: string, mobileNo: string, columnName: string, citizenType: number, companyIdNo: string): Observable<any> {
        return this.customeHttpClient.Get<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/citizen_user_pan_exists?pan=" + panNumber + '&mobileNo=' + mobileNo + '&columnName=' + columnName + '&citizenType=' + citizenType + '&companyIdNo=' + companyIdNo
        ).pipe(
            map((result: any) => {
                ;

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    getCitizenUserDetailById(userId: any): Observable<any> {
        return this.customeHttpClient.Get<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/get_citizen_user_detail_by_id?userId=" + userId
        ).pipe(
            map((result: any) => {
                ;

                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    loginSearchModule(id: string) {
        const param = {
            "uid": id
        }
        return this.customeHttpClient
            .Post<ResultModel<any>>(
                this.settings.igrs_gateway_url + "common/authenticate_redirect_citizen_user",
                param
            ).pipe(
                map((result: any) => {

                    if (result && result.status == 'true') {
                        this.setSession(result);
                    }
                    return result;
                })
            );
    }

    getUserListByContactNo(param: any) {
        let encVal = this._AESEncryptDecryptService.encryptInAngular(JSON.stringify(param));
        return this.customeHttpClient.Post<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/get_user_list_by_contact_no", encVal
        ).pipe(
            map((result: any) => {
                ;
                //
                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    verifyOtpForParty(mobileNo: string, otp: string, eRegId: string, userId: number): Observable<any> {
        return this.customeHttpClient.Get<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/verify_otp_for_party?mobile_no=" + mobileNo + "&otp=" + otp + "&eRegId=" + eRegId
            + "&userId=" + userId
        ).pipe(
            map((result: any) => {
                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    saveUserProfilePic(citizenModel: Citizen) {
        let encVal = this._AESEncryptDecryptService.encryptInAngular(JSON.stringify(citizenModel));
        return this.customeHttpClient.Post<ResultModel<any>>(
            this.settings.igrs_gateway_url + "common/downloadUserProfilePic", encVal
        ).pipe(
            map((result: any) => {
                ;
                //
                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    //  getUserProfileImgUrl(userId: number, ownerType: string):Observable<any> {
    //     return this.customeHttpClient.Get<ResultModel<any>>(
    //         this.settings.igrs_gateway_url + "common/getUserImagUrl?&userId=" + userId + "&userType=c" + "&ownerType=" + ownerType
    //     ).pipe(
    //         map((result: any) => {
    //             ;

    //             if (result && result.responseStatus == 'true') {
    //                 return result;
    //             } else {
    //                 return result;
    //             }

    //         })
    //     );
    // }
  

    savePartyImage(citizenModel: Citizen) {
        let encVal = this._AESEncryptDecryptService.encryptInAngular(JSON.stringify(citizenModel));
        return this.customeHttpClient.Post<ResultModel<any>>(
            this.settings.igrs_services_url + "common/ereg/downloadPartyImage", encVal
        ).pipe(
            map((result: any) => {
                ;
                //
                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }

            })
        );
    }

    getEqMortgageRegList(param: any) {

        return this.customeHttpClient
          .PostApiToken<ResultModel<any>>(
            this.settings.igrs_services_url + "citizen/ereg/getEQMortgageRegDetalis", param
          ).pipe(
            map((result: any) => {
    
              if (result && result.responseStatus == 'true') {
                return result;
              } else {
                return result;
              }
    
            })
          );
      }

      getEqMortgageRegVaultList(param: any) {

        return this.customeHttpClient
          .PostApiToken<ResultModel<any>>(
            this.settings.igrs_services_url + "citizen/ereg/getEQMortgageRegVaultDetalis", param
          ).pipe(
            map((result: any) => {
    
              if (result && result.responseStatus == 'true') {
                return result;
              } else {
                return result;
              }
    
            })
          );
      }

      getDeMortgageRegVaultList(param: any) {

        return this.customeHttpClient
          .PostApiToken<ResultModel<any>>(
            this.settings.igrs_services_url + "citizen/ereg/getDeMortgageRegVaultDetalis", param
          ).pipe(
            map((result: any) => {
    
              if (result && result.responseStatus == 'true') {
                return result;
              } else {
                return result;
              }
    
            })
          );
      }

      getAllFinancialYear(): Observable<any> {
        return this.customeHttpClient.Get<ResultModel<any>>(
            this.settings.igrs_services_url + "common/getFinancialYear"
        ).pipe(
            map((result: any) => {
    
                if (result && result.responseStatus == 'true') {
                    return result;
                } else {
                    return result;
                }
    
            })
        );
    }

    timer(seconds: number): Observable<string> {
        return new Observable<string>((observer) => {
          this.seconds = seconds;
          let textSec: any = '0';
          let statSec: number = seconds;
          const prefix = '0';
      
          const timerInterval = setInterval(() => {
            this.seconds--;
            if (statSec !== 0) {
              statSec--;
            } else {
              statSec = 59;
            }
      
            if (statSec < 10) {
              textSec = '0' + statSec;
            } else {
              textSec = statSec;
            }
      
            const display = `${prefix}${Math.floor(this.seconds / 60)}:${textSec}`;
            observer.next(display);
      
            if (this.seconds === 0) {
              console.log('finished');
              clearInterval(timerInterval);
              observer.complete();
            }
          }, 1000);
      
          // Cleanup function to clear interval if unsubscribed
          return () => clearInterval(timerInterval);
        });
      }
}
