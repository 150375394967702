import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

import { IgrsEregPartyDetailsBean, IgrsEregTxnDetlsBean } from 'projects/common-lib/src/lib/models/e_registry/e-registry.model';
import Swal from 'sweetalert2';
import { AddEstamp, EstampCommonBean } from 'projects/common-lib/src/lib/models/addEstamp.model/add-estamp.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CitizenUserProfileDetails } from 'projects/common-lib/src/lib/models/citizen.login.model/citizen.profile.model';
import { HttpClient } from '@angular/common/http';
import { AppSetting, AppSettingService } from 'projects/common-lib/src/lib/helper-lib/app.setting';

import { CommunicationServiceService } from '../../duty-calculation/duty-service/communication-service.service';
import { CustomHttpClient } from 'projects/common-lib/src/lib/helper-lib/httpclient/custom.httpclient';
import { EregistryService } from '../../../service/eregistry.service';
import { EstampService } from '../../../service/estamp.service';
import { AlertmessageComponent } from 'projects/common-lib/src/lib/component/alertmessage/alertmessage.component';
import { AuthenticationService } from 'projects/common-lib/src/lib/helper-lib/services/auth.service/authentication.service';

@Component({
  selector: 'app-estamp-updation-list',
  templateUrl: './estamp-updation-list.component.html',
  styleUrls: ['./estamp-updation-list.component.scss']
})
export class EstampUpdationListComponent implements OnInit {
  @ViewChild(AlertmessageComponent, { static: false }) alertmessage: AlertmessageComponent;
  loginContactNumber: string;
  sNo: number;
  deedCatagory: string;
  deedType: string;
  instrument: string;
  transactionBehalf: any;
  amount: number;
  estampId: any;
  inputEstampId: any;
  encryptedEregId: any;
  totalRows = 0;
  pageSize = 5;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  filname: string;
  igrsEregTxnDetlsBean: IgrsEregTxnDetlsBean = new IgrsEregTxnDetlsBean();
  dataSource = new MatTableDataSource<any>();
  inputMobileNo: any;//this is direct pass by ereg party esign window
  inputEstampIdNo: any;//this is direct pass by ereg party esign window

  readioSelected: any = 'esign';
  spEstampId: any;
  estampCommonBean: EstampCommonBean = new EstampCommonBean();
  partyDetailsModel: IgrsEregPartyDetailsBean = new IgrsEregPartyDetailsBean();
  userProfileModel: CitizenUserProfileDetails = new CitizenUserProfileDetails();
  SignpartyName: string;
  SignpartyNameHindi: string;
  base64DeedPdf: any;
  pageNo = 0;
  contentStatus: any;
  userid: any;
  aadharPhoto: any;
  mobileNo: any;
  ekycStatus: any;
  settings: any;
  lan: number;
  partyId: any = 0;
  userId: any;
  eregFlag: any;
  igrsEregEstampBean: AddEstamp = new AddEstamp();
  partyAadharphoto: string;


  //displayedColumns: string[] = ["sNo","estampCode","isERegistry", "deedCatagory", "deedType", "instrument","natureInstr","transactionBehalf","estampStatus","estampcontentstatus","partystatus","remarkStatus","partyType","contentStatusId","createdOn","modifiedDate","userName","actions"];
  displayedColumns: string[] = ["sNo", "estampCode", "deedCatagory", "deedType", "instrument", "estampStatus", "estampcontentstatus", "partystatus", "remarkStatus", "partyType", "contentStatusId", "createdOn", "modifiedDate", "userName", "actions"];
  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;
  constructor(private ngxService: NgxUiLoaderService, private translateService: TranslateHEService,
    private eregistryservice: EregistryService,
    private translate: TranslateService, public dialog: MatDialog, private commServ: CommunicationServiceService,
    private customeHttpClient: CustomHttpClient, private estampService: EstampService, private appSettingsService: AppSettingService,
    private modalService: NgbModal,
    private httpClient: HttpClient,
    private eRegService: EregistryService,
    private translateServiceIns: TranslateService,
    private authenticationService:AuthenticationService,
    private router: Router) {

    this.translateService.currentApprovalStageMessage.subscribe(msg => {
      this.lan = msg
      this.translate.use(msg == 1 ? 'hi' : 'en')
    })
    //this.lan = sessionStorage.getItem("selectedLanguage") == 'hi' ? 1 : 0;


    this.loginContactNumber = router.getCurrentNavigation().extras.state.data;
    this.inputEstampId = router.getCurrentNavigation().extras.state.estampId;
    this.inputMobileNo = router.getCurrentNavigation().extras.state.inputMobileNo;
    this.inputEstampIdNo = router.getCurrentNavigation().extras.state.inputEstampId;
    this.encryptedEregId = router.getCurrentNavigation().extras.state.encryptedEregId;
    this.partyId = router.getCurrentNavigation().extras.state.partyId;
    this.userId = router.getCurrentNavigation().extras.state.userId;
    this.eregFlag = router.getCurrentNavigation().extras.state.eregFlag;

    this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
      this.settings = settings;
    });
    console.log("loginContactNumber::" + this.loginContactNumber + "inputEstampId::" + this.inputEstampId + " inputMobileNo::" + this.inputMobileNo + " inputEstampIdNo::" + this.inputEstampIdNo + " encryptedEregId::" + this.encryptedEregId + " partyId::" + this.partyId + " userId::" + this.userId + " eregFlag::" + this.eregFlag);
  }
  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    if (this.inputEstampIdNo != null && this.inputEstampIdNo != undefined) {
      this.loginContactNumber = this.inputMobileNo;
      this.redirectPartyEsign(0, 5);
    } else {
      this.getAllEstampByContactNumber(0, 5);
    }

  }
  pageChangedES(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getAllEstampByContactNumber(this.currentPage, this.pageSize);
  }

  getAllEstampByContactNumber(pageNumber: number, pageSize: number) {
    this.ngxService.start();

    this.estampService.getEstampListByContactNumber(pageNumber, pageSize, this.loginContactNumber, this.inputEstampId, 0, this.partyId, this.userId, this.eregFlag).subscribe(res => {
      if (this.inputEstampId != null && this.inputEstampId != undefined && res.responseStatus == 'true' && res.responseData[0].aadharEkyc == 1) {

        if (res.responseData[0].partyContentStatusId == 73 || res.responseData[0].partyContentStatusId == 25) {//status=73 means Deed Submitted for eSign
          //goToEsign(element.estampId,element.partyContentStatus,element.partyId)
          this.viewEregEsign(res.responseData[0].estampId, res.responseData[0].partyContentStatus, res.responseData[0].partyId, res.responseData[0].userId);
        } else {
          this.goToPreview(res.responseData[0].estampId, res.responseData[0].partyContentStatusId, res.responseData[0].partyType,
            res.responseData[0].partySubType, res.responseData[0].contentStatusId, res.responseData[0].natureInstr, res.responseData[0].eregFlag,
            res.responseData[0].tempEregId, res.responseData[0].partyTypeNameEn, res.responseData[0].partyName, res.responseData[0].userId, res.responseData[0].deedAcceptLink, res.responseData[0].partyTypeNameHi, res.responseData[0].partyId);
        }
      }
      else if (res.responseStatus == 'true') {
        this.ngxService.stop();

        this.dataSource = new MatTableDataSource<any>(res.responseData);
        console.log("party List ::" + JSON.stringify(res.responseData))
        setTimeout(() => {
          this.paginator.length = res.totalRecords;
          this.paginator.pageIndex = this.currentPage;

        });
      }
      else {
        this.dataSource = new MatTableDataSource<any>([]);
        this.ngxService.stop();
        Swal.fire((this.lan == 0) ? "Record Not Found" : "रिकॉर्ड नहीं मिला");
      }
    });

  }

  redirectPartyEsign(pageNumber: number, pageSize: number) {
    this.ngxService.start();

    this.estampService.getEstampListByContactNumber(pageNumber, pageSize, this.inputMobileNo, this.inputEstampIdNo, 0, this.partyId, this.userId, this.eregFlag).subscribe(res => {
      //console.log("getEstampListByContactNumber response ::"+JSON.stringify(res.responseData[0]))
      if (this.inputEstampIdNo != null && this.inputEstampIdNo != undefined && res.responseStatus == 'true' && res.responseData[0].aadharEkyc == 1) {

        if (res.responseData[0].partyContentStatusId == 73 || res.responseData[0].partyContentStatusId == 25) {//status=73 means Deed Submitted for eSign
          //goToEsign(element.estampId,element.partyContentStatus,element.partyId)
          this.viewEregEsign(res.responseData[0].estampId, res.responseData[0].partyContentStatus, res.responseData[0].partyId, res.responseData[0].userId);
        } else {
          this.goToPreview(res.responseData[0].estampId, res.responseData[0].partyContentStatusId, res.responseData[0].partyType,
            res.responseData[0].partySubType, res.responseData[0].contentStatusId, res.responseData[0].natureInstr, res.responseData[0].eregFlag,
            res.responseData[0].eregId, res.responseData[0].partyTypeNameEn, res.responseData[0].partyName, res.responseData[0].userId, res.responseData[0].deedAcceptLink, res.responseData[0].partyTypeNameHi, res.responseData[0].partyId);
        }
      }
      else if (res.responseStatus == 'true') {
        this.ngxService.stop();
        console.log("redirectPartyEsign RES:" + JSON.stringify(res.responseData));
        this.dataSource = new MatTableDataSource<any>(res.responseData);
        setTimeout(() => {
          this.paginator.length = res.totalRecords;
          this.paginator.pageIndex = this.currentPage;

        });
      }
      else {
        this.dataSource = new MatTableDataSource<any>([]);
        this.ngxService.stop();
        Swal.fire((this.lan == 0) ? "Record Not Found" : "रिकॉर्ड नहीं मिला");
      }
    });

  }


  goToPreview(estampId: number, contentStatus: string, partyType: number, partySubType: number, estampContentStatus: number, natureOfInstrument: string, eregFlag: any, code: any, partyTypeNameEn: any, partyName: any, userId: any, deedAcceptLink: any, partyTypeNameHi: any, partyId: any) {
    this.router.navigate(['estamp-deed-updation'], {
      state: {
        data: [estampId, this.loginContactNumber, contentStatus, partyType, estampContentStatus, natureOfInstrument, partySubType, eregFlag, code, partyTypeNameEn, partyName, userId, deedAcceptLink, partyTypeNameHi, partyId]
      }
    });
  }
  goToEregPreview(estampId: number, contentStatus: number, partyType: number, partySubType: number, estampContentStatus: number, natureOfInstrument: string, eregFlag: any) {
    this.router.navigate(['estamp-deed-updation'], {
      state: {
        data: [estampId, this.loginContactNumber, contentStatus, partyType, estampContentStatus, natureOfInstrument, partySubType, eregFlag]
      }
    });
  }

  goToEsign(estampId: number, contentStatus: string, partyId: number, userId: any,userType:number) {
    this.userType = userType;
    if (estampId != null && partyId != null) {
      if(this.eregFlag == 0){
        this.router.navigate(['e-sign'], {
          state: {
          //  data: [this.spEstampId, this.igrsEregPartyDetailsBean.mobileNo, "", this.userProfileModel.id, "", 6,this.igrsEregPartyDetailsBean.id, this.igrsEregPartyDetailsBean.userType]
            data: [estampId, this.loginContactNumber, "", userId, "", 6,partyId,userType]
          }
        });
      }else{
        this.router.navigate(['e-sign'], {
          state: {
            data: [estampId, this.loginContactNumber, contentStatus, userId]
          }
        });
      }
      
    }
    else {
      alert("something wrong in parameters");
    }
  }

  //   getPartyType(partyType:number){
  //  if(partyType==1){
  //   return 'First Party'
  //  }else if(partyType==2){
  //   return 'Second Party'
  //  }else if(partyType==3){
  //   return 'Witness'
  //  }else{
  //   return ''
  //  }
  //   }

  downloadEsign(stampid: number) {
    this.ngxService.start();
    // this.estampId=1219;
    this.estampService.downloadEstampEsign(stampid).subscribe(res => {
      this.ngxService.stop();
      window.open(res.responseData);
      //window.location.href=res.responseData;
    }, error => {
      console.log('file not found or download error:', JSON.stringify(error));
      alert(error)
    }, () => {
      console.log('Completed file download.')
    });
  }

  downloadDMSEstamp(stampid: number) {
    this.ngxService.start();

    this.estampService.downloadDMSEstamp(stampid).subscribe(res => {
      this.ngxService.stop();
      const source = `data:application/pdf;base64,${res.responseData}`;
      const link = document.createElement("a");
      link.href = source;
      link.download = `estamp-${stampid}.pdf`
      link.click();
    }, error => {
      console.log('DMS Estamp file not found or download error:', JSON.stringify(error));
    }, () => {
      console.log('Completed DMS Estamp file download.')
    });
  }
  
  downloadCompletedEreg(regId:any,eregTxnId:any){
    this.ngxService.start();
    this.eRegService.downloadFinalCompletedEregDocumentForCitizenWithDmsIdCommon(eregTxnId).subscribe(response => {
      if (response.responseStatus == 'true') {
        this.ngxService.stop();
        let pdfBase64: any = response.responseData;

        const linkSource = 'data:application/pdf;base64,'+pdfBase64;
        const downloadLink = document.createElement("a");
        const fileName = regId+".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
       
              }
      else {
        this.ngxService.stop();
      }

    }
      , error => {
        this.ngxService.stop();
        console.log('file not found or download error:', JSON.stringify(error));
        alert(error)
      });
  }

  viewEregEsign(estampId: number, contentStatus: number, partyId: number, userId: any) {
    this.igrsEregTxnDetlsBean.estampId = estampId;
    let eregFlag = 1;

     //************************************** */
     this.ngxService.start();
     this.estampService.getEstampDetailsById(Number(estampId)).subscribe(res => {
       if (res.responseStatus == 'true') {
         this.ngxService.stop();
         this.igrsEregEstampBean = res.responseData
         // *****start check validation ***********
         if(!(this.igrsEregEstampBean.isFinalDeedGenerated==1)){
           Swal.fire({
             icon: 'warning',
             title: (this.lan==0)?'Final Deed':'अंतिम विलेख',
             text: (this.lan==0)?'Please wait for few seconds as your deed is being generate':'कृपया डीड तैयार होने तक कुछ सेकंड प्रतीक्षा करें',
             confirmButtonText: this.translateServiceIns.instant('DecisionOnDocument.ok'),
           }).then((result) => {
             if (result.isConfirmed) {
               //window.location.reload();
             }
           });
         }else{
            this.router.navigate(['EregEsignTemplate'], {
              state: {
                data: [estampId, '1', this.loginContactNumber, contentStatus, userId, this.encryptedEregId, this.inputEstampId,,partyId]
              }
            });
         }
         // *****End check validation ***********
 
 
       } else {
         this.ngxService.stop();
         this.alertmessage.errorAlertMessage(res);
       }
     },
       (err) => {
         this.ngxService.stop();
         this.alertmessage.errorAlertMessage('tech_error_msg');
       });
       //******************************************** */

    // if (estampId != null) {

    //   this.router.navigate(['EregEsignTemplate'], {
    //     state: {
    //       data: [estampId, '1', this.loginContactNumber, contentStatus, userId, this.encryptedEregId, this.inputEstampId,,partyId]
    //     }
    //   });
    // }
    // else {
    //   alert("something wrong in parameters");
    // }

  }

  viewEstampEsign(estampId: number, contentStatus: number, userid: number) {
    this.igrsEregTxnDetlsBean.estampId = estampId;
    let eregFlag = 1;
    if (estampId != null) {

      this.router.navigate(['EregEsignTemplate'], {
        state: {
          data: [estampId, '0', this.loginContactNumber, contentStatus, userid, '', this.inputEstampId]
        }
      });
    }
    else {
      alert("something wrong in parameters");
    }



  }

  onClickRadio(val: any) {
    if (val == "dsc") {
      this.readioSelected = 'dsc';
    } else if (val == "esign") {
      this.readioSelected = 'esign';
    }
  }
userType:number;
  openEsignPopup(content: any, estampId: number, contentStatus: string, userid: number,
    aadharPhoto: string, partyName: string, mobileNo: any, ekycStatus: any, partyId: any, partyNameHindi: string,userType:number) {
      this.userType = userType;
    this.spEstampId = estampId;
    this.contentStatus = contentStatus;
    this.userid = userid;
    this.partyId = partyId;
    this.aadharPhoto = aadharPhoto;
    this.SignpartyName = partyName;
    this.SignpartyNameHindi = partyNameHindi;
    this.mobileNo = mobileNo;
    this.ekycStatus = ekycStatus;
    if (true) {
      this.readioSelected = 'dsc';
    } else {
      this.readioSelected = 'esign';
    }
    //************************************** */
    this.ngxService.start();
    this.estampService.getEstampDetailsById(Number(estampId)).subscribe(res => {
      if (res.responseStatus == 'true') {
        this.ngxService.stop();
        this.igrsEregEstampBean = res.responseData
        // *****start check validation ***********
        if(!(this.igrsEregEstampBean.isFinalDeedGenerated==1)){
          Swal.fire({
            icon: 'warning',
            title: (this.lan==0)?'Final Deed':'अंतिम विलेख',
            text: (this.lan==0)?'Please wait for few seconds as your deed is being generate':'कृपया डीड तैयार होने तक कुछ सेकंड प्रतीक्षा करें',
            confirmButtonText: this.translateServiceIns.instant('DecisionOnDocument.ok'),
          }).then((result) => {
            if (result.isConfirmed) {
              //window.location.reload();
            }
          });
        }else{
          this.modalService.open(content, {
            windowClass: "modalNew",
            size: 'md',
            centered: true,
          });
        }
        // *****End check validation ***********


      } else {
        this.ngxService.stop();
        this.alertmessage.errorAlertMessage(res);
      }
    },
      (err) => {
        this.ngxService.stop();
        this.alertmessage.errorAlertMessage('tech_error_msg');
      });
      //******************************************** */
   
  }

  getEstampDetailsForCheckFinalDeedGeneration(estampId:any) {
    this.ngxService.start();
    this.estampService.getEstampDetailsById(Number(estampId)).subscribe(res => {
      if (res.responseStatus == 'true') {
        this.ngxService.stop();
        this.igrsEregEstampBean = res.responseData
        // *****start check validation ***********
        if(!(this.igrsEregEstampBean.isFinalDeedGenerated==1)){
          Swal.fire({
            icon: 'warning',
            title: (this.lan==0)?'Final Deed':'अंतिम विलेख',
            text: (this.lan==0)?'Please wait for few seconds as your deed is being generate':'कृपया डीड तैयार होने तक कुछ सेकंड प्रतीक्षा करें',
            confirmButtonText: this.translateServiceIns.instant('DecisionOnDocument.ok'),
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        }
        // *****End check validation ***********


      } else {
        this.ngxService.stop();
        this.alertmessage.errorAlertMessage(res);
      }
    },
      (err) => {
        this.ngxService.stop();
        this.alertmessage.errorAlertMessage('tech_error_msg');
      });
  }


  partyEsignNext() {
    //var esignRadioVal = (<HTMLInputElement>document.getElementById("flexRadioDefault1")).value;
    var esignRadioVal = this.readioSelected;
    if (esignRadioVal == 'esign') {
      this.redirectForPartyEsign();
    } else if (esignRadioVal == 'dsc') {

      this.getEstampDetails();
    }
  }

  redirectForPartyEsign() {

    if (this.spEstampId != null) {
      if(this.eregFlag == 0){ //this condition redirection after esign in estamp listing page
        this.router.navigate(['e-sign'], {
          state: {
            data: [this.spEstampId, this.loginContactNumber, this.contentStatus, this.userid, "", 6,this.partyId,this.userType]
          }
        });
      }else{
        this.router.navigate(['e-sign'], {
          state: {
            data: [this.spEstampId, this.loginContactNumber, this.contentStatus, this.userid, , ,this.partyId,this.userType]
          }
        });
      }
      
    }

  }

  getEstampDetails() {
    //this.userProfileModel = JSON.parse(sessionStorage.getItem("authData") || "");
    this.ngxService.start();
    this.estampService.getEstampDetailsById(Number(this.spEstampId)).subscribe(templateData => {
      if (templateData.responseStatus == 'true') {
        this.ngxService.stop();
        //this.estampDetails = templateData.responseData;
        this.estampCommonBean.igrsEregEstampBean = templateData.responseData;
        this.viewEstampDeedJrxmlToHtml("");



        //this.partyAadharphoto=this.userProfileModel.profilePic; //in sp esign photo will not required.

        if (this.userProfileModel.firstName != undefined && this.userProfileModel.firstName != "") {
          this.SignpartyName = this.userProfileModel.firstName;
        }
        if (this.userProfileModel.middleName != undefined && this.userProfileModel.middleName != "") {
          this.SignpartyName = this.SignpartyName + " " + this.userProfileModel.middleName;
        }
        if (this.userProfileModel.lastName != undefined && this.userProfileModel.lastName != "") {
          this.SignpartyName = this.SignpartyName + " " + this.userProfileModel.lastName;
        }
        if(this.userProfileModel.firstNameHi != undefined && this.userProfileModel.firstNameHi != ""){
          this.SignpartyNameHindi = this.userProfileModel.firstNameHi;
         }
         if(this.userProfileModel.middleNameHi != undefined && this.userProfileModel.middleNameHi != ""){
           this.SignpartyNameHindi=this.SignpartyNameHindi+" "+this.userProfileModel.middleNameHi;
         }
         if(this.userProfileModel.lastNameHi != undefined && this.userProfileModel.lastNameHi != ""){
           this.SignpartyNameHindi=this.SignpartyNameHindi+" "+this.userProfileModel.lastNameHi;
        }

        // this.getAadharNumber(this.userProfileModel.kycTxnLogNo);
      } else {
        this.ngxService.stop();
        //this.alertmessage.errorAlertMessage(templateData);
      }
    },
      (err) => {
        this.ngxService.stop();
        // this.alertmessage.errorAlertMessage('tech_error_msg');
      });
  }

  viewEstampDeedJrxmlToHtml(content: any) {
    this.estampCommonBean.viewJasperDeed = true;
    this.estampCommonBean.igrsEregEstampBean.createdDate = null;
    this.estampCommonBean.igrsEregEstampBean.modifiedDate = null;//"Signature*" + this.eSignatureBean.mobileNo
    this.estampCommonBean.findPdfText = "Signature*" + this.loginContactNumber + "*" + this.partyId;
    this.ngxService.startLoader('viewEstamp');
    this.estampService.viewCommonEstampDeedJrxmlToHtml(this.estampCommonBean).subscribe(result => {
      this.ngxService.stopLoader('viewEstamp');
      if (result.responseStatus == "true") {

        if (result.responseData.pageNumber != null && result.responseData.pageNumber != "" && result.responseData.pageNumber != "0") {
          this.pageNo = Number(result.responseData.pageNumber);
        }
        this.base64DeedPdf = result.responseData.pdfBase64;

        this.dscPartyEsignRequest();

      } else {
        // this.alertmessage.errorAlertMessage(result.templateData);
      }
    });
  }

  dscPartyEsignRequest() {
    this.getPartyBase64Image(this.partyId);
    // let pageNo = 0;
    let pdfBase64v = "";
    // this.encryptedEregId=this._AESEncryptDecryptService.encrypt("" + this.igrsEregTxnDetlsBean.id);
    //const headers = { 'ITSUPPORTSECRETKEY': 'e170245abbcfe9b0384704d0851f7f41', 'RNUM': '1671527062', 'APPLICATIONCODE': 'MSDC01' };
    const headers = { "Content-Type": "application/json" };

    this.ngxService.startLoader('viewEstamp');
    let status = 0;
    let msg = "Back";

    let reason : any = 'Deed Signing';
    if(this.partyDetailsModel?.partyType == 33){//Witness
     reason = 'Witness of Deed Execution';
   }else{
     reason = 'Deed Signing';
   }

    let isPhoto = false;
    if (this.aadharPhoto != null && this.aadharPhoto != "" && this.aadharPhoto != undefined) {
      isPhoto = true;
    }
    console.log("isPhoto::" + isPhoto)
    const body = {
      "MatchThumbprint": false,
      "Title": "Digital Sign Sampada 2.0",
      "Message": "Deed Signing of Sampada 2.0",
      "RequiredSystemInfo": true,
      "SignType": "pdf",
      "SignatureType": "DigitalSignature",
      "SetPIN": false,
      "PinCode": "",
      "IsBulkSign": false,

      "DataFormat": {
        "PDFFile": {
          //"SignField": "SignatureField",
          "Reason": "Deed Signing",
          "Location": "India",
          "PageNo": this.pageNo,
          "TextLocation": "Signature*" + this.loginContactNumber + "*" + this.partyId,
          "PlaceImage": isPhoto,
          "GenerateQR": false,
          "PlaceOnTextLocation": true,
          "NoChangeAllowed": false,
          "RectangleCoordinates": "50,0,350,80",
          "InputPDF": this.base64DeedPdf,
          "Fonts": {
            "Color": "RED",
            "SetFamily": "Cambria",
            "Size": 12
          },
          "Image": {
            // "ImageBytes": this.partyDetailsModel.aadharPhoto
            "ImageBytes": (this.partyAadharphoto != null && this.partyAadharphoto != undefined ? this.partyAadharphoto : "")
          },
          "QRCode": {
            "QRIcon": "",
            "Color": "BLUE",
            "DisplayValue": "Ankur Dixit",
            "PixelPerModule": 3,
            "IconSize": 5,
            "IconBorderWidth": 6
          }
        }
      },
      "BulkFiles": [
        {
          "Key": "File1.txt",
          "Value": "base64-encoded-file-data-here"
        },
        {
          "Key": "File2.txt",
          "Value": "base64-encoded-file-data-here"
        }
      ]
    };

    this.httpClient.post<any>('http://localhost:9030/Services/Sign', body, { headers }).subscribe({
      next: data => {
        // this.ngxService.stopLoader('viewEstamp');
        this.partyDetailsModel.id = this.partyId;
        this.partyDetailsModel.dscSignPartyCert = data[0].Certificate;
        this.partyDetailsModel.dscSignPartyPdfBase64 = data[0].SignedPDF;
        this.partyDetailsModel.estampId = this.spEstampId;
        this.partyDetailsModel.userId = this.userid;
        this.partyDetailsModel.esignMode = 2; //1==aadhaar esign;2==dsc esign
        this.partyDetailsModel.directionFlag = 1;

        //start save party esign certificate
        if (data[0].IsSuccess) {
          this.ngxService.startLoader('viewEstamp');
          this.eregistryservice.saveEstampDscSignPartyDetails(this.partyDetailsModel).subscribe(res => {
            if (res.responseStatus == 'true') {
              this.ngxService.stopLoader('viewEstamp');
              let status = 1;
              this.partyDetailsModel.esignMode = 2;
              //window.location.reload();
              Swal.fire({
                //title: 'DC Esign',
                text: `${this.lan == 0 ? "DSC sign successfully" : "डीएससी सफलतापूर्वक हस्ताक्षरित"}`,
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: (this.lan == 0) ? 'Ok' : 'ठीक है',
              }).then((result) => {
                if (result.isConfirmed) {

                  this.router.navigate([`estamp-Login`]);

                }
              })
            } else {
              this.ngxService.stopLoader('viewEstamp');
              //this.alertmessage.errorAlertMessage(res.responseMessage);
            }

          },
            (err) => {
              this.ngxService.stopLoader('viewEstamp');
              //this.alertmessage.errorAlertMessage('TECHNICAL Error in save party dsc certificate!');
            })
          //end save party esign certificate

        } else {
          Swal.fire((this.lan == 0) ? this.SignpartyName + "'s DSC sign Not Done " : this.SignpartyNameHindi + " का डीएससी साइन पूरा नहीं हुआ");
          this.ngxService.stopLoader('viewEstamp');
        }

      },
      error: error => {
        this.ngxService.stopLoader('viewEstamp');
        // this.router.navigate(['/citizen/e-registry/response-from-esign/'+this.encryptedEregId+'/'+status+'/'+msg]);
        console.error('There was an error in dscPartyEsignRequest()!', error);
        if (error.status == -1) {
          error.status = 408;
          error.statusText = '(this.lan == 0) ? "Please check if your DSC is connected properly" : "कृपया जांचें कि आपका डीएससी ठीक से कनेक्ट हुआ है या नहीं"';
          // alert('Please check your DSD connect properly');
          Swal.fire((this.lan == 0) ? "Please connect your DSC properly " : "कृपया अपना डीएससी ठीक से कनेक्ट करें");
        }
        //alert("There is some problem while reading DSC, Please try again later.");
        Swal.fire((this.lan == 0) ? "There is some problem while reading DSC, Please try again later." : "डीएससी पढ़ते समय कुछ समस्या आ रही है, कृपया बाद में पुनः प्रयास करें।");
      }
    })

  }

  getPartyBase64Image(partyId:any){
    this.authenticationService
    this.authenticationService.getPartyPhotoBase64ByPartyId(partyId,"c").subscribe(templateData => {
      if (templateData.responseStatus == 'true') {
        this.partyAadharphoto = templateData.responseData;
      //  console.log("partyAadharphoto base64::" + this.partyAadharphoto);
      }
    })
  }
}
