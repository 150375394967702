<div class="position-relative">
  <div class="fid-header"></div>
  <div class="breadcrumb-content">
    <div class="container">


      <div class="d-flex justify-content-between">
         
          <h2>
            {{'userManual' |translate}} 
          </h2>
          <button class="btn btn-primary" routerLink="/Software_Download">
            {{'softDownload.soft_docu_download' | translate}}
          </button>
         
      </div>
    </div>
  </div>

  <div class="container">
    <mat-card class="example-card mb-3">
      <mat-card-title></mat-card-title>
      <mat-card-content class="mat-card-content">

        <fieldset class="legend-section">
          <div class="table-responsive shadow-none">
            <table class="table table-bordered">
              <thead class="thead-dark">
                <tr>
                  <th>{{'userManual' |translate}} {{'name' |translate}}</th>
                  <th *ngIf="lan==0" style="width:80px;" class="text-center">Video</th>
                  <th *ngIf="lan==1" style="width:80px;" class="text-center">वीडियो</th>
                  <th *ngIf="lan==0" style="width:80px;" class="text-center">Hindi</th>
                  <th *ngIf="lan==0" style="width:80px;" class="text-center">English</th>
                  <th *ngIf="lan==1" style="width:80px;" class="text-center">हिंदी</th>
                  <th *ngIf="lan==1" style="width:80px;" class="text-center">अंग्रेज़ी</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span *ngIf="lan==0">E-Registry Initiation - User Manual</span>
                    <span *ngIf="lan==1">ई-पंजीयन आरंभ - उपयोगकर्ता पुस्तिका </span>

                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('HINDIE_Registry_Initiation_User_Manual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('englishE_Registry_Initiation_User_Manual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                </tr>



                <tr>
                  <td>
                    <span *ngIf="lan==0"> Duty Calculation - User Manual </span>
                    <span *ngIf="lan==1">शुल्क गणना - उपयोगकर्ता पुस्तिका </span>
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('HINDIDutyCalculation_UserManual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('englishDutyCalculation_UserManual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span *ngIf="lan==0">Payment - User Manual</span>
                    <span *ngIf="lan==1">भुगतान - उपयोगकर्ता पुस्तिका </span>
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('HINDIPayment_UserManual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('englishPayment_UserManual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span *ngIf="lan==0">Property Valuation - User Manual</span>
                    <span *ngIf="lan==1">संपत्ति के मूल्यांकन - उपयोगकर्ता पुस्तिका </span>
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('HINDIPropertyValuation_UserManual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('englishPropertyValuation_UserManual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span *ngIf="lan==0">Service Provider - User Manual</span>
                    <span *ngIf="lan==1">सेवा प्रदाता - उपयोगकर्ता पुस्तिका </span>
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('HindiServiceprovider_UserManual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('englishServiceprovider_UserManual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span *ngIf="lan==0">SAMPADA2.0 Mobile App - User Manual</span>
                    <span *ngIf="lan==1">सम्पदा 2.0 मोबाइल ऐप - उपयोगकर्ता पुस्तिका </span>
                  </td>
                  <td class="text-center">
                    -
                  </td>

                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('HindiSAMPADA2.0MobileApp_UserManual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('englishSAMPADA2.0MobileApp_UserManual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span *ngIf="lan==0">User Role Manangement - User Manual</span>
                    <span *ngIf="lan==1">उपयोगकर्ता भूमिका प्रबंधन - उपयोगकर्ता पुस्तिका </span>
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('HINDIUserRoleManangementCitizen_UserManual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('englishUserRoleManangementCitizen_UserManual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                </tr>
                <!-----------E-Stamp -old----------->
                <!-- <tr>
                  <td>
                    <span *ngIf="lan==0">E-Stamp - User Manual</span>
                    <span *ngIf="lan==1">ई-स्टाम्प - उपयोगकर्ता पुस्तिका </span>
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('HindiEStamp_UserManual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('englishEStamp_UserManual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                </tr> -->
         <!--------E-Stamp  new ----------->

 <tr>
                  <td>
                    <span *ngIf="lan==0">E-Stamp - User Manual</span>
                    <span *ngIf="lan==1">ई-स्टाम्प - उपयोगकर्ता पुस्तिका </span>
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('HindiE-StampSimplification_UserManual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('EnglishE-StampSimplification_UserManual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                </tr>

   <!--------end E-Stamp  new ----------->

                <tr>
                  <td>
                    <span *ngIf="lan==0">Document Search Certified Copy - User Manual</span>
                    <span *ngIf="lan==1">दस्तावेज़ खोज प्रमाणित प्रति - उपयोगकर्ता पुस्तिका </span>
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('HindiDocumentSearchCertifiedCopy_UserManual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('EnglishDocumentSearchCertifiedCopy_UserManual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span *ngIf="lan==0">Bank Admin, Checker and Maker Onboarding - User Manual</span>
                    <span *ngIf="lan==1">बैंक एडमिन, चेकर और मेकर ऑनबोर्डिंग - उपयोगकर्ता पुस्तिका </span>
                  </td>
                  <td class="text-center">


                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('englishIGRSBankOnboarding_V1-1_UserManual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('englishIGRSBankOnboarding_V1-1_UserManual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span *ngIf="lan==0">Interactive Video KYC Based E-Registry - User Manual</span>
                    <span *ngIf="lan==1">इंटरएक्टिव वीडियो केवाईसी आधारित ई-पंजीयन - उपयोगकर्ता पुस्तिका </span>
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="videoDownload('E_Registry_Assisted_VKYC_VideoV1.0')">
                      <mat-icon>visibility</mat-icon>
                    </button>

                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('E_Registry_Assisted_VKYC_User_Manual_V1.2')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('E_Registry_Assisted_VKYC_User_Manual_V1.2')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span *ngIf="lan==0">E-Registry Initiation - Property Identification - User Manual</span>
                    <span *ngIf="lan==1">ई-पंजीयन आरंभ - संपत्ति पहचान - उपयोगकर्ता पुस्तिका </span>
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('ERegistry_Property_Identification_User_Manual_V1.0')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('ERegistry_Property_Identification_User_Manual_V1.0')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span *ngIf="lan==0">E-Registry Initiation - Equitable Mortgage - User Manual</span>
                    <span *ngIf="lan==1">ई-पंजीयन आरंभ - बंधक - उपयोगकर्ता पुस्तिका </span>
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('ERegistry_Initiaiton_Equitable Mortgage_V1.0')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('ERegistry_Initiaiton_Equitable Mortgage_V1.0')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span *ngIf="lan==0">e-Nagar Palika Municipal Property Temporary ID Generation - User Manual</span>
                    <span *ngIf="lan==1">ई-नगर पालिका नगरपालिका संपत्ति अस्थायी आईडी जनरेशन - उपयोगकर्ता पुस्तिका </span>
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('IGRS_e-NagarPalika_Municipal_Property_Temporary_ID_Generation_User_Manual_V1.0')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('IGRS_e-NagarPalika_Municipal_Property_Temporary_ID_Generation_User_Manual_V1.0')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span *ngIf="lan==0">E-Registry Initiation - Add Government User as Party - User Manual</span>
                    <span *ngIf="lan==1">ई-पंजीयन आरंभ - सरकारी उपयोगकर्ता को पार्टी के रूप में जोड़ें - उपयोगकर्ता पुस्तिका </span>
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('E_Registry_How_To_Add_Government_Department_As_Party_User_Manual_V1.0')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('E_Registry_How_To_Add_Government_Department_As_Party_User_Manual_V1.0')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                </tr>

               <tr>
                  <td>
                    <span *ngIf="lan==0"> Refund SAMPADA2.0 - User Manual</span>
                    <span *ngIf="lan==1">भुगतान सपदा 2.0- उपयोगकर्ता पुस्तिका </span>
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('HindiRefund_UserManual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('EnglishRefund_UserManual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                </tr>
               <!-- <tr>
                  <td>
                    <span *ngIf="lan==0"> Token Display System - User Manual</span>
                    <span *ngIf="lan==1"> टोकन डिस्प्ले सिस्टम - उपयोगकर्ता पुस्तिका </span>
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('HindiSROTokenDisplay_UserManual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('EnglishSROTokenDisplay_UserManual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                </tr> -->
               <tr>
                  <td>
                    <span *ngIf="lan==0">Govt Office Onboarding as Legal Entity - User Manual</span>
                    <span *ngIf="lan==1">सरकारी कार्यालय को कानूनी इकाई के रूप में शामिल करना- उपयोगकर्ता पुस्तिका </span>
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('HindiGovtOfficeOnboardingLegalEntity_UserManual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('englishGovtOfficeOnboardingLegalEntity_UserManual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                </tr>

                  <tr>
                  <td>
                    <span *ngIf="lan==0">Change Guideline Location - E Registry Initiation - User Manual</span>
                    <span *ngIf="lan==1">गाइडलाइन बदलने की प्रक्रिया - ई-रजिस्ट्री आरंभ - उपयोगकर्ता पुस्तिका </span>
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('HindiERegistryInitiationChangeGuideline_UserManual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('englishERegistryInitiationChangeGuideline_UserManual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                </tr>


<!------------------Change Document Registration Mode” “E Registry Initiation”---------------->
                  <tr>
                  <td>
                <span *ngIf="lan==0">Change Document Registration Mode - E Registry Initiation - User Manual</span>
                    <span *ngIf="lan==1">दस्तावेज़ पंजीकरण मोड बदलें - ई-रजिस्ट्री आरंभ - उपयोगकर्ता पुस्तिका </span>
                  </td>
                  <td class="text-center">
                    -
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('HindiIGRSChangeDocumentRegistrationMode_UserManual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('EnglishIGRSChangeDocumentRegistrationMode_UserManual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                </tr>

<!------------------Face Auth RD based E-Sign on SAMPADA 2.0 Mobile App---------------->

                  <tr>
                  <td>
                    <span *ngIf="lan==0">Face Auth RD based E-Sign on SAMPADA 2.0 Mobile App - User Manual</span>
                    <span *ngIf="lan==1">संपदा 2.0 मोबाइल ऐप पर फेस ऑथ आरडी आधारित ई-साइन - उपयोगकर्ता पुस्तिका </span>
                  </td>
                  <td class="text-center">
                    -
                  </td>

 <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('englishFaceAuthRDEsignOnSampada2.0mobileapp_UserManual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="pdfView('englishFaceAuthRDEsignOnSampada2.0mobileapp_UserManual')">
                      <mat-icon>visibility</mat-icon>
                    </button>
                  </td>
 
                </tr>



              </tbody>
            </table>
          </div>
        </fieldset>

      </mat-card-content>
    </mat-card>

 

  </div>
</div>