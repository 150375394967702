import { DOCUMENT } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import {  Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { TranslateService } from '@ngx-translate/core';
import { AlertmessageComponent } from 'projects/common-lib/src/lib/component/alertmessage/alertmessage.component';
import { MapComponent } from 'projects/common-lib/src/lib/component/map/map.component';
import { AppSetting, AppSettingService } from 'projects/common-lib/src/lib/helper-lib/app.setting';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { AgriPropertyValuationRequestBean } from 'projects/common-lib/src/lib/models/agriPropertyValuationRequestBean';
import { BuildingPropertyValuationRequestBean } from 'projects/common-lib/src/lib/models/buildingPropertyValuationRequestBean';
import { MPenagarpalikaResponsModel } from 'projects/common-lib/src/lib/models/enagarpalikaModel';
import { IgrsSubClausePropertyMappingBean } from 'projects/common-lib/src/lib/models/IgrsSubClausePropertyMappingBean';
import { MstDemographyBean } from 'projects/common-lib/src/lib/models/MstDemographyBean';
import { MstPropertyTypeBean } from 'projects/common-lib/src/lib/models/MstPropertyTypeBean';
import { PropertyValuationRequestBean } from 'projects/common-lib/src/lib/models/PropertyValuationRequestBean';
import { PropertyValuationRequestBeanMap } from 'projects/common-lib/src/lib/models/PropertyValuationRequestBeanMap';
import { WebGisResponseBeanData } from 'projects/common-lib/src/lib/models/WebGisResponseData';
import { Observable, Observer, Subscription } from 'rxjs';
import {COMMA, ENTER, F} from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CLRResponseModel } from 'projects/common-lib/src/lib/models/CLRResponseModel';
import { UaddResponseModel } from 'projects/common-lib/src/lib/models/UaddResponseModel';
import { PropertyOwner } from 'projects/common-lib/src/lib/models/PropertyOwner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as turf from '@turf/turf';
import { MapMyindiaService } from 'projects/common-lib/src/lib/helper-lib/services/MapMyindia.service';
import { PnrdModel } from 'projects/common-lib/src/lib/models/PnrdModel';
import {  IgrsEregTxnDetlsBean } from 'projects/common-lib/src/lib/models/e_registry/e-registry.model';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { PropertyValuationService } from 'projects/front-web/src/app/modules/property-valuation/property-valuation.service';
import { DefaultServiceService } from 'projects/front-web/src/app/default/default-service.service';
import { DialogData } from 'projects/common-lib/src/lib/models/DialogData';
import { MannualProcessPropertyIdentifationComponent } from 'projects/front-web/src/app/modules/mannual-process-property-identifation/mannual-process-property-identifation.component';
import { QueryLayerServices } from 'projects/common-lib/src/lib/service/queryLayer.services';
import { HtmlTagDefinition } from '@angular/compiler';
import { IgrsEregPropImgDisplayBean } from 'projects/common-lib/src/lib/models/IgrsEregPropImgDisplayBean';
import { UtilityService } from 'projects/common-lib/src/lib/service/UtilityService';
import { NgForm } from '@angular/forms';
import { ProtestChargesService } from 'projects/common-lib/src/lib/service/protest-charges.service';


declare const $: any;

@Component({
  selector: 'app-eregistry-property-identification',
  templateUrl: './eregistry-property-identification.component.html',
  styleUrls: ['./eregistry-property-identification.component.scss']
})
export class EregistryPropertyIdentificationComponent implements OnInit,OnDestroy {
  @ViewChild('suggetionList')  suggetionList!: ElementRef<any>;
  @ViewChild('inputMapMyIndia') searcBox!:ElementRef;
  @ViewChild(MapComponent) mapcomponent:MapComponent;
  @ViewChild('drawer') drawer:any
  @ViewChild(AlertmessageComponent, { static: false }) alertmessage: AlertmessageComponent;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild('propertySelectionModal', { static: true }) propertySelectionModalRef: TemplateRef<any>;
  @ViewChild('infoContent') infoContent:ElementRef;
  @ViewChild('confContent') confContent:ElementRef;
  @ViewChild('ermapview') ermapView:ElementRef;
  @ViewChild('holdsuccessfullyMessage') holdsuccessfullyMessage: TemplateRef<any>;
  @ViewChild('unitAndIsAgriPlot') unitAndIsAgriPlot: TemplateRef<any>;
  @ViewChild('Adjacent') Adjacent: TemplateRef<any>;
  @ViewChild('erNotFoundGuidelineModal') erNotFoundGuidelineModal:ElementRef;
  @ViewChild('f') form: NgForm;
  IsPanchayat:boolean=false;
  disableSaveBttn:boolean=true;
  mainAreaType: number = 1;
  filterType: string;
  prevFilterType:string;
  id: any
  isWebGisData:boolean=false
  islistShow:boolean=false;
  islCencelBtnShow:boolean=false;
  markerList:Array<L.Marker>=[];
  map:L.Map;
  private settings!: AppSetting;
  showMyContainer: boolean = false;
  status: boolean = false;
  statusLink: boolean = false;
  mstDemographyBeanList: MstDemographyBean[] = new Array();
  uomList: any[] = new Array();
  mstPropertyTypeBean: MstPropertyTypeBean[] = new Array();
  subClauseMasterBean: IgrsSubClausePropertyMappingBean[] = new Array();
  selectedMstDemographyBean: MstDemographyBean;
  propertyValuationRequestBean?: PropertyValuationRequestBean = new PropertyValuationRequestBean();
  mpenagarpalikamodel:MPenagarpalikaResponsModel
  prndData:PnrdModel
  agriPropertyValuationRequestBean: AgriPropertyValuationRequestBean = new AgriPropertyValuationRequestBean();
  buildingPropertyValuationRequestBean:BuildingPropertyValuationRequestBean=new BuildingPropertyValuationRequestBean();
  propertyValuationRequestBeanMap: PropertyValuationRequestBeanMap = new PropertyValuationRequestBeanMap();
  webGisResponseData: WebGisResponseBeanData=new WebGisResponseBeanData();
  webGisResponseDataArr: WebGisResponseBeanData[]=new Array();
  propertyValuationRequestBeanEmit: PropertyValuationRequestBean = new PropertyValuationRequestBean();
  propertyValuationRequestBeanRes:AgriPropertyValuationRequestBean
  igrsEregPropImgDisplayBean:IgrsEregPropImgDisplayBean
  lan: any;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  step = 0;
  guildeLineObj: any
  selectedDemography: string=null
  hideDropdownForMultipleDemography: boolean = false;
  selectDemography$: Subscription;
  pnrdInfo$:Subscription
  getmpenagarpalikaPropertyinfo$:Subscription
  etKhasraAndOwnerByKhasraId$:Subscription
  flag: boolean = true;
  isEnagarPalika:boolean=false
  params=new Array()
  cLRResponseModel:CLRResponseModel[]=[];
  uaddResponseModel:UaddResponseModel[]=[];
  propertyOwner: PropertyOwner[] = [];
  showOtherSearch:boolean=false;
  showOtherSearchActive:boolean=false;
  confObserver:Observer<boolean>;
  geoAddress:string="";
  fullGeoAddress:string=";"
  isHide:boolean=false
  workGroupId:number
  isCoowner:boolean=false
  isPnrd:boolean=false
  return:boolean=true
  propFlagReturn:boolean=true
  panFlagReturn:boolean=true

  placeHolder:string;
  filterType2:string="";
  isPlot:boolean=false
  pnrdId:number
  enagarpalikaId:number
  igrsEregTxnDetlsBean:IgrsEregTxnDetlsBean;
  districtlayer:any=null;
  type_id:number=3;
  parent_id:number=1;

  type_id2:number=3;
  parent_id2:number=1;


public markers: any = [];
  private demographyLoaded:boolean = false;
  selectPropertyLayer:any;
  selectPropertyLayer_2:any;
  selectGuideLineLayer:any;
  private mpBoundaryGeom:any;
  duplicateNset=new Set();
  
  bylatlng:boolean=false;
  needToadd_K_P_P:boolean=false;
  khasraColor:any[]=[
    {"bg":"background-color:#FF0000;","color":"color:#ffffff;","gc":"#FF0000"},
    {"bg":"background-color:#00FFFF;","color":"color:#000000;","gc":"#00FFFF"},
    {"bg":"background-color:#800080;","color":"color:#ffffff;","gc":"#800080"},
    {"bg":"background-color:#FFA500;","color":"color:#ffffff;","gc":"#FFA500"},
    {"bg":"background-color:#008000;","color":"color:#ffffff;","gc":"#008000"},
  ];
  geoTagid:string="";
  ismannualActive:boolean=true;
  getDemographyForSampada1:boolean=false;
  baseValue: string;
  minValue: number;
  maxValue: number;
  showChangeDemography:boolean =false;
  tempSelecteGuidlineLocation:MstDemographyBean;
  showRular:boolean=true;

  constructor(public matDialogRef: MatDialogRef<EregistryPropertyIdentificationComponent>,
    public matMannualDialogRef: MatDialogRef<MannualProcessPropertyIdentifationComponent>,
    private defaultServiceService:DefaultServiceService ,
    private router:Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private pvService: PropertyValuationService,
    private modalService: NgbModal,
    private ngxService: NgxUiLoaderService,
    private translateService: TranslateHEService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private appSettingsService: AppSettingService,
    @Inject(DOCUMENT) private document:Document,
    private renderer: Renderer2,
    public utilityService:UtilityService,
    private mapmyindia:MapMyindiaService,
    private queryLayerServices:QueryLayerServices,
    private protestChargesService: ProtestChargesService,
   ) {
 
  this.translateService.currentApprovalStageMessage.subscribe(msg =>{
    this.lan = msg
    this.translate.use(msg==1?'hi':'en')
    if(this.filterType=='propid'){
      this.lan==0? this.placeHolder="Please Enter Nagar Nigam / Nagar Palika / Nagar Parishad / PTR Property ID":this.placeHolder="कृपया नगर निगम / नगर पालिका / नगर परिषद / पीटीआर संपत्ति आईडी दर्ज करें"
    }
    else if(this.filterType=='panchayatid'){
      this.lan==0? this.placeHolder="Please Enter Panchayat Property ID":this.placeHolder="कृपया पंचायत संपत्ति आईडी दर्ज करें"
    }
    else if(this.filterType=='searchByGeotag'){
      this.lan==0? this.placeHolder="Please Enter SAMPADA Geo Tag ID":this.placeHolder="कृपया संपदा जियो टैग आईडी दर्ज करें"
    }
  } );

  this.filterType='khasraid';
  this.prevFilterType='khasraid';
  this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
  this.settings = settings;
  });
  console.log("property Identifation ",data)
  this.mapmyindia.saveTocken();
 this.isHide=data.isHideTab
  this.propertyValuationRequestBeanRes= data.propertyRequestBean!=undefined?data.propertyRequestBean: new PropertyValuationRequestBean();
 this.igrsEregTxnDetlsBean=data?.igrsEregTxnDetlsBean



      data.igrsEregTxnDetlsBean?.igrsEregPropIdentificationBean?.forEach(set=>{

        this. duplicateNset.add(set.propertyValuationRequestBean?.mPenagarpalikaResponseData?.propertyNo)
        this.duplicateNset.add(set.propertyValuationRequestBean?.pNRDResponseData?.propertyId)
        set.propertyValuationRequestBean?.webGisResponseBeanDataArr?.forEach(web=>{
          this.duplicateNset.add(web?.landParcelId)
          this.duplicateNset.add(web?.ULPin)
          
        })
      })
}

getPlotLabelchange(label:any){
  this.isPlot=false
 
if(label=='P'){
  this.isPlot=true
}
}

// restrictedNumberValidator(value: number): { [key: string]: boolean } | null {
//   const totalArea = this.propertyValuationRequestBeanEmit.totalArea;
//   if (value < totalArea * 0.96 || value > totalArea * 1.04) {
//     return { restrictedNumber: true };
//   }
//   return null;
// }

// restrictedNumberValidator(value: number): boolean {
//   return value >= this.minValue && value <= this.maxValue;
// }


  ngOnInit(): void {
    this.defaultServiceService.setDefaultType('property');
    this.getAllSubClause()
    this.getAllPropertyType();
    this.getAllUOM()
    //this.mpBoundaryGeom = this.mpBoundaryGeom.features[0].geometry;
    this.ngxService.startLoader('loader-identification');;
   
  }

 

  ngAfterViewInit():void{
    this.ismannualActive=(this.data?.ismannual != undefined)?this.data.ismannual:true;
    this.getDemographyForSampada1=(this.data?.getDemographyForSampada1 != undefined)?this.data.getDemographyForSampada1:false;
    this.hideMapview();
  }
  ngOnDestroy(){
    if(this.getmpenagarpalikaPropertyinfo$){
      // console.log(this.getmpenagarpalikaPropertyinfo$)
        this.getmpenagarpalikaPropertyinfo$.unsubscribe();
     }
     if(this.selectDemography$){
      // console.log(this.selectDemography$)
        this.selectDemography$.unsubscribe();
     }
     if(this.etKhasraAndOwnerByKhasraId$){
      // console.log(this.etKhasraAndOwnerByKhasraId$)
        this.etKhasraAndOwnerByKhasraId$.unsubscribe();
     }
     if(this.pnrdInfo$){
      this.pnrdInfo$.unsubscribe()
     }
  }
  getUnitMea(text:any):string{
    if(text=="HA"){
     return 'HA'
    }else if(text=='SQMT'){
     return 'SQM'
    }
      return' SFT'
  
  
   }
   getUnitMeaHi(text:any){
    if(text=="HA"){
      return 'हेक्टेयर'
     }else if(text=='SQMT'){
      return 'वर्ग मीटर'
     }
       return'वर्ग फुट'
   }

  getAllPropertyType() {
    this.pvService.getAllPropertyType().subscribe(dmodata => {
      this.mstPropertyTypeBean = dmodata.responseData;
    });

  }
  getAllSubClause() {
    this.pvService.getAllSubClauses().subscribe(dmodata => {

      this.subClauseMasterBean = dmodata.responseData;
      this.subClauseMasterBean=this.subClauseMasterBean.filter(s=>{return s.propertyTypeId==3})
      console.log(this.subClauseMasterBean)

  
    });

  }
  convertedUOM(uom:any){
    return this.utilityService.convertedUOM(uom,this.lan)
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  clearSearch(){
    //this.clearMarker(true);
    this.searcBox.nativeElement.value='';
    this.islistShow=false;
    this.islCencelBtnShow=false;
    if(this.ifManualEnagarPalika || this.ifManualPanchayat){
      this.removeGuidelineBoundry();
      this.removeDistrictBoundry();
      this.selectedMstDemographyBean=null;
      this.prndData=null;
      this.mpenagarpalikamodel = null;
      this.flag=true;
      this.mapReset();
    }
  }
  clearmarker(){
    this.clearMarker();
    this.removeGuidelineBoundry();
    this.removeDistrictBoundry();
    this.removePropertyBoundry();
    this.removePropertyBoundry_2();
  }

  ifManualEnagarPalika:boolean=false
  ifManualPanchayat:boolean=false
  isPreviousKhasraErrormsg:boolean=false
  selectKhIdAndPrIdAndUnid(filterType: any) {
    this.showChangeDemography=false;
    if(filterType == "searchByGeotag"){
      //this.clearMarker(true);
      if (this.geoTagid == null && this.geoTagid == undefined) {
        this.alertmessage.errorAlertMessage("property-valuation.kindly-enter-rural-id")
        return
      }
      this.ngxService.startLoader('loader-identification');
      this.pvService.getPropertyByGeoId(this.geoTagid).subscribe(res => {
        this.ngxService.stopLoader('loader-identification');
        if(res.responseStatus == 'true'){
          this.igrsEregPropImgDisplayBean=res.responseData
          console.log(res.responseData);
          var latlng = L.latLng(res.responseData.centerLattitude, res.responseData.centerLongitude);
          if(this.selectGuideLineLayer && Object.keys(this.selectGuideLineLayer?._layers)?.length>0 && 
          (this.selectedMstDemographyBean?.demographyTypeId == 15 || this.selectedMstDemographyBean?.demographyTypeId ==  14) &&
          this.checkMarkerInOut(latlng, this.selectGuideLineLayer.getLayers()[0].feature.geometry)){
              this.getAdressByLatLng(latlng).subscribe((address:string)=>{
                this.ngxService.stopLoader('loader-identification');
                this.openConfPopup(address).subscribe((value)=>{
                  if(value && this.needToadd_K_P_P){
                    this.clearMarker(true);
                    //this.setMarker(latlng, true);
                    this.getKhasraBoundry_2([latlng], 'geo_khasra', true);
                    this.needToaddKhasraAndproperty(latlng);
                  }else{
                    //marker.setLatLng(latlng);
                }});
              },(error:any)=>{
                this.ngxService.stopLoader('loader-identification');
              });
          }
          else if(this.districtlayer && this.checkMarkerInOut(latlng, this.districtlayer.getLayers()[0].feature.geometry)){
            if(this.filterType2 == "khasraid" && this.markers.length>0){
              this.alertmessage.errorAlertMessage({responseMessage:'The location of this Geotag ID is outside the guideline boundary, you have to select all Khasras in the same guideline.',
              responseMessageEn:'The location of this Geotag ID is outside the guideline boundary, you have to select all Khasras in the same guideline.',
              responseMessageHi:'इस जियोटैग आईडी का स्थान गाइडलाइन सीमा से बाहर है, आपको सभी खसरा एक ही गाइडलाइन में चुनना होगा।'})
            }else{
              this.outSideguidelineClickprocess({'latlng':latlng});
            }
          }else if(this.ifManualEnagarPalika || this.ifManualPanchayat){
            this.outSideguidelineClickprocess({'latlng':latlng});
          }
          else{
            this.ngxService.stopLoader('loader-identification');
            this.alertmessage.errorAlertMessage({responseMessage:'The location of this Geotag ID is outside the District Boundary',responseMessageEn:'The location of this Geotag ID is outside the District Boundary',responseMessageHi:'इस जियोटैग आईडी का स्थान ज़िला सीमा से बाहर है '});
          }
        }
        else{
          this.ngxService.stopLoader('loader-identification');
          // this.alertmessage.errorAlertMessage(res)
          setTimeout(()=>{
            Swal.fire({
              title: `${this.lan==0?"Something went wrong!":"कुछ गलत हो गया!"}`,
              html: this.lan==0?res?.responseMessageEn: res?.responseMessageHi,
              icon: "error",
              allowOutsideClick: false
            });
          }, 300);
       //   this.alertmessage.errorAlertMessage({responseMessage:'Kindly enter the valid Geo Id',responseMessageEn:'Kindly enter the valid Geo Id',responseMessageHi:'कृपया मान्य भू आईडी दर्ज करें'})
        }
      },(error:any)=>{
        this.ngxService.stopLoader('loader-identification');
      })
    }else{
      this.isCoowner=false
      this.return=true
      this.propFlagReturn=true
      this.panFlagReturn=true
      this.agriPropertyValuationRequestBean = new AgriPropertyValuationRequestBean();
      this.prndData = null;
      this.mpenagarpalikamodel=null;
      if (filterType == "khasraid") {
        this.clearmarker();
        this.mstDemographyBeanList=[];
        this.selectedDemography =null;
        if(this.params.length==10){
          this.id=null
          return
        }
  
        if (this.params.length==0) {
          this.alertmessage.errorAlertMessage("property-valuation.kindly-enter-khashra-id");
          return
        }
  
        if(this.propertyValuationRequestBeanRes!=undefined&& this.propertyValuationRequestBeanRes!=null){
        let idSet=new Set()
        idSet.clear();
        this.propertyValuationRequestBeanRes.webGisResponseBeanDataArr.forEach(web=>{
          idSet.add(web.ULPin)
          idSet.add(web.landParcelId)
         
        })
  
        console.log("propertyValuationRequestBeanRes set",idSet.size)
        this.params.forEach(p=>{
         if(idSet.has(p)){
          this.alertmessage.errorAlertMessage({responseMessage:'This property id is already added.',responseMessageEn:'This property id is already added',responseMessageHi:'यह प्रॉपर्टी आईडी पहले ही जोड़ी जा चुकी है'})
          this.return=false
  
         }
        })
        this.params.forEach(p=>{
          if(this.duplicateNset.has(p)){
           this.alertmessage.errorAlertMessage({responseMessage:'This property id is already added',responseMessageEn:'This property id is already added',responseMessageHi:'यह प्रॉपर्टी आईडी पहले ही जोड़ी जा चुकी है'})
           this.return=false
  
          }
         })
  
      }
      if(this.return){
        this.ngxService.startLoader('loader-identification');
  
        this.etKhasraAndOwnerByKhasraId$=this.pvService.getKhasraAndOwnerByKhasraId(
          this.params,this.propertyValuationRequestBeanRes,true,false).subscribe(
            res => {
          try {
  
          this.filterType2 = this.filterType;
          if(res.responseStatus == 'true'){
            this.ngxService.stopLoader('loader-identification');

            // this.protestChargesService.getProtestAndReleasePropertyDetails(this.params[0]).subscribe(res => {
            //   if(res.responseStatus == "true"){
            //    // alert("this property is logged");
            //     Swal.fire({
            //             title: "Are you sure to process?",
            //             text: "This Property is protested by "+res.responseData.applicantNameEn+" with ref no. "+res.responseData.protestInitiId,
            //             icon: "warning",
            //             showCancelButton: true,
            //             confirmButtonText: "Yes",
            //             allowOutsideClick: false
            //           }).then((result)=> {
            //               if (result.value) {
                           
            //               }
            //           });
            //     //this.alertmessage.successAlertMessage("this property is logged");
            //   }else{
            //     console.log('There was an error in getProtestAndReleasePropertyDetails()', res.responseMessage);
            //    // this.alertmessage.errorAlertMessage(res.responseMessage);
            //   }
            // }
            // );


            if(this.data?.districtId && res?.responseData?.demography && this.data?.districtId!=res.responseData.demography.location.district.id){
              this.alertmessage.errorAlertMessage({responseMessage:'Your property ID does not belong to this district',responseMessageEn:'Your property ID does not belong to this district',responseMessageHi:'आपकी संपत्ति आईडी इस जिले से संबंधित नहीं है'})
              return
            }
  
         this.propertyOwner=res.responseData.propertyOwner
         this.agriPropertyValuationRequestBean=res.responseData
         this.totalArea= this.agriPropertyValuationRequestBean.totalArea
         this.webGisResponseDataArr=res.responseData.webGisResponseBeanDataArr
         res.responseData.webGisResponseBeanDataArr?.forEach((w:WebGisResponseBeanData)=>{
          if((w.subClause!=null&&w.subClause!=undefined)&&w.subClause.length>0){
          w.subClause?.forEach((s:IgrsSubClausePropertyMappingBean)=>{
            this.subClauseMasterBean.forEach(d=>{
              if(d.id==s.id){
                d.checked=s.checked
                this.agriPropertyValuationRequestBean?.subClause.push(d)
              }
            })
        })
      }
      })
         if(this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr?.length>1 && this.agriPropertyValuationRequestBean?.isAdjacentKhashra!=undefined && this.agriPropertyValuationRequestBean?.isAdjacentKhashra==false){
          this.openPrimary(this.holdsuccessfullyMessage);
         }
         setTimeout(()=>this.registerDragElement(),300);
          if (null!=res.responseData.demography && null!=res.responseData.demography.demographyTypeId && res.responseData.demography.demographyTypeId == 10) {
            let khasradetail = res;
            this.selectedMstDemographyBean=res.responseData.demography
            this.ngxService.startLoader('loader-identification');
            this.selectDemography$= this.pvService.selectDemography(res.responseData.demography.id,15).subscribe(res => {
              if(res.responseStatus=='true'){
                this.ngxService.stopLoader('loader-identification');
                this.mstDemographyBeanList = res.responseData
                if(this.mstDemographyBeanList.length==1){
                this.mstDemographyBeanList.forEach(dempgraphy=>{
                  this.getCurrentGuildeLine(dempgraphy.id)
                  this.selectedMstDemographyBean=dempgraphy
                 this.agriPropertyValuationRequestBean.demography=dempgraphy
                  this.hideDropdownForMultipleDemography=false
                  this.convertWebGisKhasharaToRequest( this.agriPropertyValuationRequestBean)
                 })
                 let latlng:any={lat:Number(khasradetail.responseData.latitude),lng:Number(khasradetail.responseData.longitude)}
                 this.addMarker(latlng, this.agriPropertyValuationRequestBean)
                }
                else{
                  this.convertWebGisKhasharaToRequest( this.agriPropertyValuationRequestBean)
                  this.hideDropdownForMultipleDemography = true;
                  this.addMarker(null, this.agriPropertyValuationRequestBean);
                }
              }
              else{
                this.ngxService.stopLoader('loader-identification');
                this.alertmessage.errorAlertMessage(res)
                this.selectedDemography=null
                this.isWebGisData=false
              }
  
            })
          }
          else{
            // when get demography id got 15
            if(res.responseData.demography!=null){
            this.hideDropdownForMultipleDemography = false
            this.convertWebGisKhasharaToRequest(this.agriPropertyValuationRequestBean)
            this.getCurrentGuildeLine(res.responseData.demography.id)
            let latlng:any={lat:Number(res.responseData.latitude),lng:Number(res.responseData.longitude)}
            this.addMarker(latlng, this.agriPropertyValuationRequestBean)
          //  this.addMarker(null, this.webGisResponseDataArr)
            }
          }
          this.flag = false;
  
          this.mapReset();
        }
        else{
          //when fail response
          this.ngxService.stopLoader('loader-identification');
          this.alertmessage.errorAlertMessage(res)
          this.isWebGisData=false
          this.selectedDemography=null
          this.params=[]
          this.webGisResponseDataArr=[]
        }
      } catch (error) {
        this.ngxService.stopLoader('loader-identification');
            console.log(error)
      }
    },
    (error:any)=>{
      this.ngxService.stopLoader('loader-identification');
    });
      
  
    }
  
  
      }
      else if (filterType == "propid") {
        this.disableSaveBttn  = true;
        this.flag = true;
        this.selectedMstDemographyBean=null
        this.clearmarker();
        this.filterType='propid';
        this.filterType2 = this.filterType;
        if (this.id == null && this.id == undefined && this.id=='') {
          this.alertmessage.errorAlertMessage("property-valuation.kindly-enter-properid")
          return
        }
        if(this.duplicateNset.has(this.id)){
          this.alertmessage.errorAlertMessage({responseMessage:'This property id is already added',responseMessageEn:'This property id is already added',responseMessageHi:'यह प्रॉपर्टी आईडी पहले ही जोड़ी जा चुकी है'})
          this.propFlagReturn=false
  
         }
  
  
        if(this.propFlagReturn){
  
       this.ngxService.startLoader('loader-identification');
        this.getmpenagarpalikaPropertyinfo$= this.pvService.getmpenagarpalikaPropertyinfo(this.id,false).subscribe(res => {
         if(res.responseStatus == 'true'){
          if(this.data?.districtId && res?.responseData?.demography && this.data?.districtId!=res?.responseData?.demography?.location?.district?.id){
            this.ngxService.stopLoader('loader-identification')
            this.alertmessage.errorAlertMessage({responseMessage:'Your property ID does not belong to this district',responseMessageEn:'Your property ID does not belong to this district',responseMessageHi:'आपकी संपत्ति आईडी इस जिले से संबंधित नहीं है'})
            return
          }
         let mResponse:MPenagarpalikaResponsModel=  res.responseData
         this.enagarpalikaId=res.responseData.id

          let obj = { PropertyObject:res.responseData ,isTab:true};
          if(mResponse.mauunalProcess){
            this.ifManualEnagarPalika=true
            this.ngxService.stopLoader('loader-identification')
          
          this.showMapview();
          this.showOtherSearch=true;
          this.showPopup();
          this.filterType="bynameUrbon";
          }else{
          this.propertyOwner=res.responseData.propertyOwner
          this.ngxService.stopLoader('loader-identification')
            this.mpenagarpalikamodel=res.responseData
            this.filterType2 = this.filterType;
            setTimeout(()=>this.registerDragElement(),300);
            if (null!=res.responseData.demography && null!=res.responseData.demography.demographyTypeId &&res.responseData?.demography?.demographyTypeId == 8) {
              this.hideDropdownForMultipleDemography = false;
              let latlng:any;
              if(res.responseData.latitude && res.responseData.longitude && res.responseData.latitude != "" && res.responseData.longitude != ""){
                latlng={lat:Number(res.responseData.latitude),lng:Number(res.responseData.longitude)}
              }else{
                latlng=null;
              }
              this.addMarker(latlng,this.mpenagarpalikamodel);
              this.ngxService.startLoader('loader-identification');
              this.selectDemography$= this.pvService.selectDemography(res.responseData.demography.id,14).subscribe(res => {
  
                if(res.responseStatus=="true"){
                  this.ngxService.stopLoader('loader-identification')
                  this.mstDemographyBeanList = res.responseData
                  // if dropdown Value is single
                  if(this.mstDemographyBeanList.length==1){
                    //this.addMarker(latlng,res.responseData.demography);
                   
                    this.hideDropdownForMultipleDemography = true;
                    this.convertMpenagarPalikaPropertyToRequest( this.mpenagarpalikamodel)
                  }else{
                    this.hideDropdownForMultipleDemography = false;
                    this.convertMpenagarPalikaPropertyToRequest( this.mpenagarpalikamodel)
                  }
  
                }
                else{
                  this.ngxService.stopLoader('loader-identification')
                }
              })
            }else{
              this.hideDropdownForMultipleDemography = true;
              this.convertMpenagarPalikaPropertyToRequest( this.mpenagarpalikamodel)
              let latlng:any={lat:Number(res.responseData.latitude),lng:Number(res.responseData.longitude)}
              if(res.responseData.demography){
                this.addMarker(latlng,res.responseData);
                this.getCurrentGuildeLine(res.responseData.demography.id)
              }else{
                this.alertmessage.errorAlertMessage({responseMessage:'Demography not found.',responseMessageEn:'Demography not found.',responseMessageHi:'डेमोग्राफी नहीं मिली|'})
              }
              
            }
            this.flag = false;
            this.mapReset();
          }
          }
          
          else if(res.responseStatus == 'false'){
            this.ngxService.stopLoader('loader-identification')
            this.alertmessage.errorAlertMessage(res)
            this.selectedMstDemographyBean=null
          }
       },(error:any)=>{
        this.ngxService.stopLoader('loader-identification')
       })
        //this.ngxService.stopLoader('loader-identification');
  
      }
      }
      else if (filterType == "panchayatid") {
        this.disableSaveBttn  = true;
        //this.flag = true;
        this.filterType2 = this.filterType;
        this.selectedMstDemographyBean=null
        this.clearmarker();
        if (this.id == null && this.id == undefined) {
          this.alertmessage.errorAlertMessage("property-valuation.please-enter-Panchayat_id");
  
         // this.alertmessage.errorAlertMessage("'Please Enter Valid Id'")
          return;
        }
        if(this.duplicateNset.has(this.id)){
          this.alertmessage.errorAlertMessage({responseMessage:'This property id is already added',responseMessageEn:'This property id is already added',responseMessageHi:'यह प्रॉपर्टी आईडी पहले ही जोड़ी जा चुकी है'})
          this.panFlagReturn=false
  
         }
         if(this.panFlagReturn){
        this.ngxService.startLoader('loader-identification');
  
        this.pvService.getpnrdPropertyinfo(this.id,false).subscribe(res => {
  
         if(res.responseStatus == 'true'){
          if(this.data?.districtId && res?.responseData?.demography &&  this.data?.districtId!=res.responseData?.demography?.location?.district?.id){
            this.ngxService.stopLoader('loader-identification')
            //msg आपकी संपत्ति आईडी इस जिले से संबंधित नहीं है||Your property ID does not belong to this district
            this.alertmessage.errorAlertMessage({responseMessage:'Your property ID does not belong to this district',responseMessageEn:'Your property ID does not belong to this district',responseMessageHi:'आपकी संपत्ति आईडी इस जिले से संबंधित नहीं है'})
            return
          }
          let pnrdResponse:PnrdModel=  res.responseData
          this.pnrdId=res.responseData.id
          let obj = { PropertyObject:res.responseData,isTab:false};
          if(pnrdResponse.mauunalProcess){
            this.ifManualPanchayat=true;
            this.ngxService.stopLoader('loader-identification')
         this.showMapview();
         this.showOtherSearch=true;
         this.showPopup();
         this.filterType="bynameUrbon";
          //   this.filterType2 = this.filterType;
          //   this.ngxService.stopLoader('loader-identification')
          // this.matMannualDialogRef=this.dialog.open(MannualProcessPropertyIdentifationComponent, {
          //   data: obj,
          //   maxWidth:"60vw",
          //   height: 'auto',
          //   width: '60%',
          //   panelClass: 'full-screen-modal', disableClose: true
          // });
          // this.matMannualDialogRef.afterClosed()
          // .pipe()
          // .subscribe(result => {
          //   this.ngxService.stopLoader('loader-identification')
          //   console.log("mauunal Process result",result)
          //  this.prndData=result
          //   this.convertPanchayatToRequest(this.prndData)
          //  let latlng:any={lat:Number(this.prndData.demography.latitude),lng:Number(this.prndData.demography.longitude)}
          //   this.addMarker(latlng,this.prndData);
          //  this.flag = false;
          //   this.getCurrentGuildeLine(this.prndData.demography.id)
          // })
          }
          else{
          this.flag = false;
          this.ngxService.stopLoader('loader-identification');
          this.propertyOwner=res.responseData.propertyOwner
          this.mainAreaType=res.responseData.propertyTypeId
          this.prndData=res.responseData;
          this.filterType2 = this.filterType;
          setTimeout(()=>this.registerDragElement(),300);
          if (null!=res.responseData.demography && null!=res.responseData.demography.demographyTypeId &&res.responseData?.demography?.demographyTypeId == 10) {
            this.selectedMstDemographyBean=res.responseData.demography
            let latlng:any;
            if(res.responseData.latitude && res.responseData.longitude && res.responseData.latitude != "" && res.responseData.longitude != ""){
              latlng={lat:Number(res.responseData.latitude),lng:Number(res.responseData.longitude)}
            }else{
              latlng=null;
            }
            //this.addMarker(null, res.responseData.demography);
            this.selectDemography$= this.pvService.selectDemography(res.responseData.demography.id,15).subscribe(res => {
              //console.log("parent demography urban", res.responseData)
              if(res.responseStatus=="true"){
  
  
                this.mstDemographyBeanList = res.responseData
                if(this.mstDemographyBeanList.length==1){
                  this.mstDemographyBeanList.forEach(dempgraphy=>{
                    this.getCurrentGuildeLine(dempgraphy.id)
                    this.selectedMstDemographyBean=dempgraphy
                    this.prndData.demography=dempgraphy
                    this.addMarker(latlng, res.responseData[0]);
                    this.hideDropdownForMultipleDemography = false;
                    this.convertPanchayatToRequest(this.prndData)
                   })
  
                   return
                  }
                  else{
                    this.addMarker(latlng, this.prndData);
                    this.hideDropdownForMultipleDemography = true;
                    this.convertPanchayatToRequest(this.prndData)
                  }
  
  
  
              }
            })
          }
          else{
            this.mainAreaType=res.responseData.propertyTypeId
            this.convertPanchayatToRequest( this.prndData)
          }
        }
         }
         else{
          this.flag = true;
          this.ngxService.stopLoader('loader-identification');
          this.alertmessage.errorAlertMessage(res)
          this.alertmessage.errorAlertMessage(res)
         }
  
        },(error:any)=>{
          this.flag = true;
          this.ngxService.stopLoader('loader-identification')
         })
      }
      
      }
    }
    
  }

  onLoadMap(map:L.Map){
    try{
      this.map=map;
      //this.ngxService.stopLoader('loader-identification');
      this.pvService.getstateGeom().subscribe((res:any)=>{
        this.mpBoundaryGeom = res.features[0].geometry;
        this.ngxService.stopLoader('loader-identification');
      },(error:any)=>{
        this.ngxService.stopLoader('loader-identification');
      });
    }catch(error:any){
      this.ngxService.stopLoader('loader-identification');
    }
    // this.map.on('click', (e:any)=>{
    //   if(this.map.getZoom()<13){
    //     return;
    //   }
    //   if(this.demographyLoaded){
    //     Swal.fire({
    //       title: "Are you sure?",
    //       text: "you want to check other property",
    //       icon: "warning",
    //       showCancelButton: true,
    //       confirmButtonText: "Yes",
    //       allowOutsideClick: false
    //     }).then((result)=> {
    //         if (result.value) {
    //           this.clearMarker();
    //           this.addMarker(e.latlng);
    //         }
    //     });
    //   }else{
    //     this.clearMarker();
    //     this.addMarker(e.latlng);
    //   }
    // });
  }


  mapReset():void{
    if(this.map)
      setTimeout(()=>{ this.map.invalidateSize()}, 1000);
  }

  clickAreaTypeEvent(mainAreaType: any) {

    if (mainAreaType.id == 3) {
     this.mainAreaType=3
     this.selectedDemography=null
    }
    else if(mainAreaType.id==2){
     this.mainAreaType=2
     this.mpenagarpalikamodel=new MPenagarpalikaResponsModel();
     this.buildingPropertyValuationRequestBean=new BuildingPropertyValuationRequestBean();
     this.selectedDemography=null
    }
    else if(mainAreaType.id==1){
     this.mainAreaType=1
     this.selectedDemography=null
     this.propertyValuationRequestBean=new PropertyValuationRequestBean();
    }

 }








  getsuggetions(searchValue:any){

    if(searchValue.value.length>0){
      this.islCencelBtnShow=true;
    }else{
      this.islCencelBtnShow=false;
    }
    if(searchValue.value.length>2){
      let queryParams = new HttpParams();
      //queryParams = queryParams.append("query",`${searchValue.value}`);//.append("region","IND").append("filter","bounds:26.961246,73.952636;20.899871,82.961425");28.598882, 77.212407; 28.467375, 77.353513
      //this.pvService.getPlaceSuggetion(null, this.settings.suggestionURLofMapMyIndia, queryParams, this.settings.keyMapMyIndia).subscribe((response:any)=>{
      if(this.districtlayer && this.districtlayer!= null){
        let ne =this.districtlayer.getBounds().getNorthWest();
        let sw = this.districtlayer.getBounds().getSouthEast();
        queryParams = queryParams.append("query",`${searchValue.value}`).append('access_token', this.mapmyindia.getToken()).append("filter","bounds:"+ne.lat+","+ne.lng+";"+sw.lat+","+sw.lng);
      }else{
        queryParams = queryParams.append("query",`${searchValue.value}`).append('access_token', this.mapmyindia.getToken());
      } 
      this.pvService.getPlaceSuggetion(this.settings.suggestionURLofMapMyIndia, queryParams).subscribe((response:any)=>{
        if(response){
          this.islistShow=true;
          setTimeout(()=>{
            if(this.suggetionList?.nativeElement?.childNodes.length>0){
              this.suggetionList.nativeElement.replaceChildren();
            }
              response.suggestedLocations.forEach((list:any)=>{
              const sugetionList:HTMLElement = this.document.createElement('li');
              sugetionList.setAttribute('id',list.eLoc);
              sugetionList.setAttribute('class','suggetionListing')
              sugetionList.innerHTML=`<h6>${list.placeName}</h6><div>${list.placeAddress}</div>`;
              sugetionList.addEventListener('click',(e:Event)=>{
                  //this.clearmarker();
                  searchValue.value=list.placeName+` (${list.placeAddress})`;
                  this.suggetionList.nativeElement.replaceChildren();
                  this.getPosition(list);
                  this.islistShow=false;
              });
            this.renderer.appendChild(this.suggetionList.nativeElement, sugetionList);
          })
          }, 300)
        }
      },(error:any)=>{
        if(this.islistShow && this.suggetionList.nativeElement.childNodes.length>0){
          this.suggetionList.nativeElement.replaceChildren();
        }
        this.mapmyindia.saveTocken();
        //this.addLatLngInSuggetion(searchValue);
        if(error?.status == 401 || error?.responsecode == 400){
          
        }else{
          console.log(error);
        }
      })
    }else{
      this.islistShow=false;
    }




  }

  getAdressByLatLng(latlng:any):any{
    let queryParams = new HttpParams();
    return new Observable((observer) =>{
      let type = this.selectedMstDemographyBean?.demographyType?.demographyAreaType;
      if(this.filterType2 == "propid"){
        queryParams = queryParams.append("lat",latlng.lat).append("lng",latlng.lng).append('access_token', this.mapmyindia.getToken());
        this.pvService.getPlaceSuggetion(this.settings.getLatLngMapMyIndia, queryParams).subscribe((response:any)=>{
          if(response.responseCode=200){
            var result = response.results[0];
            //result="Distirct: "+result.district+", Village:" + result.village +", Sub-Area : "+result.formatted_address;
            let guideline:string="";
            if(this.selectedMstDemographyBean && type=="R"){
              guideline += "Village Colony - "+this.selectedMstDemographyBean?.location?.village_colony?.demographyName+", ";
           }else if(this.selectedMstDemographyBean && type=="U"){
              guideline += "Ward Colony - "+this.selectedMstDemographyBean?.location?.ward_colony?.demographyName+", ";
           }
            var _result=result.formatted_address.split(".")[1].trim();
            this.fullGeoAddress = guideline+"Distirct: "+result.district+", Village:" + result.village +", Sub-Area : "+result.formatted_address;
            observer.next(_result);
          }else{
           observer.next("Not Found");
          }
        },(error:any)=>{
          this.mapmyindia.saveTocken();
          return observer.next("Not Found");
        });
      }else if(this.filterType2 == "khasraid" || this.filterType2 == "panchayatid"){
        let tableName = 'geo_khasra';
        let dist:any=this.selectedMstDemographyBean?.location?.district;
        if(dist != undefined && dist != null && dist?.clrDistrictId){
          if(dist.clrDistrictId.toString().length==1){
            tableName += '_0'+dist.clrDistrictId;
          }else{
            tableName += '_'+dist.clrDistrictId;
          }
        }
        let query:string = "INTERSECTS(geom,%20POINT("+latlng.lng+" "+latlng.lat+"))";
        let url:string=this.queryLayerServices.getEncurlwithQuery(query, tableName);
        //this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3A'+tableName+'&CQL_FILTER=&maxFeatures=1&outputFormat=application%2Fjson&PropertyName=kid'
        
        this.queryLayerServices.customgetDecRequest('GET',url, null, null).subscribe((response:any)=>{
          this.ngxService.stopLoader('loader-identification');
          if(response.body?.features.length>0){
            var result = "";            
            let kid = (response?.body?.features[0].properties?.kid != null)?response?.body?.features[0].properties?.kid:"";
            result = (kid != "")?"Khasra No - "+kid+",":"";
           
            if(this.selectedMstDemographyBean && type=="R"){
               result += "Village Colony - "+this.selectedMstDemographyBean?.location?.village_colony?.demographyName+",";
               result += "Village - "+this.selectedMstDemographyBean?.location?.village?.demographyName+",";
               result += "Tehsil - "+this.selectedMstDemographyBean?.location?.tehsil?.demographyName+",";
               result += "District - "+this.selectedMstDemographyBean?.location?.district?.demographyName+",";
            }else if(this.selectedMstDemographyBean && type=="U"){
               result += "Ward Colony - "+this.selectedMstDemographyBean?.location?.ward_colony?.demographyName+",";
               result += "Ward - "+this.selectedMstDemographyBean?.location?.ward?.demographyName+",";
               result += "Local Body - "+this.selectedMstDemographyBean?.location?.localBody?.demographyName+",";
               result += "District - "+this.selectedMstDemographyBean?.location?.district?.demographyName+",";
            }
            this.fullGeoAddress = result;
            observer.next(result);
          } 
          else{
            observer.next("Not Found");
            //this.alertmessage.errorAlertMessage({responseMessage:'Geometry not found',responseMessageEn:'Geometry not found',responseMessageHi:'ज्यामिति नहीं मिली'});
          }
        },(error:any)=>{
          observer.next("Not Found");
          this.ngxService.stopLoader('loader-identification');
          console.log("error");
        })
      }else{
        observer.next("Not Found");
      }
    });
  }

   convertWebGisKhasharaToRequest(agriPropertyValuationRequestBean: AgriPropertyValuationRequestBean) {
    //this.totalArea=this.agriPropertyValuationRequestBean.totalArea
    this.isWebGisData=true
    this.selectedMstDemographyBean = agriPropertyValuationRequestBean.demography
    this.mstPropertyTypeBean.filter(data => {
      if (data.id === 3) {
        this.mainAreaType = 3
        this.agriPropertyValuationRequestBean.propertyType = data
      }
    })
    



  this.propertyValuationRequestBeanEmit=this.agriPropertyValuationRequestBean;
 // this.propertyValuationRequestBeanEmit.agriculturalWithPlot=this.utilityService.deepClone(this.agriPropertyValuationRequestBean)
 this.propertyValuationRequestBeanEmit.agriculturalWithPlot={...this.agriPropertyValuationRequestBean}
  
    // this.openPrimary(this.unitAndIsAgriPlot);
     this.isConversion=false
  }
  updatePlot(event:boolean){
    let unit:any="HA"
    console.log(event)
    if(event){
      this.agriPropertyValuationRequestBean.isPlotOnAgriLand=event
      
    }
    else{
      this.agriPropertyValuationRequestBean.isPlotOnAgriLand=event
      this.propertyValuationRequestBeanEmit.totalArea=this.totalArea
      this.propertyValuationRequestBeanEmit.overAllArea=this.totalArea
      this.propertyValuationRequestBeanEmit.partialTransactArea=this.totalArea
      this.propertyValuationRequestBeanEmit.unit=unit
      this.plotunit=""
      this.isConversion=false
    }
    
  }

  totalArea:number
  isConversion:boolean=false
 unitChangeInAgricultureLand(event:any){
  let unitType:any=event
  this.plotunit=event
  if(this.propertyValuationRequestBeanEmit.isPlotOnAgriLand){
  this.agriPropertyValuationRequestBean.unit=unitType
  this.propertyValuationRequestBeanEmit.totalArea=
  unitType=='SQMT'? this.utilityService.hectaresToSquareMeter(Number(this.totalArea)).toFixed(2):this.utilityService.hectaresToSquareFeet(Number(this.totalArea)).toFixed(2)
  this.propertyValuationRequestBeanEmit.overAllArea=
  unitType=='SQMT'?this.utilityService.hectaresToSquareMeter(Number(this.totalArea)).toFixed(2):this.utilityService.hectaresToSquareFeet(Number(this.totalArea)).toFixed(2)
  this.propertyValuationRequestBeanEmit.partialTransactArea=
  unitType=='SQMT'?this.utilityService.hectaresToSquareMeter(Number(this.totalArea)).toFixed(2):this.utilityService.hectaresToSquareFeet(Number(this.totalArea)).toFixed(2)
  this.isConversion=true
    

   
}
}
closeModelUnitSelection(f: NgForm){
  if (f.invalid) {
    return;
  }
  this.modalService.dismissAll();
}
getAllUOM() {
  this.pvService.getAllUOM().subscribe(dmodata => {
    // this.MstDemographyBeanList=dmodata.responseData;
    this.uomList = dmodata.responseData;
    
  });
}
isAllowedTochangeArea:boolean=false
startsWithB(str: string): boolean {
  return str.startsWith("B");
}
isPropertyTypeChange:boolean=false
  convertMpenagarPalikaPropertyToRequest(enagarpalikaModel: MPenagarpalikaResponsModel) {
   this.isPropertyTypeChange=false
    this.isAllowedTochangeArea=false
    this.mpenagarpalikamodel = enagarpalikaModel
    this.isEnagarPalika=true
    this.selectedMstDemographyBean = enagarpalikaModel.demography
    if(this.mpenagarpalikamodel.subClause!=null&&this.mpenagarpalikamodel.subClause!=undefined){
    this.mpenagarpalikamodel.subClause?.forEach(es=>{
      this.subClauseMasterBean.forEach(s=>{
        if(es.id==s.id){
         // s.checked
          this.buildingPropertyValuationRequestBean.subClause.push(s)
        }
      })
    })
  }
    if (2 === enagarpalikaModel.propertyTypeId) {
    this.isAllowedTochangeArea=enagarpalikaModel.demography.location.district.id==43
    this.baseValue=enagarpalikaModel.demography.location.district.id==43?String(enagarpalikaModel.totalArea):null
    this.mainAreaType = enagarpalikaModel.propertyTypeId
    this.buildingPropertyValuationRequestBean.demography = enagarpalikaModel.demography
    this.buildingPropertyValuationRequestBean.mPenagarpalikaResponseData=enagarpalikaModel
    this.buildingPropertyValuationRequestBean.buildingSubType=enagarpalikaModel.buildingSubType
    this.buildingPropertyValuationRequestBean.buildingType=enagarpalikaModel.buildingtype
    this.buildingPropertyValuationRequestBean.id=enagarpalikaModel.id;
    this.buildingPropertyValuationRequestBean.northboundary=enagarpalikaModel.DirectionNorth
    this.buildingPropertyValuationRequestBean.southboundary=enagarpalikaModel.DirectionSouth
    this.buildingPropertyValuationRequestBean.eastboundary=enagarpalikaModel.DirectionEast
    this.buildingPropertyValuationRequestBean.westboundary=enagarpalikaModel.DirectionWest
    this.buildingPropertyValuationRequestBean.totalArea=enagarpalikaModel.totalArea
    this.buildingPropertyValuationRequestBean.overAllArea=enagarpalikaModel.totalArea
    this.buildingPropertyValuationRequestBean.partialTransactArea=enagarpalikaModel.partialTransactArea??enagarpalikaModel.totalArea
    this.buildingPropertyValuationRequestBean.floorResidentialCumCommercialList=[]
    this.buildingPropertyValuationRequestBean.unit = '' + enagarpalikaModel.unit
    this.buildingPropertyValuationRequestBean.propertyOwner=enagarpalikaModel.propertyOwner
    this.buildingPropertyValuationRequestBean.propertySource="ENP"
    this.buildingPropertyValuationRequestBean.houseFlatNo=enagarpalikaModel.houseFlatNo
    this.buildingPropertyValuationRequestBean.custodiomDeparementIdArr.push(this.id)
    if(enagarpalikaModel.totalArea==0){
      this.buildingPropertyValuationRequestBean.mPenagarpalikaResponseData.isZero=true
    }
    if(this.mpenagarpalikamodel.address){
      this.buildingPropertyValuationRequestBean.address=this.mpenagarpalikamodel.address
    }
    }else if(1 === enagarpalikaModel.propertyTypeId){
      this.isPropertyTypeChange=true
      this.isAllowedTochangeArea=enagarpalikaModel.demography.location.district.id==43
      this.baseValue=enagarpalikaModel.demography.location.district.id==43?String(enagarpalikaModel.totalArea):null
      this.mainAreaType = enagarpalikaModel.propertyTypeId
      this.buildingPropertyValuationRequestBean.demography = enagarpalikaModel.demography
      this.buildingPropertyValuationRequestBean.mPenagarpalikaResponseData=enagarpalikaModel
      this.buildingPropertyValuationRequestBean.buildingSubType=enagarpalikaModel.buildingSubType
      this.buildingPropertyValuationRequestBean.buildingType=enagarpalikaModel.buildingtype
      this.buildingPropertyValuationRequestBean.id=enagarpalikaModel.id;
      this.buildingPropertyValuationRequestBean.northboundary=enagarpalikaModel.DirectionNorth
      this.buildingPropertyValuationRequestBean.southboundary=enagarpalikaModel.DirectionSouth
      this.buildingPropertyValuationRequestBean.eastboundary=enagarpalikaModel.DirectionEast
      this.buildingPropertyValuationRequestBean.westboundary=enagarpalikaModel.DirectionWest
      this.buildingPropertyValuationRequestBean.totalArea=enagarpalikaModel.totalArea
      this.buildingPropertyValuationRequestBean.overAllArea=enagarpalikaModel.totalArea
      this.buildingPropertyValuationRequestBean.partialTransactArea=enagarpalikaModel.partialTransactArea??enagarpalikaModel.totalArea
      this.buildingPropertyValuationRequestBean.floorResidentialCumCommercialList=[]
      this.buildingPropertyValuationRequestBean.unit = '' + enagarpalikaModel.unit
      this.buildingPropertyValuationRequestBean.propertyOwner=enagarpalikaModel.propertyOwner
      this.buildingPropertyValuationRequestBean.propertySource="ENP"
      if(enagarpalikaModel.totalArea==0){
        this.buildingPropertyValuationRequestBean.mPenagarpalikaResponseData.isZero=true
      }
      if(this.mpenagarpalikamodel.address){
        this.buildingPropertyValuationRequestBean.address=this.mpenagarpalikamodel.address
      }
      this.buildingPropertyValuationRequestBean.houseFlatNo=enagarpalikaModel.houseFlatNo
      this.buildingPropertyValuationRequestBean.custodiomDeparementIdArr.push(this.id)
    }

    this.mstPropertyTypeBean.filter(data => {
      if (data.id === enagarpalikaModel.propertyTypeId) {
        this.buildingPropertyValuationRequestBean.propertyType = data
      }
    })
    
    this.propertyValuationRequestBeanEmit=this.buildingPropertyValuationRequestBean
  }



  convertPanchayatToRequest(pnrdModel: PnrdModel) {
    this.prndData=pnrdModel
    this.isPnrd=true
    this.selectedMstDemographyBean = pnrdModel.demography
        if (2 === pnrdModel.propertyTypeId) {
          this.mainAreaType=pnrdModel.propertyTypeId
          this.buildingPropertyValuationRequestBean.id=pnrdModel.id;
          this.buildingPropertyValuationRequestBean.propertyType = this.mstPropertyTypeBean.find(id=>id.id==pnrdModel.propertyTypeId)
          this.buildingPropertyValuationRequestBean.demography = pnrdModel.demography
          this.buildingPropertyValuationRequestBean.buildingSubType=pnrdModel.buildingSubType
          this.buildingPropertyValuationRequestBean.buildingType=pnrdModel.buildingtype
          this.buildingPropertyValuationRequestBean.totalArea=pnrdModel.totalArea
          this.buildingPropertyValuationRequestBean.floorResidentialCumCommercialList=[]
          this.buildingPropertyValuationRequestBean.unit = '' + pnrdModel.unit
          this.buildingPropertyValuationRequestBean.propertyOwner=pnrdModel.propertyOwner
          this.buildingPropertyValuationRequestBean.pNRDResponseData=pnrdModel
          this.buildingPropertyValuationRequestBean.floorResidentialCumCommercialList=[]
          this.buildingPropertyValuationRequestBean.overAllArea = pnrdModel.totalArea
          this.buildingPropertyValuationRequestBean.partialTransactArea=pnrdModel.totalArea
          this.buildingPropertyValuationRequestBean.pNRDResponseData=pnrdModel
          this.buildingPropertyValuationRequestBean.propertySource="PNRD"
          this.buildingPropertyValuationRequestBean.southboundary=pnrdModel.directionNorth
          this.buildingPropertyValuationRequestBean.northboundary=pnrdModel.directionNorth
          this.buildingPropertyValuationRequestBean.westboundary=pnrdModel.directionWest
          this.buildingPropertyValuationRequestBean.eastboundary=pnrdModel.directionEast
          this.buildingPropertyValuationRequestBean.address=pnrdModel.property_Address
          this.buildingPropertyValuationRequestBean.houseFlatNo=pnrdModel.houseFlatNo
          this.propertyValuationRequestBeanEmit=this.buildingPropertyValuationRequestBean
          if(pnrdModel?.address){
            this.buildingPropertyValuationRequestBean.address=pnrdModel.address
          }
        }
        else if(1 === pnrdModel.propertyTypeId){
          this.mainAreaType=pnrdModel.propertyTypeId
          this.propertyValuationRequestBean.id=pnrdModel.id;
          this.propertyValuationRequestBean.propertyType =  this.buildingPropertyValuationRequestBean.propertyType = this.mstPropertyTypeBean.find(id=>id.id==pnrdModel.propertyTypeId)
          this.propertyValuationRequestBean.demography = pnrdModel.demography
          this.buildingPropertyValuationRequestBean.buildingSubType=pnrdModel.buildingSubType
          this.buildingPropertyValuationRequestBean.buildingType=pnrdModel.buildingtype
          this.propertyValuationRequestBean.totalArea=pnrdModel.totalArea
          this.propertyValuationRequestBean.overAllArea = pnrdModel.totalArea
          this.propertyValuationRequestBean.partialTransactArea=pnrdModel.totalArea
          this.propertyValuationRequestBean.floorResidentialCumCommercialList=[]
          this.propertyValuationRequestBean.unit = '' + pnrdModel.unit
          this.propertyValuationRequestBean.propertyOwner=pnrdModel.propertyOwner
          this.propertyValuationRequestBean.pNRDResponseData=pnrdModel
          this.propertyValuationRequestBean.propertySource="PNRD"
          this.propertyValuationRequestBean.pNRDResponseData=pnrdModel
          this.propertyValuationRequestBean.southboundary=pnrdModel.directionSouth
          this.propertyValuationRequestBean.northboundary=pnrdModel.directionNorth
          this.propertyValuationRequestBean.westboundary=pnrdModel.directionWest
          this.propertyValuationRequestBean.eastboundary=pnrdModel.directionEast
          this.propertyValuationRequestBean.address=pnrdModel.property_Address
          this.propertyValuationRequestBean.houseFlatNo=pnrdModel.houseFlatNo
          this.propertyValuationRequestBeanEmit=this.propertyValuationRequestBean

          if(pnrdModel.address){
            this.propertyValuationRequestBean.address=pnrdModel.address
          }
        }



      this.flag=false;

    }


  add(event: MatChipInputEvent): void {

    const value = (event.value || '').trim();
    if (value&&value!='') {
      if((this.propertyValuationRequestBeanEmit!=null||undefined)&&this.propertyValuationRequestBeanEmit.isPlotOnAgriLand){
        //remove last element
        this.params = this.params.slice(0, 1);
        event.chipInput!.clear();
       this.alertmessage.errorAlertMessage(
        {responseMessage:'you can not add other khasra in already existing khasra which is being transacted as plot.'
        ,responseMessageEn:'you can not add other khasra in already existing khasra which is being transacted as plot.'
        ,responseMessageHi:'आप पहले से मौजूद खसरे में कोई अन्य खसरा नहीं जोड़ सकते हैं जिसे प्लॉट के रूप में लेन-देन किया जा रहा है।'})
      }
      else{
        this.params.push(value)
      }
    }
    this.params=[...new Set(this.params)]
    event.chipInput!.clear();
  }


  omit_special_charAdress(event:any)
  {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
      return true;    
  }

  omit_special_char(event:any){
    var k;
      k = event.charCode;
       
       return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k != 32 || (k >= 48 && k <= 57));

  }
  allFormReset(drawer?: any,type?:string){
    if(type && this.prevFilterType == type)
      return;
    this.prevFilterType = type;
    this.id = null
    this.selectedDemography=null
    this.selectedMstDemographyBean = null
    this.hideDropdownForMultipleDemography = false
    this.propertyValuationRequestBean=new PropertyValuationRequestBean();
    this.agriPropertyValuationRequestBean = new AgriPropertyValuationRequestBean();
    this.prndData = null;
    this.pnrdId=null;
    this.mpenagarpalikamodel=null;
    this.isWebGisData=false
    this.params=[]
    this.clearmarker();
    this.mapReset();
    this.mstDemographyBeanList=[];
    this.flag = true;
    this.showOtherSearchActive=false;
    this.ifManualEnagarPalika = false;
    this.ifManualPanchayat = false;
    this.hiddenvalue = "hidden";
    this.resetDemo=false;
    this.islCencelBtnShow=false;
    setTimeout(()=>{
      if(this.filterType == 'byname'){
        //this.mapcomponent.googlesearch();
      }
    },500);

  }
result: { containsAlphabetic: boolean, containsSpecialChar: boolean } = { containsAlphabetic: false, containsSpecialChar: false };
  checkInput(inputText: string) {
    // Regular expressions to match alphabetic characters and special characters
    const alphabeticRegex = /[a-zA-Z]/;
    const specialCharRegex = /[^a-zA-Z\s]/;

    const containsAlphabetic = alphabeticRegex.test(inputText);
    const containsSpecialChar = specialCharRegex.test(inputText);

    return this.result= { containsAlphabetic, containsSpecialChar }
  }

  remove(fruit: any ,drawer?:any): void {
    const index = this.params.indexOf(fruit);

    if (index >= 0) {
      this.params.splice(index, 1);
    }
    this.isWebGisData=false
    this.selectedMstDemographyBean=null
    this.webGisResponseData=null;
    this.flag = true;
    this.agriPropertyValuationRequestBean = new AgriPropertyValuationRequestBean();
    this.clearmarker();
    this.mapReset();
  }

  receivedLatAndLngHandler(p: any, bydropdown:boolean=false) {
    //console.log(p);
    // this.isWebGisData=false
    this.selectedMstDemographyBean=null
    this.needToadd_K_P_P = false;
    this.disableSaveBttn =false;
    this.showOtherSearchActive=false;
    this.showOtherSearch =false;
    //this.propertyValuationRequestBean.isConstOnAgriLand=false
    this.hideDropdownForMultipleDemography=true;
    this.mstDemographyBeanList = new Array();
    //this.id=''
    this.ngxService.startLoader('loader-identification');
    this.pvService.getDemographyDataByLatlng(p.latlng.lat, p.latlng.lng).subscribe(resp => {
      if (resp.responseStatus == 'true') {
        this.ngxService.stopLoader('loader-identification');
        // this.uaddResponseModel=resp?.responseData?.listUaddPropertyBean
        this.selectedMstDemographyBean = resp?.responseData?.mstDemographyBean;
        //this.getCurrentGuildeLine(resp.responseData.mstDemographyBean.id)
        this.guildeLineObj = {}
        this.guildeLineObj['demography'] = resp?.responseData?.mstDemographyBean;
        this.selectDemographybyDropDown(this.selectedMstDemographyBean, null, p);
        if(this.filterType2 == "khasraid"   && this.agriPropertyValuationRequestBean){
          this.filterType = "khasraid";
          this.agriPropertyValuationRequestBean.demography = this.selectedMstDemographyBean;
          this.addMarker(null,this.agriPropertyValuationRequestBean);
        }else if (this.filterType2 == "propid"  && this.mpenagarpalikamodel){
          this.filterType = "propid";
          this.propertyValuationRequestBean.demography=this.selectedMstDemographyBean;
          this.buildingPropertyValuationRequestBean.demography = this.selectedMstDemographyBean;
          this.mpenagarpalikamodel.demography = this.selectedMstDemographyBean;
          this.addMarker(null,this.mpenagarpalikamodel);
        } else if (this.filterType2 == "panchayatid" && this.prndData){
          this.filterType = "panchayatid";
          this.propertyValuationRequestBean.demography=this.selectedMstDemographyBean;
          this.buildingPropertyValuationRequestBean.demography = this.selectedMstDemographyBean;
          this.prndData.demography = this.selectedMstDemographyBean;
          this.addMarker(null,this.prndData);
        }else{
          if(this.enagarpalikaId){
            this.mpenagarpalikamodel=new MPenagarpalikaResponsModel()
            this.mpenagarpalikamodel.demography=resp.responseData.mstDemographyBean;
            this.mpenagarpalikamodel.id=this.enagarpalikaId;
            this.getCurrentGuildeLine(resp.responseData.mstDemographyBean.id)
          }
          if(this.pnrdId){
            this.prndData=new PnrdModel()
            this.prndData.demography=resp.responseData.mstDemographyBean;
            this.prndData.id=this.pnrdId;
            this.getCurrentGuildeLine(resp.responseData.mstDemographyBean.id)
          }
          var obj = {"demography":resp?.responseData?.mstDemographyBean};
          if(this.filterType == "searchByGeotag"){
            this.addMarker(p.latlng, obj);
          }else if(bydropdown){
            this.addMarker(p.latlng, obj);
          }else{
            this.addMarker(null, obj);
          }
        }
       
      }else {
        this.ngxService.stopLoader('loader-identification');
        this.guildeLineObj=null
        this.selectedDemography=null
        this.alertmessage.errorAlertMessage({responseMessage:'No demography details available in this location',responseMessageEn:'No demography details available in this location',responseMessageHi:'इस स्थान पर कोई डेमोग्राफी विवरण उपलब्ध नहीं है'})
      }
        this.mapReset();
    })
   this.flag = false;

   this.mapReset();
  }


  clearDataWhenDataNotFound(){
    //this.alertmessage.errorAlertMessage({responseMessage:'You have Click or Search Outsite of Boundary',responseMessageEn:'You Had Click or Search Outsite of Boundary',responseMessageHi:'आपने सीमा के बाहर क्लिक या सर्च किया है '})
    this.alertmessage.errorAlertMessage("property-valuation.click-outsidemap");
    this.isWebGisData=false
    this.selectedMstDemographyBean=null
    //this.disableSaveBttn = false;
    this.mapReset();
  }

 

  selectDemographybyDropDown(item: any, drawer?: any,p:any=null) {
    this.getCurrentGuildeLine(item.id)
    if(this.mainAreaType==3){

      this.agriPropertyValuationRequestBean.demography = item;
      this.propertyValuationRequestBeanEmit.demography=item
    }
    else if(this.mainAreaType==2){
      this.buildingPropertyValuationRequestBean.demography = item;
      this.propertyValuationRequestBeanEmit.demography=item
    }
    else{
      this.propertyValuationRequestBean.demography=item
      this.propertyValuationRequestBeanEmit.demography = item
     // this.propertyValuationRequestBeanEmit.pNRDResponseData=item
    }
    this.propertyValuationRequestBean.demography = item;
    if (this.propertyValuationRequestBeanEmit.pNRDResponseData) {
      this.propertyValuationRequestBeanEmit.pNRDResponseData.demography = item
    }
    //this.addMarker(null, this.propertyValuationRequestBean.demography);
    if(this.mpenagarpalikamodel && this.mpenagarpalikamodel != null){
      this.mpenagarpalikamodel.latitude=null;
      this.mpenagarpalikamodel.longitude=null;
      this.propertyValuationRequestBean.address=null;
    }
    if(this.prndData && this.prndData != null){
      this.prndData.latitude=null;
      this.prndData.longitude=null;
      this.propertyValuationRequestBean.address=null;
    }
    if(this.propertyValuationRequestBean && this.propertyValuationRequestBean != null)
      this.propertyValuationRequestBean.address=null;
    
    if(p!=null){
      this.clearmarker();
      if(this.filterType2 == "khasraid" && this.agriPropertyValuationRequestBean?.webGisResponseBeanDataArr?.length>0){
        this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr[0].latitude=p.latlng.lat;
        this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr[0].longitude=p.latlng.lng;
        this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr[0].demography=this.selectedMstDemographyBean;
      }else if(this.mpenagarpalikamodel && this.mpenagarpalikamodel != null){
        this.mpenagarpalikamodel.latitude=p.latlng.lat;
        this.mpenagarpalikamodel.longitude=p.latlng.lng;
        this.mpenagarpalikamodel.demography = this.selectedMstDemographyBean;
        //this.propertyValuationRequestBean.address=null;
      }else if(this.prndData && this.prndData != null){
        this.prndData.latitude=p.latlng.lat;
        this.prndData.longitude=p.latlng.lng;
        this.mpenagarpalikamodel.demography = this.selectedMstDemographyBean;
        //this.propertyValuationRequestBean.address=null;
      }
    }else{
      this.clearMarker();
      this.removeGuidelineBoundry();
      this.removePropertyBoundry();
      this.removePropertyBoundry_2();
      this.agriPropertyValuationRequestBean?.webGisResponseBeanDataArr.forEach((prop:any)=>{
        prop.latitude=null;
        prop.longitude=null;
        this.agriPropertyValuationRequestBean.address=null;
      });
      if(this.mpenagarpalikamodel && this.mpenagarpalikamodel != null){
        this.mpenagarpalikamodel.latitude=null;
        this.mpenagarpalikamodel.longitude=null;
        //this.propertyValuationRequestBean.address=null;
      }else if(this.prndData && this.prndData != null){
        this.prndData.latitude=null;
        this.prndData.longitude=null;
        //this.propertyValuationRequestBean.address=null;
      }
      this.getdemographyGeom(this.propertyValuationRequestBean.demography);
      this.needToadd_K_P_P = true;
    }
  }



  flag1:boolean=false;
  leftColtoggle(){
    if(this.flag==false){
      this.flag = true;
      this.flag1=true;
    }else{
      this.flag = false;
      this.flag1=false;
    }

  }

  showAlert(){
    try {
      this.modalService.dismissAll();
    } catch (e) {
      console.error('Storage upload error', e);
    }


    console.log(this.agriPropertyValuationRequestBean.isCoOwner)
    console.log(this.agriPropertyValuationRequestBean.isOwnerSameFamily)

   if(this.agriPropertyValuationRequestBean.isCoOwner==false &&this.agriPropertyValuationRequestBean.isOwnerSameFamily==false){

    this.modalService.open(this.Adjacent, {
      windowClass:"modalNew",
      size: 'sm',
      centered: true
    });
  }
  }
  closeAlert(){
    try {
      this.disableSaveBttn  = true;
      this.modalService.dismissAll();
      this.selectedMstDemographyBean=null
      this.id=null
      this.params=[]
      this.hideMapview();
     // this.matDialogRef.close();
    } catch (e) {
      console.error('Storage upload error', e);
    }
  }

  plotunit:string=""
  emitDataEregModule(propertyValuationRequestBean?:PropertyValuationRequestBean){
    
   
    //console.log(this.form)
    if(this.isWebGisData&&this.form){
     this.form.submitted=true
    if(this.form.invalid){
     // console.log(this.form)
     return
    }
  }
  if(this.isAllowedTochangeArea&&this.form){
    this.form.submitted=true
   if(this.form.invalid){
     console.log(this.form)
    return
   }
 }
    //console.log(this.selectedMstDemographyBean)
   
    this.ngxService.startLoader('loader-identification');
    
    if(this.propertyValuationRequestBeanEmit.isPlotOnAgriLand){
      const options: Intl.NumberFormatOptions = {
        minimumFractionDigits: 0, // Minimum number of fraction digits
        maximumFractionDigits: 2, 
        useGrouping: false // Maximum number of fraction digits
        };
        const formattedArea = (area: number) => new Intl.NumberFormat('en-US', options).format(area);
      if(this.plotunit=='SQFT'&&(this.form.controls['restrictedNumber']&&this.form.controls['restrictedNumber'].value<2500)){
        let unitType:any=this.propertyValuationRequestBeanEmit.unit
        this.baseValue=this.form.controls['restrictedNumber'].value
        this.propertyValuationRequestBeanEmit.overAllArea=this.baseValue
        this.propertyValuationRequestBeanEmit.totalArea=this.baseValue
        this.propertyValuationRequestBeanEmit.agriculturalWithPlot.demography=this.selectedMstDemographyBean
        this.propertyValuationRequestBeanEmit.partialTransactArea=this.baseValue
        this.propertyValuationRequestBeanEmit.webGisResponseBeanDataArr.forEach(web=>{
          web.totalArea=this.baseValue
          web.partialTransactArea=this.baseValue
          web.unit=unitType
          web.propertyOwner.forEach(owner=>{
            owner.ownerArea=this.baseValue
            if(null!=owner.ownerShare){
              const parts: string[] = owner.ownerShare.split("/");
              const numerator: number = parseInt(parts[0], 10);
              const denominator: number = parseInt(parts[1], 10);
            if(!isNaN(denominator)){
            const decimalValue: number = numerator / denominator;
            const percentage: number = decimalValue * 100;
            owner.ownerShare=String(formattedArea(percentage))
            let oneShare=Number(this.baseValue)/100
            owner.ownerArea=String(Number(oneShare * Number(owner.ownerShare)).toFixed(2))
            }
            else{
              const percentage: number = Number(owner.ownerShare)
              owner.ownerShare=String(formattedArea(percentage* 100))
            }
            }
          })
        })
      }else{
        let unitType:any=this.propertyValuationRequestBeanEmit.unit
        this.propertyValuationRequestBeanEmit.webGisResponseBeanDataArr.forEach(web=>{
          web.totalArea=unitType=='SQMT'?this.utilityService.hectaresToSquareMeter(Number( web.totalArea)).toFixed(2):this.utilityService.hectaresToSquareFeet(Number(web.totalArea)).toFixed(2)
          web.partialTransactArea=unitType=='SQMT'?this.utilityService.hectaresToSquareMeter(Number( web.partialTransactArea)).toFixed(2):this.utilityService.hectaresToSquareFeet(Number(web.partialTransactArea)).toFixed(2)
          if(unitType=="SQMT"){
              web.unit=unitType
          }
          else{
              web.unit=unitType
         }
          web.propertyOwner.forEach(owner=>{
          
            if(owner.ownerShare){
            owner.ownerArea=String(unitType=='SQMT'?this.utilityService.hectaresToSquareMeter(Number(owner.ownerArea)).toFixed(2):this.utilityService.hectaresToSquareFeet(Number(owner.ownerArea)).toFixed(2))
            const parts: string[] = owner.ownerShare.split("/");
            
            const numerator: number = parseInt(parts[0], 10);
            const denominator: number = parseInt(parts[1], 10);
            if(!isNaN(denominator)){
            const decimalValue: number = numerator / denominator;
            const percentage: number = decimalValue * 100;
            owner.ownerShare=String(formattedArea(percentage))
            }
            else{
              const percentage: number = Number(owner.ownerShare)
              owner.ownerShare=String(formattedArea(percentage* 100))
            }}
            else{
              owner.ownerShare=""
            }
          })
        })
      }
      if(this.propertyValuationRequestBeanEmit.agriculturalWithPlot){
        this.propertyValuationRequestBeanEmit.buildingAreaType="no"
        this.propertyValuationRequestBeanEmit.residentialCumCommercialPlotArea=''
        this.propertyValuationRequestBeanEmit.residentialPlotArea=''
        this.propertyValuationRequestBeanEmit.commercialPlotArea=''
        this.propertyValuationRequestBeanEmit.industrialPlotArea=''
        this.propertyValuationRequestBeanEmit.healthPlotArea=''
        this.propertyValuationRequestBeanEmit.eduPlotArea=''
        this.propertyValuationRequestBeanEmit.othersPlotArea=''
        }
      
    }
    // allowed to change Area
    if(this.isAllowedTochangeArea && this.form.controls['allowedAreaNumber']&&this.form.controls['allowedAreaNumber'].value){
      this.baseValue=this.form.controls['allowedAreaNumber'].value
      this.propertyValuationRequestBeanEmit.totalArea=this.form.controls['allowedAreaNumber'].value
      this.propertyValuationRequestBeanEmit.overAllArea=this.form.controls['allowedAreaNumber'].value
      this.propertyValuationRequestBeanEmit.partialTransactArea=this.form.controls['allowedAreaNumber'].value
      this.propertyValuationRequestBeanEmit.mPenagarpalikaResponseData.totalArea=this.form.controls['allowedAreaNumber'].value
      this.propertyValuationRequestBeanEmit.mPenagarpalikaResponseData.partialTransactArea=this.form.controls['allowedAreaNumber'].value
    }
   
    this.pvService.propIndentifation(this.propertyValuationRequestBeanEmit).subscribe(res=>{
      if(res.responseStatus=="true"){
        this.ngxService.stopLoader('loader-identification');
        console.log("save Identifaction Response",res)
        let propertyValuationRequestBean:PropertyValuationRequestBean
        propertyValuationRequestBean=res.responseData
        if(this.igrsEregPropImgDisplayBean!=undefined&&this.igrsEregPropImgDisplayBean!=null){
          propertyValuationRequestBean.igrsEregPropImgDisplayBean=this.igrsEregPropImgDisplayBean
          propertyValuationRequestBean.igrsEregPropImgDisplayBean.imgRefId=this.geoTagid
        }
        if(this.propertyValuationRequestBeanEmit.mPenagarpalikaResponseData!=undefined){
        propertyValuationRequestBean.mPenagarpalikaResponseData.isZero=this.propertyValuationRequestBeanEmit.mPenagarpalikaResponseData.isZero
        }
        // propertyValuationRequestBean.propertySource=this.propertyValuationRequestBeanEmit.propertySource
        // propertyValuationRequestBean.workGroupId=this.propertyValuationRequestBeanEmit.workGroupId
        console.log("pass data to validation component",propertyValuationRequestBean)
        this.matDialogRef.close(propertyValuationRequestBean);
      }
      else{
        this.ngxService.stopLoader('loader-identification');
        this.alertmessage.errorAlertMessage(res)
      }

    })


  }

  emitDataEregModule2(){
    if(this.propertyValuationRequestBeanEmit.isPlotOnAgriLand){
      const options: Intl.NumberFormatOptions = {
        minimumFractionDigits: 0, // Minimum number of fraction digits
        maximumFractionDigits: 2, 
        useGrouping: false // Maximum number of fraction digits
        };
        const formattedArea = (area: number) => new Intl.NumberFormat('en-US', options).format(area);
      let unitType:any=this.propertyValuationRequestBeanEmit.unit
      this.propertyValuationRequestBeanEmit.webGisResponseBeanDataArr.forEach(web=>{
       
        web.totalArea=unitType=='SQMT'?this.utilityService.hectaresToSquareMeter(Number( web.totalArea)).toFixed(2):this.utilityService.hectaresToSquareFeet(Number(web.totalArea)).toFixed(2)
        web.partialTransactArea=unitType=='SQMT'?this.utilityService.hectaresToSquareMeter(Number( web.partialTransactArea)).toFixed(2):this.utilityService.hectaresToSquareFeet(Number(web.partialTransactArea)).toFixed(2)
        if(unitType=="SQMT"){
            web.unit=unitType
        }
        else{
            web.unit=unitType
       }
        web.propertyOwner.forEach(owner=>{
         
          if(owner.ownerShare){
            owner.ownerArea=String(unitType=='SQMT'?this.utilityService.hectaresToSquareMeter(Number(owner.ownerArea)).toFixed(2):this.utilityService.hectaresToSquareFeet(Number(owner.ownerArea)).toFixed(2))
          const parts: string[] = owner.ownerShare.split("/");
          
          const numerator: number = parseInt(parts[0], 10);
          const denominator: number = parseInt(parts[1], 10);
          if(!isNaN(denominator)){
          const decimalValue: number = numerator / denominator;
          const percentage: number = decimalValue * 100;
          owner.ownerShare=String(formattedArea(percentage))
          }
          else{
            const percentage: number = Number(owner.ownerShare)
            owner.ownerShare=String(formattedArea(percentage* 100))
          }
        }else{
          owner.ownerShare=""
        }
        })
      })
      if(this.propertyValuationRequestBeanEmit.agriculturalWithPlot){
        this.propertyValuationRequestBeanEmit.buildingAreaType="no"
        this.propertyValuationRequestBeanEmit.residentialCumCommercialPlotArea=''
        this.propertyValuationRequestBeanEmit.residentialPlotArea=''
        this.propertyValuationRequestBeanEmit.commercialPlotArea=''
        this.propertyValuationRequestBeanEmit.industrialPlotArea=''
        this.propertyValuationRequestBeanEmit.healthPlotArea=''
        this.propertyValuationRequestBeanEmit.eduPlotArea=''
        this.propertyValuationRequestBeanEmit.othersPlotArea=''
        }
    }
   
    this.matDialogRef.close(this.propertyValuationRequestBeanEmit);
  }
  uaddpropId:any
  getUaddObjectByEnpId(event:any){

    if(event){
      this.id=event
      console.log(event)
      this.selectKhIdAndPrIdAndUnid('propid')
      console.log(event)
    }

  }

  openPropertyDetailDialog(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef  , {
      maxWidth: '30vw',
      width: '100%',
     });
  }

  closeModal2(){
    this.modalService.dismissAll()
  }

  getCurrentGuildeLine(demography_id:number){
    //this.ngxService.startLoader('loader-identification');
    this.pvService.getCurrentGLbyDemography(demography_id).subscribe(res => {
      //  console.log("guilde Line", res)
        if (res.responseStatus == 'true') {
          //this.ngxService.stopLoader('loader-identification');
          this.guildeLineObj = res.responseData
          this.selectedMstDemographyBean = res.responseData?.demography;
        }
        else if(res.responseStatus == 'false'){
          this.alertmessage.errorAlertMessage({responseMessage:'No GuildeLine Rate details available in this location',responseMessageEn:'No GuildeLine Rate details available in this location',responseMessageHi:'इस स्थान पर कोई गाईडलाइन दर  विवरण उपलब्ध नहीं है'})
          this.guildeLineObj = null
          //this.ngxService.stopLoader('loader-identification');
        }
      })
  }



  popup: any;

  openverificationMobileDialog(content: any) {
    this.modalService.open(content,
      {
        ariaLabelledBy: 'modal-primary-title',
        size: 'lg',
        windowClass: 'custom-class',
        centered: true,
        scrollable: true
    });
  }


  popucClose(){
    const element = this.popup?.getElement();
    $(element).popover("dispose");

  }

  getPosition(response:any){
    //this.pvService.customgetRequest('GET', this.settings.getLatLngMapMyIndia+eloc, null, this.settings.keyMapMyIndia).subscribe((response:any)=>{
      //if(response){
        var latlng = L.latLng(response.latitude, response.longitude);
        if(this.selectGuideLineLayer || this.districtlayer){
          if(this.selectGuideLineLayer && Object.keys(this.selectGuideLineLayer?._layers)?.length>0 && 
          (this.selectedMstDemographyBean?.demographyTypeId == 15 || this.selectedMstDemographyBean?.demographyTypeId ==  14) && this.checkMarkerInOut(latlng, this.selectGuideLineLayer?.getLayers()[0].feature.geometry)){
            this.setMarker(latlng, true);
            if(this.filterType2 == "khasraid" || this.filterType2 == "panchayatid"){
              this.getKhasraBoundry_2([latlng], 'geo_khasra', false);
            }else if(this.filterType2 == "propid"){
              this.getPropertyBoundry_2([latlng], 'plot_property_vw',1);
            }
            this.needToaddKhasraAndproperty(latlng)
          }else if (this.districtlayer && this.checkMarkerInOut(latlng, this.districtlayer?.getLayers()[0].feature.geometry)){
            if(this.filterType2 == "khasraid" && this.markers.length>0){
              this.alertmessage.errorAlertMessage({responseMessage:'You have to select or Search all the khasra in one guideline.',responseMessageEn:'You have to select or Search all the khasra in one guideline.',responseMessageHi:'आपको एक ही गाइडलाइन में सभी खसरा का चयन या खोज करना होगा।'})
            }else{
              this.outSideguidelineClickprocess({'latlng':latlng});
            }
          }else if(this.ifManualEnagarPalika || this.ifManualPanchayat){
            this.outSideguidelineClickprocess({'latlng':latlng});
          }else{
            if(this.filterType2 == "khasraid" || this.filterType2 == "panchayatid"){
              this.alertmessage.errorAlertMessage({responseMessage:'You have Search Outside of District Boundary.',responseMessageEn:'You have Search Outside of District Boundary.',responseMessageHi:'आपने ज़िला सीमा के बाहर सर्च किया है|'});
            }else if(this.filterType2 == "propid"){
              this.alertmessage.errorAlertMessage({responseMessage:'You have Search Outside of District Boundary.',responseMessageEn:'You have Search Outside of District Boundary.',responseMessageHi:'आपने ज़िला सीमा के बाहर सर्च किया है|'});
            } 
          }
        }else if(this.ifManualEnagarPalika || this.ifManualPanchayat){
          this.outSideguidelineClickprocess({'latlng':latlng});
        }
        else{
          let latlngObj:any={"latlng":{"lat":response.latitude,"lng":response.longitude}}
          //this.outSideguidelineClickprocess(latlngObj);
          //this.alertmessage.errorAlertMessage({responseMessage:'You have Search Outside of District Boundary.',responseMessageEn:'You have Search Outside of District Boundary.',responseMessageHi:'आपने ज़िला सीमा के बाहर सर्च किया है|'});
        }
      //}
    //});
  }

  showPopup(){
    this.infoContent.nativeElement.style.display="block";
  }

  closePopup(){
    this.infoContent.nativeElement.style.display="none";
  }

  openConfPopup(address?:string){
    this.geoAddress = (address)?address:"";
    this.confContent.nativeElement.style.display="block";
    return new Observable((observer) => {
      this.confObserver=observer;
    });
  }
  closeConfPopup(s?:boolean){
    //this.geoAddress=""
    this.confContent.nativeElement.style.display="none";
    if(this.confObserver)
      this.confObserver.next(s);
  }

  showMapview():void{
    this.ermapView.nativeElement.style.display="block";
    this.map.invalidateSize();
  }

  hideMapview():void{
    this.ermapView.nativeElement.style.display="none";
  }

  showErNotFoundGuidelinePopup():void{
    this.erNotFoundGuidelineModal.nativeElement.style.display="block";
  }

  hideErNotFoundGuidelinePopup():void{
    this.erNotFoundGuidelineModal.nativeElement.style.display="none";
  }

  addMarker(latlng:any, propertyOrKhasrsaDetails?:any){
    console.log(this.filterType, this.filterType2);
    this.disableSaveBttn = true;
    this.showMapview();//  = true;
    this.hiddenvalue = "hidden";
    this.resetDemo=false;
    this.isShow=true;
    setTimeout(async ()=>{
      //console.log(this.agriPropertyValuationRequestBean);
      if(this.mstDemographyBeanList.length>1)
        this.hideDropdownForMultipleDemography=true;
      else
        this.hideDropdownForMultipleDemography=false;
      if(propertyOrKhasrsaDetails !== "" && propertyOrKhasrsaDetails != undefined){
        this.demographyLoaded = true;
        if(propertyOrKhasrsaDetails?.webGisResponseBeanDataArr?.length && propertyOrKhasrsaDetails?.webGisResponseBeanDataArr?.length>0 && this.filterType2 == "khasraid"){
          await this.getDistrictlayer(propertyOrKhasrsaDetails.demography);
          await this.getdemographyGeom(propertyOrKhasrsaDetails.demography);
          let latlngs:any[] = []
          let ulpiids:string[] = [];
          let notIdsOf_k_p_p:string[]=[];
          propertyOrKhasrsaDetails.webGisResponseBeanDataArr.forEach((d:any, no:number)=>{
            if(d.latitude !== undefined && d.longitude !== undefined && d.latitude !== "" && d.longitude !== ""){
              let p_latlng:any = {lat:Number(d.latitude), lng:Number(d.longitude)};
              latlngs.push(p_latlng);
              this.setMarker(p_latlng, (d.ideStatus)?false:true, no);
            }else{
              notIdsOf_k_p_p.push(d.landParcelId);
            }
            // if(d.ulpin != "")
            //   ulpiids.push(d.ulpin);
            // else{
             
            // }
          });
          if(latlngs.length>0){
            this.getAdressByLatLng(latlngs[0]).subscribe(()=>{
              if((this.filterType2 == "khasraid" || this.filterType2 == "panchayatid") && latlngs.length>0){
                this.getKhasraBoundry_2(latlngs, 'geo_khasra',false,latlngs.length);
              }else if(latlngs.length>0){
                this.getPropertyBoundry_2(latlngs, 'plot_property_vw',latlngs.length)
              }
              this.agriPropertyValuationRequestBean.address = this.fullGeoAddress;            
              if(latlngs.length<propertyOrKhasrsaDetails?.webGisResponseBeanDataArr?.length && notIdsOf_k_p_p){
                this.needToadd_K_P_P = true;
                this.disableSaveBttn = true;
                this.showOtherSearchActive=true;
                this.showOtherSearch =true;
                this.showPopupPropertyNotFound(propertyOrKhasrsaDetails, notIdsOf_k_p_p);
              }else{
                this.disableSaveBttn =false;
              }
            });
          }else{
            this.needToadd_K_P_P = true;
            this.disableSaveBttn = true;
            this.showOtherSearchActive=true;
            this.showOtherSearch =true;
            this.showPopupPropertyNotFound(propertyOrKhasrsaDetails, notIdsOf_k_p_p);
          }        
        }else{
          await this.getDistrictlayer((propertyOrKhasrsaDetails?.demography)?propertyOrKhasrsaDetails.demography:propertyOrKhasrsaDetails);
          await this.getdemographyGeom((propertyOrKhasrsaDetails?.demography)?propertyOrKhasrsaDetails.demography:propertyOrKhasrsaDetails);
          if(this.bylatlng || this.filterType2 == "propid"){
            if((propertyOrKhasrsaDetails.latitude && propertyOrKhasrsaDetails.latitude != "") && (propertyOrKhasrsaDetails.longitude && propertyOrKhasrsaDetails.longitude != "") && propertyOrKhasrsaDetails.propertyNo !== undefined){
              let latlng_p:any = {lat:Number(propertyOrKhasrsaDetails.latitude), lng:Number(propertyOrKhasrsaDetails.longitude)};
              this.getAdressByLatLng(latlng_p).subscribe(()=>{
                this.setMarker(latlng_p, true);
                this.disableSaveBttn =false;
                this.buildingPropertyValuationRequestBean.address=this.fullGeoAddress;
                if(this.filterType2 == "khasraid" || this.filterType2 == "panchayatid"){
                  this.getKhasraBoundry([latlng_p], 'geo_khasra');
                }else{
                  this.getPropertyBoundry_2([latlng_p], 'plot_property_vw');
                }
                if(this.ifManualEnagarPalika){
                  this.updatedLatLngOnPropertyData(latlng_p);
                }
              });
            }else if(latlng && !Number.isNaN(latlng.lat) && !Number.isNaN(latlng.lng)){
              this.getAdressByLatLng(latlng).subscribe(()=>{
                this.setMarker(latlng, true);
                this.disableSaveBttn =false;
                this.buildingPropertyValuationRequestBean.address=this.fullGeoAddress;
                if(this.filterType2 == "khasraid" || this.filterType2 == "panchayatid"){
                  this.getKhasraBoundry([latlng], 'geo_khasra',1);
                }else{
                  this.getPropertyBoundry_2([latlng], 'plot_property_vw',1);
                }
                if(this.ifManualEnagarPalika){
                  this.updatedLatLngOnPropertyData(latlng);
                }
              });
            }else{
              this.needToadd_K_P_P=true;
              this.disableSaveBttn =true;
              this.showOtherSearchActive=true;
              this.showOtherSearch =true;
              this.showPopupPropertyNotFound(propertyOrKhasrsaDetails, [this.id]);
            }
          }else if(this.filterType2 == "panchayatid"){
            if(this.prndData.latitude && this.prndData.longitude){
              let latlng_n:any = {"lat":this.prndData?.latitude, "lng":this.prndData?.longitude};
              this.getAdressByLatLng(latlng_n).subscribe(()=>{
                this.setMarker(latlng_n, true);
                this.getKhasraBoundry_2([latlng_n], 'geo_khasra');
                this.propertyValuationRequestBean.address = this.fullGeoAddress;
                this.disableSaveBttn =false;
                this.showOtherSearchActive=false;
                this.showOtherSearch =false;
                if(this.ifManualPanchayat){
                  this.updatedLatLngOnPunchayatProperty(latlng_n);
                }
              });
            }else if(latlng && !Number.isNaN(latlng.lat) && !Number.isNaN(latlng.lng)){
              this.getAdressByLatLng(latlng).subscribe(()=>{
                this.setMarker(latlng, true);
                this.getKhasraBoundry_2([latlng], 'geo_khasra');
                this.propertyValuationRequestBean.address = this.fullGeoAddress;
                this.disableSaveBttn =false;
                this.showOtherSearchActive=false;
                this.showOtherSearch =false;
                if(this.ifManualPanchayat){
                  this.updatedLatLngOnPunchayatProperty(latlng);
                }
              });
            }else{
              this.needToadd_K_P_P=true;
              this.disableSaveBttn =true;
              this.showOtherSearchActive=true;
              this.showOtherSearch =true;
              if(propertyOrKhasrsaDetails?.demography)
                this.showPopupPropertyNotFound(propertyOrKhasrsaDetails, [this.id]);
              else
                this.showPopupPropertyNotFound({"demography":propertyOrKhasrsaDetails}, [this.id]);
            }
          }
          else if(this.filterType=="bynameUrbon"){
            this.needToadd_K_P_P=true
          }
        }
      }

    },300);
  }

  showPopupPropertyNotFound(propertyOrKhasrsaDetails:any, ids:string[]=[]){
    let html:HTMLElement =document.createElement('div') as HTMLElement;
    let btndiv:HTMLElement =document.createElement('div') as HTMLElement;
    let btndiv2:HTMLElement =document.createElement('div') as HTMLElement;
    let ordiv:HTMLElement =document.createElement('div') as HTMLElement;
    let ordiv2:HTMLElement =document.createElement('div') as HTMLElement;
    let geotagdiv:HTMLElement =document.createElement('div') as HTMLElement;
    let geotag:HTMLInputElement =document.createElement('input') as HTMLInputElement;
    geotag.classList.add('form-control');
    geotag.id="geotaginputbox";
    geotagdiv.appendChild(geotag);
    geotag.placeholder=(this.lan==0)?"Please Enter GeoTag Id":"कृपया जियोटैग आईडी दर्ज करें"
    ordiv.innerText = (this.lan==0)?"OR":"या";
    ordiv2.innerText = (this.lan==0)?"OR":"या";
    btndiv.classList.add("swal-btn-div","mt-2","mb-2");
    let btn:HTMLButtonElement =document.createElement('button') as HTMLButtonElement;
    btn.classList.add('btn','btn-primary','mr-1','geotag-swal-btn');
    let btn2:HTMLButtonElement =document.createElement('button') as HTMLButtonElement;
    btn2.classList.add('btn','btn-primary','ml-1','geotag-swal-btn');
    btndiv2.appendChild(btn);
    btndiv.appendChild(btn2);
    if(this.filterType == "bynameUrbon"){
      btn.innerText=(this.lan==0)?"Identify by Click":"क्लिक से पहचानें";
    }else{
      btn.innerText=(this.lan==0)?"Identify by Address Search OR Click":"पता खोजें या क्लिक से पहचानें";
    }
    btn2.innerText = (this.lan==0)?"Identify by GeoTag Id":"जियोटैग आईडी से पहचानें";
    let inText:HTMLElement =document.createElement('div') as HTMLElement;
    if(propertyOrKhasrsaDetails?.demography?.demographyTypeId == 10 || propertyOrKhasrsaDetails?.demography?.demographyTypeId == 8){
      inText.innerText = `${this.lan==0?"The location of your property/khasra could not be found, Please select village/town colony first and then click on selected colony boundary for select your khasra /property boundary of id:  "+ids.join(','):"आपकी संपत्ति/खसरा का स्थान नहीं मिला, कृपया सबसे पहले आप ग्राम/वर्ड कॉलोनी का चयन करें और फिर अपनी आईडी "+ids.join(',')+" का खसरा/संपत्ति की सीमा का चयन करने के लिए चयनित कॉलोनी सीमा के अन्दर क्लिक करें।"}`;
    }else if(propertyOrKhasrsaDetails?.demography?.demographyTypeId == 15 || propertyOrKhasrsaDetails?.demography?.demographyTypeId == 14){
      inText.innerText = `${this.lan==0?"The location of your property/Khasra could not be found, Please click inside on the seleted colony to identify your property/khasra of id: "+ids.join(','):"आपकी संपत्ति/खसरा का स्थान नहीं मिला, कृपया आप अपनी आईडी "+ids.join(',')+" का खसरा/संपत्ति सीमा चुनने के लिए चयनित कॉलोनी सीमा के अन्दर क्लिक करें।"}`;
    }else{
      inText.innerText = `${this.lan==0?"The location of your property/khasra could not be found, please go to the option below.":" आपकी संपत्ति/खसरा का स्थान नहीं मिला, कृपया नीचे दिए गए विकल्प पर जाएं।"}`;
    }
    html.appendChild(inText);
    html.appendChild(btndiv2);
    html.appendChild(ordiv);
    html.appendChild(geotagdiv);
    html.appendChild(btndiv);
    //html.appendChild(ordiv2);
    Swal.fire({
      icon: 'info',
      html:html,
      allowOutsideClick: false,
      showConfirmButton: false,
      showCancelButton: false
    })
    btn.addEventListener('click',()=>{
      this.filterType = "bynameUrbon";
      Swal.close();
    });
    btn2.addEventListener('click',()=>{
      this.filterType = "searchByGeotag";
      this.geoTagid = (geotag.value)?geotag.value:"";
      Swal.close();
      if(geotag.value != "" && geotag.value != null){
        this.selectKhIdAndPrIdAndUnid("searchByGeotag");
      }
    });
  }

  checkMarkerInOut(latlng:any, boundary:any):boolean{
    var pt = turf.point([latlng.lng, latlng.lat]);
    //this.hideDropdownForMultipleDemography = false;
    if(turf.booleanPointInPolygon(pt, boundary)){
      return true;
    }else{
      return false;
    }
  }

  setMarker(latlng:any, dragend:boolean, no:number=0){
    let marker =   L.marker(latlng,{
      data:{id:"", no:no},
      zIndexOffset: 0,
      draggable:dragend
    }).addTo(this.map);
    
    if(dragend){
      marker.on('dragend', (event)=>{
        var position = marker.getLatLng();
        //var pt = turf.point([position.lng, position.lat]);
        if(this.selectPropertyLayer){
          if(this.checkMarkerInOut(position, this.getMergeBoundary([this.selectPropertyLayer._layers[marker.options?.data?.id]]))){
              this.ngxService.startLoader('loader-identification');;
              this.getAdressByLatLng(position).subscribe((address:string)=>{
                this.ngxService.stopLoader('loader-identification');
                this.openConfPopup(address).subscribe((value)=>{
                  if(value){
                    latlng = position;
                    if(this.filterType2 == "khasraid")
                      this.updatedLatLngOnkhasraData(marker.options?.data?.no, latlng);
                    else if (this.filterType2 == "propid")
                      this.updatedLatLngOnPropertyData(latlng)
                    else if (this.filterType2 == "panchayatid")
                      this.updatedLatLngOnPunchayatProperty(latlng);
                  }else{
                    marker.setLatLng(latlng);
                }})
              },(error:any)=>{
                this.ngxService.stopLoader('loader-identification');
              });
          }else{
            marker.setLatLng(latlng);
          }
        }
        // else if(this.demographyLoaded){
        //   if(this.checkMarkerInOut(position, this.selectGuideLineLayer.getLayers()[0].feature.geometry)){
        //     this.ngxService.startLoader('loader-identification');;
        //       this.getAdressByLatLng(position).subscribe((address:string)=>{
        //         this.ngxService.stopLoader('loader-identification');
        //         this.openConfPopup(address).subscribe((value)=>{
        //             if(value){
        //               latlng = position;
        //               this.removePropertyBoundry_2();
        //               if(this.webGisResponseData?.demography?.demographyType?.demographyAreaType == "U" || this.propertyValuationRequestBeanEmit?.demography?.demographyType.demographyAreaType == "U"){
        //                 this.getPropertyBoundry_2([latlng], 'plot_property_vw')
        //               }else{
        //                 this.getKhasraBoundry_2([latlng], 'geo_khasra');
        //               }
        //               this.propertyValuationRequestBeanEmit.latitude=latlng.lat;
        //               this.propertyValuationRequestBeanEmit.longitude=latlng.lng;
        //               this.propertyValuationRequestBeanEmit.address = this.geoAddress
        //               console.log(this.propertyValuationRequestBeanEmit);
        //             }else{
        //               marker.setLatLng(latlng);
        //           }})
        //         },(error:any)=>{
        //           this.ngxService.stopLoader('loader-identification');
        //       });
        //   }else{
        //     marker.setLatLng(latlng);
        //   }
        // }
        else{
          marker.setLatLng(latlng);
        }
        //this.MapButtonEvent.emit({position, undefined, "areaType":""});
        //this.removePropertyBoundry();
        //this.removeGuidelineBoundry();
        //this.getguideLineurbanBoundry(position);
        //this.getPropertyBoundry_2([position], 'plot_property_vw');
      });
    }
    this.markers.push(marker);
  }

  getPropertyBoundry_2(latlngs:any[], layerName:string, maxfeature:number=1):void{
    this.ngxService.startLoader('loader-identification');;
    let query:string ="INTERSECTS(geom,%20POINT("+latlngs[0].lng+" "+latlngs[0].lat+"))";
    if(latlngs.length>1){
      for(let i=1;i<latlngs.length;i++){
        query = query+" OR "+"INTERSECTS(geom,%20POINT("+latlngs[i].lng+" "+latlngs[i].lat+"))";
      }
    }
    let url:string=this.queryLayerServices.getEncurlwithQuery(query, layerName);
    //this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3A'+layerName+'&CQL_FILTER='+query+'&maxFeatures='+maxfeature+'&outputFormat=application%2Fjson'
    this.queryLayerServices.customgetDecRequest('GET',url, null, null).subscribe((response:any)=>{
      this.ngxService.stopLoader('loader-identification');
      if(response.body?.features.length==0){
        //this.getKhasraBoundry_2(latlngs, 'geo_khasra');
        this.alertmessage.warnAlertMessage({responseMessage:'GIS map of local body registered property could not be obtained at the clicked site.',responseMessageEn:'GIS map of local body registered property could not be obtained at the clicked site.',responseMessageHi:'क्लिक की गई साइट पर स्थानीय निकाय पंजीकृत संपत्ति का जीआईएस मानचित्र प्राप्त नहीं किया जा सका।'});
      }
      else if(response){
        this.addPropertyBoundry(response.body);

      }else{
        //alert("Something is going wrong.");
        this.alertmessage.warnAlertMessage({responseMessage:'GIS map of local body registered property could not be obtained at the clicked site.',responseMessageEn:'GIS map of local body registered property could not be obtained at the clicked site.',responseMessageHi:'क्लिक की गई साइट पर  स्थानीय निकाय पंजीकृत संपत्ति का जीआईएस मानचित्र प्राप्त नहीं किया जा सका।'});
      }
    },(error:any)=>{
      this.ngxService.stopLoader('loader-identification');
      console.log("error");
    })
  }

  getKhasraBoundry_2(latlngs:any, layerName:string, next:boolean=false, maxfeature:number=1):void{
    let dist:any=this.selectedMstDemographyBean?.location?.district;
    if(dist != undefined && dist != null && dist?.clrDistrictId){
      if(dist.clrDistrictId.toString().length==1){
        layerName += '_0'+dist.clrDistrictId;
      }else{
        layerName += '_'+dist.clrDistrictId;
      }
    }
    this.ngxService.startLoader('loader-identification');
    let query:string ="INTERSECTS(geom,%20POINT("+latlngs[0].lng+" "+latlngs[0].lat+"))";
    if(latlngs.length>1){
      for(let i=1;i<latlngs.length;i++){
        query = query+" OR "+"INTERSECTS(geom,%20POINT("+latlngs[i].lng+" "+latlngs[i].lat+"))";
      }
    }
    let url:string=this.queryLayerServices.getEncurlwithQuery(query, layerName);
    //this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3A'+layerName+'&CQL_FILTER='+query+'&maxFeatures='+maxfeature+'&outputFormat=application%2Fjson'
    this.queryLayerServices.customgetDecRequest('GET',url, null, null).subscribe((response:any)=>{
      this.ngxService.stopLoader('loader-identification');
      if(response.body?.features.length>0){
        this.addPropertyBoundry(response.body);
      }else if(next){
        //alert("Data Not Found");
        this.getPropertyBoundry_2(latlngs, 'geo_khasra')
      }else{
        //alert("data not found");
        this.alertmessage.warnAlertMessage({responseMessage:'GIS map of Khasra could not be obtained at the clicked site.',responseMessageEn:'GIS map of Khasra could not be obtained at the clicked site.',responseMessageHi:'क्लिक की गई साइट पर खसरा का जीआईएस मानचित्र प्राप्त नहीं किया जा सका।'});
      }
    },(error:any)=>{
      this.ngxService.stopLoader('loader-identification');
      console.log("error");
    })
  }


  getPropertyBoundry(latlngs:any[], layerName:string, maxfeature:number=1):void{
    this.ngxService.startLoader('loader-identification');
    let query:string ="INTERSECTS(geom,%20POINT("+latlngs[0].lng+" "+latlngs[0].lat+"))";
    if(latlngs.length>1){
      for(let i=1;i<latlngs.length;i++){
        query = query+" OR "+"INTERSECTS(geom,%20POINT("+latlngs[i].lng+" "+latlngs[i].lat+"))";
      }
    }
    let url:string=this.queryLayerServices.getEncurlwithQuery(query, layerName);
    //this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3A'+layerName+'&CQL_FILTER='+query+'&maxFeatures='+maxfeature+'&outputFormat=application%2Fjson'
    this.pvService.customgetRequest('GET',url, null, null).subscribe((response:any)=>{
      this.ngxService.stopLoader('loader-identification');
      if(response && response.body?.features.length==0){
        this.getKhasraBoundry_2(latlngs, 'geo_khasra');
        //this.alertmessage.errorAlertMessage({responseMessage:'Property Geometry not found',responseMessageEn:'Property Geometry not found',responseMessageHi:'संपत्ति ज्यामिति नहीं मिली'});
      }
      else if(response){
        this.addPropertyBoundry(response.body);
      }else{
        this.alertmessage.warnAlertMessage({responseMessage:'GIS map of local body registered property could not be obtained at the clicked site.',responseMessageEn:'GIS map of local body registered property could not be obtained at the clicked site.',responseMessageHi:'क्लिक की गई साइट पर स्थानीय निकाय पंजीकृत संपत्ति का जीआईएस मानचित्र प्राप्त नहीं किया जा सका।'});
      }
    },(error:any)=>{
      this.alertmessage.warnAlertMessage({responseMessage:'GIS map of local body registered property could not be obtained at the clicked site.',responseMessageEn:'GIS map of local body registered property could not be obtained at the clicked site.',responseMessageHi:'क्लिक की गई साइट पर स्थानीय निकाय पंजीकृत संपत्ति का जीआईएस मानचित्र प्राप्त नहीं किया जा सका।'});
      this.ngxService.stopLoader('loader-identification');
      console.log("error");
    })
  }

  getKhasraBoundry(ulpinIds:any, layerName:string,maxfeature:number=1):void{
    let dist:any=this.selectedMstDemographyBean?.location?.district;
    if(dist != undefined && dist != null && dist?.clrDistrictId){
      if(dist.clrDistrictId.toString().length==1){
        layerName += '_0'+dist.clrDistrictId;
      }else{
        layerName += '_'+dist.clrDistrictId;
      }
    }
    this.ngxService.startLoader('loader-identification');
    //let query:string ="INTERSECTS(geom,%20POINT("+latlngs[0].lng+" "+latlngs[0].lat+"))";
    let query:string ="ulpin in ('"+ulpinIds[0]+"'";
    if(ulpinIds.length>1){
      for(let i=1;i<ulpinIds.length;i++){
        //query = query+" OR "+"INTERSECTS(geom,%20POINT("+latlngs[i].lng+" "+latlngs[i].lat+"))";
        query = query+" ,'"+ulpinIds[i]+"'";
      }
    }
    query = query+")";
    let url:string=this.queryLayerServices.getEncurlwithQuery(query, layerName);
    //this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3A'+layerName+'&CQL_FILTER='+query+'&maxFeatures='+maxfeature+'&outputFormat=application%2Fjson'
    this.pvService.customgetRequest('GET',url, null, null).subscribe((response:any)=>{
      this.ngxService.stopLoader('loader-identification');
      if(response.body?.features.length>0){
        this.addPropertyBoundry(response.body);
      }else{
        //alert("Data Not Found");
        this.alertmessage.warnAlertMessage({responseMessage:'GIS map of Khasra could not be obtained at the clicked site.',responseMessageEn:'GIS map of Khasra could not be obtained at the clicked site.',responseMessageHi:'क्लिक की गई साइट पर खसरा का जीआईएस मानचित्र प्राप्त नहीं किया जा सका।'});
      }
    },(error:any)=>{
      this.ngxService.stopLoader('loader-identification');
      console.log("error");
    })
  }

  // getguideLineurbanBoundry(latlng:any):void{
  //   let query:string ="INTERSECTS(geom,%20POINT("+latlng.lng+" "+latlng.lat+"))";
  //   let url:string=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+':igrs_urban_final&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson'
  //   this.pvService.customgetRequest('GET',url, null, null).subscribe((response:any)=>{
  //     if(response.features.length==0){
  //       this.getguideLineruralBoundry(latlng);
  //     }
  //     else if(response){
  //       this.addGuidelineBoundary(response)
  //     }else{
  //       alert("Data Not Found");
  //     }
  //   },(error:any)=>{
  //     console.log("error");
  //   })
  // }

  // getguideLineruralBoundry(latlng:any):void{
  //   let query:string ="INTERSECTS(geom,%20POINT("+latlng.lng+" "+latlng.lat+"))";
  //   let url:string=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+':igrs_rural_final&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson'
  //   this.pvService.customgetRequest('GET',url, null, null).subscribe((response:any)=>{
  //     if(response.features.length==0){
  //       this.zoomToPoint(latlng);
  //     }
  //     else if(response){
  //       this.addGuidelineBoundary(response)
  //     }else{
  //       alert("Data Not Found");
  //     }
  //   },(error:any)=>{
  //     console.log("error");
  //   })
  // }

  addGuidelineBoundary(layer:any, demography?:any, bydropdown:boolean=false):void{
    this.demographyLoaded = true;
    this.selectGuideLineLayer=L.geoJSON(layer);
    this.selectGuideLineLayer.addTo(this.map);
    this.selectGuideLineLayer.setStyle({color:'#0046FF',dashArray: '5, 5', dashOffset: '0'});
    this.selectGuideLineLayer.setZIndex(51);
    if(demography && demography != "" && (demography.demographyTypeId == 15 || demography.demographyTypeId ==  14)){
      if(bydropdown)
        this.showPopupPropertyNotFound({"demography":demography}, [this.id]);
      if(this.showOtherSearchActive){
        this.showOtherSearch=true;
      }
      this.selectGuideLineLayer.on('click',async (e:any)=>{
        //var pt = turf.point([e.latlng.lng, e.latlng.lat]);
        // if(this.selectPropertyLayer && this.selectPropertyLayer?._layers){
        //   if(this.checkMarkerInOut(e.latlng, this.getMergeBoundary(this.selectPropertyLayer.getLayers()))){
        //     if(this.markers.length>0){
        //       this.ngxService.startLoader('loader-identification');;
        //       this.getAdressByLatLng(e.latlng).subscribe((address:string)=>{
        //         this.ngxService.stopLoader('loader-identification');
        //         this.openConfPopup(address).subscribe((value)=>{
        //           if(value){
        //             this.clearMarker(true);
        //             this.setMarker(e.latlng, true);
        //           }else{
        //             //marker.setLatLng(latlng);
        //         }});
        //       },(error:any)=>{
        //         this.ngxService.stopLoader('loader-identification');
        //       });
        //     }else{
        //       this.setMarker(e.latlng, true);
        //     }
        //   }
        // }else 
        if(bydropdown){
          this.outSideguidelineClickprocess({'latlng':e.latlng}, true);
          //this.receivedLatAndLngHandler({'latlng':e.latlng}, true);
        }else if(this.needToadd_K_P_P){
          if(this.markers.length>0){
            if(demography.demographyType.demographyAreaType == "U"){
              this.getPropertyBoundry_2([e.latlng], 'plot_property_vw')
            }else{
              this.getKhasraBoundry_2([e.latlng], 'geo_khasra');
            }
            this.needToaddKhasraAndproperty(e.latlng)
          }else{
            this.ngxService.startLoader('loader-identification');
            this.getAdressByLatLng(e.latlng).subscribe((address:string)=>{
              this.ngxService.stopLoader('loader-identification');
              this.openConfPopup(address).subscribe((value)=>{
                if(value){
                  if(demography.demographyType.demographyAreaType == "U"){
                    this.getPropertyBoundry_2([e.latlng], 'plot_property_vw')
                  }else{
                    this.getKhasraBoundry_2([e.latlng], 'geo_khasra');
                  }
                  this.needToaddKhasraAndproperty(e.latlng)
                }else{
                  //marker.setLatLng(latlng);
              }});
            },(error:any)=>{
              this.ngxService.stopLoader('loader-identification');
            });
          }
        }
      });
    }else{
      this.showOtherSearch=true;
      this.selectGuideLineLayer.on('click',async (e:any)=>{
        this.outSideguidelineClickprocess(e);
      });
      
    }
    setTimeout(()=>{this.map.fitBounds(this.selectGuideLineLayer.getBounds());},1000)
  }

  addPropertyBoundry(layer:any):void{
    if(this.selectPropertyLayer == null){
      this.selectPropertyLayer=L.geoJSON(layer);
      this.selectPropertyLayer.addTo(this.map);
      this.selectPropertyLayer.setStyle({color:'#FF1700',dashArray: '5, 5', dashOffset: '0'})
      this.selectPropertyLayer.setZIndex(52);
      // this.selectPropertyLayer.on('click',(e:any)=>{
      //   if(this.markers.length>0){
      //     this.ngxService.startLoader('loader-identification');;
      //     this.getAdressByLatLng(e.latlng).subscribe((address:string)=>{
      //       this.ngxService.stopLoader('loader-identification');
      //       this.openConfPopup(address).subscribe((value)=>{
      //         if(value){
      //           this.clearMarker(true);
      //           this.setMarker(e.latlng, true);
      //         }else{
      //           //marker.setLatLng(latlng);
      //       }});
      //     },(error:any)=>{
      //       this.ngxService.stopLoader('loader-identification');
      //     });
      //   }else{
      //     this.setMarker(e.latlng, true);
      //   }
      // });
    }else{
      let lyr = L.geoJSON(layer);
      lyr.setStyle({color:'#FF1700',dashArray: '5, 5', dashOffset: '0'})
      this.selectPropertyLayer.addLayer(lyr.getLayers()[0]);
    }
    this.updatedMarkerId();
    //setTimeout(()=>{this.map.fitBounds(this.selectPropertyLayer.getBounds());},1000)
  }

   addPropertyBoundry_2(layer:any):void{
    this.selectPropertyLayer_2=L.geoJSON(layer);
    this.selectPropertyLayer_2.addTo(this.map);
    this.selectPropertyLayer_2.setStyle({color:'#FF1700',dashArray: '5, 5', dashOffset: '0'})
    this.selectPropertyLayer_2.setZIndex(54);
  }

  updatedMarkerId(){
    for(let layer in this.selectPropertyLayer._layers){
      this.markers.forEach((marker:L.Marker)=>{
        if(this.checkMarkerInOut(marker.getLatLng(), this.selectPropertyLayer._layers[layer].feature.geometry)){
          marker.options.data.id = layer;
          if(this.bylatlng){
            if(this.uaddResponseModel != undefined){
              marker.options.data["properties"]=this.uaddResponseModel[0];
            }else if(this.cLRResponseModel != undefined){
              marker.options.data["properties"]=this.cLRResponseModel;
            }
          }else if(this.filterType2 == "khasraid"){
            marker.options.data["properties"]=this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr[marker.options.data.no];
            if(this.khasraColor[marker.options.data.no])
              this.selectPropertyLayer._layers[layer].setStyle({color:this.khasraColor[marker.options.data.no].gc,fillColor:this.khasraColor[marker.options.data.no].gc,dashArray: '5, 5', dashOffset: '0'});
          }else if(this.filterType2 == "propid")
            marker.options.data["properties"]=this.mpenagarpalikamodel;
        }
      })
    }
  }



  removePropertyBoundry():void{
    if(this.selectPropertyLayer){
      this.map.removeLayer(this.selectPropertyLayer);
      this.selectPropertyLayer=null;
    }
  }

  removePropertyBoundry_2():void{
    if(this.selectPropertyLayer_2){
      this.map.removeLayer(this.selectPropertyLayer_2);
      this.selectPropertyLayer_2=null;
    }
  }

  removeGuidelineBoundry():void{
    if(this.selectGuideLineLayer){
      this.map.removeLayer(this.selectGuideLineLayer);
      this.selectGuideLineLayer=null;
    }
  }

  removeDistrictBoundry():void{
    if(this.districtlayer){
      this.map.removeLayer(this.districtlayer);
      this.districtlayer=null;
    }
  }

  zoomToPoint(latlng:any){
    this.map.flyTo([latlng.lat, latlng.lng], 18, {
      animate: true,
      duration: 1
    });
  }

  async getdemographyGeom(e:any, bydropdown:boolean=false):Promise<boolean>{
    try{
      let url=this.queryLayerServices.getBoundryURLsEncfinal(e);
      if(url != ''){
        this.ngxService.startLoader('loader-identification');
        var response:any = await this.queryLayerServices.customgetDecRequest('GET',url, null, null).toPromise();//=>{
        if(response && response.body?.features.length>0){
          this.ngxService.stopLoader('loader-identification');
          this.addGuidelineBoundary(response.body, e, bydropdown);
          return true;
        }else{
          //this.alertmessage.errorAlertMessage("property-valuation.");
          this.ngxService.stopLoader('loader-identification');
          return true;
        }
        
      }else{
        console.log('URL Not Configuried');
        return true;
      }
    }catch(error:any){
      this.ngxService.stopLoader('loader-identification');
      console.log('data not found');
      return true;
    }
      
  }

  async getDistrictlayer(e:any):Promise<boolean>{
    try{
      var url="";
      if(this.filterType2 == "khasraid" || this.filterType2 == "panchayatid"){
        url=this.queryLayerServices.getBoundryURLsEncfinal((e.location?.district)?e.location?.district:"");
      }else if(this.filterType2 == "propid"){
        url=this.queryLayerServices.getBoundryURLsEncfinal((e.location?.district)?e.location?.district:"");
      }
      if(url != ''){
        this.ngxService.startLoader('loader-identification');
        var response:any = await this.queryLayerServices.customgetDecRequest('GET',url, null, null).toPromise();//=>{
        if(response && response.body?.features.length>0){
          this.ngxService.stopLoader('loader-identification');
          this.addDistrictLayer(response.body, e);
          return true;
        }else{
          //this.alertmessage.errorAlertMessage("property-valuation.");
          this.ngxService.stopLoader('loader-identification');
          return true;
        }
        
      }else{
        console.log('URL Not Configuried');
        return true;
      }
    }catch(error:any){
      this.ngxService.stopLoader('loader-identification');
      console.log('data not found');
      return true;
    }
      
  }

  addDistrictLayer(layer:any, demography?:any){
    this.districtlayer=L.geoJSON(layer);
    this.districtlayer.addTo(this.map);
    this.districtlayer.setStyle({color:'yellow',dashArray: '5, 5', dashOffset: '0'});
    this.districtlayer.setZIndex(51);
    if(demography && demography != ""){
      if(this.showOtherSearchActive){
        this.showOtherSearch=true;
      }
      this.districtlayer.on('click',async (e:any)=>{
        if(this.needToadd_K_P_P){
          //console.log(this.markers);
          if(this.filterType2 == "khasraid" && this.markers.length>0){
            this.alertmessage.errorAlertMessage({responseMessage:'You have to select all the khasra in one guideline.',responseMessageEn:'You have to select all the khasra in one guideline.',responseMessageHi:'आपको एक ही गाइडलाइन में सभी खसरा का चयन करना होगा।'})
          }else if(this.markers.length==0){
            this.outSideguidelineClickprocess(e);
          }else{
            this.alertmessage.errorAlertMessage({responseMessage:'Please delete previous property/khasra',responseMessageEn:'Please delete previous property/khasra.',responseMessageHi:'कृपया पिछली संपत्ति/खसरा डिलीट करें।'})
          }
        }
      });
    }else{
      this.showOtherSearch=true;
    }
  }

  outSideguidelineClickprocess(event:any, bydropdown:boolean=false){
    this.ngxService.start();
    this.pvService.getDemographyDataByLatlng(event.latlng?.lat, event.latlng?.lng).subscribe(res => {
      
      if(res.responseStatus=="true" && (res?.responseData?.mstDemographyBean?.demographyTypeId == 14 || res?.responseData?.mstDemographyBean?.demographyTypeId == 15)){
        if(this.data?.districtId && res?.responseData?.demography && this.data?.districtId!=res.responseData?.demography?.location?.district?.id){
          this.ngxService.stop();
          this.alertmessage.errorAlertMessage({responseMessage:'Your property ID does not belong to this district',responseMessageEn:'Your property ID does not belong to this district',responseMessageHi:'आपकी संपत्ति आईडी इस जिले से संबंधित नहीं है'})
          return
        }
        //console.log(res);
        // if(this.filterType2 == "panchayatid" && (res?.responseData?.mstDemographyBean?.demographyType?.demographyAreaType == "U" || res?.responseData?.mstDemographyBean?.demographyTypeId == 14)){
        //   this.ngxService.stop();
        //   this.alertmessage.errorAlertMessage({responseMessage:'Your searched location is urban area, please search in rural area.',responseMessageEn:'Your searched location is urban area, please search in rural area.',responseMessageHi:'आपकी सर्च की गई स्थान शहरी क्षेत्र है, कृपया ग्रामीण क्षेत्र मै सर्च करें।'});
        //   return;
        // }else 
        if(this.filterType2 == "propid" && (res?.responseData?.mstDemographyBean?.demographyType?.demographyAreaType == "R" || res?.responseData?.mstDemographyBean?.demographyTypeId == 15)){
          this.ngxService.stop();
          this.alertmessage.errorAlertMessage({responseMessage:'Your searched location is rural area, please search in urban area.',responseMessageEn:'Your searched location is rural area, please search in urban area.',responseMessageHi:'आपकी सर्च की गई स्थान ग्रामीण क्षेत्र है, कृपया शहरी क्षेत्र मै सर्च करे|'})
          return;
        }
        if(this.filterType2 == "khasraid" || this.filterType2 == "panchayatid"){
          this.getKahsraByLatLng(event.latlng).then((res2:any)=>{
            this.ngxService.stop();
            if(res2.status){
              this.confirmationalert('The name of the guideline at the clicked location is <strong>'+this.capitalizeFirstLetter(res?.responseData?.mstDemographyBean?.demographyNameEn)+'</strong> and Khasra No <strong>'+res2?.data?.features[0].properties?.kid+'</strong>. do you want to pursue it?<br><br> क्लिक किए गए स्थान पर गाईडलाइन का नाम <strong>'+res?.responseData?.mstDemographyBean?.demographyNameHi+'</strong> और खसरा आईडी <strong>'+res2?.data?.features[0].properties?.kid+'</strong> है। क्या आप इसे आगे बढ़ाना चाहते हैं?').then((result)=>{
                if (result.isConfirmed) {
                  this.receivedLatAndLngHandler(event, bydropdown)
                } else {
                  //this.splitedPolygons=[];
                }
              });
            }else{
              this.confirmationalert('The name of the guideline at the clicked location is <strong>'+this.capitalizeFirstLetter(res?.responseData?.mstDemographyBean?.demographyNameEn)+'</strong>. do you want to pursue it?<br><br> क्लिक किए गए स्थान पर गाईडलाइन का नाम <strong>'+res?.responseData?.mstDemographyBean?.demographyNameHi+'</strong> है। क्या आप इसे आगे बढ़ाना चाहते हैं?').then((result)=>{
                if (result.isConfirmed) {
                  this.receivedLatAndLngHandler(event, bydropdown)
                } else {
                  //this.splitedPolygons=[];
                }
              });
            }
          },(error:any)=>{
            this.ngxService.stop();
          });
          
        }else if(this.filterType2 == "propid"){
          this.ngxService.stop();
          this.confirmationalert('The name of the guideline at the clicked location is <strong>'+this.capitalizeFirstLetter(res?.responseData?.mstDemographyBean?.demographyNameEn)+'</strong>. do you want to pursue it?<br><br> क्लिक किए गए स्थान पर गाईडलाइन का नाम <strong>'+res?.responseData?.mstDemographyBean?.demographyNameHi+'</strong> है। क्या आप इसे आगे बढ़ाना चाहते हैं?').then((result)=>{
            if (result.isConfirmed) {
              this.receivedLatAndLngHandler(event, bydropdown)
            } else {
              //this.splitedPolygons=[];
            }
          });
        }
      }else{
        this.ngxService.stop();
        this.alertmessage.errorAlertMessage({responseMessage:'Could not find your guideline location, Please try again',responseMessageEn:'Could not find your guideline location, Please try again',responseMessageHi:'आपका गाइडलाइन स्थान नहीं मिल सका, कृपया पुनः प्रयास करें|'})
      }
    },(error:any)=>{
      this.ngxService.stop();
    });
    // this.getGuidelineByLatLng(event.latlng).then((res:any)=>{
    //   if(res.status){
         
    //   }    
    // },(error:any)=>{

    // })
  }

  async getGuidelineByLatLng(latlng:any):Promise<any>{
    try{
      let cqlFilter = "INTERSECTS(geom,%20POINT("+latlng.lng+" "+latlng.lat+"))";
      let f_name ="col_id,col_nm,col_nm_h"
      let url=this.queryLayerServices.getEncurlwithQuery(cqlFilter, "final_igrs_gis_gl");
      //this.settings.geoServerWmsUrl + this.settings.workSpace+"/ows?service=WFS&version=1.0.0&request=GetFeature&typeName="+this.settings.workSpace+"%3Afinal_igrs_gis_gl&CQL_FILTER="+cqlFilter+"&maxFeatures=1&outputFormat=application%2Fjson&PropertyName="+f_name;
      if(url != ''){
        this.ngxService.startLoader('loader-identification');
        var response:any = await this.queryLayerServices.customgetDecRequest('GET',url, null, null).toPromise();//=>{
        if(response && response.body?.features.length>0){
          this.ngxService.stopLoader('loader-identification');
          return {"status":true,"data":response.body};
        }else{
          this.alertmessage.errorAlertMessage({responseMessage:'Guideline not found at clicked location, please try again.',responseMessageEn:'Guideline not found at clicked location, please try again.',responseMessageHi:'क्लिक किए गए स्थान पर गाईडलाइन नहीं मिला, कृपया पुनः प्रयास करें।'})
          this.ngxService.stopLoader('loader-identification');
          return {"status":false};
        }
        
      }else{
        console.log('URL Not Configuried');
        return {"status":false};
      }
    }catch(error:any){
      this.ngxService.stopLoader('loader-identification');
      console.log('data not found');
      return {"status":false};
    }
  }

  async getKahsraByLatLng(latlng:any):Promise<any>{
    try{
      this.ngxService.startLoader('loader-identification');
      let tableName = 'geo_khasra';
        let dist:any=this.selectedMstDemographyBean?.location?.district;
        if(dist != undefined && dist != null && dist?.clrDistrictId){
          if(dist.clrDistrictId.toString().length==1){
            tableName += '_0'+dist.clrDistrictId;
          }else{
            tableName += '_'+dist.clrDistrictId;
          }
        }
        let property:string="PropertyName=kid";
        let query:string = "INTERSECTS(geom,%20POINT("+latlng.lng+" "+latlng.lat+"))";
        let url:string=this.queryLayerServices.getEncurlwithQueryAndProperty(query,tableName, property);
        //this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3A'+tableName+'&CQL_FILTER=INTERSECTS(geom,%20POINT('+latlng.lng+' '+latlng.lat+'))&maxFeatures=1&outputFormat=application%2Fjson&PropertyName=kid'
        var response:any = await this.queryLayerServices.customgetDecRequest('GET',url, null, null).toPromise();
        this.ngxService.stopLoader('loader-identification');
        if(response.body?.features.length>0){
          return {"status":true,"data":response.body};
        } 
        else{
          return {"status":false};
        }
        
    }catch(error:any){
      this.ngxService.stopLoader('loader-identification');
      console.log(error);
      return {"status":false};
    }
  }

  // getBoundryURLs(obj:any){
  //   let query:string;
  //   let url:string='';
  //   let D:any=obj.demographyType;
  //   switch(obj.demographyTypeId){
  //     case 2:
  //         query='div_cd='+obj.lgdCode;
  //         url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Ampdivision&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson';
  //       break;
  //     case 3:
  //         query='dist_cd='+obj.lgdCode;
  //         url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Ampdistrict&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson'
  //       break;
  //     case 4:
  //         query = 'sr_off_cd='+obj.lgdCode;
  //         url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Asro_boundary&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson';
  //       break;
  //     case 7:
  //         query="lgd_ulb_cd="+obj.lgdCode;
  //         url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Aulb_igrs&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson'
  //       break;
  //     case 8:
  //         query="ward_cd="+obj.lgdCode;
  //         url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Award_igrs&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson'
  //       break;
  //     case 12:
  //         query="teh_cd='"+obj.lgdCode+"'";
  //         url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Atehsil_clr_430&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson'
  //       break;
  //     case 10:
  //         query="vil_cd="+obj.lgdCode;
  //         url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Avillage_igrs&maxFeatures=1&CQL_FILTER='+query+'&outputFormat=application%2Fjson'
  //       break;
  //     case 14:
  //         query="col_id='"+obj.colonyId+"'";
  //         url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Afinal_igrs_gis_gl&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson'
  //       break;
  //     case 15:
  //         query="col_id='"+obj.colonyId+"'";
  //         url=this.settings.geoServerWmsUrl+this.settings.workSpace+'/ows?service=WFS&version=1.0.0&request=GetFeature&typeName='+this.settings.workSpace+'%3Afinal_igrs_gis_gl&CQL_FILTER='+query+'&maxFeatures=1&outputFormat=application%2Fjson'
  //       break;
  //   }
  //    return url;
  // }

  public clearMarker(cldemo: boolean = false) {
    this.markers.forEach((marker: any) => {
      this.map.removeLayer(marker)
    });
    this.markers = [];
    if(!cldemo){
      this.demographyLoaded = false;
      this.showOtherSearch=false;
      this.disableSaveBttn=true;
      //this.showOtherSearchActive=false;
      this.geoAddress = "";
      this.fullGeoAddress="";
    }
    this.closeConfPopup();
    this.hideErNotFoundGuidelinePopup();
    this.closePopup();
    this.removePropertyBoundry_2();
  }

  removeMarkerMannula(){
    this.markers.forEach((marker: any) => {
      this.map.removeLayer(marker)
    });
    this.markers=[];
  }
  //adjacentKhasra Modal Ad
  openPrimary(content:any) {
    this.modalService.open(content, {
      windowClass:"modalNew",
      size: 'md',
      centered: true
    });
  }

  getMergeBoundary(layers:any[]):any{
    var returnGeom = layers[0].feature.geometry;//turf.union(poly1, poly2);
    for(let i=1 ;i<layers.length;i++){
      returnGeom = turf.union(returnGeom, layers[i].feature.geometry);
    }
    return returnGeom;
  }

  changePlaceHolder(){
    if(this.filterType=='propid'){
      this.lan==0? this.placeHolder="Please Enter Nagar Nigam / Nagar Palika / Nagar Parishad / PTR Property ID":this.placeHolder="कृपया नगर निगम / नगर पालिका / नगर परिषद / पीटीआर संपत्ति आईडी दर्ज करें"
    }
    else if(this.filterType=='panchayatid'){
      this.lan==0? this.placeHolder="Please Enter Panchayat Property ID":this.placeHolder="कृपया पंचायत संपत्ति आईडी दर्ज करें"
    }
    else if(this.filterType=='searchByGeotag'){
      this.lan==0? this.placeHolder="Please Enter SAMPADA Geo Tag ID":this.placeHolder="कृपया संपदा जियो टैग आईडी दर्ज करें"
    }
  }

  needToaddKhasraAndproperty(latlng:any){
    // if(this.bylatlng){
    //   if(this.uaddResponseModel != undefined){
    //     //this.uaddResponseModel.latitude = latlng.lat.toString();
    //     //this.uaddResponseModel.longitude = latlng.lng.toString();
    //   }else if(this.cLRResponseModel != undefined){
    //     this.cLRResponseModel.latitude = latlng.lat.toString();
    //     this.cLRResponseModel.longitude = latlng.lng.toString();
    //   }
    // }else 
    if(this.filterType2 == "khasraid"){
      let no:number=null;
      if(this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr?.length>this.markers.length){
        for(let i=0; i<this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr.length;i++){
          if((this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr[i]?.latitude == undefined && this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr[i]?.longitude == undefined)
          || (this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr[i]?.latitude == "" && this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr[i]?.longitude == "")){
            no=i;
            this.updatedLatLngOnkhasraData(no, latlng);
            break;
          }
        }
        this.setMarker(latlng, true, no);        
      }
      if(this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr?.length==this.markers.length){
        this.needToadd_K_P_P=false;
        this.disableSaveBttn =false;
        this.showOtherSearch = false;
        this.filterType = "khasraid";
      }
    }else if(this.filterType2 == "propid"){
      this.getAdressByLatLng(latlng).subscribe(()=>{
        this.updatedLatLngOnPropertyData(latlng);
        this.setMarker(latlng, true);
        //this.getPropertyBoundry_2([latlng], 'plot_property_vw');
        this.needToadd_K_P_P=false;
        this.disableSaveBttn =false;
        this.showOtherSearch = false;
        this.filterType = "propid";
      });    
    }else if(this.filterType2 == "panchayatid"){
      this.getAdressByLatLng(latlng).subscribe(()=>{
        this.updatedLatLngOnPunchayatProperty(latlng);
        this.setMarker(latlng, true);
        //this.getPropertyBoundry_2([latlng], 'plot_property_vw');
        this.needToadd_K_P_P=false;
        this.disableSaveBttn =false;
        this.showOtherSearch = false;
        this.filterType = "panchayatid";

      });
    }
    
  }

  updatedLatLngOnkhasraData(no:number, latlng:any){
    this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr[no].latitude = latlng.lat.toString();
    this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr[no].longitude = latlng.lng.toString();
    this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr[no].demography=this.selectedMstDemographyBean
    if(no==0)
      this.agriPropertyValuationRequestBean.address = this.fullGeoAddress;

    console.log(this.agriPropertyValuationRequestBean);
  }
  
  updatedLatLngOnPropertyData(latlng:any){
    this.mpenagarpalikamodel.latitude = latlng.lat.toString();
    this.mpenagarpalikamodel.longitude = latlng.lng.toString();
    this.buildingPropertyValuationRequestBean.address=this.fullGeoAddress;

    if(this.ifManualEnagarPalika && latlng.lat != "" && latlng.lng != ""){
      this.mpenagarpalikamodel.propertyId=this.id
      this.mpenagarpalikamodel.propertyNo=this.id
      if(this.ismannualActive){
        let obj = { PropertyObject:this.mpenagarpalikamodel};
        this.matMannualDialogRef=this.dialog.open(MannualProcessPropertyIdentifationComponent, {
          data: obj,
          maxWidth:"60vw",
          height: 'auto',
          width: '60%',
          panelClass: 'full-screen-modal', disableClose: true
        });
        this.matMannualDialogRef.afterClosed()
        .pipe()
        .subscribe(result => {
          if(result){
          this.ngxService.stopLoader('loader-identification')
          console.log("mauunal Process result enagarpalika",result)
          this.mpenagarpalikamodel=result;
          this.mpenagarpalikamodel.address=this.fullGeoAddress;
          this.mpenagarpalikamodel.latitude = latlng.lat.toString();
          this.mpenagarpalikamodel.longitude = latlng.lng.toString();
          setTimeout(()=>this.registerDragElement(),300);
          //this.selectedMstDemographyBean=this.mpenagarpalikamodel.demography
          // this.mpenagarpalikamodel.propertyTypeId=2
          this.convertMpenagarPalikaPropertyToRequest( this.mpenagarpalikamodel)
          this.flag = false;
          //this.setMarker(latlng,false)
          }else{
            this.mpenagarpalikamodel.latitude = null;
            this.mpenagarpalikamodel.longitude = null;
            this.disableSaveBttn=true;
            this.removePropertyBoundry();
            this.removeMarkerMannula();
            this.needToadd_K_P_P=true;
            this.showOtherSearchActive=true;
            this.showOtherSearch =true;
          }
        })
      }else{
        this.mpenagarpalikamodel.address=this.fullGeoAddress;
        this.mpenagarpalikamodel.latitude = latlng.lat.toString();
        this.mpenagarpalikamodel.longitude = latlng.lng.toString();
      }
    }

  }
  
  updatedLatLngOnPunchayatProperty(latlng:any){
    this.prndData.latitude = latlng.lat.toString();
    this.prndData.longitude =latlng.lng.toString();
    this.propertyValuationRequestBean.address = this.fullGeoAddress;

    if(this.ifManualPanchayat && latlng.lat != "" && latlng.lng != ""){      
      this.prndData.propertyId=this.id
      this.prndData.propertyNo=this.id
      if(this.ismannualActive){
        let obj = { PropertyObject:this.prndData};
        this.matMannualDialogRef=this.dialog.open(MannualProcessPropertyIdentifationComponent, {
          data: obj,
          maxWidth:"60vw",
          height: 'auto',
          width: '60%',
          panelClass: 'full-screen-modal', disableClose: true
        });
        this.matMannualDialogRef.afterClosed()
        .pipe()
        .subscribe(result => {
          if(result){
          this.ngxService.stopLoader('loader-identification')
          console.log("mauunal Process result pnrd",result)
          this.prndData=result;
          this.prndData.address=this.fullGeoAddress;
          this.prndData.partialTransactArea=result.totalArea
          this.prndData.latitude = latlng.lat.toString();
          this.prndData.longitude =latlng.lng.toString();
          setTimeout(()=>this.registerDragElement(),300);
          //this.selectedMstDemographyBean=this.mpenagarpalikamodel.demography
          // this.mpenagarpalikamodel.propertyTypeId=2
          this.convertPanchayatToRequest( this.prndData)
          this.flag = false;
         
          //this.setMarker(latlng,false)
          }else{
            this.prndData.latitude = null;
            this.prndData.longitude = null;
            this.disableSaveBttn=true;
            this.removePropertyBoundry();
            this.removeMarkerMannula();
            this.needToadd_K_P_P=true;
            this.showOtherSearchActive=true;
            this.showOtherSearch =true;
          }
        })
      }else{
        this.prndData.address=this.fullGeoAddress;
        this.prndData.latitude = latlng.lat.toString();
        this.prndData.longitude =latlng.lng.toString();
      }
    }
  }
  

  private registerDragElement() {
    const elmnt = document.getElementById('k-dragablediv');
    
    let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    const dragMouseDown = (e:any) => {
      e = e || window.event;
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    };

    const elementDrag = (e:any) => {
      e = e || window.event;
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:
      elmnt.style.top = elmnt.offsetTop - pos2 + 'px';
      elmnt.style.left = elmnt.offsetLeft - pos1 + 'px';
    };

    const closeDragElement = () => {
      /* stop moving when mouse button is released:*/
      document.onmouseup = null;
      document.onmousemove = null;
    };

    if (document.getElementById(elmnt.id + 'header')) {
      /* if present, the header is where you move the DIV from:*/
      document.getElementById(elmnt.id + 'header').onmousedown = dragMouseDown;
    } else {
      /* otherwise, move the DIV from anywhere inside the DIV:*/
      elmnt.onmousedown = dragMouseDown;
    }
  }

  removeAddedKhasra(data:WebGisResponseBeanData=null,i:number){
    //console.log(data, i);
    this.markers.forEach((marker:L.Marker, n:number)=>{
      for(let layer in this.selectPropertyLayer?._layers){
        if(marker.options.data.no == i && this.checkMarkerInOut(marker.getLatLng(), this.selectPropertyLayer._layers[layer].feature.geometry)){
          this.selectPropertyLayer.removeLayer(this.selectPropertyLayer._layers[layer]);
        }
      }
      if(marker.options.data.no == i){
        let latlng:any={lat:'',lng:''};
        this.updatedLatLngOnkhasraData(marker.options.data.no, latlng);
        marker.remove();
        this.markers.splice(n, 1);
        this.needToadd_K_P_P=true;
        this.disableSaveBttn =true;
        this.showOtherSearch=true;
      }
    });
  }

  removeAddedPunchayatProperty(){
    this.clearMarker(false);
    this.removePropertyBoundry();
    this.needToadd_K_P_P=true;
    this.showOtherSearch=true;
    let latlng:any={lat:'',lng:''};
    this.updatedLatLngOnPunchayatProperty(latlng);
  }

  removeAddedProperty(){
    this.clearMarker(false);
    this.removePropertyBoundry();
    this.needToadd_K_P_P=true;
    this.showOtherSearch=true;
    let latlng:any={lat:'',lng:''};
    this.updatedLatLngOnPropertyData(latlng);
  }

  openModelForUnitAndIsPlot(content:any) {
    this.modalService.open(content, {
      windowClass:"modalNew",
      size: 'auto',
      centered: true
    });
  }

  confirmationalert(p:string=''){
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success confirmbtns',
        cancelButton: 'btn btn-danger confirmbtns'
      },
      buttonsStyling: false,
      allowOutsideClick: false,
    });

    return swalWithBootstrapButtons.fire({
      position: 'top-end',
      title: "",
      html: p,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true,
      allowOutsideClick: false,
      backdrop: false
    });
}

  capitalizeFirstLetter(str:string):string {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
// 
  resetDemo: boolean = true;
  isShow = true;
  hiddenvalue: string = "hidden";
  toggleStatus(rmGu:boolean=true) {
    this.isShow = !this.isShow;
    if (this.isShow) {
      this.hiddenvalue = "hidden";
      this.resetDemo=false;
    } else {
      this.hiddenvalue = "";
      this.resetDemo=true;
      if(this.selectedMstDemographyBean){
        this.type_id = 4;
        this.parent_id = this.selectedMstDemographyBean.location.district.id;      
      }else if(this.data?.districtId){
        this.type_id = 4;
        this.parent_id = this.data?.districtId; 
      }else{
        this.type_id = 3;
        this.parent_id =1;
      }
      //this.allFormReset1(this.drawer);
    }
  }


  /*getDemography(demography:any){
    this.removeGuidelineBoundry();
    if(this.selectedMstDemographyBean && (demography.demographyTypeId == 14 || demography.demographyTypeId == 15)){
      this.getdemographyGeom(demography, false);
      this.selectedMstDemographyBean = demography;
      this.hideDropdownForMultipleDemography = false;
      if(this.filterType2 == "khasraid"   && this.agriPropertyValuationRequestBean){
        this.agriPropertyValuationRequestBean.demography = this.selectedMstDemographyBean;
        this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr.forEach((prop:WebGisResponseBeanData)=>{
            prop.demography = this.selectedMstDemographyBean;
        });
      }else if (this.filterType2 == "propid"  && this.mpenagarpalikamodel){
        this.propertyValuationRequestBean.demography=this.selectedMstDemographyBean;
        this.buildingPropertyValuationRequestBean.demography = this.selectedMstDemographyBean;
        this.mpenagarpalikamodel.demography = this.selectedMstDemographyBean;
      } else if (this.filterType2 == "panchayatid" && this.prndData){
        this.propertyValuationRequestBean.demography=this.selectedMstDemographyBean;
        this.buildingPropertyValuationRequestBean.demography = this.selectedMstDemographyBean;
        this.prndData.demography = this.selectedMstDemographyBean;
      }
    }else
      this.getdemographyGeom(demography, true);
  }*/

  async getDemography(demography:any, changeGuideline:boolean=false){
    this.removeGuidelineBoundry();
    this.tempSelecteGuidlineLocation = null;
    if(this.selectedMstDemographyBean && (demography.demographyTypeId == 14 || demography.demographyTypeId == 15)){
      await this.getdemographyGeom(demography, false);
      if(!changeGuideline){
        this.addDemography(demography);
      }else{
        this.tempSelecteGuidlineLocation = demography;
      }
    }else
      await this.getdemographyGeom(demography, true);
  }

  addDemography(demography:MstDemographyBean){
    this.selectedMstDemographyBean = demography;
    this.getCurrentGuildeLine(this.selectedMstDemographyBean.id);
    this.hideDropdownForMultipleDemography = false;
    if(this.filterType2 == "khasraid"   && this.agriPropertyValuationRequestBean){
      this.agriPropertyValuationRequestBean.demography = this.selectedMstDemographyBean;
      this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr.forEach((prop:WebGisResponseBeanData)=>{
          prop.demography = this.selectedMstDemographyBean;
      });
    }else if (this.filterType2 == "propid"  && this.mpenagarpalikamodel){
      this.propertyValuationRequestBean.demography=this.selectedMstDemographyBean;
      this.buildingPropertyValuationRequestBean.demography = this.selectedMstDemographyBean;
      this.mpenagarpalikamodel.demography = this.selectedMstDemographyBean;
    } else if (this.filterType2 == "panchayatid" && this.prndData){
      this.propertyValuationRequestBean.demography=this.selectedMstDemographyBean;
      this.buildingPropertyValuationRequestBean.demography = this.selectedMstDemographyBean;
      this.prndData.demography = this.selectedMstDemographyBean;
    }
  }

  openKharsaFormat(content:any){
      this.modalService.open(content, {
        windowClass:"modalNew",
        size: 'xl',
        centered: true
      });
  }

  changePropertyType(propertyTypeId:any){
    this.mstPropertyTypeBean.filter(data => {
      if (data.id === Number(propertyTypeId)) {
        this.buildingPropertyValuationRequestBean.propertyType = data
      }
    })
  }

  changeGuidelineLocaion(){
    this.showRular =true;
    if(this.filterType2=='khasraid' || this.filterType2=='panchayatid'){
      let type = this.selectedMstDemographyBean?.demographyType?.demographyAreaType;
      if(this.selectedMstDemographyBean && type=="R"){
        this.type_id2 = 15;
        this.parent_id2 = this.selectedMstDemographyBean.location.village.id; 
      }else if(this.selectedMstDemographyBean && type=="U"){
        this.type_id2 = 4;
        this.parent_id2 = this.selectedMstDemographyBean.location.district.id;
        this.showRular =false; 
      }
      this.removeGuidelineBoundry();
      this.disableSaveBttn =true;
      this.showChangeDemography =true;
      this.tempSelecteGuidlineLocation = null;
    
    }else if(this.filterType2=='propid'){
      if(this.selectedMstDemographyBean.location.localBody){
        this.type_id2 = 8;
        this.parent_id2 = this.selectedMstDemographyBean.location.localBody.id;
        this.removeGuidelineBoundry();
        this.disableSaveBttn =true;
        this.showChangeDemography =true;
        this.tempSelecteGuidlineLocation = null;
      }
    }
    
  }
  
  UpdateNewGuideline(){
    if(this.tempSelecteGuidlineLocation == null){
      this.alertmessage.errorAlertMessage({responseMessage:'Please Select Guideline Location.',responseMessageEn:'Please Select Guideline Location.',responseMessageHi:'कृपया गाईडलाइन स्थान चुनें।'});
      return;
    }else if(this.tempSelecteGuidlineLocation.demographyTypeId != 14 && this.tempSelecteGuidlineLocation.demographyTypeId != 15){
      this.alertmessage.errorAlertMessage({responseMessage:'Please Select Guideline Location.',responseMessageEn:'Please Select Guideline Location.',responseMessageHi:'कृपया गाईडलाइन स्थान चुनें।'});
      return;
    }
    this.addDemography(this.tempSelecteGuidlineLocation);
    this.tempSelecteGuidlineLocation=null;
    this.showChangeDemography = false;
    if(this.filterType2=='propid'){
      if(this.mpenagarpalikamodel.latitude != null && this.mpenagarpalikamodel.longitude != null){
        this.disableSaveBttn =false;
      }     
    }else if(this.filterType2=='khasraid'){
      if(this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr?.length==this.markers.length){
        this.disableSaveBttn =false;
      }     
    }else if(this.filterType2=='panchayatid'){
      if(this.prndData.latitude != null && this.prndData.longitude != null){
        this.disableSaveBttn =false;
      }     
    }
  }

  async closeChangeDemography(){
    this.removeGuidelineBoundry();
    await this.getdemographyGeom(this.selectedMstDemographyBean, false);
    this.showChangeDemography = !this.showChangeDemography;
    this.tempSelecteGuidlineLocation = null;
    if(this.selectedMstDemographyBean && (this.selectedMstDemographyBean.demographyTypeId == 14 || this.selectedMstDemographyBean.demographyTypeId == 15)){
      if(this.filterType2=='propid'){
        if(this.mpenagarpalikamodel.latitude != null && this.mpenagarpalikamodel.longitude != null){
          this.disableSaveBttn =false;
        }     
      }else if(this.filterType2=='khasraid'){
        if(this.agriPropertyValuationRequestBean.webGisResponseBeanDataArr?.length==this.markers.length){
          this.disableSaveBttn =false;
        }     
      }else if(this.filterType2=='panchayatid'){
        if(this.prndData.latitude != null && this.prndData.longitude != null){
          this.disableSaveBttn =false;
        }     
      }
    }    
  }


}
