import { MstDemographyBean } from "../MstDemographyBean";
import { MstPropertyTypeBean } from "../MstPropertyTypeBean";
import { DeedInstrumentBean } from "./deed-instrument-bean";
import { DutyExemptionMapping } from "./duty-exemption-mapping";
import { DutyInputFields } from "./duty-input-fields";
import { DutyOtherInputFields } from "./duty-other-input-fields";
import { DutyPropInputFields } from "./duty-prop-input-fields";
import { DutyPropInputFieldsDto } from "./duty-prop-input-fields-dto";
import { IgrsConsenterDetlsDto } from "./igrs-consenter-detls-dto";
import { IgrsConsenterMap } from "./igrs-consenter-map";
import { IgrsDutyCalculation } from "./igrs-duty-calculation";

export class DutyCalculationRequest {

    constructor(){
        console.log("DutyCalculationRequest constructor");
       // this.isConsenterAdded =  false;
        this.dutyInputFieldList = new Array();
        this.selectedExemptionList  = new Array();  
        this.dutyInputOtherFieldList = new Array();
  
        // marketValueList: Array < any>;   
        // marketValueListFTP:Array<any>;
        // marketValueListSTP:Array<any>;
        // fieldArray:Array<any>;
        this.propValFieldArray = new Array();
        this.dutyInputOtherFieldListReqArray = new Array();
        this.instrumentBean;
        this.consenterDetailMap= new Array();
    }
    dutyId: number;
    instrumentBean: DeedInstrumentBean;
    instrumentId: number;
    //fieldValuesReqMap: Map<number, string> = new Map<number,string>();
    dutyInputFieldList: DutyInputFields[] = new Array();
    selectedExemptionList:  DutyExemptionMapping[]  = new Array();  
    dutyInputOtherFieldList: DutyOtherInputFields[] = new Array();
    isExemptionSelected: boolean;
    isPropFlow: boolean;
    propValId: number;
    propMarketValue:number;
    builderMarketValue: number;
    marketValueList: Array < any>;   
    marketValueListFTP:Array<any>;
    marketValueListSTP:Array<any>;
    propValListFTPSTP:DutyPropInputFields[] ;
    fieldArray:Array<any>;
    propValFieldArray: DutyPropInputFieldsDto[] = new Array();
    propertyType:MstPropertyTypeBean
    dutyInputOtherFieldListReqArray = new Array();
    isAddPropApplicable:boolean;
    cancellationFlag:boolean=false;
    isMovableProp:boolean =false;
    movPropValfieldArray: Array<any> = [];
    isConsenterAdded: boolean=false;
    //consenterDetailMap = new Map<number, IgrsConsenterDetlsDto[]>();
    consenterDetailMap:IgrsConsenterMap[];
     totalConsiderationAmt:number;
     mstDemographyBean:MstDemographyBean;
     isDutyMovableProp:boolean =false;
     totalMarketvalue:number;
     moduleId:number;
     totalConsenterPSD:number;
     totalConsenterRegFee:number;
     dutyRegFeeGuildelineVal:number;
     consumeEstampSam1Result:any
}
