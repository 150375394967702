import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';

import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatRippleModule } from '@angular/material/core';
import { HeaderNewComponent } from './header-new/header-new.component';
import { MaterialModule } from 'projects/common-lib/material/material.module';
import { HttpClient } from '@angular/common/http';
import { CommonLibModule, httpTranslateLoader } from 'projects/common-lib/src/projects';



@NgModule({
  declarations: [    
    HeaderComponent,
    FooterComponent,
    HeaderNewComponent
  ],
  imports: [
    CommonLibModule,
    CommonModule,
    RouterModule,
    SlickCarouselModule,
    MatSlideToggleModule,
    NgbModule,
    MaterialModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      },
      defaultLanguage: 'hi',
     // isolate: false,
      //extend: true

    }),
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    HeaderNewComponent
    
  ]
})
export class SharedModule { }
