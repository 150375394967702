<fieldset class="detail legend-section" id="dutyPrint">
   <legend class="sidebar__widget-title">
      {{'duty-calculation.calculate' | translate}}
   </legend>



   <form #dutyform="ngForm" (ngSubmit)="dutyform.form.valid && calculateDuty(dutyform)">
      <lib-alertmessage></lib-alertmessage>
      <!-- <div class="requiredColor"> {{lan==0?errMsgEn:errMsgHi}}</div> -->


      <div *ngIf="instrumentBean!==undefined && instrumentBean!==null? instrumentBean.id!=37:false">
         <div class="row mt-3">
            <ng-container *ngFor="let field of instMappingBeanList | orderBy: 'orderId'; index as i">
               <ng-container *ngIf="field.parentId == null && field.status">

                  <ng-container *ngIf="field.fieldType==='TEXT' ">
                     <div class="col-md-4">
                        <div class="form-group">
                           <label class="label h-40">
                              {{lan==0?field.fieldNameEn:field.fieldNameHi}} </label>
                           <input type="text" [readonly]="field.readonly" maxlength="12" [appCopyPastValidation]="true"
                              [required]="field.isRequired" id="{{field.id}}" [(ngModel)]="inputFields[field.id]"
                              #inputFields[field.id]="ngModel" name="F_{{field.id}}"
                              placeholder="{{'duty-calculation.pls_enter' | translate}}"
                              class="form-control form-control-sm">
                           <a *ngIf="field.isPopup !=0" (click)="showPopUp(field.isPopup, field)" class="mt-1 d-block">

                              <strong>{{lan==0?field.popupNameEn:field.popupNameHi}}</strong>
                           </a>
                        </div>
                        <div *ngIf="dutyform.submitted && !dutyform.form.valid " class="requiredColor div1">
                           {{lan==0?field.validationMsgEn:field.validationMsgHi}}</div>
                        <div
                           *ngIf="dutyform.submitted && !dutyform.form.valid  && !inputFields[field.id] && field.fieldType!='LABEL' && field.fieldType!='BUTTON'  && field.fieldType!='CHECKBOX' && field.fieldType!='DROPDOWN'"
                           class="requiredColor div2"> {{lan==0?field.fieldNameEn:field.fieldNameHi}}
                           {{'duty-calculation.is_required' | translate}}
                        </div>
                     </div>
                  </ng-container>

                  <ng-container *ngIf="field.fieldType==='NUMBER' && field.otherCalFlag!==DutyOtherFlagEnum.AMF">
                     <div class="col-md-4">
                        <div class="form-group">
                           <label class="label h-40"> {{lan==0?field.fieldNameEn:field.fieldNameHi}} </label>
                           <input type="number" [readonly]="field.readonly" [OnlyNumber]="true"
                              [appCopyPastValidation]="true" onkeypress="return !(event.charCode == 46)" step="1"
                              maxlength="10" [required]="field.isRequired" class="form-control" id="{{field.id}}"
                              [(ngModel)]="inputFields[field.id]" #inputFields[field.id]="ngModel" name="F_{{field.id}}"
                              placeholder="{{'duty-calculation.pls_enter' | translate}}" [min]="field.minimum"
                              [max]="field.maximum" class="form-control form-control-sm">
                           <a *ngIf="field.isPopup !=0" (click)="showPopUp(field.isPopup, field)" class="mt-1 d-block">

                              <strong>{{lan==0?field.popupNameEn:field.popupNameHi}}</strong>
                           </a>
                        </div>
                        <div>
                           {{inputFields[field.id] | AmtToWords}}
                        </div>
                        <div *ngIf="dutyform.submitted && !dutyform.form.valid " class="requiredColor div1">
                           {{lan==0?field.validationMsgEn:field.validationMsgHi}}</div>
                        <div
                           *ngIf="dutyform.submitted && !dutyform.form.valid  && !inputFields[field.id] && field.fieldType!='LABEL' && field.fieldType!='BUTTON'  && field.fieldType!='CHECKBOX' && field.fieldType!='DROPDOWN'"
                           class="requiredColor div2"> {{lan==0?field.fieldNameEn:field.fieldNameHi}}
                           {{'duty-calculation.is_required' | translate}}
                        </div>
                     </div>
                  </ng-container>

                  <ng-container *ngIf="field.fieldType==='NUMBER' && field.otherCalFlag===DutyOtherFlagEnum.AMF">
                     <div class="col-md-4">
                        <div class="form-group">
                           <label class="label h-40"> {{lan==0?field.fieldNameEn:field.fieldNameHi}}</label>

                           <div class="row">
                              <div class="col-md-9">
                                 <input type="number" [readonly]="field.readonly" [OnlyNumber]="true"
                                    [appCopyPastValidation]="true" onkeypress="return !(event.charCode == 46)" step="1"
                                    maxlength="10" class="form-control" id="{{field.id}}" [required]="field.isRequired"
                                    [(ngModel)]="inputFields[field.id]" #inputFields[field.id]="ngModel"
                                    name="F_{{field.id}}" placeholder="{{'duty-calculation.pls_enter' | translate}}"
                                    [min]="field.minimum" [max]="field.maximum" class="form-control form-control-sm">
                              </div>
                              <div class="col-md-auto">
                                 <button class="btn btn-primary btn-sm" type="button" id="button-addField"
                                    name="addField" (click)="addMoreField(field)"> {{ 'duty-calculation.add' | translate
                                    }}</button>
                              </div>
                              <div class="col-md-12">
                                 {{inputFields[field.id] | AmtToWords}}
                              </div>
                           </div>

                           <div
                              *ngIf="dutyform.submitted && field.instrumentId.id!=108 && (fieldArray.length==0 || fieldArray.length==1 )"
                              class="requiredColor col-md-12 p-0">
                              {{ 'duty-calculation.partitionAgriLandError' | translate }}</div>
                        </div>
                        <div *ngIf="dutyform.submitted && !dutyform.form.valid " class="requiredColor div1">
                           {{lan==0?field.validationMsgEn:field.validationMsgHi}}</div>

                        <div
                           *ngIf="dutyform.submitted && !dutyform.form.valid  && !inputFields[field.id] && field.fieldType!='LABEL' && field.fieldType!='BUTTON'  && field.fieldType!='CHECKBOX' && field.fieldType!='DROPDOWN'"
                           class="requiredColor div2"> {{lan==0?field.fieldNameEn:field.fieldNameHi}}
                           {{'duty-calculation.is_required' | translate}}
                        </div>


                     </div>

                     <div class="col-md-12">
                        <table class="table table-bordered mb-0" *ngIf="fieldArray.length!== 0">
                           <thead class="thead-dark">
                              <tr>
                                 <th> {{'duty-calculation.SNO' | translate}}</th>
                                 <th>{{lan==0?field.fieldNameEn:field.fieldNameHi}}</th>
                                 <th>{{'duty-calculation.action' | translate}}</th>
                              </tr>
                           </thead>
                           <tr *ngFor="let fld of fieldArray; let f = index ">
                              <td style="vertical-align: middle;">{{f+1}}</td>
                              <td style="vertical-align: middle;"><input readonly value="{{fld}}"
                                    class="form-control sy-width" type="text" name="{{fld}}" /></td>
                              <td style="vertical-align: middle;">
                                 <!-- <button class="btn btn-primary btn-sm" type="button" id="button-deleteField"
                                       name="deleteField" (click)="deleteField(f)"> <mat-icon>delete</mat-icon> {{ 'duty-calculation.delete' |
                                       translate }}</button> -->
                                 <em class=" btn btn-danger btn-sm" type="button" id="button-deleteField"
                                    name="deleteField" (click)="deleteField(f)"> <mat-icon>delete</mat-icon> </em>
                              </td>
                           </tr>
                        </table>


                        <!-- <label class="label h-40">{{lan==0?field.fieldNameEn:field.fieldNameHi}}</label>
                            <div class="row">
                              <div class="col-md-8">
                                 <input readonly value="{{fld}}" class="form-control" type="text" name="{{fld}}" />

                              </div>
                              <div class="col-md-auto">
                                 <button class="btn btn-primary btn-sm" type="button" id="button-deleteField"
                                 name="deleteField" (click)="deleteField(f)"> {{ 'duty-calculation.delete' |
                                 translate }}</button>
                              </div>
                           </div> -->


                     </div>

                  </ng-container>


                  <ng-container *ngIf="field.fieldType==='BUTTON'">
                     <div class="col-md-6">
                        <div class="card">
                           <div class="card-body p-1">

                              <div class="text-center">
                                 <button class="btn" [ngClass]="{'btn-primary': i%2==0, 'btn-danger': i%2!=0}"
                                    type="button" [(ngModel)]="otherCalFlag" id="button-addProperty" name="addProperty"
                                    (click)="addProperty(field)">
                                    {{lan==0?field.fieldNameEn:field.fieldNameHi}}</button>
                              </div>

                              <ng-container class="form-group p-3 mb-0"
                                 *ngIf="field.otherCalFlag===DutyOtherFlagEnum.FTP">

                                 <table class="table bg-light table-striped table-bordered mb-0 mt-2"
                                    *ngIf="propValListFTP.length>0">
                                    <tr>
                                       <th scope="col">{{'duty-calculation.SNO' | translate}}</th>
                                       <th scope="col">{{'duty-calculation.propValId' | translate}}</th>
                                       <th scope="col">{{'duty-calculation.propMarketValue' | translate}}</th>
                                       <th scope="col">{{'duty-calculation.delete_property' | translate}}</th>
                                    </tr>
                                    <tr *ngFor="let prop of propValListFTP; let f = index ">

                                       <td>{{f+1}}</td>
                                       <td> {{prop.propValId}}</td>
                                       <td> {{prop.marketValue}}</td>
                                       <td>
                                          <em id="button-deleteProp" class="fa fa-trash btn btn-danger btn-sm"
                                             (click)="deleteProperty(f,'FTP')"></em>

                                          <!-- <button class=" btn btn-primary" type="button"
                                          name="deleteProp" > {{
                                          'duty-calculation.delete_property' | translate }}</button> -->
                                       </td>
                                    </tr>
                                 </table>

                              </ng-container>

                              <ng-container *ngIf="field.otherCalFlag===DutyOtherFlagEnum.STP">

                                 <table class="table bg-light table-striped table-bordered mb-0 mt-2"
                                    *ngIf="propValListSTP.length>0">
                                    <tr>
                                       <th scope="col">{{'duty-calculation.SNO' | translate}}</th>
                                       <th scope="col">{{'duty-calculation.propValId' | translate}}</th>
                                       <th scope="col">{{'duty-calculation.propMarketValue' | translate}}</th>
                                       <th scope="col">{{'duty-calculation.delete_property' | translate}}</th>
                                    </tr>
                                    <tr *ngFor="let prop of propValListSTP; let s = index">

                                       <td>{{s+1}}</td>
                                       <td> {{prop.propValId}}</td>
                                       <td> {{prop.marketValue}}</td>
                                       <td>
                                          <em id="button-deleteProp" class="fa fa-trash btn btn-danger btn-sm"
                                             (click)="deleteProperty(s,'STP')"></em>

                                          <!-- <button class="btn btn-primary" type="button"
                                       name="deleteProp" > {{
                                       'duty-calculation.delete_property' | translate }}</button> -->
                                       </td>

                                    </tr>
                                 </table>
                              </ng-container>
                           </div>
                        </div>
                     </div>

                  </ng-container>

                  <!-- <div *ngIf="dutyform.submitted && !dutyform.form.valid " class="requiredColor col-md-12">
                     {{lan==0?field.validationMsgEn:field.validationMsgHi}}</div> -->



                  <ng-container
                     *ngIf="(field.fieldType==='LABEL' || field.fieldType==='DROPDOWN' || field.fieldType==='CHECKBOX') && field.status">
                     <div class="col-md-12 mt-3 mb-2">
                        <div class="row">
                           <div class="col-md-12">
                              <label class="label d-block-label" *ngIf="field.parentId == null">
                                 <ng-container *ngIf="field.fieldType==='CHECKBOX' && field.status">

                                    <input class="m-0 mr-1 top-rel" type="checkbox" #checkboxNgSelect
                                       name="F_{{field.id}}" [(ngModel)]="inputFields[field.id]"
                                       (change)="userTypeCheckBoxEvent($event,field)" [value]="field.fieldNameEn">

                                 </ng-container>

                                 {{lan==0?field.fieldNameEn:field.fieldNameHi}}</label>

                              <ng-container *ngIf="field.fieldType==='DROPDOWN' ">
                                 <div class="col-md-4 p-0 mb-3">
                                    <ng-select name="F_{{field.id}}"
                                       placeholder="{{'duty-calculation.pls_enter' | translate}}" class=""
                                       [required]="field.isRequired" [clearable]="true" [(ngModel)]="fieldNameEn"
                                       (click)="userTypeEvent($event,pMappingDropBean)">
                                       <ng-option [value]="pMapping.fieldNameEn"
                                          *ngFor="let pMapping of field.parentMappingList | orderBy: 'orderId'; index as i">
                                          {{lan==0?pMapping.fieldNameEn:pMapping.fieldNameHi}}
                                       </ng-option>
                                    </ng-select>
                                 </div>
                              </ng-container>

                           </div>
                           <ng-container
                              *ngFor="let pMapping of field.parentMappingList | orderBy:'orderId'; index as i">


                              <ng-container *ngIf="pMapping.fieldType==='RADIO' && pMapping.status">
                                 <div class="form-check form-check-inline pl-3">
                                    <input type="radio" [required]="pMapping.isRequired" id="RADIO_{{pMapping.id}}"
                                       [(ngModel)]="inputFields[pMapping.parentId]" name="F_{{pMapping.parentId}}"
                                       (click)="userTypeEvent($event,pMapping)" [value]="pMapping.fieldNameEn">
                                    {{lan==0?pMapping.fieldNameEn:pMapping.fieldNameHi}}
                                    <!--    <a (click)="showPopUp(pMapping.isPopup)"><label  *ngIf="pMapping.isPopup !=0"><strong>{{lan==0?pMapping.popupNameEn:pMapping.popupNameHi}}</strong></label></a> -->
                                    <div *ngIf="dutyform.submitted && !inputFields[pMapping.parentId] "
                                       class="requiredColor d-block ml-3">
                                       {{lan==0?pMapping.fieldNameEn:pMapping.fieldNameHi}}
                                       {{'duty-calculation.is_required' | translate}}</div>
                                 </div>
                              </ng-container>

                              <ng-container *ngIf="pMappingCheckSel == pMapping.parentId">

                                 <div class="col-md-4 form-group12 ">
                                    <ng-container *ngIf="pMapping.fieldType==='TEXT' && pMapping.status">
                                       <input type="text" [readonly]="pMapping.readonly" [appCopyPastValidation]="true"
                                          [required]="pMapping.isRequired" maxlength="12" id="{{pMapping.id}}"
                                          [(ngModel)]="inputFields[pMapping.id]" name="F_{{pMapping.id}}"
                                          #inputFields[pMapping.id]="ngModel"
                                          placeholder="{{'duty-calculation.pls_enter' | translate}}"
                                          class="form-control form-control-sm">
                                       <small class="span">{{lan==0?pMapping.fieldNameEn:pMapping.fieldNameHi}}</small>
                                    </ng-container>

                                    <ng-container *ngIf="pMapping.fieldType==='NUMBER' && pMapping.status">
                                       <input type="number" [readonly]="pMapping.readonly"
                                          onkeypress="return !(event.charCode == 46)" step="1" [OnlyNumber]="true"
                                          [appCopyPastValidation]="true" [required]="pMapping.isRequired" maxlength="10"
                                          id="{{pMapping.id}}" [(ngModel)]="inputFields[pMapping.id]"
                                          name="F_{{pMapping.id}}" #inputFields[pMapping.id]="ngModel"
                                          [min]="pMapping.minimum" [max]="pMapping.maximum"
                                          placeholder="{{'duty-calculation.pls_enter' | translate}}"
                                          class="form-control form-control-sm">
                                       <small>{{lan==0?pMapping.fieldNameEn:pMapping.fieldNameHi}}</small>
                                    </ng-container>
                                    <div class="w-100">
                                       {{inputFields[pMapping.id] | AmtToWords}}
                                    </div>
                                    <div
                                       *ngIf="dutyform.submitted && !inputFields[pMapping.id] && (pMapping.fieldType!=='CHECKBOX')"
                                       class="requiredColor">
                                       {{lan==0?pMapping.fieldNameEn:pMapping.fieldNameHi}}
                                       {{'duty-calculation.is_required' | translate}}</div>


                                 </div>

                              </ng-container>

                              <ng-container *ngIf="pMappingCheckSel != pMapping.parentId">

                                 <div class="col-md-4 form-group12   notvisible">
                                    <ng-container *ngIf="pMapping.fieldType==='TEXT' && pMapping.status">
                                       <input type="text" [readonly]="pMapping.readonly" [appCopyPastValidation]="true"
                                          required="false" maxlength="12" value=null id="{{pMapping.id}}"
                                          [(ngModel)]="inputFields[pMapping.id]" name="F_{{pMapping.id}}"
                                          #inputFields[pMapping.id]="ngModel"
                                          placeholder="{{'duty-calculation.pls_enter' | translate}}"
                                          class="form-control form-control-sm">
                                       <label class="label">{{lan==0?pMapping.fieldNameEn:pMapping.fieldNameHi}}</label>
                                    </ng-container>
                                    <ng-container *ngIf="pMapping.fieldType==='NUMBER' && pMapping.status">
                                       <input type="number" [readonly]="pMapping.readonly"
                                          onkeypress="return !(event.charCode == 46)" [OnlyNumber]="true"
                                          [appCopyPastValidation]="true" required="false" value="0" maxlength="10"
                                          id="{{pMapping.id}}" [(ngModel)]="inputFields[pMapping.id]"
                                          name="F_{{pMapping.id}}" #inputFields[pMapping.id]="ngModel"
                                          [min]="pMapping.minimum" [max]="pMapping.maximum"
                                          placeholder="{{'duty-calculation.pls_enter' | translate}}"
                                          class="form-control form-control-sm">
                                       <label
                                          class="label row">{{lan==0?pMapping.fieldNameEn:pMapping.fieldNameHi}}</label>
                                    </ng-container>
                                    <div
                                       *ngIf="dutyform.submitted && !inputFields[pMapping.id] && (pMapping.fieldType!=='CHECKBOX' )"
                                       class="requiredColor">
                                       {{lan==0?pMapping.fieldNameEn:pMapping.fieldNameHi}}
                                       {{'duty-calculation.is_required' | translate}}</div>
                                 </div>
                                 <div>
                                    {{inputFields[pMapping.id] | AmtToWords}}
                                 </div>
                              </ng-container>
                              <ng-container *ngFor="let cfields of pMapping.parentMappingList | orderBy: 'orderId'">
                                 <ng-container *ngIf="pMappingSel == cfields.parentId">

                                    <div class="col-md-3 form-group12">
                                       <ng-container *ngIf="cfields.fieldType==='TEXT' && cfields.status">
                                          <label>{{lan==0?cfields.fieldNameEn:cfields.fieldNameEn}}</label>
                                          <input type="text" [readonly]="cfields.readonly"
                                             [appCopyPastValidation]="true" [required]="cfields.isRequired"
                                             maxlength="12" id="{{cfields.id}}" [(ngModel)]="inputFields[cfields.id]"
                                             name="F_{{cfields.id}}" #inputFields[cfields.id]="ngModel"
                                             placeholder="{{'duty-calculation.pls_enter' | translate}}"
                                             class="form-control form-control-sm">
                                          <a (click)="showPopUp(cfields.isPopup, cfields)"><label class="label"
                                                *ngIf="cfields.isPopup !=0"><strong>{{lan==0?cfields.popupNameEn:cfields.popupNameHi}}</strong></label></a>
                                       </ng-container>
                                       <ng-container *ngIf="cfields.fieldType==='NUMBER' && cfields.status">
                                          <label>{{lan==0?cfields.fieldNameEn:cfields.fieldNameEn}}</label>
                                          <input type="number" [readonly]="cfields.readonly" [OnlyNumber]="true"
                                             [appCopyPastValidation]="true" onkeypress="return !(event.charCode == 46)"
                                             step="1" [required]="cfields.isRequired" maxlength="10" id="{{cfields.id}}"
                                             [(ngModel)]="inputFields[cfields.id]" name="F_{{cfields.id}}"
                                             #inputFields[cfields.id]="ngModel" [min]="cfields.minimum"
                                             [max]="cfields.maximum"
                                             placeholder="{{'duty-calculation.pls_enter' | translate}}"
                                             class="form-control form-control-sm">
                                          <a (click)="showPopUp(cfields.isPopup, cfields)"><label class="label"
                                                *ngIf="cfields.isPopup !=0"><strong>{{lan==0?cfields.popupNameEn:cfields.popupNameHi}}</strong></label></a>
                                       </ng-container>
                                       <div *ngIf="dutyform.submitted && !inputFields[cfields.id]"
                                          class="requiredColor">
                                          {{lan==0?cfields.fieldNameEn:cfields.fieldNameHi}}
                                          {{'duty-calculation.is_required' | translate}}
                                       </div>
                                       <div>
                                          {{inputFields[cfields.id] | AmtToWords}}
                                       </div>
                                    </div>
                                 </ng-container>
                              </ng-container>

                           </ng-container>

                        </div>
                     </div>
                  </ng-container>
                  <!-- <div
                     *ngIf="dutyform.submitted && !dutyform.form.valid  && !inputFields[field.id] && field.fieldType!='LABEL' && field.fieldType!='BUTTON'  && field.fieldType!='CHECKBOX' && field.fieldType!='DROPDOWN'"
                     class="requiredColor col-md-12"> {{lan==0?field.fieldNameEn:field.fieldNameHi}}
                     {{'duty-calculation.is_required' | translate}}
                  </div> -->
               </ng-container>
            </ng-container>


            <div class="col-md-12 mt-3 mb0">




               <div *ngIf="instrumentBean.propValReqFlag && displayPropDiv">

                  <div class="detail5 mb0">
                     <label *ngIf="propDivTitle!=''" class="m-0">{{propDivTitle}}
                     </label>
                     <ng-container class="form-group">
                        <div class="row">
                           <div class="col-md-auto">
                              <div *ngIf="addMorePropEnable">
                                 <div class="form-group1 row">
                                    <label class="col-md-12">{{'duty-calculation.PROP_VAL_FLOW' | translate}} <span
                                          class="asterisk">*</span></label>
                                    <div class="col-md-auto">
                                       <div class="form-check form-check-inline">
                                          <input type="radio" [(ngModel)]="propValAttribute.propValSelect"
                                             id="propValSelect1" name="propValSelect" (click)="onPropValSelect($event)"
                                             value="true">{{'duty-calculation.Yes' | translate}}
                                       </div>
                                       <div class="form-check form-check-inline">
                                          <input type="radio" [(ngModel)]="propValAttribute.propValSelect"
                                             id="propValSelect2" name="propValSelect" (click)="onPropValSelect($event)"
                                             value="false" [checked]="!propValSelect">{{'duty-calculation.No' |
                                          translate}}
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-md-4" *ngIf="!propValFlow">
                              <div class="form-group">
                                 <label class="">
                                    {{'duty-calculation.propValId' | translate}}
                                 </label>
                                 <div class="row">
                                    <div class="col-md-9">
                                       <input type="number" maxlength="12" [(ngModel)]="propValId" [OnlyNumber]="true"
                                          [appCopyPastValidation]="true" required appPositiveNumbersOnlyDirective=""
                                          name="propValId" placeholder="{{'duty-calculation.propValId' | translate}}"
                                          class="form-control form-control-sm">
                                       <div *ngIf="dutyform.submitted && propValId==null" class="requiredColor">
                                          {{'duty-calculation.PropIdRequired' | translate}}
                                       </div>
                                       <div *ngIf="dutyform.submitted && propValId!=null && propValIdValid==false"
                                          class="requiredColor">
                                          {{'duty-calculation.PropIdValidate' | translate}}
                                       </div>

                                    </div>
                                    <div class="col-md-auto col-md-auto p-0">
                                       <button class="btn btn-info btn-sm" type="button" (click)="validateId()"> {{
                                          'duty-calculation.Validate' | translate }}</button>

                                    </div>
                                 </div>

                              </div>

                           </div>
                           <!-- <div class="col-md-12" *ngIf="propValAttribute.propMarketValue>0|| propValAttribute.propMarketValue!=null">
                              <div class="form-group">

                                          <div class="row mb-2">


                                             <div class="col-md-3">
                                                <label class="d-block">{{'duty-calculation.propValId' | translate}}</label>
                                                <input type="text" maxlength="12" required
                                                   [(ngModel)]="propValAttribute.valTxnId" id="valTxnId"
                                                   name="valTxnId" [readonly]="true" appPositiveNumbersOnlyDirective
                                                   placeholder="{{'duty-calculation.propValId' | translate}}"
                                                   class="form-control form-control-sm">
                                             </div>
                                             <div class="col-md-3">
                                                <label class="d-block">{{'duty-calculation.propMarketValue' | translate}}</label>
                                                <input type="text" maxlength="12" required
                                                   [(ngModel)]="propValAttribute.propMarketValue" id="propMarketValue"
                                                   name="propMarketValue" [readonly]="true" appPositiveNumbersOnlyDirective
                                                   placeholder="{{'duty-calculation.propMarketValue' | translate}}"
                                                   class="form-control form-control-sm">
                                             </div>
                                             <div class="col-md-3">
                                                <label class="d-block">{{'duty-calculation.having_consenter' | translate}}</label>
                                                <div class="custom-control custom-radio custom-control-inline">
                                                   <input type="radio"  class="custom-control-input" id="customRadioY_{{propValAttribute.valTxnId}}" name="customRadio_{{propValAttribute.valTxnId}}"
                                                   value="true" (click)="consenterChange($event,propValAttribute.valTxnId)" [checked]="propValAttribute.propConstSelect">
                                                   <label class="custom-control-label" for="customRadioY_{{propValAttribute.valTxnId}}">{{'duty-calculation.Yes' | translate}}</label>
                                                </div>
                                                <div class="custom-control custom-radio custom-control-inline">
                                                   <input type="radio"  class="custom-control-input" id="customRadioN_{{propValAttribute.valTxnId}}" name="customRadio_{{propValAttribute.valTxnId}}" value="false" (click)="consenterChange($event,propValAttribute.valTxnId)" [checked]="propValAttribute.propConstSelect">
                                                   <label class="custom-control-label" for="customRadioN_{{propValAttribute.valTxnId}}">{{'duty-calculation.No' | translate}}</label>
                                                </div>

                                                </div>

                                                <div class="col-md-3">col-md-12 requiredColor
                                                   <label class="d-block">{{'duty-calculation.action' | translate }}</label>
                                                   <button class="btn btn-primary mr-3 btn-sm" type="button" id="button-deleteProp"
                                                   name="deleteProp" (click)="deleteLastOrOnlyProperty()"> {{ 'duty-calculation.delete_property' | translate }}</button>
                                                </div>
                                             </div>

                                             <div class="col-md-auto p-0">

                                                <button *ngIf="instrumentBean.addPropertyBttn" class="btn btn-primary mr-3 btn-sm"
                                                   type="button" id="button-addProp" name="addProp" (click)="addMoreProperty()"> {{
                                                   'duty-calculation.add_property' | translate }}</button>
                                                <button *ngIf="otherCalFlag!=''" class="btn btn-primary mr-3 btn-sm" type="button"
                                                   id="button-addProp" name="addProp" (click)="addMoreProperty()"> {{
                                                   'duty-calculation.add' | translate }}</button>

                                             </div>
                               </div>
                           </div> -->


                           <!-- <div class="col-md-12 requiredColor " *ngIf="dutyform.submitted &&  propValAttribute.propMarketValue==null && propValArray.length==0">

                           <div class="col-md-12 requiredColor" *ngIf="dutyform.submitted &&  propValAttribute.propMarketValue==null && propValArray.length==0"> -->

                           <div class="col-md-12 requiredColor mb-1"
                              *ngIf="dutyform.submitted &&  propValAttribute.propMarketValue==null  && propValAttribute.propValSelect==null">

                              {{'duty-calculation.PROP_VAL_FLOW' | translate}} {{'duty-calculation.is_required' |
                              translate}}
                           </div>
                        </div>

                     </ng-container>
                  </div>

               </div>
               <div class="col-md-auto p-0 mb-2"
                  *ngIf="propValAttribute.propMarketValue>0|| propValAttribute.propMarketValue!=null">
                  <button *ngIf="instrumentBean.addPropertyBttn" class="btn btn-primary mr-3 btn-sm" type="button"
                     id="button-addProp" name="addProp" (click)="addMoreProperty()"> {{
                     'duty-calculation.add_property' | translate }}</button>
                  <button *ngIf="otherCalFlag!=''" class="btn btn-primary mr-3 btn-sm" type="button" id="button-addProp"
                     name="addProp" (click)="addMoreProperty()"> {{
                     'duty-calculation.add' | translate }}</button>
               </div>
               <ng-container class="form-group p-3 mb0" *ngIf="instrumentBean.propValReqFlag">

                  <div class="mb0 mt-0">
                     <div>
                        <div *ngIf="propValArray.length>0 || propValAttribute.propMarketValue!=null">
                           <div class="detail5 mb-2">
                              <h3 class="sidebar__widget-title m-0"> {{'duty-calculation.prop_detls_title' | translate}}
                              </h3>
                              <!-- <div class="card-body p-1"> -->
                              <div class="row">
                                 <div class="col-md-12">
                                    <table class="table bg-light table-striped table-bordered mb-0 mt-1">
                                       <thead class="thead-dark">
                                          <tr>
                                             <th scope="col" style="width: 50px;">{{'duty-calculation.SNO' | translate}}
                                             </th>
                                             <th scope="col"> {{'duty-calculation.propValId' | translate}}</th>
                                             <th scope="col"> {{'duty-calculation.Property_Type' | translate}}</th>
                                             <th scope="col"
                                                *ngFor="let fieldName of propValAttributeColEn; let i = index"> {{lan==0
                                                ? propValAttributeColEn[i] : propValAttributeColHi[i]}}</th>

                                             <th scope="col">{{'duty-calculation.propMarketValue' | translate}}</th>
                                             <!-- <th scope="col"> {{'duty-calculation.having_consenter' | translate}} </th> -->
                                             <th scope="col">{{'duty-calculation.action' | translate }}</th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                          <tr *ngFor="let prop of propValArray; let i = index">
                                             <td>{{i+1}}</td>
                                             <td>{{prop.valTxnId}}</td>
                                             <td> {{lan==0?prop.propertyType.nameEn:prop.propertyType.nameHi}}</td>
                                             <td *ngFor="let fieldName of propValAttributeColEn; let j = index">
                                                {{prop.fieldvalue[j]|amount}}</td>
                                             <td>{{prop.propMarketValue|amount}}</td>
                                             <!-- <td style="white-space: nowrap;">
                                             <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" class="custom-control-input" id="customRadioPropY_{{prop.valTxnId}}" name="customRadioProp_{{prop.valTxnId}}"
                                                value="true"  (click)="consenterChangeFromList($event,prop,i)" [checked]="prop.propConstSelect">
                                                <label class="custom-control-label" for="customRadioPropY_{{prop.valTxnId}}">{{'duty-calculation.Yes' | translate}}</label>
                                             </div>
                                             <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" class="custom-control-input" id="customRadioPropN_{{prop.valTxnId}}"
                                                name="customRadioProp_{{prop.valTxnId}}" value="false" (click)="consenterChangeFromList($event,prop,i)" [checked]="prop.propConstSelect">
                                                <label class="custom-control-label" for="customRadioPropN_{{prop.valTxnId}}">{{'duty-calculation.No' | translate}}</label>
                                             </div>
                                          </td> -->
                                             <td>
                                                <em id="button-deleteProp" class="fa fa-trash btn btn-danger btn-sm"
                                                   title="{{'duty-calculation.delete' | translate}}"
                                                   (click)="deleteProperty(i,'')" placement="top"
                                                   ngbTooltip="{{'duty-calculation.delete' | translate}}"
                                                   container="body"></em>
                                             </td>
                                          </tr>

                                          <tr
                                             *ngIf="propValAttribute.propMarketValue>0|| propValAttribute.propMarketValue!=null">
                                             <td>{{propValAttribute.sno}}</td>
                                             <td>{{propValAttribute.valTxnId}}</td>
                                             <td>
                                                {{lan==0?propValAttribute.propertyType.nameEn:propValAttribute.propertyType.nameHi}}
                                             </td>

                                             <td *ngFor="let fieldName of propValAttributeColEn; let j = index">
                                                {{propValAttribute.fieldvalue[j]|amount}}</td>

                                             <td>{{propValAttribute.propMarketValue|amount}}</td>
                                             <!-- <td style="white-space: nowrap;">
                                                <div >
                                                      <div class="custom-control custom-radio custom-control-inline">
                                                         <input type="radio"  class="custom-control-input" id="customRadioY_{{propValAttribute.valTxnId}}" name="customRadio_{{propValAttribute.valTxnId}}"
                                                         value="true" (click)="consenterChange($event,propValAttribute.valTxnId)" [checked]="propValAttribute.propConstSelect">
                                                         <label class="custom-control-label" for="customRadioY_{{propValAttribute.valTxnId}}">{{'duty-calculation.Yes' | translate}}</label>
                                                      </div>
                                                      <div class="custom-control custom-radio custom-control-inline">
                                                         <input type="radio"  class="custom-control-input" id="customRadioN_{{propValAttribute.valTxnId}}" name="customRadio_{{propValAttribute.valTxnId}}" value="false" (click)="consenterChange($event,propValAttribute.valTxnId)" [checked]="propValAttribute.propConstSelect">
                                                         <label class="custom-control-label" for="customRadioN_{{propValAttribute.valTxnId}}">{{'duty-calculation.No' | translate}}</label>
                                                      </div>
                                                </div>
                                             </td> -->
                                             <td>
                                                <!-- <div >
                                                    <button class="btn btn-primary mr-3 btn-sm" type="button" id="button-deleteProp"
                                                       name="deleteProp" (click)="deleteLastOrOnlyProperty()"> {{ 'duty-calculation.delete_property' | translate }}</button>
                                                   </div> -->
                                                <em id="button-deleteProp" class="fa fa-trash btn btn-danger btn-sm"
                                                   title="{{'duty-calculation.delete' | translate}}" placement="top"
                                                   ngbTooltip="{{'duty-calculation.delete' | translate}}"
                                                   container="body" (click)="deleteLastOrOnlyProperty()"></em>
                                             </td>


                                          </tr>
                                       </tbody>
                                    </table>

                                 </div>
                              </div>
                           </div>


                           <div class="detail5 mb-2" style="border-top: 0;" *ngIf="isAgreementPartitionParty">
                              <h3 class="sidebar__widget-title m-0"> {{'eregInitiation.partyDetails' | translate}}
                              </h3>
                              <div class="row">
                                 <ng-container class="col-md-12" *ngFor="let prop of propValArrayPartitionDeed; let i = index">
                                    <fieldset class="legend-section">
                                       <legend class="sidebar__widget-title partyDetails_legend">
                                          {{'ereg-property-details.propertyValautionId' | translate}} : {{prop.propValAttribute.valTxnId}} | |
                                          {{'duty-calculation.Property_Type' | translate}}: {{lan==0?prop.propValAttribute.propertyType.nameEn:prop.propValAttribute.propertyType.nameHi}} | |
                                          {{'ereg-property-details.Total Area' | translate}}: {{prop.propValAttribute.totalArea }} {{prop.unit}} | |
                                          {{'ereg-property-details.Transact Area' | translate}} : {{prop?.propValAttribute.transactingArea}} {{prop.propValAttribute.unit}}
                                       </legend>

                                       <div class="table-responsive shadow-none">
                                          <table class="table table-bordered">
                                             <thead class="thead-dark">
                                                <tr >
                                                   <th scope="col" style="width: 50px;">{{'duty-calculation.SNO' |
                                                      translate}}
                                                   </th>
                                                   <th>{{'eregInitiation.partyDetails' | translate}}</th>
                                                   <th>
                                                      {{(lan==0)?"Transaction (Receiving )Share in the Land /
                                                      Property":"भूमि/संपत्ति में हिस्सेदारी का लेन-देन (प्राप्ति)"}}
                                                   </th>
                                                   <th scope="col">{{'duty-calculation.action' | translate }}</th>
                                                </tr>
                                             </thead>
                                            
                                                      <tbody>
                                                         <tr *ngFor="let party of prop.partyList; let p = index">
                                         
                                                         <td>{{p+1}}</td>
                                                         <td><input type="text" maxlength="10" readonly class="form-control" [(ngModel)]="party.name" name="partyname_{{i}}_{{p}}"
                                                            placeholder="{{(lan==0)?'Owner Name':'मालिक का नाम'}}">
                                                      </td>
                                                         <td>
                                                            <input type="number" maxlength="10" [readonly]="party.isPartySaved" class="form-control" [(ngModel)]="party.partyShare.shareAreaSold" name="partyshareAreaSold_{{i}}_{{p}}"
                                                               placeholder="{{(lan==0)?'Enter the party transacting (receiving) share':'शेयर का लेन-देन (प्राप्त) करने वाले पक्ष का नाम दर्ज करें'}}">
                                                         </td>
                                                         <td *ngIf="!party.isPartySaved">
                                                            <button type="button" class="btn btn-primary btn-sm" (click)="savePartyObj(p,party,prop,i)">
                                                               {{'duty-calculation.save' | translate}}
                                                            </button>
                                                         </td>
                                                         <td *ngIf="party.isPartySaved">
                                                            <button type="button" class="btn btn-primary btn-sm" (click)="editPartyObj(p,party,prop,i)">
                                                               {{'edit'| translate}}
                                                            </button>
                                                            <button *ngIf="p>1" type="button" class="btn btn-primary btn-sm" (click)="deletePartyObj(p,party,prop,i)">
                                                               {{'duty-calculation.delete' | translate}}
                                                            </button>
                                                         </td>
                                                         </tr>
                                                      </tbody>                                          
                                          </table>
                                       </div>
                                       <div class="add_party">
                                          <a class="link" href="javascript:void(0)" (click)="addMoreParty()"><em>
                                                {{(lan==0)? "Click here add more party (Owner)":"और पार्टी (स्वामी)
                                                जोड़ने
                                                के लिए यहां क्लिक करें"}}
                                             </em></a>
                                       </div>

                                    </fieldset>


                                 </ng-container>
                              </div>

                           </div>





                        </div>


                     </div>
                  </div>

               </ng-container>




            </div>
            <!-- marking it false as not required as per BA <div class="col-md-12 mb-3" *ngIf="instrumentBean.isMovableProp">-->
            <div class="col-md-12 mb-3" *ngIf="instrumentBean.isDutyMovableProp">
               <div class="detail5">
                  <!-- <h3 class="widget-title"> {{'duty-calculation.mv_prop_dtls' | translate}}</h3> -->
                  <div class="row">
                     <div class="col-md-12">
                        <div class="form-group">
                           <label class="mr-5">
                              {{'duty-calculation.have_mv_prop' | translate}} </label>


                           <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                                 value="!ischeckMoveble" (click)="checkBtnMoveble('self_tab')" />
                              <label class="form-check-label" for="inlineRadio1">{{'duty-calculation.Yes' |
                                 translate}}</label>
                           </div>
                           <div class="form-check form-check-inline">
                              <input class="form-check-input" value="!other" (click)="checkBtnMoveble('other_tab')"
                                 type="radio" name="inlineRadioOptions" id="inlineRadio2" [checked]="!ischeckMoveble" />
                              <label class="form-check-label" for="inlineRadio2"> {{'duty-calculation.No' |
                                 translate}}</label>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div id="self_tab" [hidden]="!ischeckMoveble">
                     <div class="row">
                        <div class="col-md-3">
                           <div class="form-group">
                              <label class="label h-40">{{'duty-calculation.detls_of_mv_prop' | translate}}</label>
                              <input type="text" class="form-control" [(ngModel)]="movPropValAttribute.movPropDetls"
                                 id="movPropDetls" name="movPropDetls" #movPropDetls="ngModel" />
                              <!-- <div  *ngIf="self && !movPropDetls || movPropDetls.invalid || movPropDetls.errors.required">
                                 {{'duty-calculation.err_msg_valid_mv_prop' | translate}}
                              </div> -->
                           </div>
                        </div>
                        <div class="col-md-4 ">
                           <div class="form-group">
                              <label class="label h-40">{{'duty-calculation.consideration_mv_prop' | translate}}
                              </label>
                              <input type="number" maxlength="10" [OnlyNumber]="true" [appCopyPastValidation]="true"
                                 onkeypress="return !(event.charCode == 46)" step="1" class="form-control"
                                 [(ngModel)]="movPropValAttribute.movPropConsdAmt" id="movPropConsdAmt"
                                 name="movPropConsdAmt" #movPropConsdAmt="ngModel" />
                              <!-- <div *ngIf="self && !movPropConsdAmt || movPropConsdAmt.errors.required">
                                 {{'duty-calculation.err_msg_consideration_mv_prop' | translate}}
                              </div> -->
                           </div>
                           <div>
                              {{movPropValAttribute.movPropConsdAmt | AmtToWords}}
                           </div>
                        </div>

                        <div class="col-md-3" *ngIf="removeUploadMovable">
                           <div class="form-group">
                              <label class="label h-40"> {{'duty-calculation.up_proof' | translate}}<span
                                    class="strick">*</span></label>
                              <input type="file" class="form-control p-0" aria-label="file example"
                                 [(ngModel)]="movPropValAttribute.fileUpload" (change)="handleFileInput($event)"
                                 id="fileUpload" name="fileUpload" #fileUpload="ngModel" />
                              <!-- <div  *ngIf="fileUpload.errors.required">
                                 {{'duty-calculation.err_msg_invalid_file_mv' | translate}}
                              </div> -->
                           </div>
                        </div>
                        <div class="col-md-2">
                           <div class="form-group">
                              <label class="d-block label h-40 m-0"> &nbsp; </label>
                              <button class="btn btn-primary mt-1" type="button" id="button-addMovPropValue"
                                 name="addMovPropValue" (click)="addMovPropValue()"> {{'duty-calculation.add' |
                                 translate}}</button>
                           </div>
                        </div>
                     </div>

                     <div class="row" *ngIf="self">
                        <div class="col-md-12 mt-3 mb-3">
                           <div class="table-responsive" style="box-shadow: none;">

                              <table class="table table-bordered">
                                 <thead class="thead-dark">
                                    <tr>
                                       <th>{{'duty-calculation.SNO' | translate}}</th>
                                       <th>{{'duty-calculation.detls_of_mv_prop' | translate}}</th>
                                       <th>{{'duty-calculation.consideration_mv_prop' | translate}}</th>
                                       <th *ngIf="removeUploadMovable">File Uploaded</th>
                                       <th>{{'duty-calculation.action' | translate}}</th>

                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr *ngFor="let field of movPropValfieldArray; let i = index">
                                       <td>{{i+1}}</td>
                                       <td>
                                          <input readonly [(ngModel)]="field.movPropDetls" class="form-control"
                                             type="text" name="{{field.movPropDetls}}" />
                                       </td>
                                       <td>
                                          <input readonly [(ngModel)]="field.movPropConsdAmt" class="form-control"
                                             type="text" name="{{field.movPropConsdAmt}}" />
                                          <div>
                                             {{field.movPropConsdAmt | AmtToWords}}
                                          </div>
                                       </td>
                                       <td *ngIf="removeUploadMovable">
                                          <input [(ngModel)]="field.fileUpload" class="form-control" type="text"
                                             name="{{field.fileUpload}}" />
                                       </td>
                                       <td style="white-space: nowrap;">
                                          <!-- <button mat-icon-button color="primary"
                                             aria-label="Example icon button with a home icon"
                                             class="p-0 w-auto h-auto">
                                             <mat-icon>edit</mat-icon>
                                          </button> -->
                                          <button mat-icon-button color="warn"
                                             aria-label="Example icon button with a heart icon"
                                             class="p-0 w-auto h-auto">
                                             <mat-icon (click)="deleteMovPropValue(i)">delete</mat-icon>
                                          </button>
                                       </td>
                                    </tr>

                                 </tbody>
                              </table>



                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div class="col-md-12 mt-2">
               <ng-container>

                  <div class="row">
                     <label class="col-md-12"> <em style="font-weight: 500;">{{'duty-calculation.AVAIL_EXEMPTION' |
                           translate}}</em></label>


                     <div class="col-md-auto pl-1 mb-2">
                        <div class="form-check form-check-inline ml-25">
                           <input type="radio" name="radioExemption" (click)="onAvailExemption($event)"
                              value="true">{{'duty-calculation.Yes' |
                           translate}}

                        </div>
                        <div class="form-check form-check-inline">
                           <input type="radio" name="radioExemption" (click)="onAvailExemption($event)" value="false"
                              [checked]="!showExemptions">{{'duty-calculation.No' |
                           translate}}

                        </div>
                     </div>
                  </div>

               </ng-container>
            </div>


            <div class="col-md-12">
               <div *ngIf="showExemptions && exemptionsNotAvailable">
                  <label>{{'duty-calculation.No_Exemp_Available' | translate}}</label>
               </div>

               <div class="showExemptions" *ngIf="showExemptions && !exemptionsNotAvailable">

                  <table class="table table-bordered">
                     <thead class="thead-dark">
                        <tr>
                           <th colspan="2">
                              <label class="d-block m-0" style="font-weight: 600;"><em>{{
                                    'duty-calculation.STAMP_DUTY_EXEMPTIONS' |
                                    translate }}</em></label>
                           </th>
                        </tr>
                     </thead>

                     <tbody>

                        <ng-container *ngFor="let exemList of instExempMappingList; let s = index">
                           <ng-container *ngIf="exemList.exemptionMaster.applicableOn ==='S'">
                              <label class="form-check-label pl-4 pb-2 table-tr"
                                 for="selectedExemptions{{exemList.id}}">
                                 <td>
                                    <input class="form-check-input  " type="checkbox" [(ngModel)]="exemList.checked"
                                       [disabled]="exemList.disabled" name="{{exemList.id}}"
                                       (click)="addExemptionEvent($event,exemList)" value="{{exemList}}"
                                       id="selectedExemptions{{exemList.id}}">
                                 </td>
                                 <td>
                                    <div
                                       [title]="exemList.exemptionMaster!==undefined? lan==0?exemList.exemptionMaster.exemptionDescEn:exemList.exemptionMaster.exemptionDescHi:''">
                                       {{lan==0?exemList.exemptionMaster.exemptionNameEn:exemList.exemptionMaster.exemptionNameHi}}
                                    </div>
                                 </td>
                              </label>
                           </ng-container>
                        </ng-container>

                     </tbody>

                     <thead class="thead-dark">
                        <tr>
                           <th colspan="2">

                              <label class="d-block m-0" style="font-weight:600;"><em>{{
                                    'duty-calculation.REG_FEE_EXEMPTIONS'
                                    | translate }}</em></label>

                           </th>
                        </tr>
                     </thead>

                     <tbody>
                        <ng-container *ngFor="let exemList of instExempMappingList; let r = index">
                           <ng-container *ngIf="exemList.exemptionMaster.applicableOn ==='R'">
                              <label class="form-check-label pl-4 pb-2 table-tr"
                                 for="selectedExemptions{{exemList.id}}">
                                 <td>
                                    <input class="form-check-input " type="checkbox" [(ngModel)]="exemList.checked"
                                       [disabled]="exemList.disabled" name="{{exemList.id}}"
                                       (click)="addExemptionEvent($event,exemList)" value="{{exemList}}"
                                       id="selectedExemptions{{exemList.id}}">
                                 </td>
                                 <td>
                                    <div
                                       [title]="exemList.exemptionMaster!==undefined? lan==0?exemList.exemptionMaster.exemptionDescEn:exemList.exemptionMaster.exemptionDescHi:''">
                                       {{lan==0?exemList.exemptionMaster.exemptionNameEn:exemList.exemptionMaster.exemptionNameHi}}
                                    </div>
                                 </td>
                              </label>
                           </ng-container>
                        </ng-container>
                     </tbody>
                  </table>

               </div>

            </div>

            <div class="col-lg-6 form-group  p-3 mb-0"></div>


            <div class="col-md-12 penal-body-no-padding">
               <div class="btn-cal">
                  <button mat-flat-button class="btn btn-primary mr-3" type="submit" id="button-addon2"
                     [disabled]="submitDisable"> {{
                     'duty-calculation.Calculate_Duty' | translate }}</button>

                  <button mat-flat-button class="btn btn-secondary" type="button" (click)="resetDutyFields()"> {{
                     'duty-calculation.Reset' | translate }}</button>
               </div>


               <div class="card-footer p-0 row mt-3" *ngIf="displayCalDutyFieldsFlag">
                  <div class="col-md-12">
                     <div class="table-responsive mb-3">
                        <table class="table table-bordered mb-0">
                           <thead>
                              <tr>
                                 <th class="bg-light">{{'duty-calculation.SNO' | translate}}</th>
                                 <th class="bg-light"
                                    *ngIf="instrumentBean.propValReqFlag && instrumentBean.addPropertyApplicable && instrumentBean.id!=68">
                                    {{'duty-calculation.propValId' | translate}}</th>
                                 <th class="bg-light"
                                    *ngIf="instrumentBean.propValReqFlag && instrumentBean.addPropertyApplicable && instrumentBean.id!=68">
                                    {{'duty-calculation.propMarketValue' | translate}}</th>
                                 <th class="bg-light" *ngFor="let fieldName of propValAttributeColEn; let i = index">
                                    {{lan==0? propValAttributeColEn[i] : propValAttributeColHi[i]}}</th>
                                 <th class="bg-light">{{ 'duty-calculation.Govt_Stamp_Duty' | translate }}</th>
                                 <th class="bg-light">{{ 'duty-calculation.Municipal_Duty' | translate }}</th>
                                 <th class="bg-light">{{ 'duty-calculation.Janpad_Duty' | translate }}</th>
                                 <th class="bg-light">{{ 'duty-calculation.Upkar_Duty' | translate }}</th>
                                 <th class="bg-light">{{ 'duty-calculation.regFee' | translate }}</th>
                           </thead>
                           <tbody>
                              <tr
                                 *ngFor="let propWiseList of dutyCalculationResponseBean.igrsAllDutyDtoList; index as i">
                                 <td>{{i+1}}</td>
                                 <td class="p-2"
                                    *ngIf="instrumentBean.propValReqFlag && instrumentBean.addPropertyApplicable && instrumentBean.id!=68">
                                    {{propWiseList.dutyPropInputFields.propValId }}</td>
                                 <td class="p-2"
                                    *ngIf="instrumentBean.propValReqFlag && instrumentBean.addPropertyApplicable && instrumentBean.id!=68">
                                    {{propWiseList.dutyPropInputFields.marketValue| amount}}</td>
                                 <ng-container *ngFor="let prop of propValArray; let p = index">
                                    <ng-container *ngIf="propWiseList.dutyPropInputFields.propValId==prop.valTxnId">
                                       <td *ngFor="let fieldName of propValAttributeColEn; let j = index">
                                          {{prop.fieldvalue[j]| amount}}
                                       </td>
                                    </ng-container>
                                 </ng-container>
                                 <td class="p-2">{{propWiseList.calculatedPSD | amount}}</td>
                                 <td class="p-2">{{propWiseList.calculatedMunicipalDuty | amount}}</td>
                                 <td class="p-2">{{propWiseList.calculatedJanpadDuty | amount}}</td>
                                 <td class="p-2">{{propWiseList.calculatedUpkarDuty | amount}}</td>
                                 <td class="p-2">{{propWiseList.calculatedRegFee | amount}}</td>
                              </tr>
                           </tbody>
                        </table>

                        <div
                           *ngIf="dutyCalculationResponseBean.igrsDutyConsenterTxnDtoListInsert!=null &&  dutyCalculationResponseBean.igrsDutyConsenterTxnDtoListInsert!=undefined">
                           <table class="table table-bordered mb-0"
                              *ngIf="dutyCalculationResponseBean.igrsDutyConsenterTxnDtoListInsert.length>0">
                              <thead>
                                 <tr>
                                    <th class="bg-light">{{'duty-calculation.SNO' | translate}}</th>
                                    <th class="bg-light">{{'duty-calculation.propValId' | translate}}</th>
                                    <th class="bg-light">{{'duty-calculation.propId' | translate}}</th>
                                    <th class="bg-light">{{'duty-calculation.totalConsenter' | translate}}</th>
                                    <th class="bg-light">{{'duty-calculation.withConsideration' | translate}}</th>
                                    <th class="bg-light">{{ 'duty-calculation.consdAmt' | translate }}</th>
                                    <th class="bg-light">{{ 'duty-calculation.Const_Stamp_Duty' | translate }}</th>
                                    <th class="bg-light">{{ 'duty-calculation.Const_RegFee' | translate }}</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 <tr
                                    *ngFor="let constTxnList of dutyCalculationResponseBean.igrsDutyConsenterTxnDtoListInsert; index as i">
                                    <td>{{i+1}}</td>
                                    <td class="p-2">{{constTxnList.propValId }}</td>
                                    <td class="p-2">{{constTxnList.propertyId }}</td>
                                    <td class="p-2">{{constTxnList.noOfConsenter }}</td>
                                    <!-- <td class="p-2" >{{constTxnList.withConsideration }}</td> -->
                                    {{constTxnList.withConsideration == true?('duty-calculation.Yes' |
                                    translate):('duty-calculation.No' | translate) }}
                                    <td class="p-2">{{constTxnList.consenterAmount| amount}}</td>
                                    <td class="p-2">{{constTxnList.consenterPSD| amount}}</td>
                                    <td class="p-2">{{constTxnList.consenterRegFee| amount}}</td>

                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </div>

                  </div>


                  <div class="col-md-6">
                     <table class="w-100 table shadow1">
                        <tbody>
                           <tr>
                              <td class="p-0" colspan="2">
                                 <h3 class="bg-theme text-light p-2 mb-1 font-weight-normal">{{
                                    'duty-calculation.Stamp_Duty' | translate }} </h3>
                              </td>
                           </tr>
                           <tr>
                              <td class="p-2">{{ 'duty-calculation.Govt_Stamp_Duty' | translate }}</td>
                              <td class="p-2">{{dutyCalculationResponseBean.calculatedPSD | amount}}</td>
                           </tr>
                           <tr>
                              <td class="p-2">{{ 'duty-calculation.Municipal_Duty' | translate }} </td>
                              <td class="p-2">{{dutyCalculationResponseBean.calculatedMunicipalDuty |
                                 amount}}</td>
                           </tr>
                           <tr>
                              <td class="p-2">{{ 'duty-calculation.Janpad_Duty' | translate }} </td>
                              <td class="p-2">{{dutyCalculationResponseBean.calculatedJanpadDuty | amount}}
                              </td>
                           </tr>
                           <tr>
                              <td class="p-2">{{ 'duty-calculation.Upkar_Duty' | translate }} </td>
                              <td class="p-2">{{dutyCalculationResponseBean.calculatedUpkarDuty | amount}}
                              </td>
                           </tr>
                           <tr *ngIf="instrumentBean.isDutyMovableProp">
                              <td class="p-2">{{ 'duty-calculation.mvPSD' | translate }} </td>
                              <td class="p-2">{{dutyCalculationResponseBean.calculatedMvPSD | amount}}</td>
                           </tr>

                           <tr>
                              <td class="font-weight-bold">{{ 'duty-calculation.Total_Stamp_Duty' | translate }}</td>
                              <td class="font-weight-bold">{{dutyCalculationResponseBean.totalStampDuty |
                                 amount}}</td>
                           </tr>
                           <tr *ngIf="dutyCalculationResponseBean?.totalStampDutyPaid!=null">
                              <td class="font-weight-bold">
                                 {{'duty-calculation.alPaidTStampDuty' | translate }}
                              </td>
                              <td class="font-weight-bold">{{dutyCalculationResponseBean.totalStampDutyPaid |
                                 amount}}</td>
                           </tr>
                           <tr>
                              <td class="font-weight-bold">{{'stampDutyAndRegistartionFee.exemptedAmount' | translate}}

                                 <div *ngIf="selectedExemptions?.length>0">
                                    {{'ereg-property-details.AvailedStampDutyExemptionDetails' | translate}}
                                    <ul *ngFor="let exem of selectedExemptions;let e = index;">
                                       <li *ngIf="exem.exemptionMaster?.applicableOn=='S'">
                                          {{lan==0?exem.exemptionMaster?.exemptionNameEn:exem.exemptionMaster?.exemptionNameHi}}
                                       </li>
                                    </ul>
                                 </div>
                              </td>
                              <td class="font-weight-bold">{{dutyCalculationResponseBean.stampDutyExemption | amount}}
                              </td>
                           </tr>
                           <tr>
                              <td class="font-weight-bold">{{ 'duty-calculation.Total_Payable_Stamp_Duty' | translate }}
                              </td>
                              <td class="font-weight-bold">{{dutyCalculationResponseBean.totalPayableStampDuty |
                                 amount}}</td>
                           </tr>
                           <tr class="">
                              <td class="p-0" colspan="2">
                                 <h3 class="bg-theme text-light p-2 mb-1 font-weight-normal">{{
                                    'duty-calculation.regFee' | translate }}</h3>
                              </td>
                           </tr>
                           <tr *ngIf="instrumentBean.isDutyMovableProp">
                              <td>{{ 'duty-calculation.mvRegFee' | translate }}</td>
                              <td>{{dutyCalculationResponseBean.calculatedMvRegFee | amount}}</td>
                           </tr>
                           <tr>
                              <td>{{ 'duty-calculation.regFee' | translate }}</td>
                              <td>{{dutyCalculationResponseBean.totalRegFee | amount}}</td>
                           </tr>
                           <tr *ngIf="dutyCalculationResponseBean?.totalRegFeePaid!=null">
                              <td class="font-weight-bold">
                                {{'duty-calculation.alPaidTRegFee' | translate }}
                              </td>
                              <td class="font-weight-bold">{{dutyCalculationResponseBean.totalRegFeePaid |
                                 amount}}</td>
                           </tr>
                           <tr>
                              <td class="font-weight-bold"> {{'stampDutyAndRegistartionFee.exemptedAmount' | translate}}
                                 <div *ngIf="selectedExemptions?.length>0">
                                    {{'ereg-property-details.AvailedRegistrationFee' | translate}}
                                    <ul *ngFor="let exem of selectedExemptions;let e = index;">
                                       <li *ngIf="exem.exemptionMaster?.applicableOn=='R'">
                                          {{lan==0?exem.exemptionMaster?.exemptionNameEn:exem.exemptionMaster?.exemptionNameHi}}
                                       </li>
                                    </ul>
                                 </div>
                              </td>
                              <td class="font-weight-bold">{{dutyCalculationResponseBean.regFeeExemption | amount}}</td>
                           </tr>
                           <tr>
                              <td class="font-weight-bold">{{ 'duty-calculation.Total_Payable_RegFee' | translate }}
                              </td>
                              <td class="font-weight-bold">{{dutyCalculationResponseBean.totalPayableRegFee |
                                 amount}}</td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
                  <div class="col-md-6 pt-3">
                     <div style="display: block;">
                        <canvas baseChart height="135" [data]="pieChartData" [labels]="pieChartLabels"
                           [chartType]="pieChartType" [options]="pieChartOptions" [plugins]="pieChartPlugins"
                           [legend]="pieChartLegend">
                        </canvas>
                     </div>
                     <div style="display: block;" class="mt-3">
                        <canvas baseChart height="135" [data]="pieChartData1" [labels]="pieChartLabels1"
                           [chartType]="pieChartType1" [options]="pieChartOptions1" [plugins]="pieChartPlugins1"
                           [legend]="pieChartLegend1">
                        </canvas>
                     </div>
                  </div>

                  <div class="col-md-12 text-right">
                     <button (click)="onBtnPrintClick()"
                        class="btn btn-primary float-right mt-2 d-print-none">Print</button>
                  </div>

               </div>

            </div>


            <!--
                <mat-accordion class="col-md-12 penal-body-no-padding">
                  <mat-expansion-panel class="shadow-none p-0">
                     <mat-expansion-panel-header class="p-0">

                     </mat-expansion-panel-header>
                   <div class="card-footer p-0 row">
                        <div class="col-md-12">
                     <div class="table-responsive mb-3">
                        <table class="table table-bordered mb-0">
                           <thead>
                              <tr>
                                 <th class="bg-light">{{'duty-calculation.SNO' | translate}}</th>
                                 <th class="bg-light" *ngIf="instrumentBean.propValReqFlag">{{'duty-calculation.propId' | translate}}</th>
                                 <th class="bg-light" *ngIf="instrumentBean.propValReqFlag">{{'duty-calculation.propMarketValue' | translate}}</th>
                                 <th class="bg-light">{{ 'duty-calculation.Govt_Stamp_Duty' | translate }}</th>
                                 <th class="bg-light">{{ 'duty-calculation.Municipal_Duty' | translate }}</th>
                                 <th class="bg-light">{{ 'duty-calculation.Janpad_Duty' | translate }}</th>
                                 <th class="bg-light">{{ 'duty-calculation.Upkar_Duty' | translate }}</th>
                                 <th class="bg-light">{{ 'duty-calculation.regFee' | translate }}</th>
                           </thead>
                           <tbody>
                              <tr
                                 *ngFor="let propWiseList of dutyCalculationResponseBean.igrsAllDutyDtoList; index as i">
                                 <td>{{i+1}}</td>
                                 <td class="p-2" *ngIf="instrumentBean.propValReqFlag">{{propWiseList.dutyPropInputFields.propId }}</td>
                                 <td class="p-2" *ngIf="instrumentBean.propValReqFlag">{{propWiseList.dutyPropInputFields.marketValue| amount}}</td>
                                 <td class="p-2">{{propWiseList.calculatedPSD | amount}}</td>
                                 <td class="p-2">{{propWiseList.calculatedMunicipalDuty | amount}}</td>
                                 <td class="p-2">{{propWiseList.calculatedJanpadDuty | amount}}</td>
                                 <td class="p-2">{{propWiseList.calculatedUpkarDuty | amount}}</td>
                                 <td class="p-2">{{propWiseList.calculatedRegFee | amount}}</td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                     </div>


               </mat-accordion>  -->
         </div>
      </div>
   </form>
   <div *ngIf="instrumentBean!==undefined && instrumentBean!==null">
      <div *ngIf="instrumentBean.id==37? openDutyCalDialog():false">
         <!-- <app-duty-calculation></app-duty-calculation> -->
      </div>
   </div>

</fieldset>




<div class="print-page-section" id="printID" style="position: relative;">

   <table class="headsection" style="width: 100%;">
      <tr>
         <th style="text-align: center;">
            <img src="assets/images/sampada-logo.png">
         </th>
      </tr>
   </table>
   <table style="width: 100%;" style="border: 1px solid;border-collapse: collapse;">
      <tr>
         <th colspan="2" style="font-size: 18px;border: 1px solid; padding: 8px; background: #ddd; text-align: center;">
            {{ 'duty-calculation.duty_cal_Hd' | translate }}
         </th>
      </tr>
      <tr>
         <th colspan="2" style="font-size: 16px;border: 1px solid; padding: 8px; background: #ddd; text-align: center;">

            {{ 'duty-calculation.Inst_desc_duty_detls' | translate }}
         </th>
      </tr>
      <tr>
         <td style="border: 1px solid;padding: 5px;"><strong>{{'duty-calculation.Deed-Catagory' | translate }}</strong>
         </td>
         <td style="border: 1px solid;padding: 5px;">
            {{lan==0?instrumentBean.deedTypeId.deedCategoryId.deedCategoryNameEn:instrumentBean.deedTypeId.deedCategoryId.deedCategoryNameHi}}
         </td>
      </tr>
      <tr>
         <td style="border: 1px solid;padding: 5px;"><strong>{{'duty-calculation.Deed-Type' | translate }}</strong></td>
         <td style="border: 1px solid;padding: 5px;">
            {{lan==0?instrumentBean.deedTypeId.deedTypeNameEn:instrumentBean.deedTypeId.deedTypeNameHi}}</td>
      </tr>
      <tr>
         <td style="border: 1px solid;padding: 5px;"><strong>{{'duty-calculation.inst_name' | translate }}</strong></td>
         <td style="border: 1px solid;padding: 5px;">
            {{lan==0?instrumentBean.instrumentNameEn:instrumentBean.instrumentNameHi}}</td>
      </tr>


      <tr>
         <td colspan="2" style="border: 1px solid;padding: 5px;">
            <table class="table table-bordered mb-0" style="width: 100%;border: 1px solid;border-collapse: collapse;">
               <thead>
                  <tr>
                     <th style="border: 1px solid;padding: 5px;" class="bg-light">{{'duty-calculation.SNO' | translate}}
                     </th>
                     <th style="border: 1px solid;padding: 5px;" class="bg-light" *ngIf="instrumentBean.propValReqFlag">
                        {{'duty-calculation.propValId' | translate}}</th>
                     <th style="border: 1px solid;padding: 5px;" class="bg-light" *ngIf="instrumentBean.propValReqFlag">
                        {{'duty-calculation.propMarketValue' | translate}}</th>

                     <th style="border: 1px solid;padding: 5px;" class="bg-light"
                        *ngFor="let fieldName of propValAttributeColEn; let i = index">
                        {{lan==0? propValAttributeColEn[i] : propValAttributeColHi[i]}}</th>
                     <th style="border: 1px solid;padding: 5px;" class="bg-light">{{ 'duty-calculation.Govt_Stamp_Duty'
                        | translate }}</th>
                     <th style="border: 1px solid;padding: 5px;" class="bg-light">{{ 'duty-calculation.Municipal_Duty' |
                        translate }}</th>
                     <th style="border: 1px solid;padding: 5px;" class="bg-light">{{ 'duty-calculation.Janpad_Duty' |
                        translate }}</th>
                     <th style="border: 1px solid;padding: 5px;" class="bg-light">{{ 'duty-calculation.Upkar_Duty' |
                        translate }}</th>
                     <th style="border: 1px solid;padding: 5px;" class="bg-light">{{ 'duty-calculation.regFee' |
                        translate }}</th>
                  </tr>
               </thead>
               <tbody>
                  <tr *ngFor="let propWiseList of dutyCalculationResponseBean.igrsAllDutyDtoList; index as i">
                     <td style="border: 1px solid;padding: 5px;">{{i+1}}</td>
                     <td style="border: 1px solid;padding: 5px;" class="p-2" *ngIf="instrumentBean.propValReqFlag">
                        {{propWiseList.dutyPropInputFields.propValId }}</td>
                     <td style="border: 1px solid;padding: 5px;" class="p-2" *ngIf="instrumentBean.propValReqFlag">
                        {{propWiseList.dutyPropInputFields.marketValue| amount}}</td>
                     <div *ngFor="let prop of propValArray; let p = index">
                        <div *ngIf="propWiseList.dutyPropInputFields.propValId==prop.valTxnId">
                           <td *ngFor="let fieldName of propValAttributeColEn; let j = index">
                              {{prop.fieldvalue[j]|amount}}
                           </td>
                        </div>
                     </div>
                     <td style="border: 1px solid;padding: 5px;" class="p-2">{{propWiseList.calculatedPSD |
                        amount}}</td>
                     <td style="border: 1px solid;padding: 5px;" class="p-2">{{propWiseList.calculatedMunicipalDuty |
                        amount}}</td>
                     <td style="border: 1px solid;padding: 5px;" class="p-2">{{propWiseList.calculatedJanpadDuty |
                        amount}}</td>
                     <td style="border: 1px solid;padding: 5px;" class="p-2">{{propWiseList.calculatedUpkarDuty |
                        amount}}</td>
                     <td style="border: 1px solid;padding: 5px;" class="p-2">{{propWiseList.calculatedRegFee |
                        amount}}</td>
                  </tr>
               </tbody>
            </table>

         </td>
      </tr>
   </table>

   <table style="width: 100%;border: 1px solid;border-collapse: collapse;">
      <tbody>
         <tr>
            <td colspan="2"
               style="font-size: 16px;border: 1px solid; padding: 8px; background: #ddd; text-align: center;">
               <strong> {{ 'duty-calculation.Stamp_Duty' | translate }}</strong>
            </td>
         </tr>
         <tr>
            <td class="p-2" style="border: 1px solid;padding: 5px;">{{ 'duty-calculation.Govt_Stamp_Duty' | translate }}
            </td>
            <td class="p-2" style="border: 1px solid;padding: 5px;">{{dutyCalculationResponseBean.calculatedPSD |
               amount}}</td>
         </tr>
         <tr>
            <td class="p-2" style="border: 1px solid;padding: 5px;">{{ 'duty-calculation.Municipal_Duty' | translate }}
            </td>
            <td class="p-2" style="border: 1px solid;padding: 5px;">
               {{dutyCalculationResponseBean.calculatedMunicipalDuty | amount}}</td>
         </tr>
         <tr>
            <td class="p-2" style="border: 1px solid;padding: 5px;">{{ 'duty-calculation.Janpad_Duty' | translate }}
            </td>
            <td class="p-2" style="border: 1px solid;padding: 5px;">{{dutyCalculationResponseBean.calculatedJanpadDuty |
               amount}}</td>
         </tr>
         <tr>
            <td class="p-2" style="border: 1px solid;padding: 5px;">{{ 'duty-calculation.Upkar_Duty' | translate }}
            </td>
            <td class="p-2" style="border: 1px solid;padding: 5px;">{{dutyCalculationResponseBean.calculatedUpkarDuty |
               amount}}</td>
         </tr>
         <tr *ngIf="instrumentBean.isDutyMovableProp">
            <td class="p-2" style="border: 1px solid;padding: 5px;">{{ 'duty-calculation.mvPSD' | translate }} </td>
            <td class="p-2" style="border: 1px solid;padding: 5px;">{{dutyCalculationResponseBean.calculatedMvPSD |
               amount}}</td>
         </tr>

         <tr>
            <td class="font-weight-bold" style="border: 1px solid;padding: 5px;">{{ 'duty-calculation.Total_Stamp_Duty'
               | translate }}</td>
            <td class="font-weight-bold" style="border: 1px solid;padding: 5px;">
               {{dutyCalculationResponseBean.totalStampDuty | amount}}</td>
         </tr>
         <tr *ngIf="dutyCalculationResponseBean?.totalStampDutyPaid!=null">
            <td class="font-weight-bold" style="border: 1px solid;padding: 5px;">Total Stamp Duty Paid</td>
            <td class="font-weight-bold" style="border: 1px solid;padding: 5px;">{{dutyCalculationResponseBean.totalStampDutyPaid |
               amount}}</td>
         </tr>
         <tr>
            <td class="font-weight-bold" style="border: 1px solid;padding: 5px;">
               {{'stampDutyAndRegistartionFee.exemptedAmount' | translate}}

               <div *ngIf="selectedExemptions?.length>0">
                  {{'ereg-property-details.AvailedStampDutyExemptionDetails' | translate}}
                  <ul *ngFor="let exem of selectedExemptions;let e = index;">
                     <li *ngIf="exem.exemptionMaster?.applicableOn=='S'">
                        {{lan==0?exem.exemptionMaster?.exemptionNameEn:exem.exemptionMaster?.exemptionNameHi}}</li>
                  </ul>
               </div>
            </td>
            <td class="font-weight-bold" style="border: 1px solid;padding: 5px;">
               {{dutyCalculationResponseBean.stampDutyExemption | amount}}</td>
         </tr>
         <tr>
            <td class="font-weight-bold" style="border: 1px solid;padding: 5px;">{{
               'duty-calculation.Total_Payable_Stamp_Duty' | translate }}</td>
            <td class="font-weight-bold" style="border: 1px solid;padding: 5px;">
               {{dutyCalculationResponseBean.totalPayableStampDuty | amount}}</td>
         </tr>
         <tr class="">
            <td colspan="2"
               style="font-size: 16px;border: 1px solid; padding: 8px; background: #ddd; text-align: center;">
               <strong>{{ 'duty-calculation.regFee' | translate }}</strong>
            </td>
         </tr>
         <tr *ngIf="instrumentBean.isDutyMovableProp">
            <td style="border: 1px solid;padding: 5px;">{{ 'duty-calculation.mvRegFee' | translate }}</td>
            <td style="border: 1px solid;padding: 5px;">{{dutyCalculationResponseBean.calculatedMvRegFee |
               amount}}</td>
         </tr>
         <tr>
            <td style="border: 1px solid;padding: 5px;">{{ 'duty-calculation.regFee' | translate }}</td>
            <td style="border: 1px solid;padding: 5px;">{{dutyCalculationResponseBean.totalRegFee | amount}}
            </td>
         </tr>
         <tr *ngIf="dutyCalculationResponseBean?.totalRegFeePaid!=null">
            <td class="font-weight-bold" style="border: 1px solid;padding: 5px;">Total RegFee Paid</td>
            <td class="font-weight-bold" style="border: 1px solid;padding: 5px;">{{dutyCalculationResponseBean.totalRegFeePaid |
               amount}}</td>
         </tr>
         <tr>
            <td class="font-weight-bold" style="border: 1px solid;padding: 5px;">
               {{'stampDutyAndRegistartionFee.exemptedAmount' | translate}}
               <div *ngIf="selectedExemptions?.length>0">
                  {{'ereg-property-details.AvailedRegistrationFee' | translate}}
                  <ul *ngFor="let exem of selectedExemptions;let e = index;">
                     <li *ngIf="exem.exemptionMaster?.applicableOn=='R'">
                        {{lan==0?exem.exemptionMaster?.exemptionNameEn:exem.exemptionMaster?.exemptionNameHi}}</li>
                  </ul>
               </div>
            </td>
            <td class="font-weight-bold" style="border: 1px solid;padding: 5px;">
               {{dutyCalculationResponseBean.regFeeExemption | amount}}</td>
         </tr>
         <tr>
            <td class="font-weight-bold" style="border: 1px solid;padding: 5px;">{{
               'duty-calculation.Total_Payable_RegFee' | translate }} </td>
            <td class="font-weight-bold" style="border: 1px solid;padding: 5px;">
               {{dutyCalculationResponseBean.totalPayableRegFee | amount}}</td>
         </tr>
         <tr *ngIf="dutyCalculationRequest?.instrumentId==59">
            <ng-container *ngFor="let field of instMappingBeanList | orderBy: 'orderId'; index as i">
               <ng-container *ngIf="field.id==530">
                  <td class="font-weight-bold" style="border: 1px solid;padding: 5px;">
                     {{lan==0?field.fieldNameEn:field.fieldNameHi}}
                  </td>
                  <td class="font-weight-bold" style="border: 1px solid;padding: 5px;">{{inputFields[field.id]}}</td>
               </ng-container>
            </ng-container>
         </tr>
         <tr>
            <td>
               <p>{{ 'duty-calculation.Disclamer' | translate }} : </p>
            </td>
         </tr>
      </tbody>
   </table>

   <div class="d-none d-print d-print-block rotingtxt" style="
   position: fixed;
top: 0;
bottom: 0;
left: 0;
right: 0;
height: auto;
text-align: center;
justify-content: center;
align-items: center;
flex-direction: row;
flex-wrap: wrap;
opacity: 0.1;
width: 100%;
height: 100%;
overflow: hidden;
">
      <div style="
 transform: rotate(-33deg);
 position: absolute;
 top: 0;
 bottom: 0;
 left: 0;
 right: 0;
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;height: 100%;
">
         <table width="100%" border="0" cellspacing="0" cellpadding="0" class="mt-3" style="border: none;">
            <tr>
               <td style="border: none;text-align: center;">
                  <img src="assets/images/sampada-logo.png" alt="Madhya Pradesh" height="80" />
               </td>
            </tr>
         </table>
      </div>
   </div>
</div>

<ngx-ui-loader></ngx-ui-loader>