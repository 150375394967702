import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertmessageComponent } from 'projects/common-lib/src/lib/component/alertmessage/alertmessage.component';
import { CitizenAuthenticationService } from 'projects/common-lib/src/lib/helper-lib/services/auth.service/citizen-authentication.service';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { Captcha, Citizen } from 'projects/common-lib/src/lib/models';
import { CitizenUserProfileDetails } from 'projects/common-lib/src/lib/models/citizen.login.model/citizen.profile.model';
// import { AadharEkycOTPAuthResponse} from 'projects/common-lib/src/lib/models/aadharEkyc.model/aadharEkyc.model';
import { Subscription, timer } from 'rxjs';
import Swal from 'sweetalert2';
import { DefaultServiceService } from '../../default/default-service.service';
import { IgrsEregPartyDetailsBean } from 'projects/common-lib/src/lib/models/e_registry/e-registry.model';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EstampPartyModel } from 'projects/common-lib/src/lib/models/addEstamp.model/add-estamp.model';

@Component({
  selector: 'app-living-person-registration',
  templateUrl: './living-person-registration.component.html',
  styleUrls: ['./living-person-registration.component.scss']
})
export class LivingPersonRegistrationComponent implements OnInit {

  lan: number=1;
  checkedVal: boolean;
  citizenType: number = 1;
  isIndian: number = 1;
  panKYC: number;

  userProfileModel: CitizenUserProfileDetails = new CitizenUserProfileDetails();
  userProfileModelAadharInfo: CitizenUserProfileDetails = new CitizenUserProfileDetails();

  @ViewChild(AlertmessageComponent, { static: false }) alertmessage: AlertmessageComponent;
  // @ViewChild(DemographicDetailsComponent, {static: false}) demographicDetails: DemographicDetailsComponent;

  getOtpClicked: boolean = false;
  otpTouched: boolean = false;

  mobileNo: any;
  passportNo: any;

  captchaHandler?: Captcha = {};
  captchaImageBase64Img?: string = "";
  captchaId: any;
  captchaStr: any;

  countDown: Subscription;
  counter = 0; // 10 min 10*60
  tick = 1000; // 1 sec

  otp: any;
  step0: boolean = true;
  step11: boolean = true;
  step12: boolean = false;
  step13: boolean = false;

  step21: boolean = false;
  step22: boolean = false;

  kycSource: string;
  kycObject: any;
  passportPicStr: string;
  profileImage: any;
  profileImageError: any;
  flag: boolean = true;

  step1clear: boolean = false;
  step2clear: boolean = false;
  step21clear: boolean = false;
  userType: number;

  citizen: Citizen = new Citizen();

  userList: CitizenUserProfileDetails[] = [];
  isUser: boolean = false;
  selectedUser: string;

  constructor(private translateService: TranslateHEService, private translate: TranslateService,
    private ngxService: NgxUiLoaderService, private citizenAuthService: CitizenAuthenticationService,
    private router: Router, private titleService: Title, private authService: CitizenAuthenticationService,
    private defaultServiceService: DefaultServiceService, @Inject(MAT_DIALOG_DATA) public eRegPartyModel: IgrsEregPartyDetailsBean, private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public estampPartyModel: EstampPartyModel,
  public matDialogRef: MatDialogRef<LivingPersonRegistrationComponent>) {
  
    this.checkedVal = sessionStorage.getItem("selectedLanguage") == 'hi' ? true : false;
    
    this.translateService.currentApprovalStageMessage.subscribe(msg => {
      this.lan = msg
      this.translate.use(msg==1?'hi':'en')
     })
	 
  }

  ngOnInit(): void {
    console.log("Living-person-registration_eRegPartyModel : ", this.eRegPartyModel)
    console.log("Living-person-registration_eRegPartyModel : ", this.estampPartyModel)
    this.defaultServiceService.setDefaultType('living-person');
    this.checkUserType();
    this.getStartupCaptcha();
  }

  checkUserType() {

    if (this.userProfileModel.citizenType == undefined && this.eRegPartyModel?.applicantType == "1")
      this.userType = 1;
    else if (this.userProfileModel.citizenType == undefined && this.eRegPartyModel?.applicantType == "2")
      this.userType = 2;
    else
      this.userType = 3;
  }

  closeModal() {
    // this.dialog.closeAll();
       this.matDialogRef.close()
  }

  setNationality(e: number) {
    this.isIndian = e;
    if (e == 1) {
      this.gotostep11();
    }
    if (e == 0) {
      this.gotostep21();
    }
  }
  // changeLan(ev: any) {
  //   //
  //   // 
  //   if (ev.value == 1) {
  //     this.lan = 0;
  //     sessionStorage.setItem("selectedLanguage", 'en');
  //     this.checkedVal = sessionStorage.getItem("selectedLanguage") == 'hi' ? true : false;
  //     this.translate.use(sessionStorage.getItem("selectedLanguage") || "en")
  //     this.translateService.updateApprovalMessage(this.lan);
  //   } else {
  //     this.lan = 1;
  //     sessionStorage.setItem("selectedLanguage", 'hi');
  //     this.checkedVal = sessionStorage.getItem("selectedLanguage") == 'hi' ? true : false;
  //     this.translate.use(sessionStorage.getItem("selectedLanguage") || "hi")
  //     this.translateService.updateApprovalMessage(this.lan);
  //   }
  //   this.translate.get("living-person-title").subscribe(name => {
  //     this.titleService.setTitle(name);
  //   });
  //   // window.location.reload();
  // }

  resetPassport() {
    this.passportNo = "";
  }

  resetForm() {
    this.getOtpClicked = false;
    this.mobileNo = null;
    this.captchaStr = null;
    this.otp = null;
    this.getStartupCaptcha();
    this.counter = 0;
    this.otpTouched = false;
  }

  gotostep11() {
    this.step11 = true;
    this.getOtpClicked = false;
    // this.mobileNo = null;
    this.counter = 0;
    this.captchaStr = null;
    this.otp = null;
    this.step12 = false;
    this.getStartupCaptcha();
    this.step0 = true;
  }

  gotostep12() {
    this.step12 = true;
    this.step13 = false;
    this.step11 = false;
    this.step0 = false;
  }

  gotostep13() {
    this.step12 = false;
    this.step13 = true;
    this.step11 = false;
    this.step0 = false;
  }

  gotostep21() {
    this.step22 = false;
    this.step21 = true;
    // this.passportNo = null;
    this.step0 = true;
  }

  gotostep22() {
    this.step22 = true;
    this.step21 = false;
    this.step0 = false;
  }

  goBack(step: any) {
    if (step == 12) {
      this.gotostep11();
    }
    if (step == 13) {
      this.gotostep12();
    }
    if (step == 22) {
      this.gotostep21();
    }

  }

  // changeContactNo() {
  //   //this.credential.userName;
  //   this.userList = [];
  //   this.isUser = false;
  //   if (this.eRegPartyModel != null && this.eRegPartyModel.applicantType != null && this.eRegPartyModel.applicantType != undefined) {
  //     if (this.mobileNo == null || this.mobileNo == undefined || this.mobileNo?.trim() != '') {
  //       this.ngxService.start();
  //       let param = { mobileNo: this.mobileNo };
  //       this.citizenAuthService.getUserListByContactNo(param).subscribe(result => {
  //         this.ngxService.stop();
  //         if (result.responseStatus == 'true') {
  //           this.selectedUser = "";
  //           if (result.responseData.length > 1) {
  //             this.isUser = true;
  //             this.userList = result.responseData;
  //           } else if (result.responseData.length == 0) {
  //             this.isUser = false;
  //             this.alertmessage.errorAlertMessage('office-not-assign');
  //           } else {
  //             this.isUser = false;
  //             this.selectedUser = result.responseData[0].id;
  //             this.userList = result.responseData;
  //           }

  //         } else {
  //           this.isUser = false;
  //         }

  //       },
  //         err => {
  //           this.isUser = false;
  //           this.ngxService.stop()
  //           //  Swal.fire("Error", "TECHNICAL ERROR PLEASE TRY AFTER SOMETIME !", "error");
  //           this.alertmessage.errorAlertMessage('tech_error_msg');
  //         }
  //       );
  //     }
  //   }
  // }

  setOtpClicked() {
    // var regexp = new RegExp('^[6-9]\d{9}$');
    let mb: string = this.mobileNo;
    if (this.mobileNo == null || this.mobileNo == undefined || this.mobileNo == "") {
      this.getOtpClicked = false;
      this.alertmessage.errorAlertMessage('mobile-error');
      return;
    } else if (mb.length != 10) {
      this.getOtpClicked = false;
      this.alertmessage.errorAlertMessage('mobile-error');
      return;
    }

    let mb1: string = mb.substring(0, 1);
    if (mb1 != '9' && mb1 != '8' && mb1 != '7' && mb1 != '6') {
      this.getOtpClicked = false;
      this.alertmessage.errorAlertMessage('mobile-error');
      return;
    }
    if (this.captchaStr == null || this.captchaStr == undefined || this.captchaStr == "") {
      this.getOtpClicked = false;
      this.alertmessage.errorAlertMessage('mobile-captcha-error');
      return;
    } else {
      this.citizen.mobileNo = this.mobileNo;
      this.citizen.citizenType = this.citizenType;
      this.citizen.captchaId = this.captchaId;
      this.citizen.captchaStr = this.captchaStr;
      this.citizenAuthService.getOtp(this.citizen).subscribe(res => {
        // 
        if (res && res.responseStatus == 'true') {
          this.getOtpClicked = true;
          this.otpTouched = true;
          this.counter = 60;
          // this.countDown = timer(0, this.tick).subscribe(() => {
          //   if (this.counter > 0) {
          //     --this.counter;
          //   }
          // });

          this.citizenAuthService.timer(this.counter).subscribe({
            next: (display) => {
              const words = display.split(':');
              //console.log(words[1]);
              this.counter = Number(words[1]);
            },

            complete: () => {
              console.log('Timer completed')
              this.counter = 0;
            },
          });

          //this.citizenAuthService.timer(this.counter);

          this.alertmessage.successAlertMessage(res);
        } else {
          this.getOtpClicked = false;
          this.otpTouched = false;
          this.alertmessage.errorAlertMessage(res);
          this.gotostep11();
        }
      });
    }
  }

  verifyOtp() {
    if (this.otp == null || this.otp == undefined || this.otp == "") {
      this.alertmessage.errorAlertMessage('otp-error');
    } else {
      this.citizenAuthService.verifyOtp(this.mobileNo, this.otp).subscribe(res => {
        // 
        if (res && res.responseStatus == 'true') {
          this.alertmessage.successAlertMessage(res);
          this.step1clear = true;
          this.gotostep12();
        } else {
          this.step1clear = false;
          this.alertmessage.errorAlertMessage(res);
          this.otp = null;
        }
      });
    }
  }

  verifyAdharOtpOut(event: any) {
    // 
    if (event != null && event.ret == 1) {

      this.kycObject = event;
      this.kycSource = 'Aadhaar';
      //this.step2clear = true;

      //let kycUniqueToken: any = this.kycObject.residentDetails.uidToken;
      //console.log("kycObject-----"+kycUniqueToken) ;
      const param = {
        "kyc_unique_token": this.kycObject.residentDetails.uidToken,
        "citizen_type": this.citizenType
      }

      if ((this.eRegPartyModel == null && this.eRegPartyModel == undefined) || (this.eRegPartyModel != null && this.eRegPartyModel != undefined && this.eRegPartyModel?.applicantType != '2')) {
        this.citizenAuthService.checkAadhaarAlreadyExist(param).subscribe(res => {
          // 
          if (res && res.responseStatus == 'true') {
            this.gotostep13();
            this.step2clear = true;
            //this.alertmessage.successAlertMessage(res);
          } else {
            this.step2clear = false;
            this.alertmessage.errorAlertMessage(res);
            this.gotostep12();
          }
        });
      } else {
        this.gotostep13();
        this.step2clear = true;
      }

      //this.gotostep13();
      // this.demographicDetails.updateMobileNumber(this.mobileNo);
    } else {
      this.step2clear = false;
      this.gotostep12();
    }
    // 
  }

  verifyPanOut(event: any) {
    // 
    if (event != null && event.skip == 1) {
      this.kycObject = null;
      this.kycSource = '';
      this.step2clear = true;
      this.gotostep13();
    } else if (event != null && event.status == 1) {
      this.kycObject = event;
      this.kycSource = 'Pan';
      //this.step2clear = true;

      this.citizenAuthService.checkPanAlreadyExist(this.kycObject.pan, '', '', this.citizenType, '').subscribe(res => {
        // 
        if (res && res.responseStatus == 'true') {
          this.gotostep13();
          this.step2clear = true;
          //this.alertmessage.successAlertMessage(res);
        } else {
          this.step2clear = false;
          this.alertmessage.errorAlertMessage(res);
          this.gotostep12();
        }
      });

      //this.gotostep13();
    } else {
      this.alertmessage.errorAlertMessage('Kyc Failed!!! ');
      this.step2clear = false;
      this.gotostep12();
    }
  }

  demographicDetailsSaveOut(event: any) {
    // 
    if (event != null) {
      if (event.responseStatus == "true") {
        this.alertmessage.successAlertMessage(event);
        setTimeout(() => {
          this.router.navigate(['']);
        }, 2000)
      } else {
        this.alertmessage.errorAlertMessage(event);
      }
    }

  }

  getStartupCaptcha() {
    // 
    this.ngxService.start();
    this.citizenAuthService.getLoginCaptcha().subscribe(result => {
      this.ngxService.stop();
      this.captchaHandler = result;
      this.captchaImageBase64Img = "data:image/png;base64," + this.captchaHandler.responseData?.captchaBase64;
      this.captchaId = this.captchaHandler.responseData?.id;
    },
      err => {
        this.ngxService.stop()
        this.alertmessage.errorAlertMessage('captcha-error');
      }
    );
  }


  PassportFileChangeEvent(pfileInput: any): any {
    this.flag = false;
    this.profileImageError = null;
    if (pfileInput.target.files && pfileInput.target.files[0]) {
      // Size Filter Bytes
      const pmax_size = 1000000;
      const pallowed_types = ["application/pdf"];
      const pmax_height = 15200;
      const pmax_width = 25600;

      if (pfileInput.target.files[0].size > pmax_size) {
        this.alertmessage.errorAlertMessage('file-size-error');
        Swal.fire({
          title: `${this.lan == 0 ? "File Size Exceeds Allowed Limit" : "फ़ाइल का आकार अनुमत सीमा से अधिक है"}`,
          // title: "File Size Exceeds Allowed Limit",
          text: `${this.lan == 0 ? "Maximum size allowed is " + pmax_size / 1000 + "Kb" : "अधिकतम आकार अनुमत है " + pmax_size / 1000 + "के.बी"}`,
          // text: "Maximum size allowed is " + pmax_size / 1000 + "Kb",
          icon: "error",
          showCancelButton: false,
          confirmButtonText: (this.lan == 0) ? 'Ok' : 'ठीक है',
          cancelButtonText: (this.lan == 0) ? 'No, keep it' : 'नहीं, इसे रखो',
          allowOutsideClick: false,
        }).then((result) => {
          return false;
        });
        return false;
      }
      if (!pallowed_types.includes(pfileInput.target.files[0].type, 0)) {
        this.alertmessage.errorAlertMessage('file-type-error');
        Swal.fire({
          title: `${this.lan == 0 ? "UnSupported File Type" : "असमर्थित फ़ाइल प्रकार"}`,
          // title: "UnSupported File Type",
          text: `${this.lan == 0 ? "Only files are allowed of type ( pdf )" : "केवल (पीडीएफ) प्रकार की फ़ाइलों की अनुमति है"}`,
          // text: "Only files are allowed of type ( pdf )  ",
          icon: "error",
          showCancelButton: false,
          confirmButtonText: (this.lan == 0) ? 'Ok' : 'ठीक है',
          cancelButtonText: (this.lan == 0) ? 'No, keep it' : 'नहीं, इसे रखो',
          allowOutsideClick: false,
        }).then((result) => {
          return false;
        });

        return false;
      }
      const preader = new FileReader();
      preader.onload = (pe: any) => {
        const pimage = new Image();
        pimage.src = pe.target.result;
        this.passportPicStr = (pe.target.result).split(',')[1];

      };
      preader.readAsDataURL(pfileInput.target.files[0]);
    }
  }

  savePassport() {
    if (this.passportNo == null || this.passportNo == undefined || this.passportNo == "") {
      this.alertmessage.errorAlertMessage('passport_can_not_empty');
    } else {
      // 
      this.kycSource = 'passport';
      this.kycObject = { 'id': 1, 'status': 'success', 'passportNo': this.passportNo, 'passportPicStr': this.passportPicStr };

      this.step21clear = true;
      this.gotostep22();
    }


  }

}

