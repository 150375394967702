<button mat-button mat-dialog-close class="colsebtn">
    <em class="fa fa-times"></em></button>


<h2 class="Consenterdialog-title">
    {{"property-identificationValuation.pro-Identi" | translate}}
    <span class="fs-13"> ( {{"property-identificationValuation.enterUniIDPro" | translate}} )</span>
</h2>


<lib-alertmessage></lib-alertmessage>

<div class="PropertyValuation mt-2 p-3 pb-1 ">

 
    <div class="PropertyValuationInnerBody new_search">
     

        <div class="row justify-content-center">
            <div class="col-lg-12 col-sm-12">
                <div class="searchPenal">
                    <form>
                        <div class="search-box d-sm-flex align-items-center">
                            <div class="search-box-content">
                                <!-- <h2 class="filter_tit">{{filterType}}</h2> -->
                                <mat-expansion-panel class="" [expanded]="step === 0" (opened)="setStep(0)">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="">
                                            <div class="my-panel" (click)="$event.stopPropagation();">

                                                <form class="formlabel-letter">
                                                    <div class="p-1 tab_div-change">

                                                        <div
                                                            class="form-check form-check-inline no-radio-box childlabel">
                                                            <input class="form-check-input" type="radio"
                                                                [(ngModel)]="filterType"
                                                                (click)="allFormReset(drawer, 'khasraid')"
                                                                [value]="'khasraid'" name="inlineRadioOptions"
                                                                id="inlineRadio2">
                                                            <label class="form-check-label"
                                                                for="inlineRadio2">{{'property-valuation.Agriculture-Property'
                                                                | translate}}
                                                                <span
                                                                    class="d-block small1">{{'property-valuation.Khasra-Unique-ID'
                                                                    | translate}}</span>
                                                            </label>
                                                        </div>


                                                        <div *ngIf="!isHide"
                                                            class="form-check form-check-inline no-radio-box childlabel">
                                                            <input hidden="isHide" class="form-check-input" type="radio"
                                                                (change)="changePlaceHolder()"
                                                                (click)="allFormReset(drawer,'propid')"
                                                                [(ngModel)]="filterType" [value]="'propid'"
                                                                name="inlineRadioOptions" id="inlineRadio5">
                                                            <label class="form-check-label" for="inlineRadio5">
                                                                {{'property-valuation.Property-ID' | translate}}
                                                                <span
                                                                    class="d-block small1">{{'property-valuation.E-Nagarpalika'
                                                                    | translate}}</span>
                                                            </label>
                                                        </div>

                                                        <div *ngIf="!isHide"
                                                            class="form-check form-check-inline no-radio-box childlabel">
                                                            <input class="form-check-input" type="radio"
                                                                [(ngModel)]="filterType" (change)="changePlaceHolder()"
                                                                (click)="allFormReset(drawer, 'panchayatid')"
                                                                [value]="'panchayatid'" name="inlineRadioOptions"
                                                                id="inlineRadio4">
                                                            <label class="form-check-label"
                                                                for="inlineRadio4">{{'property-valuation.Panchayat-ID' |
                                                                translate}}
                                                                <span
                                                                    class="d-block small1">{{'property-valuation.Swamitva-ID'
                                                                    | translate}}</span>
                                                            </label>
                                                        </div>

                                                        <div class="form-check form-check-inline no-radio-box childlabel"
                                                            *ngIf="showOtherSearch">
                                                            <input [hidden]="isHide" class="form-check-input"
                                                                type="radio" [(ngModel)]="filterType"
                                                                [value]="'bynameUrbon'" name="inlineRadioOptions"
                                                                id="inlineRadio1">
                                                            <label class="form-check-label"
                                                                for="inlineRadio1">{{'property-valuation.By-Area' |
                                                                translate}}
                                                                <span
                                                                    class="d-block small1">{{'property-valuation.Address-Search'
                                                                    | translate}}</span>
                                                            </label>
                                                        </div>

                                                        <div class="form-check form-check-inline no-radio-box childlabel"
                                                            *ngIf="showOtherSearch">
                                                            <input [hidden]="isHide" class="form-check-input"
                                                                type="radio" [(ngModel)]="filterType"
                                                                (change)="changePlaceHolder()"
                                                                [value]="'searchByGeotag'" name="inlineRadioOptions"
                                                                id="inlineRadio1">
                                                            <label class="form-check-label"
                                                                for="inlineRadio1">{{'property-valuation.Geo-Tag-ID' |
                                                                translate}}
                                                                <span
                                                                    class="d-block small1">{{'property-valuation.By-Property-Photo'
                                                                    | translate}}</span>
                                                            </label>
                                                        </div>

                                                        <div>
                                                            <mat-icon class="fa-2x refresh"
                                                                (click)="allFormReset(drawer)">refresh</mat-icon>
                                                            <!-- <i class="fa fa-whatsapp py-3 ml-2 text-success fa-2x"></i> -->
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <div class="input-group pb-1 searchField zindex-1">

                                        <div class="panchayatSelect w-100 pl-3 mt-2" *ngIf="IsPanchayat">
                                            <div class="d-flex">
                                                <label class="mr-3">
                                                     {{(lan==0)?"Do you have Panchayat Property ID":"क्या आपके पास पंचायत संपत्ति आईडी है?"}}
                                                </label>
                                                <div class="d-flex flex-nowrap">
                                                    <div class="form-check-inline d-flex flex-nowrap">
                                                        <label class="form-check-label d-flex flex-nowrap">
                                                            <input type="radio" class="form-check-input"
                                                                name="panchayatId">{{"yes" | translate}}
                                                        </label>
                                                    </div>
                                                    <div class="form-check-inline d-flex flex-nowrap">
                                                        <label class="form-check-label d-flex flex-nowrap">
                                                            <input type="radio" class="form-check-input"
                                                                name="panchayatId">{{"No" | translate}}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div *ngIf="filterType!='byname' && filterType!='bynameUrbon'"
                                            class="d-flex w-100 p-1">

                                            <div class="input-group-prepend">
                                                <span
                                                    class="input-group-text text-muted rounded-pill border-0 ps-3 bg-transparent"
                                                    id="button-addon2"><i class="fa fa-search"></i> </span>
                                            </div>
                                            <div *ngIf="filterType!='khasraid'&& filterType!='searchByGeotag'"
                                                class="w-100">
                                                <input type="text" name="idV" class="form-control" [(ngModel)]="id"
                                                    id="inp" maxlength="20" [placeholder]="placeHolder" aria-label=""
                                                    AlphaNumeric 
                                                    aria-describedby="button-addon2">
                                                <span *ngIf="filterType=='propid'"
                                                    style="color: #767676;padding-left: 12px;">
                                                    {{(lan==0)?"for Bhopal District Property
                                                    Please append B before Property
                                                    Id":"भोपाल जिले की संपत्ति के लिए कृपया संपत्ति आईडी से पहले B
                                                    जोड़ें"}}
                                                </span>
                                            </div>
                                            <div *ngIf="filterType=='searchByGeotag'" class="w-100">
                                                <input type="text" class="form-control" name="customId"
                                                    [(ngModel)]="geoTagid" maxlength="50" [placeholder]="placeHolder"
                                                    aria-describedby="button-addon2">
                                            </div>
                                            <div *ngIf="filterType=='khasraid'" class="w-100">
                                                <mat-form-field class="example-chip-list border-0 bg_white pt-2">
                                                    <mat-label>{{'property-valuation.enter-Khasra-ID' |
                                                        translate}}</mat-label>
                                                    <mat-chip-list #chipList aria-label="">
                                                       
                                                        <mat-chip *ngFor="let param of params; let i = index"
                                                            (removed)="remove(param,drawer)"
                                                            [style]="(khasraColor[i])?khasraColor[i].bg+khasraColor[i].color:''">
                                                            {{param}}
                                                            <button matChipRemove>
                                                                <mat-icon>close</mat-icon>
                                                            </button>
                                                        </mat-chip>
                                                        <input appAlphaNumericSpace maxlength="20" id="inp2"
                                                            placeholder="{{'property-valuation.Write-Then-Enter' | translate}}"
                                                            [matChipInputFor]="chipList"
                                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                            [matChipInputAddOnBlur]="addOnBlur"
                                                            (matChipInputTokenEnd)="add($event)"
                                                            (keypress)="omit_special_char($event)">
                                                    </mat-chip-list>
                                                </mat-form-field>
                                            </div>




                                            <div class="input-group-append">
                                                <button mat-flat-button class="btn btn-primary mt-1 mr-1" type="button"
                                                    (click)="selectKhIdAndPrIdAndUnid(filterType)" id="button-addon2">
                                                    {{'property-valuation.Submit' | translate}}</button>
                                            </div>

                                        </div>

                                        <div class="ng-autocomplete d-flex pt-2  px-2 w-100"
                                            *ngIf="filterType=='byname' || filterType=='bynameUrbon'">
                                            <!-- <div class="dropdown bdr-rt" *ngIf="false">

                                                <select class="form-control bdr-rt border-0 w-auto"
                                                    id="exampleFormControlSelect1" (change)="selectSearchType($event)">
                                                    <option value="R">{{'rural' | translate}}</option>
                                                    <option value="U">{{'urban' | translate}}</option>

                                                </select>

                                            </div> -->
                                            <!-- <div class="input-group-prepend" *ngIf="filterType=='byname'"> -->
                                            <div class="input-group-prepend">
                                                <span
                                                    class="input-group-text text-muted rounded-pill border-0 ps-3 bg-transparent"
                                                    id="button-addon2"><i class="fa fa-search"></i> </span>
                                            </div>
                                            <div class="search_section_both w-100"
                                                *ngIf="filterType=='bynameUrbon' && showOtherSearch">
                                                <div class="ng-autocomplete d-flex dd-field">
                                                    <!-- <div class="input-group-prepend">
                                                    <span
                                                        class="input-group-text text-muted rounded-pill border-0 ps-3 bg-transparent"
                                                        id="button-addon2"><i class="fa fa-search"></i> </span>
                                                </div> -->
                                                    <input type="text" maxlength="50"
                                                        placeholder="{{'property-valuation.Search-By'| translate}} {{'address' | translate}}"
                                                        #inputMapMyIndia class="noborder wurban"
                                                        (keyup)="getsuggetions(inputMapMyIndia)" specialIsAlphaNumericwithSpace>
                                                    <button type="button" class="btn close-btn"
                                                        style="margin-left: -40px; z-index: 100;"
                                                        *ngIf="islCencelBtnShow" (click)="clearSearch()">
                                                        x
                                                    </button>
                                                    <div #suggetionDiv class="suggetionDiv" *ngIf="islistShow">
                                                        <ul #suggetionList class="suggetionList">

                                                        </ul>
                                                    </div>
                                                </div>

                                                <div class="search_location" *ngIf="!islCencelBtnShow">
                                                    <a class="search_locationBtn" (click)="toggleStatus()">
                                                        <i class="fa fa-search searchicon_color"></i> Search by
                                                        guideline location
                                                    </a>
                                                    <div class="advanceFilterOption  slideDown  d-block {{hiddenvalue}}"
                                                        *ngIf="resetDemo" cdkDrag>
                                                        <button class="filterClosebtn"
                                                            (click)="toggleStatus()">X</button>
                                                        <div class="p-2">
                                                            <comman-demography-hierarchy-load style="width: 100%;"
                                                                [componentFlag]="'columnwise'" [type_id]="type_id"
                                                                [parent_id]="parent_id" [lastdemograpgy_id]="15"
                                                                (loadgldata)="getDemography($event)"></comman-demography-hierarchy-load>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>





                                            <!-- end rahul -->
                                        </div>



                                    </div>
                                </mat-expansion-panel>
                            </div>


                        </div>
                    </form>



                </div>
            </div>

        </div>
        <div class="row m-0">
            <div *ngIf="selectedMstDemographyBean!=null" [ngClass]=" flag ? 'col-md-0' : 'col-md-3'"
                class="p-0 new-chnage">
                <button mat-raised-button (click)="leftColtoggle();;mapReset()" class="fixed-pos">
                    <mat-icon>arrow_left</mat-icon><mat-icon>arrow_right</mat-icon></button>


                <!-- <button mat-raised-button (click)="leftColtoggle()" class="fixed-pos" >
                    <mat-icon>arrow_left</mat-icon><mat-icon>arrow_right</mat-icon>
                </button> -->
                <div class="card  border-0">
                    <div class="card-body pt-0 pb-0 px-3 badge-light" *ngIf="!showChangeDemography">
                        <div class="searchRecord mb-0 heading_guideline justify-content-center">
                            <div class="heading_guideline-tit">{{'property-valuation.ward_colony' | translate}}</div>
                            <div *ngIf="selectedMstDemographyBean?.location?.district?.demographyName!=null&&selectedMstDemographyBean?.location?.district?.demographyName!=null"
                                class="new-design-box p-0 ">
                                <div class="single-block">
                                    <div id="bg">
                                        <div id="bg-bottom"></div>
                                        <div id="top"><img src="./assets/images/district.png"></div>

                                        <div id="bottom"></div>
                                    </div>
                                    <p class="text-theme">{{'property-valuation.District' |
                                        translate}}</p>
                                    <h3 class="mb-0 pb-0">
                                        {{lan==0?selectedMstDemographyBean?.location?.district?.demographyNameEn:selectedMstDemographyBean?.location?.district?.demographyNameHi}}
                                    </h3>
                                </div>
                            </div>

                            <div *ngIf="selectedMstDemographyBean?.location?.sroffice?.demographyName!=null&&selectedMstDemographyBean?.location?.sroffice?.demographyName!=null"
                                class="new-design-box p-0 ">
                                <div class="single-block">
                                    <div id="bg">
                                        <div id="bg-bottom"></div>
                                        <div id="top"><img src="./assets/images/district.png"></div>

                                        <div id="bottom"></div>
                                    </div>
                                    <p class="text-theme">{{'property-valuation.SROffice' |
                                        translate}}</p>
                                    <h3 class="mb-0 pb-0">
                                        {{lan==0?selectedMstDemographyBean?.location?.sroffice?.demographyNameEn:selectedMstDemographyBean?.location?.sroffice?.demographyNameHi}}
                                    </h3>
                                </div>
                            </div>

                            <div *ngIf="selectedMstDemographyBean?.demographyType?.demographyAreaType=='R'"
                                class="new-design-box p-0 ">
                                <div class="single-block">
                                    <div id="bg">
                                        <div id="bg-bottom"></div>
                                        <div id="top"><img src="./assets/images/areatype.png"></div>
                                        <div id="bottom"></div>
                                    </div>
                                    <p class="text-theme">{{'property-valuation.Area-Type' |
                                        translate}}</p>
                                    <h3 class="mb-0 pb-0">{{'property-valuation.Rural' | translate}}
                                    </h3>
                                </div>
                            </div>

                            <div *ngIf="selectedMstDemographyBean?.demographyType?.demographyAreaType=='U'"
                                class="new-design-box p-0 ">
                                <div class="single-block">
                                    <div id="bg">
                                        <div id="bg-bottom"></div>
                                        <div id="top"><img src="./assets/images/areatype.png"></div>
                                        <div id="bottom"></div>
                                    </div>
                                    <p class="text-theme">{{'property-valuation.Area-Type' |
                                        translate}}</p>
                                    <h3 class="mb-0 pb-0">{{'property-valuation.Urban' | translate}}
                                    </h3>
                                </div>
                            </div>
                            <div *ngIf="selectedMstDemographyBean?.location?.tehsil?.demographyName!=null&&selectedMstDemographyBean?.location?.tehsil?.demographyName!=''"
                                class="new-design-box p-0 ">
                                <div class="single-block">
                                    <div id="bg">
                                        <div id="bg-bottom"></div>
                                        <div id="top"><img src="./assets/images/tehsile.png"></div>
                                        <div id="bottom"></div>
                                    </div>
                                    <p class="text-theme">{{'property-valuation.Tehsil' |
                                        translate}}</p>
                                    <h3 class="mb-0 pb-0">
                                        {{lan==0?selectedMstDemographyBean?.location?.tehsil?.demographyNameEn:selectedMstDemographyBean?.location?.tehsil?.demographyNameHi}}
                                    </h3>
                                </div>
                            </div>
                            <div *ngIf="selectedMstDemographyBean?.location?.localBody?.demographyName!=null&&selectedMstDemographyBean?.location?.localBody?.demographyName!=''"
                                class="new-design-box p-0 ">
                                <div class="single-block">
                                    <div id="bg">
                                        <div id="bg-bottom"></div>
                                        <div id="top"><img src="./assets/images/tehsile.png"></div>
                                        <div id="bottom"></div>
                                    </div>
                                    <p class="text-theme">{{'property-valuation.localbody' |
                                        translate}}</p>
                                    <h3 class="mb-0 pb-0">
                                        {{lan==0?selectedMstDemographyBean?.location?.localBody?.demographyNameEn:selectedMstDemographyBean?.location?.localBody?.demographyNameHi}}
                                    </h3>
                                </div>
                            </div>
                            <div *ngIf="selectedMstDemographyBean?.location?.ward?.demographyName!=null&&selectedMstDemographyBean?.location?.ward?.demographyName!=''"
                                class="new-design-box p-0 ">
                                <div class="single-block">
                                    <div id="bg">
                                        <div id="bg-bottom"></div>
                                        <div id="top"><img src="./assets/images/village.png"></div>
                                        <div id="bottom"></div>
                                    </div>
                                    <p class="text-theme">{{'property-valuation.Ward' | translate}}
                                    </p>
                                    <h3 class="mb-0 pb-0">
                                        {{lan==0?selectedMstDemographyBean?.location?.ward?.demographyNameEn:selectedMstDemographyBean?.location?.ward?.demographyNameHi}}
                                    </h3>
                                </div>
                            </div>


                            <div *ngIf="selectedMstDemographyBean?.location?.village?.demographyName!=null&&selectedMstDemographyBean?.location?.village?.demographyName!=''"
                                class="new-design-box p-0 ">
                                <div class="single-block">
                                    <div id="bg">
                                        <div id="bg-bottom"></div>
                                        <div id="top"><img src="./assets/images/village.png"></div>
                                        <div id="bottom"></div>
                                    </div>
                                    <p class="text-theme">{{'property-valuation.Village' |
                                        translate}}</p>
                                    <h3 class="mb-0 pb-0">
                                        {{lan==0?selectedMstDemographyBean?.location?.village?.demographyNameEn:selectedMstDemographyBean?.location?.village?.demographyNameHi}}


                                    </h3>
                                </div>
                            </div>
                            <div *ngIf="selectedMstDemographyBean?.location?.village_colony?.demographyName!=null&&selectedMstDemographyBean?.location?.village_colony?.demographyName!=''"
                                class="new-design-box p-0 ">
                                <div class="single-block">
                                    <div id="bg">
                                        <div id="bg-bottom"></div>
                                        <div id="top"><img src="./assets/images/guidline.png"></div>
                                        <div id="bottom"></div>
                                    </div>
                                    <p class="text-theme">{{'property-valuation.village_colony' |
                                        translate}}</p>
                                    <h3 class="mb-0 pb-0 lh-13 truncate">
                                        {{lan==0?selectedMstDemographyBean?.location?.village_colony?.demographyNameEn:selectedMstDemographyBean?.location?.village_colony?.demographyNameHi}}
                                    </h3>
                                    <div class="d-flex">
                                        <p class="text-theme">
                                            {{lan==0?selectedMstDemographyBean?.ulbTypeNmEn:selectedMstDemographyBean?.ulbTypeNmHi}}
                                        </p>

                                    </div>
                                    <div>
                                        <p class="text-theme">
                                            {{lan==0?selectedMstDemographyBean?.isPlanningAreaNmEn:selectedMstDemographyBean?.isPlanningAreaNmHi}}
                                        </p>

                                    </div>
                                    <div *ngIf="selectedMstDemographyBean.upBandhDesc">
                                        <p class="text-theme">
                                            {{selectedMstDemographyBean?.upBandhDesc}}
                                        </p>

                                    </div>
                                </div>
                            </div>
                            <div *ngIf="selectedMstDemographyBean?.location?.ward_colony?.demographyName!=null&&selectedMstDemographyBean?.location?.ward_colony?.demographyName!=''"
                                class="new-design-box p-0 ">
                                <div class="single-block">
                                    <div id="bg">
                                        <div id="bg-bottom"></div>
                                        <div id="top"><img src="./assets/images/guidline.png"></div>
                                        <div id="bottom"></div>
                                    </div>
                                    <p class="text-theme">{{'property-valuation.ward_colony' |
                                        translate}}</p>
                                    <h3 class="mb-0 pb-0 lh-13 truncate">
                                        {{lan==0?selectedMstDemographyBean?.location?.ward_colony?.demographyNameEn:selectedMstDemographyBean?.location?.ward_colony?.demographyNameHi}}
                                    </h3>
                                    <div *ngIf="selectedMstDemographyBean.ulbType>0">
                                        <span class="text-theme">
                                            {{lan==0?selectedMstDemographyBean?.ulbTypeNmEn:selectedMstDemographyBean?.ulbTypeNmHi}}</span>
                                    </div>
                                    <div *ngIf="selectedMstDemographyBean.isPlanningArea>0">
                                        <p class="text-theme">
                                            {{lan==0?selectedMstDemographyBean?.isPlanningAreaNmEn:selectedMstDemographyBean?.isPlanningAreaNmHi}}
                                        </p>
                                    </div>
                                    <div *ngIf="selectedMstDemographyBean.upBandhDesc">
                                        <p class="text-theme">
                                            {{selectedMstDemographyBean?.upBandhDesc}}
                                        </p>
                                    </div>
                                </div>
                            </div>





                            <div class="new-design-box p-0 " *ngIf="hideDropdownForMultipleDemography">
                                <div class="single-block">
                                    <div id="bg">
                                        <div id="bg-bottom"></div>
                                        <div id="top"><img src="./assets/images/village.png"></div>
                                        <div id="bottom"></div>
                                    </div>
                                    <p class="text-theme">{{'property-valuation.select' |
                                        translate}}</p>
                                    <select class="input-boxselect form-control h-50 p-0" name="selectedDemography"
                                        [(ngModel)]="selectedDemography" palaceholder="select demography"
                                        (ngModelChange)="selectDemographybyDropDown($event, drawer)">
                                        <option [disabled]="mstDemographyBeanList.length>0" value=null>
                                            {{'property-valuation.Select-your-demography' | translate}}</option>
                                        <option class="option-width" *ngFor="let demography of mstDemographyBeanList"
                                            [ngValue]="demography">
                                            {{lan==0?demography.demographyNameEn:demography.demographyNameHi}}</option>
                                    </select>
                                </div>
                            </div>




 
                            
                            <div class="new-design-box p-0 " >                            
                                <div><button class="click-btn btn-style507 text-light chnageGuidelineBtn" (click)="changeGuidelineLocaion()" [disabled]="disableSaveBttn"><mat-icon class="line-ht27 text-light">edit</mat-icon>
                                        {{(lan==0)?'Do you want to select another guideline location?':'क्या आप अन्य गाईडलाइन स्थान का चयन करना चाहते हैं?'}}
                                    </button>
                                </div>                               
                            </div>

                            

                            <div class="new-design-box p-0 ">
                                <div class="single-block badge-secondary">
                                    <div id="bg">
                                        <div id="bg-bottom"></div>
                                        <div id="top" data-toggle="modal" data-target="#ViewGuidelineRates">
                                            <mat-icon class="line-ht27 text-light">remove_red_eye</mat-icon>
                                        </div>
                                        <div id="bottom"></div>
                                    </div>
                                    <div><a class="click-btn btn-style507 text-light" data-toggle="modal"
                                            data-target="#ViewGuidelineRates">
                                            {{'guideline.guideline' |
                                            translate}}</a>
                                    </div>
                                </div>

                            </div>
                            <div class="new-design-box p-0 " *ngIf="isWebGisData || isEnagarPalika||isPnrd">
                                <div class="single-block badge-secondary">
                                    <div id="bg">
                                        <div id="bg-bottom"></div>
                                        <div id="top" (click)="openverificationMobileDialog(ViewPropertyDetail)">
                                            <mat-icon class="line-ht27 text-light">remove_red_eye</mat-icon>
                                        </div>
                                        <div id="bottom"></div>
                                    </div>
                                    <!-- <button mat-raised-button (click)="openDialog1('3000ms', '1500ms')">View Guideline Rates</button> -->
                                    <!-- <button mat-raised-button type="button" class="mt-2 mat-primary float-right ml-2 fd_btn_n1" data-toggle="modal"
                        data-target="#ViewGuidelineRates">View Guideline Rates</button> -->

                                    <div><a class="click-btn btn-style507 text-light"
                                            (click)="openverificationMobileDialog(ViewPropertyDetail)">

                                            {{'homepage.Property' | translate}}
                                        </a>
                                    </div>
                                </div>

                            </div>

                            <div
                                *ngIf="(isWebGisData && agriPropertyValuationRequestBean.webGisResponseBeanDataArr.length===1) && params.length===1">
                                <form #f="ngForm">
                                    <div class="form-group">
                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input" id="isPlotOnAgriLandyes"
                                                name="isPlotOnAgriLandyes"
                                                [(ngModel)]="propertyValuationRequestBeanEmit.isPlotOnAgriLand"
                                                (ngModelChange)="updatePlot($event);propertyValuationRequestBeanEmit.agriculturalWithPlot.isPlotOnAgriLand=true" />
                                            <label class="form-check-label">
                                                {{'property-valuation.Is-there-any-plot-done-on-the-Agriculture-land?'
                                                | translate}}
                                            </label>
                                        </div>
                                    </div>

                                    <div class="form-group"
                                        *ngIf="propertyValuationRequestBeanEmit.isPlotOnAgriLand==true">

                                        <label class="mr-2">{{lan==0?'Select unit of plot area':'प्लॉट क्षेत्र की इकाई
                                            का चयन करें'}}
                                            <span class="rstarcolor">*</span></label>
                                        <select class="form-control form-control-sm" [value]="plotunit"
                                            [(ngModel)]="plotunit" name="unit" #unit
                                            [ngClass]="{ 'is-invalid': f.submitted && form.controls.unit.errors }"
                                            required (ngModelChange)="unitChangeInAgricultureLand($event)">
                                            <option value="" disabled>{{lan==0?'Please select your unit':'प्लॉट क्षेत्र की इकाई का चयन करें'}}</option>
                                            <option *ngFor="let unt of uomList | slice:0:2" [value]="unt">
                                                <div *ngIf="lan==0">{{convertedUOM(unt)}}</div>
                                                <div *ngIf="lan==1">{{convertedUOM(unt)}}</div>
                                            </option>
                                        </select>
                                        <div *ngIf="f.submitted && form.controls.unit.errors" class="invalid-feedback">
                                            <div *ngIf="f.controls.unit.errors.required">{{lan==0?'Please select
                                                unit':'कृपया इकाई का चयन करें'}}</div>
                                        </div>

                                    </div>
                                    <div *ngIf="plotunit=='SQFT' && propertyValuationRequestBeanEmit.totalArea<2500">
                                        <label for="restrictedNumber">
                                        {{ lan == 0 ? "Restricted Area (within ±4% of Total Area)" : "प्रतिबंधित क्षेत्र (कुल क्षेत्र के ±4% के भीतर)" }}
                                        </label>
                                        <input id="restrictedNumber" name="restrictedNumber" type="number"
                                        TwoDigitDecimalNumber  ngModel
                                            required #restrictedNumber="ngModel" [ngModelOptions]="{updateOn: 'change'}"
                                            [ngClass]="{'is-invalid': restrictedNumber.invalid && restrictedNumber.touched}"
                                            [ngModel]="baseValue" (ngModelChange)="
                                           restrictedNumber.control.updateValueAndValidity()"
                                            [appRestrictedNumber]="propertyValuationRequestBeanEmit.totalArea">
                                        <div *ngIf="restrictedNumber.invalid && restrictedNumber.touched">
                                            {{lan==0?"Value must be within ±4% of the Total Area.":"मूल्य कुल क्षेत्रफल के ±4% के भीतर होना चाहिए।"}}
                                        </div>
                                    </div>


                                    <small *ngIf="isConversion" role="alert"
                                        class="alert-danger col-12 p-1 d-block mb-1">
                                        {{lan==0?'The total area of ​​agricultural land is '+totalArea+' hectares and
                                        its total area after selected unit will be
                                        '+propertyValuationRequestBeanEmit.totalArea+'
                                        '+propertyValuationRequestBeanEmit.unit:'
                                        कृषि भूमि का कुल क्षेत्रफल '+ totalArea+ ' हेक्टेयर है तथा चयनित इकाई के बाद इसका
                                        कुल क्षेत्रफल ' +propertyValuationRequestBeanEmit.totalArea+ ' 
                                        '+propertyValuationRequestBeanEmit.unit + ' होगा' }}
                                    </small>
                                </form>

                            </div>



                            <div class="form-group" *ngIf="isAllowedTochangeArea" >

                                <form #f="ngForm">  
                                    <div >
                                        <label class="mr-2">{{lan === 0 ? 'Plot Area (' + mpenagarpalikamodel.unit + ')' : 'प्लॉट एरिया  (' + convertedUOM(mpenagarpalikamodel.unit)+ ')'}}
                                            <span class="rstarcolor">*</span></label>
                                        <input id="allowedAreaNumber" name="allowedAreaNumber" type="number"
                                        TwoDigitDecimalNumber  ngModel required
                                            required #allowedAreaNumber="ngModel" [ngModelOptions]="{updateOn: 'change'}"
                                            [ngClass]="{'is-invalid': allowedAreaNumber.invalid && allowedAreaNumber.touched}"
                                            [ngModel]="baseValue" (ngModelChange)="
                                           allowedAreaNumber.control.updateValueAndValidity()"
                                            >
                                        <div *ngIf="allowedAreaNumber.invalid && allowedAreaNumber.touched">
                                            {{lan==0?'Please ! Enter area ':'कृपया ! क्षेत्र दर्ज करें'}}
                                        </div>
                                    </div>


                                
                                </form>

                            </div>
                            
                            <div class="form-group"
                                        *ngIf="mpenagarpalikamodel && isPropertyTypeChange">
                                    <form #form="ngForm">
                                        <label class="mr-2">{{lan==0?'Property transacting as: ':'संपत्ति का लेन-देन इस प्रकार है: '}}
                                            <span class="rstarcolor">*</span></label>
                                        <select class="form-control form-control-sm" [value]="mpenagarpalikamodel.propertyTypeId"
                                            [(ngModel)]="mpenagarpalikamodel.propertyTypeId" name="mpenagarpalikamodel.propertyTypeId" 
                                            [ngClass]="{ 'is-invalid': form.submitted && form.controls.mpenagarpalikamodel.propertyTypeId.errors }"
                                            required (ngModelChange)="changePropertyType($event)">
                                            <option value="" disabled>{{lan==0?'Please select your type':'कृपया प्रकार चुनें'}}</option>
                                            <option [value]="1">{{lan==0?"Plot":"प्लॉट"}}</option>
                                            <option [value]="2">{{lan==0?"Building":"भवन"}}</option>
                                        </select>
                                        <div *ngIf="form.submitted && form.controls.mpenagarpalikamodel.propertyTypeId.errors" class="invalid-feedback">
                                            <div *ngIf="form.controls.mpenagarpalikamodel.propertyTypeId.errors.required">{{lan==0?'Please select type':'कृपया प्रकार चुनें'}}</div>
                                        </div>
                                    </form>
                                     </div>


                        </div>
                        <div class="save-btn pb-2 text-center">
                            <button *ngIf="!getDemographyForSampada1" [disabled]="disableSaveBttn"
                                class="btn btn-primary" (click)="emitDataEregModule()">

                                {{'property-identificationValuation.consSaveProLoc' | translate}}
                            </button>

                            <button *ngIf="getDemographyForSampada1" [disabled]="disableSaveBttn"
                                class="btn btn-primary" (click)="emitDataEregModule2()">

                                {{'property-identificationValuation.consSaveProLoc' | translate}}
                            </button>
                        </div>
                    </div>
                    <div  class="card-body pt-0 pb-0 px-3 badge-light" *ngIf="showChangeDemography">
                        <button class="filterClosebtn" (click)="closeChangeDemography()">X</button>
                        <comman-demography-hierarchy-load style="width: 100%;"
                            [componentFlag]="'columnwise'" [type_id]="type_id2"
                            [parent_id]="parent_id2" [lastdemograpgy_id]="15"
                            (loadgldata)="getDemography($event, true)" [showRular]="showRular"></comman-demography-hierarchy-load>
                        <div class="m-1" style="float:right;">
                            <button class="btn btn-primary" (click)="UpdateNewGuideline()">{{'confirm' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>


            <div [ngClass]=" flag ? 'col-md-12' : 'col-md-9'">
                <!-- <app-map
                    (MapButtonEvent)="receivedLatAndLngHandler($event)" (dataNotFound)="clearDataWhenDataNotFound()" [alertmessage]="alertmessage"></app-map> -->
                <div class="er-mapview " #ermapview>
                    <app-map (MapLoadEvent)="onLoadMap($event)"></app-map>
                    <div id="erModal" class="er-modal location-not-available" #infoContent>

                        <!-- Modal content -->
                        <div class="er-modal-content">
                            <div class="er-modal-body text-center">
                                <span class="er-close" (click)="closePopup()">&times;</span>
                                <fieldset class="legend-section location-not-available text-center">
                                    <h3> Precise location is not available for the search input parameter !!</h3>
                                    <p>Kindly select ward colony / village colony and pin your property address /
                                        location.</p>
                                    <p>OR</p>
                                    <p>Pin your property address / location on the map</p>
                                    <p>OR</p>
                                    <p>Search your property address / location by Address free text search</p>
                                    <p>OR</p>
                                    <p>Enter SAMPADA Geo Tag Id and submit</p>
                                    <p>OR</p>
                                    <p>Share your property address / location via WhatsApp</p>
                                </fieldset>
                                <button type="button" id="submitBtn" (click)="closePopup()"
                                    class="btn btn-primary mb-1">OK</button>
                            </div>

                        </div>

                    </div>

                    <div id="erNotFoundGuidelineModal" class="er-modal location-not-available"
                        #erNotFoundGuidelineModal>

                        <!-- Modal content -->
                        <div class="er-modal-content">
                            <div class="er-modal-body text-center">
                                <span class="er-close" (click)="hideErNotFoundGuidelinePopup()">&times;</span>
                                <fieldset class="legend-section text-center">
                                    <p>
                                        {{'property-valuation.searchedkhasrapreciselocation' | translate}}
                                    </p>

                                </fieldset>
                                <button type="button" id="submitBtn" (click)="hideErNotFoundGuidelinePopup()"
                                    class="btn btn-primary mb-1">
                                    {{ "property-identificationValuation.oK" | translate}}
                                </button>
                            </div>

                        </div>

                    </div>

                    <div id="erConfModal" class="er-modal location-not-available" #confContent>

                        <!-- Modal content -->
                        <div class="er-modal-content conf-modal-content">
                            <!-- <div class="er-modal-header">
                                <span class="er-close" (click)="closeConfPopup()">&times;</span>
                            </div> -->
                            <div class="er-modal-body text-center">
                                <span class="er-close" (click)="closeConfPopup(false)">&times;</span>
                                <fieldset class="legend-section location-not-available text-center">
                                    <div class="d-flex justify-content-between">
                                        <div class="icon">
                                            <mat-icon>help</mat-icon>
                                        </div>
                                        <div class="detail-content">

                                            <p>
                                                {{ "property-identificationValuation.locIdenti" | translate}}
                                                {{fullGeoAddress}}
                                            </p>
                                            <P>{{ "property-identificationValuation.changeProLoc" | translate}}

                                            </P>
                                            <P>

                                                {{ "property-identificationValuation.latLongcapt" | translate}}
                                            </P>
                                        </div>

                                    </div>
                                </fieldset>
                                <button type="button" (click)="closeConfPopup(false)" class="btn btn-info mb-1"
                                    style="margin-right: 10px;">{{ "cancel" | translate}} </button>
                                <button type="button" id="submitBtn" (click)="closeConfPopup(true)"
                                    class="btn btn-primary mb-1"> {{ "property-identificationValuation.oK" |
                                    translate}}</button>
                            </div>

                        </div>

                    </div>




                    <div id="k-dragablediv"
                        *ngIf="agriPropertyValuationRequestBean?.webGisResponseBeanDataArr?.length>0 || prndData || mpenagarpalikamodel?.propertyOwner?.length>0 || mpenagarpalikamodel?.propertyNo">
                        <!-- <div id="k-dragableheader" class="bg-primary text-white">Click here to move Draggable element</div> -->
                        <div id="k-tableFixHead">
                            <table class="table table-bordered m-0"
                                *ngIf="agriPropertyValuationRequestBean?.webGisResponseBeanDataArr?.length>0">
                                <thead class="thead-dark">
                                    <tr>
                                        <th scope="col">{{'property-valuation.Land-Parecel-Id' | translate}}</th>
                                        <th scope="col">{{'property-valuation.ULPIN-id' | translate}} </th>
                                        <th scope="col"> <strong>
                                                <ng-container *ngIf="isPlot else spa">
                                                    <span>
                                                        {{lan==0?'Plot No.':'प्लॉट नंबर'}}
                                                    </span>
                                                </ng-container>
                                                <ng-template #spa>
                                                    {{lan==0?'Khasra No.':'खसरा नंबर'}}
                                                </ng-template>
                                            </strong></th>
                                        <th scope="col">{{'ereg-property-details.action' | translate}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container
                                        *ngFor="let row of agriPropertyValuationRequestBean.webGisResponseBeanDataArr; let i = index">
                                        <tr *ngIf="row.latitude && row.longitude">
                                            <td>{{ row.landParcelId }}</td>
                                            <td>{{ row.ULPin }}</td>
                                            <td>{{ row.surveyNo }}</td>
                                            <td [hidden]="true">{{ getPlotLabelchange(row.khasraType) }}</td>
                                            <td>
                                                <button [disabled]="row.ideStatus" type="button"
                                                    class="btn btn-primary btn-sm " aria-label="Close"
                                                    (click)="removeAddedKhasra(row, i)">
                                                    <mat-icon class="ionn">delete_outline</mat-icon>
                                                </button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                            <table class="table table-bordered m-0" *ngIf="prndData">
                                <thead class="thead-dark">
                                    <tr>
                                        <th scope="col">{{'property-valuation.Panchayat-ID' | translate}}</th>
                                        <th scope="col">{{'ereg-property-details.khasraNo' | translate}}</th>
                                        <th scope="col">{{'property-identificationValuation.property_Address' |
                                            translate}} </th>
                                        <th scope="col">{{'ereg-property-details.action' | translate}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngIf="prndData?.latitude && prndData?.longitude">
                                        <td>{{ prndData.propertyId }}</td>
                                        <td>{{ prndData.khasraNo }}</td>
                                        <td>{{ prndData.property_Address }}</td>
                                        <td>
                                            <button type="button" class="btn btn-primary btn-sm " aria-label="Close"
                                                (click)="removeAddedPunchayatProperty()">
                                                <mat-icon class="ionn">delete_outline</mat-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="table table-bordered m-0"
                                *ngIf="mpenagarpalikamodel?.propertyOwner?.length>0 || mpenagarpalikamodel?.propertyNo">
                                <thead class="thead-dark">
                                    <tr>
                                        <th scope="col">{{'property-valuation.Property-ID' | translate}}</th>
                                        <th scope="col">{{ "property-valuation.Owner" | translate }}</th>
                                        <!-- <th scope="col">Property Address </th> -->
                                        <th scope="col">{{'ereg-property-details.action' | translate}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngIf="mpenagarpalikamodel?.latitude && mpenagarpalikamodel?.longitude">
                                        <td>{{ mpenagarpalikamodel.propertyNo }}</td>
                                        <td>{{ mpenagarpalikamodel.name }}</td>
                                        <!-- <td>{{ prndData.property_Address }}</td> -->
                                        <td>
                                            <button type="button" class="btn btn-primary btn-sm " aria-label="Close"
                                                (click)="removeAddedProperty()">
                                                <mat-icon class="ionn">delete_outline</mat-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p class="ali-typgrap"><b>{{'ereg-property-details.note' | translate}} </b> :
                            {{'property-valuation.dragDropPin' | translate}}</p>

                    </div>






                </div>


            </div>
        </div>
    </div>


 <div *ngIf="!(agriPropertyValuationRequestBean?.webGisResponseBeanDataArr?.length>0 || prndData || mpenagarpalikamodel?.propertyOwner?.length>0 || mpenagarpalikamodel?.propertyNo)"> 
 <div class="information_note" *ngIf="filterType=='khasraid' || 'propid' || 'panchayatid'">
   
    <b>{{'legacyDocumentRequest.note' | translate}} </b>
    <ul>
        <li *ngIf="filterType=='khasraid'">
            <div *ngIf="lan==0">
                <b> Agriculture Property ID :</b>     <u (click)="openKharsaFormat(KharsaFormat)">
            <b class="ml-2"><em> Click to Check khasra Format</em></b>
          </u> 
            <ol>
                <li>
                 If you don't have the agricultural land parcel ID or ULPIN, click on the link 
                 <a href="https://mpbhulekh.gov.in/mpbhulekh.do" target="_blank"><mark> 
                    https://mpbhulekh.gov.in/mpbhulekh.do</mark></a> 
                    where you can retrieve the Land Parcel ID / ULPIN and also generate a Premutation ID.
                </li>
                <li> In the Kharsa Format 1, to obtain the Agriculture Land Parcel ID or ULPIN, 
                    kindly refer to the highlighted portion.
                </li>
            </ol>
            </div>
            <div *ngIf="lan==1">
                <b>कृषि संपत्ति आई डी :</b>     <u  (click)="openKharsaFormat(KharsaFormat)">
            <b class="ml-2"><em>खसरा प्रारूप जांचने के लिए क्लिक करें</em></b>
          </u> 
            <ol>
                <li> यदि आपके पास कृषि भूमि पार्सल आईडी या यूएलपीआईएन नहीं है तो
                     <a href="https://mpbhulekh.gov.in/mpbhulekh.do" target="_blank"><mark>  
                        https://mpbhulekh.gov.in/mpbhulekh.do </mark></a> 
                        लिंक पर क्लिक करें, जहां आप लैंड पार्सल आईडी / यूएलपीआईएन प्राप्त कर सकते हैं और प्रीम्यूटेशन आईडी भी बना सकते हैं।
 
                </li>
                <li>  खसरा प्ररूप 1 में, कृषि भूमि पार्सल आईडी या यूएलपीआईएन प्राप्त करने के लिए, कृपया हाइलाइट किए गए भाग को देखें।

                </li>
            </ol>
            </div>
       

           <ng-template #KharsaFormat let-c="close" let-d="dismiss">
    <h2 matDialogTitle class="Consenterdialog-title"> </h2>
    <button type="button" class="colsebtn" aria-label="Close" (click)="d('Cross click');closeAlert()" style="top: 0">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body pb-0">
        <img src="assets/images/KhasraFormat.png" style="width: 100%;">
    </div>
    </ng-template>
          
        </li>
        <li *ngIf="filterType=='propid'">
       <div *ngIf="lan==0">
                <b>Municipal Property ID:</b>
            <ol>
                <li>
                 For the Bhopal Nagar Nigam: if you don't know the property ID or your property ID is not yet generated, click on the following link: 
                 <a href="https://www.bmconline.gov.in/irj/portal/anonymous/ProRelServices" target="_blank"><mark> 
                   https://www.bmconline.gov.in/irj/portal/anonymous/ProRelServices</mark></a>  
                   where you can either search/retrieve the property ID or generate a temporary property ID.
                </li>
                <li>
                Other than for the Bhopal Nagar Nigam: if you don't know the Municipal property ID, click on the following link: 
                 <a href="https://mpenagarpalika.gov.in:8005/sap/bc/webdynpro/sap/zpt_existing_down#" target="_blank"><mark> 
                   https://mpenagarpalika.gov.in:8005/sap/bc/webdynpro/sap/zpt_existing_down#</mark></a>  
                  where you can search/retrieve the property ID.
                </li>
                <li> Other than for the Bhopal Nagar Nigam: if your property ID is not yet generated, click on the following link: 
                 <a href="https://www.mpenagarpalika.gov.in" target="_blank"><mark>https://www.mpenagarpalika.gov.in</mark></a>  
                  then log in or sign up on the portal. After logging in, click on 'Property Tax Dashboard' and select 'Create Temporary Property ID for Registry'.
                </li>
                
            </ol>
            </div>
            <div *ngIf="lan==1">
                <b>म्युनिसिपल संपत्ति आईडी:</b>
              <ol>
                <li> भोपाल नगर निगम: यदि आप संपत्ति आईडी नहीं जानते हैं या आपकी संपत्ति आईडी अभी तक जेनरेट नहीं हुई है, तो लिंक 
                 <a href="https://www.bmconline.gov.in/irj/portal/anonymous/ProRelServices" target="_blank"><mark> 
                   https://www.bmconline.gov.in/irj/portal/anonymous/ProRelServices</mark></a>  
                   पर क्लिक करें, जहां आप या तो संपत्ति आईडी खोज/पुनर्प्राप्त कर सकते हैं या एक अस्थायी संपत्ति आईडी बना सकते हैं।
                </li>
                <li> भोपाल नगर निगम के अलावा: यदि आप संपत्ति आईडी नहीं जानते है, तो लिंक 
                 <a href="https://mpenagarpalika.gov.in:8005/sap/bc/webdynpro/sap/zpt_existing_down#" target="_blank"><mark> 
                   https://mpenagarpalika.gov.in:8005/sap/bc/webdynpro/sap/zpt_existing_down#</mark></a>  
                 पर क्लिक करें, जहां आप संपत्ति आईडी खोज/पुनर्प्राप्त कर सकते हैं।
                </li>
                <li> भोपाल नगर निगम के अलावा: यदि आपकी संपत्ति आईडी अभी तक जनरेट नहीं हुई है, तो लिंक 
                 <a href="https://www.mpenagarpalika.gov.in" target="_blank"><mark>https://www.mpenagarpalika.gov.in</mark></a>  
                 पर क्लिक करें, फिर पोर्टल पर लॉग इन या साइन अप करें। लॉग इन करने के बाद 'प्रॉपर्टी टैक्स डैशबोर्ड' पर क्लिक करें और 'रजिस्ट्री के लिए अस्थायी प्रॉपर्टी आईडी बनाएं' चुनें।
                </li>
                
            </ol>
            </div>
           








        </li>
        <li *ngIf="filterType=='panchayatid'">
            <div *ngIf="lan==0">
                <b>Panchayat Property ID:</b>
            <ol>
                <li> If you don't have the panchayat property ID, then click on the link: 
                 <a href="https://mppanchayatdarpan.gov.in/Public/Report_Section/Search_Property_And_Tax_Details.aspx" target="_blank"><mark> 
                   https://mppanchayatdarpan.gov.in/Public/Report_Section/Search_Property_And_Tax_Details.aspx</mark></a>  
                  where you can retrieve the property ID and also generate a temporary property ID
                </li>
                  
            </ol>
            </div>
            <div *ngIf="lan==1">
                <b>पंचायत संपत्ति आईडी:</b>
            <ol>
                <li> यदि आपके पास पंचायत संपत्ति आईडी नहीं है तो
                 <a href="https://mppanchayatdarpan.gov.in/Public/Report_Section/Search_Property_And_Tax_Details.aspx" target="_blank"><mark> 
                   https://mppanchayatdarpan.gov.in/Public/Report_Section/Search_Property_And_Tax_Details.aspx</mark></a>  
                   लिंक पर क्लिक करें, जहां आप संपत्ति आईडी प्राप्त कर सकते हैं।
                </li>
                  
            </ol>
            </div>
        </li>
      
    </ul>
 </div>
</div>



</div>

<ng-template #propertySelectionModal>

    <button mat-button  color="primary" class="colsebtn" (click)="closeModal2()">
        <em class="fa fa-times"></em></button>

    <h2 matDialogTitle class="consenterdialog-title">
        {{ "property-identificationValuation.selProp" | translate}}
    </h2>
    <mat-dialog-content class="otpverification">
        <fieldset class="legend-section">
            <div class="row">
                <div class="col-lg-12">
                    <div class="form-group">
                        <label> {{ "property-identificationValuation.selProp" | translate}} </label>
                        <select class="form-control" name="uaddpropId" [(ngModel)]="uaddpropId"
                            (ngModelChange)="getUaddObjectByEnpId($event)">
                            <option selected disabled>{{ "property-identificationValuation.selPropDel" | translate}}
                            </option>
                            <option *ngFor="let uadd of uaddResponseModel" [value]="uadd.enp_prop_id"
                                [disabled]="uadd.enp_prop_id==null">
                                {{uadd.enp_prop_id}},{{uadd.own_nm_e}},{{uadd.ward_nm}}</option>

                        </select>
                    </div>
                </div>

            </div>


        </fieldset>
    </mat-dialog-content>
</ng-template>







<ng-template class="modal-lg" #ViewPropertyDetail let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="Consenterdialog-title" id="modal-primary-title"> {{'property-valuation.Property-Details'| translate}}
        </h4>
        <button type="button" class="colsebtn" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <div class="row">



            <div class="col-md-12" *ngIf="isEnagarPalika">
                <fieldset class="legend-section">
                    <div class="info-list">
                        <ul class="p-0">
                            <li>
                                <strong>
                                    <span> {{'property-valuation.Total-Area1' | translate}}: </span>
                                </strong>
                                <span>{{mpenagarpalikamodel.totalArea}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'property-valuation.area-type' | translate}}:</span>
                                </strong>
                                <span> {{mpenagarpalikamodel.areaType}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'property-valuation.Unit' | translate}}:</span>
                                </strong>
                                <span> {{mpenagarpalikamodel.unit}} </span>
                            </li>

                        </ul>
                        <!---->
                    </div>
                </fieldset>
            </div>
            <div class="col-md-12" *ngIf="isPnrd">
                <fieldset class="legend-section">
                    <div class="info-list">
                        <ul class="p-0">
                            <li>
                                <strong>
                                    <span> {{'property-valuation.Total-Area1' | translate}}: </span>
                                </strong>
                                <span>{{prndData.totalArea}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'property-valuation.area-type' | translate}}:</span>
                                </strong>
                                <span> {{prndData.AreaType}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>
                                        {{'ereg-property-details.UniProId' | translate}}:
                                    </span>
                                </strong>
                                <span> {{prndData.unique_Property_ID}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'ereg-property-details.propertyId' | translate}}:</span>
                                </strong>
                                <span> {{prndData.propertyId}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'property-identificationValuation.proTypeID' | translate}}:</span>
                                </strong>
                                <span> {{prndData.propertyTypeId}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'property-identificationValuation.proTypeID' | translate}}:</span>
                                </strong>
                                <span> {{prndData.propertyTypeId==1?"PLOT":"BUILDING"}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'property-identificationValuation.appliID' | translate}}:</span>
                                </strong>
                                <span> {{prndData.applicationNo}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'property-identificationValuation.houseNo' | translate}}:</span>
                                </strong>
                                <span> {{prndData.houseNo}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'property-identificationValuation.distName' | translate}}:</span>
                                </strong>
                                <span> {{prndData.districtName}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'property-identificationValuation.localbodyNo' | translate}}:</span>
                                </strong>
                                <span> {{prndData.localBody}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'property-identificationValuation.noOfFloor' | translate}}:</span>
                                </strong>
                                <span> {{prndData.noOfFloor}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'property-identificationValuation.gramPanchayat' | translate}}:</span>
                                </strong>
                                <span> {{prndData.gramPanchayat}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'property-identificationValuation.pincode' | translate}}:</span>
                                </strong>
                                <span> {{prndData.pincode}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'property-identificationValuation.wardName' | translate}}:</span>
                                </strong>
                                <span> {{prndData.wardName}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'property-identificationValuation.wardName' | translate}}:</span>
                                </strong>
                                <span> {{prndData.wardNo}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'property-identificationValuation.wardName' | translate}} :</span>
                                </strong>
                                <span> {{prndData.wardName}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'property-identificationValuation.wardName' | translate}}:</span>
                                </strong>
                                <span> {{prndData.wardNo}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'property-identificationValuation.village' | translate}} :</span>
                                </strong>
                                <span> {{prndData.village}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'property-identificationValuation.village' | translate}}:</span>
                                </strong>
                                <span> {{prndData.villageCode}} </span>
                            </li>


                            <li>
                                <strong>
                                    <span> {{'property-identificationValuation.property_Address' | translate}}:</span>
                                </strong>
                                <span> {{prndData.property_Address}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span> {{'property-identificationValuation.propertyStatus' | translate}}:</span>
                                </strong>
                                <span> {{prndData.propertyStatus}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span> {{'property-identificationValuation.propertyValue' | translate}}:</span>
                                </strong>
                                <span> {{prndData.propertyValue}} </span>
                            </li>

                            <li>
                                <strong>
                                    <span> {{'property-identificationValuation.eKYCDate' | translate}}:</span>
                                </strong>
                                <span> {{prndData.eKYCDate}} </span>
                            </li>
                            <li>
                                <strong>
                                    <span>{{'property-identificationValuation.eKYCStatus' | translate}}:</span>
                                </strong>
                                <span> {{prndData.eKYCStatus}} </span>
                            </li>

                        </ul>
                        <!---->
                    </div>
                </fieldset>
            </div>


            <div class="col-md-12" *ngIf="isWebGisData">
                <fieldset class="legend-section">
                    <div class="info-list">
                        <ul class="p-0">

                            <li>
                                <strong>
                                    <span>
                                        {{'property-valuation.Total-Area1' | translate}}: </span>
                                </strong>
                                <span>
                                    {{agriPropertyValuationRequestBean?.totalArea}}</span>
                            </li>

                        </ul>
                    </div>
                </fieldset>
                <div class="legend-section" *ngFor="let webGis of webGisResponseDataArr">
                    <fieldset class="legend-section">
                        <legend class="sidebar__widget-title">
                            {{'property-valuation.Property-Details'| translate}}

                        </legend>

                        <div class="info-list">
                            <ul class="p-0">
                                <li>
                                    <strong>
                                        <span>
                                            {{'property-valuation.Land-Parcel' | translate}}: </span>
                                    </strong>
                                    <span>
                                        {{webGis.landParcelId}} </span>
                                </li>
                                <li>
                                    <strong>
                                        <span>
                                            {{'property-valuation.ULPIN' | translate}}:</span>
                                    </strong>
                                    <span>
                                        {{webGis.ULPin}} </span>
                                </li>
                                <li>
                                    <strong>
                                        <ng-container *ngIf="webGis.khasraType=='P' else spa">
                                            <span>
                                                {{lan==0?'Plot No.':'प्लॉट नंबर'}}
                                            </span>
                                        </ng-container>
                                        <ng-template #spa>
                                            {{lan==0?'Khasra No.':'खसरा नंबर'}}
                                        </ng-template>
                                    </strong>
                                    <span>
                                        {{webGis.surveyNo}} </span>
                                </li>

                                <li>
                                    <strong>
                                        <span>
                                            {{'property-valuation.Is-Irrigated' | translate}}: </span>
                                    </strong>
                                    <span>
                                        {{webGis.isIrrigated=='Y'?lan==0?'YES':'हाँ':lan==1?'NO':'नहीं'}} </span>
                                </li>
                                <li>
                                    <strong>
                                        <span>
                                            {{'property-valuation.Total-Area1' | translate}}: </span>
                                    </strong>
                                    <span>
                                        {{webGis.totalArea}}</span>
                                </li>
                                <li>
                                    <strong>
                                        <span>
                                            {{'property-valuation.Unit' | translate}}: </span>
                                    </strong>
                                    <span>
                                        {{lan==0?getUnitMea(webGis.unit):getUnitMeaHi(webGis.unit)}}</span>
                                </li>
                                <li>
                                    <strong>
                                        <span>
                                            {{'property-valuation.Usage' | translate}}: </span>
                                    </strong>
                                    <span>
                                        {{webGis.usage=='diverted'?lan==0?'diverted':'व्यपवर्तित':lan==0?'undiverted':'अव्यपवर्तित'}}
                                    </span>
                                </li>

                                <li>
                                    <strong>
                                        <span>
                                            {{'property-valuation.khasraType' | translate}}: </span>
                                    </strong>
                                    <span>
                                        {{webGis.khasraType}} </span>
                                </li>
                                <li>
                                    <strong>
                                        <span>
                                            {{'property-valuation.Land-Use-Type' | translate}}: </span>
                                    </strong>
                                    <span>
                                        {{webGis.landUseType}}</span>
                                </li>

                                <li>
                                    <strong>
                                        <span>
                                            {{'property-valuation.Land-Type-Name:' | translate}} :</span>
                                    </strong>
                                    <span>
                                        {{webGis.landTypeName}} </span>
                                </li>
                                <!-- <li>
                                            <strong>
                                                <span>
                                                   {{'e-stamp.Remarks' | translate}} </span>
                                            </strong>
                                            <span>
                                                {{webGis.remarks}} </span>
                                        </li> -->

                            </ul>

                            <div class="w-remark">
                                <strong>
                                    <span>
                                        {{'e-stamp.Remarks' | translate}}
                                    </span>
                                </strong>
                                <span> {{webGis.remarks}}
                                </span>
                            </div>

                            <div class="w-remark">
                                <strong>
                                    <span>
                                        {{"e-registry-completion.mortageRemarks" | translate}}
                                    </span>
                                </strong>
                                <span> {{webGis.mortageRemarks}}
                                </span>
                            </div>
                        </div>
                    </fieldset>



                    <fieldset class="legend-section">
                        <legend class="sidebar__widget-title">
                            {{ "property-valuation.Owner-Details" | translate }}

                        </legend>
                        <div class="table-responsive shadow-none">
                            <table class="table table-bordered">
                                <thead class="thead-dark">
                                    <tr>
                                        <th>{{ "no" | translate }}</th>
                                        <th>
                                            {{ "property-valuation.Owner" | translate }}
                                        </th>
                                        <th> {{ "property-valuation.Name" | translate }}</th>
                                        <th>
                                            {{
                                            "property-valuation.Father-Name" | translate
                                            }}
                                        </th>
                                        <th>
                                            {{ "address" | translate }}
                                        </th>
                                        <th>
                                            {{
                                            "property-valuation.Owner-Caste" | translate
                                            }}
                                        </th>
                                        <th>
                                            {{
                                            "property-valuation.OwnerShip-Type" | translate
                                            }}
                                        </th>
                                        <th>
                                            {{
                                            "property-valuation.Owner-Share" | translate
                                            }}
                                        </th>

                                    </tr>

                                </thead>
                                <tbody>
                                    <tr *ngFor="let owner of webGis.propertyOwner; index as i">
                                        <td>
                                            {{i+1}}
                                        </td>
                                        <td>
                                            {{ owner?.ownerName }}
                                        </td>
                                        <td>
                                            {{ owner?.ownerName }}
                                        </td>
                                        <td>
                                            {{owner?.fatherName}}
                                        </td>
                                        <td>
                                            {{
                                            owner?.address
                                            }}
                                        </td>
                                        <td>
                                            {{
                                            owner?.ownerCaste
                                            }}
                                        </td>
                                        <td>
                                            {{
                                            owner?.ownershiptype
                                            }}
                                        </td>
                                        <td>
                                            {{
                                            owner?.ownerShare
                                            }}
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </fieldset>



                </div>
            </div>

            <div class="col-md-12" *ngIf="isEnagarPalika||isPnrd">
                <fieldset class="legend-section">
                    <legend class="sidebar__widget-title">
                        {{ "property-valuation.Owner-Details" | translate }}

                    </legend>

                    <ul class="p-0" *ngFor="let owner of propertyOwner; index as i">
                        <li>
                            <strong><span> {{ "property-valuation.Owner" | translate }}</span> </strong>
                            <span>
                                {{ owner?.ownerName }}
                            </span>
                        </li>
                        <li>
                            <strong> <span>
                                    {{ "property-valuation.Name" | translate }}
                                </span> </strong>
                            <span>
                                {{ owner?.ownerName }}
                            </span>
                        </li>
                        <li>
                            <strong> <span>
                                    {{
                                    "property-valuation.Father-Name" | translate
                                    }}
                                </span> </strong>
                            <span>
                                {{owner?.fatherName}}
                            </span>
                        </li>
                        <li>
                            <strong> <span>
                                    {{ "address" | translate }}
                                </span> </strong>
                            <span>
                                {{
                                owner?.address
                                }}
                            </span>
                        </li>
                        <li>
                            <strong> <span>
                                    {{
                                    "property-valuation.Owner-Caste" | translate
                                    }}

                                </span> </strong>
                            <span>
                                {{
                                owner?.ownerCaste
                                }}
                            </span>
                        </li>
                        <li>
                            <strong> <span>
                                    {{
                                    "property-valuation.OwnerShip-Type" | translate
                                    }}
                                </span> </strong>
                            <span>
                                {{
                                owner?.ownershiptype
                                }}
                            </span>
                        </li>
                        <li>
                            <strong> <span>
                                    {{
                                    "property-valuation.Owner-Share" | translate
                                    }}
                                </span> </strong>
                            <span>
                                {{
                                owner?.ownerShare
                                }}
                            </span>
                        </li>

                    </ul>

                </fieldset>



            </div>







        </div>

    </div>
</ng-template>



<!-- <div class="modal right  fade leftbox_detail new_width" id="ViewGuidelineRates" data-backdrop="false"
          data-keyboard="false" tabindex="-1" aria-labelledby="ViewGuidelineRates" aria-hidden="true" cdkDrag> -->

<div class="modal right  fade leftbox_detail new_width" id="ViewGuidelineRates" data-backdrop="static"
    data-keyboard="false" tabindex="-1" aria-hidden="true" aria-labelledby="ViewGuidelineRates">
    <!-- <div class="modal-dialog float-right"> -->
    <div *ngIf="guildeLineObj" class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content loginoptionsmodal d-block">
            <div class="modal-header bg-theme p-0">
                <h3 class="text-light p-1 mb-1 font-weight-normal fixed-top1">
                    {{'property-valuation.GuildeLine-Rate-Details' | translate}} {{guildeLineObj?.financialYear}}
                    <button type="button" class="close closemapbtn text-white" data-dismiss="modal" aria-label="Close"
                        (click)="popucClose()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </h3>
            </div>

            <div class="modal-body p-2">
                <div class="row">
                    <div class="col-md-12">
                        <fieldset class="legend-section">
                            <legend class="sidebar__widget-title">
                                {{'property-valuation.ward_colony' | translate}}
                            </legend>
                            <div class="table-responsive shadow-none">
                                <table class="table table-bordered">
                                    <tr>
                                        <th>{{'property-valuation.District' |
                                            translate}}</th>
                                        <th>

                                            {{'property-valuation.Area-Type' |
                                            translate}}

                                        </th>
                                        <th
                                            *ngIf="selectedMstDemographyBean?.location?.tehsil?.demographyName!=null&&selectedMstDemographyBean?.location?.tehsil?.demographyName!=''">
                                            {{'property-valuation.Tehsil' |
                                            translate}}
                                        </th>
                                        <th
                                            *ngIf="selectedMstDemographyBean?.location?.localBody?.demographyName!=null&&selectedMstDemographyBean?.location?.localBody?.demographyName!=''">
                                            {{'property-valuation.localbody' |
                                            translate}}
                                        </th>
                                        <th
                                            *ngIf="selectedMstDemographyBean?.location?.ward?.demographyName!=null&&selectedMstDemographyBean?.location?.ward?.demographyName!=''">
                                            {{'property-valuation.Ward' | translate}}
                                        </th>
                                        <th
                                            *ngIf="selectedMstDemographyBean?.location?.village?.demographyName!=null&&selectedMstDemographyBean?.location?.village?.demographyName!=''">
                                            {{'property-valuation.Village' |
                                            translate}}
                                        </th>
                                        <th
                                            *ngIf="selectedMstDemographyBean?.location?.village_colony?.demographyName!=null&&selectedMstDemographyBean?.location?.village_colony?.demographyName!=''">
                                            {{'property-valuation.village_colony' |
                                            translate}}
                                        </th>

                                        <th
                                            *ngIf="selectedMstDemographyBean?.location?.ward_colony?.demographyName!=null&&selectedMstDemographyBean?.location?.ward_colony?.demographyName!=''">
                                            {{'property-valuation.ward_colony' |
                                            translate}}
                                        </th>


                                    </tr>

                                    <tr>
                                        <td>{{lan==0?selectedMstDemographyBean?.location?.district?.demographyNameEn:selectedMstDemographyBean?.location?.district?.demographyNameHi}}
                                        </td>
                                        <td>
                                            <ng-container
                                                *ngIf="selectedMstDemographyBean?.demographyType?.demographyAreaType=='R'">
                                                {{'property-valuation.Rural' | translate}}
                                            </ng-container>
                                            <ng-container
                                                *ngIf="selectedMstDemographyBean?.demographyType?.demographyAreaType=='U'">
                                                {{'property-valuation.Urban' | translate}}
                                            </ng-container>

                                        </td>
                                        <td
                                            *ngIf="selectedMstDemographyBean?.location?.tehsil?.demographyName!=null&&selectedMstDemographyBean?.location?.tehsil?.demographyName!=''">
                                            {{lan==0?selectedMstDemographyBean?.location?.tehsil?.demographyNameEn:selectedMstDemographyBean?.location?.tehsil?.demographyNameHi}}
                                        </td>
                                        <td
                                            *ngIf="selectedMstDemographyBean?.location?.localBody?.demographyName!=null&&selectedMstDemographyBean?.location?.localBody?.demographyName!=''">
                                            {{lan==0?selectedMstDemographyBean?.location?.localBody?.demographyNameEn:selectedMstDemographyBean?.location?.localBody?.demographyNameHi}}
                                        </td>


                                        <td
                                            *ngIf="selectedMstDemographyBean?.location?.ward?.demographyName!=null&&selectedMstDemographyBean?.location?.ward?.demographyName!=''">
                                            {{lan==0?selectedMstDemographyBean?.location?.ward?.demographyNameEn:selectedMstDemographyBean?.location?.ward?.demographyNameHi}}
                                        </td>


                                        <td
                                            *ngIf="selectedMstDemographyBean?.location?.village?.demographyName!=null&&selectedMstDemographyBean?.location?.village?.demographyName!=''">
                                            {{lan==0?selectedMstDemographyBean?.location?.village?.demographyNameEn:selectedMstDemographyBean?.location?.village?.demographyNameHi}}
                                        </td>

                                        <td
                                            *ngIf="selectedMstDemographyBean?.location?.village_colony?.demographyName!=null&&selectedMstDemographyBean?.location?.village_colony?.demographyName!=''">
                                            {{lan==0?selectedMstDemographyBean?.location?.village_colony?.demographyNameEn:selectedMstDemographyBean?.location?.village_colony?.demographyNameHi}}



                                            <ng-container class="text-theme">
                                                {{lan==0?selectedMstDemographyBean?.ulbTypeNmEn:selectedMstDemographyBean?.ulbTypeNmHi}}
                                            </ng-container>
                                            <ng-container class="text-theme">
                                                {{lan==0?selectedMstDemographyBean?.isPlanningAreaNmEn:selectedMstDemographyBean?.isPlanningAreaNmHi}}
                                            </ng-container>
                                            <ng-container class="text-theme">
                                                {{selectedMstDemographyBean?.upBandhDesc}}
                                            </ng-container>

                                        </td>
                                        <td
                                            *ngIf="selectedMstDemographyBean?.location?.ward_colony?.demographyName!=null&&selectedMstDemographyBean?.location?.ward_colony?.demographyName!=''">
                                            {{lan==0?selectedMstDemographyBean?.location?.ward_colony?.demographyNameEn:selectedMstDemographyBean?.location?.ward_colony?.demographyNameHi}}

                                            <ng-container *ngIf="selectedMstDemographyBean.ulbType>0">
                                                {{lan==0?selectedMstDemographyBean?.ulbTypeNmEn:selectedMstDemographyBean?.ulbTypeNmHi}}
                                            </ng-container>

                                            <ng-container *ngIf="selectedMstDemographyBean.isPlanningArea>0">
                                                {{lan==0?selectedMstDemographyBean?.isPlanningAreaNmEn:selectedMstDemographyBean?.isPlanningAreaNmHi}}
                                            </ng-container>
                                            <ng-container class="text-theme">
                                                {{selectedMstDemographyBean?.upBandhDesc}}
                                            </ng-container>

                                        </td>



                                    </tr>




                                </table>
                            </div>
                        </fieldset>
                    </div>
                    <ng-container *ngIf="guildeLineObj!=null&&guildeLineObj!=undefined else elseguildeLineObj">
                        <div class="col-md-6">
                            <fieldset class="legend-section">
                                <legend class="sidebar__widget-title">
                                    {{'guideline.plot' | translate}} {{'guideline.sqm' |
                                    translate}}
                                    <span class="text-right fs1" *ngIf="guildeLineObj?.isVishistGram!=0">
                                        {{'property-valuation.is-Vishist-Gram' |
                                        translate}}
                                        {{'guideline.sqm' |
                                        translate}}

                                        <i class="fa fa-check" aria-hidden="true"></i>
                                    </span>
                                    <!-- <input _ngcontent-mpo-c212="" type="checkbox"
                                                          formcontrolname="isVishistGram" id="isVishistGram"
                                                          class="form-check-input"> -->
                                </legend>
                                <div class=" table-responsive shadow-none">
                                    <table class="table table-bordered">
                                        <tr>
                                            <th>{{'guideline.resi' | translate}}</th>
                                            <th>{{'guideline.comm' | translate}}</th>
                                            <th>{{'guideline.ind' | translate}}</th>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;{{guildeLineObj?.ratePlotResidential |amount}}</td>
                                            <td>&nbsp;{{guildeLineObj?.ratePlotCommercial|amount}}</td>
                                            <td>&nbsp;{{guildeLineObj?.ratePlotIndustrial |amount}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </fieldset>
                        </div>
                        <div class="col-md-6">
                            <fieldset class="legend-section">
                                <legend class="sidebar__widget-title">
                                    {{'guideline.buil-resi' | translate}} {{'guideline.sqm' |
                                    translate}}
                                </legend>
                                <div class=" table-responsive shadow-none">
                                    <table class="table table-bordered">
                                        <tr>
                                            <th>{{'guideline.rcc' | translate}} </th>
                                            <th>{{'guideline.rbc' | translate}} </th>
                                            <th> {{'guideline.tin_shade' | translate}}</th>
                                            <th> {{'guideline.kaccha_abelu' | translate}}</th>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;{{guildeLineObj?.rateBuildingRcc |amount}}</td>
                                            <td>&nbsp;{{guildeLineObj?.rateBuildingRbc |amount}}</td>
                                            <td>&nbsp;{{guildeLineObj?.rateBuildingTShade|amount}}</td>
                                            <td>&nbsp;{{guildeLineObj?.rateBuildingKabelu|amount}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </fieldset>
                        </div>
                        <div class="col-md-6">
                            <fieldset class="legend-section">
                                <legend class="sidebar__widget-title">
                                    {{'guideline.buil-comm' | translate}} {{'guideline.sqm' | translate}}
                                </legend>
                                <div class=" table-responsive shadow-none">
                                    <table class="table table-bordered">
                                        <tr>
                                            <th> {{'guideline.Shop' | translate}}</th>
                                            <th> {{'guideline.office' | translate}}</th>
                                            <th>{{'guideline.godown' | translate}}</th>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;{{guildeLineObj?.rateBuildingShop|amount}}</td>
                                            <td>&nbsp;{{guildeLineObj?.rateBuildingOffice|amount}}</td>
                                            <td>&nbsp;{{guildeLineObj?.rateBuildingGodown|amount}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </fieldset>
                        </div>
                        <div class="col-md-6">
                            <fieldset class="legend-section">
                                <legend class="sidebar__widget-title">
                                    {{'guideline.buil-multi' | translate}} {{'guideline.sqm' |
                                    translate}}
                                </legend>
                                <div class=" table-responsive shadow-none">
                                    <table class="table table-bordered">
                                        <tr>
                                            <th>{{'guideline.resi' | translate}}</th>
                                            <th>{{'guideline.comm' | translate}}</th>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;{{guildeLineObj?.rateBuildingMultiResi|amount}}</td>
                                            <td>&nbsp;{{guildeLineObj?.rateBuildingMultiComm |amount}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </fieldset>
                        </div>
                        <div class="col-md-6">
                            <fieldset class="legend-section">
                                <legend class="sidebar__widget-title">
                                    {{'guideline.agri-land' | translate}} {{'guideline.hectare' |
                                    translate}}
                                </legend>
                                <div class=" table-responsive shadow-none">
                                    <table class="table table-bordered">
                                        <tr>
                                            <th> {{'guideline.irgd' | translate}}</th>
                                            <th>{{'guideline.unirgd' | translate}}</th>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;{{guildeLineObj?.rateAgriIrregated |amount}}</td>
                                            <td>&nbsp;{{guildeLineObj?.rateAgriUnirregated |amount}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </fieldset>
                        </div>
                        <div class="col-md-6">
                            <fieldset class="legend-section">
                                <legend class="sidebar__widget-title">
                                    {{'guideline.agri-plot' | translate}} {{'guideline.sqm' |
                                    translate}}
                                </legend>
                                <div class=" table-responsive shadow-none">
                                    <table class="table table-bordered">
                                        <tr>
                                            <th>{{'guideline.sub-clause-resi' | translate}}</th>
                                            <th>{{'guideline.sub-clause-comm' | translate}}</th>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;{{guildeLineObj?.rateLandUptoResi |amount}}</td>
                                            <td>&nbsp;{{guildeLineObj?.rateLandUptoComm |amount}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </fieldset>
                        </div>
                    </ng-container>
                    <ng-template #elseguildeLineObj>
                        <div class="col-md-12 text-center">
                            <b> {{'No-data-found' | translate}}</b>
                        </div>
                    </ng-template>
                </div>

                <!-- <div class="col-md-12 p-0 fl-fd"> -->

                <!-- <div class="col-md-12">{{'guideline.plot' | translate}}{{'guideline.sqm' | translate}}is
                              Vishist Gram <input _ngcontent-mpo-c212="" type="checkbox"
                                  formcontrolname="isVishistGram" id="isVishistGram" class="form-check-input"></div> -->

                <!-- <table class="p-0 w-100 table fl-table"> -->




                <!-- </div> -->


            </div>
        </div>
    </div><!-- <button>View Guideline Rates</button> -->
    <!-- <button mat-raised-button (click)="openDialog()">Pick one</button> -->
</div>


<!-------modal---------->



<ng-template #holdsuccessfullyMessage let-c="close" let-d="dismiss">
    <h2 matDialogTitle class="Consenterdialog-title">
        {{'ereg-property-details.familyCoOwner' | translate}}
    </h2>
    <!--<button type="button" class="colsebtn" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>-->
    <div class="modal-body pt-0 pb-0">
        <fieldset class="legend-section">
            <div class="form-group">
                <div class="row">
                    <label class="col-md-8 mb-0">
                        {{'ereg-property-details.co-owner' | translate}}
                    </label>
                    <div class="col-md-auto">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="isCoOwner" id="isCoOwner1"
                                [(ngModel)]="agriPropertyValuationRequestBean.isCoOwner" [value]=true
                                (ngModelChange)="isCoowner=false">
                            <label class="form-check-label" for="isCoOwner1">{{"yes" | translate}}</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="isCoOwner" id="isCoOwner2"
                                [(ngModel)]="agriPropertyValuationRequestBean.isCoOwner" value=false
                                (ngModelChange)="agriPropertyValuationRequestBean.isCoOwner=false;isCoowner=true">
                            <label class="form-check-label" for="isCoOwner2">{{"No" | translate}}</label>
                        </div>
                    </div>
                </div>

            </div>

            <div class="form-group" *ngIf="isCoowner">
                <div class="row">
                    <label class="col-md-8 mb-0">
                        {{'ereg-property-details.samefamil' | translate}}
                    </label>
                    <div class="col-md-auto ">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="isOwnerSameFamily"
                                id="isOwnerSameFamily1" [(ngModel)]="agriPropertyValuationRequestBean.isOwnerSameFamily"
                                [value]=true>
                            <label class="form-check-label" for="isOwnerSameFamily1">{{"yes" | translate}}</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="isOwnerSameFamily"
                                id="isOwnerSameFamily2" [(ngModel)]="agriPropertyValuationRequestBean.isOwnerSameFamily"
                                (ngModelChange)="agriPropertyValuationRequestBean.isOwnerSameFamily=false" value=false>
                            <label class="form-check-label" for="isOwnerSameFamily2">{{"No" | translate}}</label>
                        </div>
                    </div>
                </div>
            </div>

        </fieldset>

        <div class="form-group text-right mb-2 mt-2">
            <button class="btn btn-primary" (click)="showAlert()">{{"eRegistry-declaration.okbtn" |
                translate}}</button>
        </div>
    </div>
</ng-template>

<ng-template #Adjacent let-c="close" let-d="dismiss">
    <h2 matDialogTitle class="Consenterdialog-title">{{"property-identificationValuation.Alert"|translate}}!</h2>
    <button type="button" class="colsebtn" aria-label="Close" (click)="d('Cross click');closeAlert()" style="top: 0">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body pb-0">

        <p class="text-left mb-1">

            {{"property-identificationValuation.ifMulKhasa"|translate}}

        </p>
        <ul class="mt-3 listnote">
            <li>{{"property-identificationValuation.AllKhasraland"|translate}} </li>
            <li>{{"property-identificationValuation.EitherKharsa"|translate}}</li>
        </ul>

        <div class="form-group text-right">
            <button class="btn btn-primary" (click)="closeAlert()">
                {{ "eRegistry-declaration.okbtn" | translate }}
            </button>
        </div>
    </div>
</ng-template>














<ngx-ui-loader [loaderId]="'loader-identification'"></ngx-ui-loader>