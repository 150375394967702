<div class="main_ordering">

    <section class="smpdSection mobile_ordering-2">
        <div class="container">
            <div class="row">

                <div class="col-xl-12 col-lg-12 col-sm-12  mobile_ordering-2">
                    <div class="row pading-section">
                        <div class="col-lg-6 col-sm-6">
                            <div class="smpd-left">
                                <h3 *ngIf="lan==0">
                                    <small>Process of </small>
                                    <span class="text-theme"><strong>e-Registry</strong></span>
                                </h3>
                                <h3 *ngIf="lan==1">
                                    <span class="text-theme"><strong>ई-रजिस्ट्री </strong></span>
                                    <small> की प्रक्रिया </small>
                                </h3>
                                <ul class="spmd-lc">
                                    <li>
                                        <span class="lc-ico"><img src="assets/images/slc/1.png" /></span>
                                        {{ "homepage.User-Registration" | translate }}
                                    </li>
                                    <li>
                                        <span class="lc-ico"><img src="assets/images/slc/2.png" /></span>
                                        {{ "homepage.Initiate Requirements" | translate }}
                                    </li>
                                    <li>
                                        <span class="lc-ico"><img src="assets/images/slc/3.png" /></span>
                                        {{ "homepage.Buyer/Seller" | translate }}
                                    </li>
                                    <li>
                                        <span class="lc-ico"><img src="assets/images/slc/4.png" /></span>
                                        {{ "homepage.Property-Identification" | translate }}
                                    </li>
                                    <li>
                                        <span class="lc-ico"><img src="assets/images/slc/5.png" /></span>
                                        {{ "homepage.Duty-Calcutation" | translate }}
                                    </li>
                                    <li>
                                        <span class="lc-ico"><img src="assets/images/slc/6.png" /></span>
                                        {{ "homepage.Deed-Drafting" | translate }}
                                    </li>
                                    <li>
                                        <span class="lc-ico"><img src="assets/images/slc/7.png" /></span>
                                        {{ "homepage.Video-e-KYC" | translate }}
                                    </li>
                                    <li>
                                        <span class="lc-ico"><img src="assets/images/slc/8.png" /></span>
                                        {{ "homepage.Easy-Payment" | translate }}
                                    </li>
                                </ul>
                            </div>
                            <div matRipple class="smpd-right bg-theme">
                                <img src="assets/images/slc/01.png" width="120" />
                            </div>

                            <a *ngIf="lan==0" class="knowmorsmapada" mat-raised-button
                                href="https://www.mpigr.gov.in/#/about" target="_blank" title="">know more about
                                <strong>SAMPADA</strong>
                                &nbsp;&nbsp;<i class="fa fa-long-arrow-right"></i>
                            </a>
                            <!-- <a *ngIf="lan==0" class="knowmorsmapada faq" mat-raised-button
                                href="https://www.mpigr.gov.in/#/faq" target="_blank" title="">FAQ
                                <strong>SAMPADA</strong>
                                &nbsp;&nbsp;<i class="fa fa-long-arrow-right"></i>
                            </a> -->
                            <a *ngIf="lan == 0" class="knowmorsmapada faq" mat-raised-button
                                [routerLink]="['/faqModules']" title="FAQ SAMPADA">
                                FAQ <strong>SAMPADA</strong>
                                &nbsp;&nbsp;<i class="fa fa-long-arrow-right"></i>
                            </a>
                            <a *ngIf="lan==1" class="knowmorsmapada" mat-raised-button
                                href="https://www.mpigr.gov.in/#/about" target="_blank" title=""><strong>सम्पदा
                                </strong>
                                के बारे में अधिक जानें &nbsp;&nbsp;<i class="fa fa-long-arrow-right"></i>
                            </a>
                            <!-- <a *ngIf="lan==1" class="knowmorsmapada faq" mat-raised-button
                                href="https://www.mpigr.gov.in/#/faq" target="_blank" title="">अक्सर
                                <strong>सम्पदा</strong>
                                के बारे में पूछे जाने वाले प्रश्न
                                &nbsp;&nbsp;<i class="fa fa-long-arrow-right"></i>
                            </a> -->
                            <a *ngIf="lan == 1" class="knowmorsmapada faq" mat-raised-button
                                [routerLink]="['/faqModules']" title="अक्सर सम्पदा के बारे में पूछे जाने वाले प्रश्न">
                                अक्सर <strong>सम्पदा</strong> के बारे में पूछे जाने वाले प्रश्न
                                &nbsp;&nbsp;<i class="fa fa-long-arrow-right"></i>
                            </a>

                        </div>
                        <div class="sampadaFeaturesSec col-lg-6 pl-lg-5 col-sm-6">
                            <div class="d-flex justify-content-between flex-wrap">
                                <h2 class="text-muted" *ngIf="lan==0">
                                    <small>Features of</small> &nbsp;<span
                                        class="text-theme"><strong>SAMPADA</strong></span>
                                </h2>

                                <h2 class="text-muted" *ngIf="lan==1">
                                    <small>संपदा की</small> &nbsp;<span
                                        class="text-theme"><strong>विशेषताएं</strong></span>
                                </h2>
                                <!-- <ng-container *ngIf="env=='prod'">
                                    <a *ngIf="lan==0" class="knowmorsmapadaBtn demo_link" mat-raised-button
                                        href="https://ersuat2.mp.gov.in/igrs/#/" target="_blank" title="">SAMPADA 2.0
                                        Demo Link
                                        &nbsp;&nbsp;<i class="fa fa-long-arrow-right bg-primary text-white"></i>
                                    </a>
                                    <a *ngIf="lan==1" class="knowmorsmapadaBtn demo_link" mat-raised-button
                                        href="https://ersuat2.mp.gov.in/igrs/#/" target="_blank" title="">सम्पदा 2.0
                                        डेमो लिंक
                                        &nbsp;&nbsp;<i class="fa fa-long-arrow-right"></i>
                                    </a>
                                </ng-container> -->
                                <ng-container>
                                    <a *ngIf="lan==0" (click)="srOfficeDis()"
                                        class=" knowmorsmapadaBtn demo_link btn btn-primary" mat-raised-button
                                        target="_blank" title="">
                                        SR Office Token Display
                                        &nbsp;&nbsp;<i class="fa fa-long-arrow-right bg-primary text-white"></i>
                                    </a>
                                    <a *ngIf="lan==1" (click)="srOfficeDis()"
                                        class="knowmorsmapadaBtn demo_link btn btn-primary" mat-raised-button
                                        target="_blank" title="">
                                        एसआर कार्यालय टोकन डिस्प्ले
                                        &nbsp;&nbsp;<i class="fa fa-long-arrow-right"></i>
                                    </a>
                                </ng-container>
                            </div>
                            <ul class="sampadaFeatures">
                                <li class="">
                                    <h3 class="theme-color">
                                        {{ "homepage.GIS-Enabled" | translate }}
                                    </h3>
                                    <p>{{ "homepage.Locate-your-propery" | translate }}</p>
                                </li>
                                <li class="">
                                    <h3 class="theme-color">{{ "homepage.e-KYC" | translate }}</h3>
                                    <p>{{ "homepage.Do-KYC-whith" | translate }}</p>
                                </li>
                                <li class="">
                                    <h3 class="theme-color">
                                        {{ "homepage.Easy-Payments" | translate }}
                                    </h3>
                                    <p>{{ "homepage.Pay-your-e-Stamp" | translate }}</p>
                                </li>
                                <li class="">
                                    <h3 class="theme-color">
                                        {{ "homepage.Digilocker" | translate }}
                                    </h3>
                                    <p>{{ "homepage.DigiLocker-aims" | translate }}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-xl-2 col-lg-3 col-sm-12  col_31 mobile_ordering-1">
                <div class="news-ticker">
                    <h3 class="text-muted" *ngIf="lan==0">
                        <small>What's</small> &nbsp;
                        <span class="text-theme"><strong>New ?</strong></span>
                    </h3>
                    <h3 class="text-muted"  *ngIf="lan==1">
                        <small>नया</small> &nbsp;<span class="text-theme"><strong>क्या है ?</strong></span>
                    </h3>
                    <ngx-slick-carousel class="newsCra" #slickNews="slick-carousel" [config]="slideConfigNews"
                        (init)="slickInit($event)">
                        <div ngxSlickItem>
                            <a class="fw500">
                                <span class="heighlight">1 Fact of the day</span> - By 2031-32,
                                India plans to boost non-fossil fuel capacity to over 500 GW
                            </a>
                        </div>
                        <div ngxSlickItem>
                            <a class="fw500">
                                <span class="heighlight">2 Fact of the day</span> - By 2031-32,
                                India plans to boost non-fossil fuel capacity to over 500 GW,
                                reducing coal reliance and promoting green energy for
                                sustainable power growth.
                            </a>
                        </div>
                        <div ngxSlickItem>
                            <a class="fw500">
                                <span class="heighlight">3 Fact of the day</span> - By 2031-32,
                                India plans to boost non-fossil
                            </a>
                        </div>
                        <div ngxSlickItem>
                            <a class="fw500">
                                <span class="heighlight">4 Fact of the day</span> - By 2031-32,
                                India plans to boost non-fossil
                            </a>
                        </div>
                        <div ngxSlickItem>
                            <a class="fw500">
                                <span class="heighlight">5 Fact of the day</span> - By 2031-32,
                                India plans to boost non-fossil fuel capacity to over 500 GW,
                                reducing coal reliance and promoting green energy for
                                sustainable power growth.
                            </a>
                        </div>
                        <div ngxSlickItem>
                            <a class="fw500">
                                <span class="heighlight">6 Fact of the day</span> - By 2031-32,
                                India plans to boost non-fossil fuel capacity to over 500 GW,
                                reducing coal reliance and promoting green energy for
                                sustainable power growth.
                            </a>
                        </div>
                        <div ngxSlickItem>
                            <a class="fw500">
                                <span class="heighlight">7 Fact of the day</span> - By 2031-32,
                                India plans to boost non-fossil fuel capacity to over 500 GW,
                                reducing coal reliance and promoting green energy for
                                sustainable power growth.
                            </a>
                        </div>
                    </ngx-slick-carousel>
                    
                </div>
            </div> -->
            </div>
        </div>
    </section>
    <div class="services-section mobile_ordering-1">
        <div class="container">
            <div class="row">
                <div class="col-xl-9 col-md-8 border-right mobile_ordering-2">
                    <div class="parents_title">
                        <h3 class="text-muted" *ngIf="lan==0">
                            <small>Online</small> &nbsp;<span class="text-theme"><strong>Services</strong></span>
                        </h3>
                        <h3 class="text-muted" *ngIf="lan==1">
                            <small>ऑनलाइन</small> &nbsp;<span class="text-theme"><strong>सेवाओं</strong></span>
                        </h3>
                    </div>
                    <div class="services_box">
                        <ul>
                            <li>
                                <div class="service" routerLink="/Ereg_Details">
                                    <div class="service_icon">
                                        <mat-icon>
                                            receipt_long
                                        </mat-icon>
                                    </div>
                                    <div class="service_info my-auto">
                                        <span>
                                            {{"homepage.eRegistration" | translate}}
                                        </span>
                                    </div>
                                    <div class="arrow" placement="top" ngbTooltip="Click Here" container="body">
                                        <mat-icon>
                                            double_arrow
                                        </mat-icon>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="service" routerLink="/Estamp_Details">
                                    <div class="service_icon">
                                        <mat-icon>
                                            display_settings
                                        </mat-icon>
                                    </div>
                                    <div class="service_info my-auto">
                                        <span>
                                            {{'living-point-2' | translate}}
                                        </span>
                                    </div>
                                    <div class="arrow" placement="top" ngbTooltip="Click Here" container="body">
                                        <mat-icon>
                                            double_arrow
                                        </mat-icon>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="service" routerLink="/DutyCalculationDetails">
                                    <div class="service_icon">
                                        <mat-icon>
                                            calculate
                                        </mat-icon>
                                    </div>
                                    <div class="service_info my-auto">
                                        <span> {{'homepage.Duty-Calcutation' | translate}}</span>
                                    </div>
                                    <div class="arrow" placement="top" ngbTooltip="Click Here" container="body">
                                        <mat-icon>
                                            double_arrow
                                        </mat-icon>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="service" routerLink="/PropertyValuationDetails">
                                    <div class="service_icon">
                                        <mat-icon>
                                            travel_explore
                                        </mat-icon>
                                    </div>
                                    <div class="service_info my-auto">
                                        <span>
                                            {{'property-valuation.property-valuation' | translate}}
                                        </span>
                                    </div>
                                    <div class="arrow" placement="top" ngbTooltip="Click Here" container="body">
                                        <mat-icon>
                                            double_arrow
                                        </mat-icon>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="service" routerLink="/DeedAcceptanceDetails">
                                    <div class="service_icon">
                                        <mat-icon>
                                            assignment_turned_in
                                        </mat-icon>
                                    </div>
                                    <div class="service_info my-auto">
                                        <span>
                                            {{'homepage.deedAcceptSing' | translate}}
                                        </span>
                                    </div>
                                    <div class="arrow" placement="top" ngbTooltip="Click Here" container="body">
                                        <mat-icon>
                                            double_arrow
                                        </mat-icon>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="service" routerLink="/DocumentSearchDetails">
                                    <div class="service_icon">
                                        <mat-icon>
                                            manage_search
                                        </mat-icon>
                                    </div>
                                    <div class="service_info my-auto">
                                        <span>
                                            {{'homepage.docSCertiCopy' | translate}}
                                        </span>
                                    </div>
                                    <div class="arrow" placement="top" ngbTooltip="Click Here" container="body">
                                        <mat-icon>
                                            double_arrow
                                        </mat-icon>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="service" routerLink="/VeiwGuidelineDetails">
                                    <div class="service_icon">
                                        <mat-icon>
                                            view_headline
                                        </mat-icon>
                                    </div>
                                    <div class="service_info my-auto">
                                        <span>
                                            {{'homepage.View-Guideline' | translate}}
                                        </span>
                                    </div>
                                    <div class="arrow" placement="top" ngbTooltip="Click Here" container="body">
                                        <mat-icon>
                                            double_arrow
                                        </mat-icon>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="service" routerLink="/SlotAvailabilityRescheduleDetails">
                                    <div class="service_icon">
                                        <mat-icon>
                                            calendar_month
                                        </mat-icon>
                                    </div>
                                    <div class="service_info my-auto">
                                        <span>
                                            {{'homepage.sloatAvil' | translate}}
                                        </span>
                                    </div>
                                    <div class="arrow" placement="top" ngbTooltip="Click Here" container="body">
                                        <mat-icon>
                                            double_arrow
                                        </mat-icon>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="service" routerLink="/SrSpLocationsComponent">
                                    <div class="service_icon">
                                        <mat-icon>location_city</mat-icon>
                                    </div>
                                    <div class="service_info my-auto">
                                        <span>{{'homepage.srspLm' | translate}}</span>
                                    </div>
                                    <div class="arrow" placement="top" ngbTooltip="Click Here" container="body">
                                        <mat-icon>
                                            double_arrow
                                        </mat-icon>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="service" routerLink="/SAMPADAWalletDetails">
                                    <div class="service_icon">
                                        <mat-icon>
                                            account_balance_wallet
                                        </mat-icon>
                                    </div>
                                    <div class="service_info my-auto">
                                        <span> {{'e-wallet.title' | translate}}

                                        </span>
                                    </div>
                                    <div class="arrow" placement="top" ngbTooltip="Click Here" container="body">
                                        <mat-icon>
                                            double_arrow
                                        </mat-icon>
                                    </div>
                                </div>
                            </li>
                            <!-- <li>
                            <div class="service">
                                <div class="service_icon">
                                    <mat-icon>
                                        currency_rupee
                                        </mat-icon>
                                </div>
                                <div class="service_info my-auto">
                                    <span> 
                                        {{'eRegistry.slot-booking.btn-payment-pending' | translate}}
                                    </span>
                                </div>
                                <div class="arrow" placement="top"
                                ngbTooltip="Click Here"
                                container="body">
                                     <mat-icon>
                                        double_arrow
                                        </mat-icon>
                                </div>
                            </div>
                        </li> -->
                            <!-- <li>
                            <div class="service">
                                <div class="service_icon">
                                    <mat-icon>
                                        settings_b_roll
                                        </mat-icon>
                                </div>
                                <div class="service_info my-auto">
                                    <span> 
                                        {{'homepage.ServiceCharges' | translate}}
                                    </span>
                                </div>
                                <div class="arrow" placement="top"
                                ngbTooltip="Click Here"
                                container="body">
                                     <mat-icon>
                                        double_arrow
                                        </mat-icon>
                                </div>
                            </div>
                        </li> -->
                            <li>
                                <div class="service" routerLink="/ServiceProviderLicenseDetails">
                                    <div class="service_icon">
                                        <mat-icon>
                                            linked_services
                                        </mat-icon>
                                    </div>
                                    <div class="service_info my-auto">
                                        <span>
                                            {{'admin-profile-page-data.center-serviceprovider-details.splicence' |
                                            translate}}
                                        </span>
                                    </div>
                                    <div class="arrow" placement="top" ngbTooltip="Click Here" container="body">
                                        <mat-icon>
                                            double_arrow
                                        </mat-icon>
                                    </div>
                                </div>
                            </li>
                            <!-- <li>
                            <div class="service">
                                <div class="service_icon">
                                    <mat-icon>
                                        checked_bag_question
                                        </mat-icon>
                                </div>
                                <div class="service_info my-auto">
                                    <span> 
                                        {{'homepage.caseStatus' | translate}}
                                    </span>
                                </div>
                                <div class="arrow" placement="top"
                                ngbTooltip="Click Here"
                                container="body">
                                     <mat-icon>
                                        double_arrow
                                        </mat-icon>
                                </div>
                            </div>
                        </li> -->
                            <li>
                                <div class="service" routerLink="/RaiseSupportTicketDetails">
                                    <div class="service_icon">
                                        <mat-icon>
                                            person_raised_hand
                                        </mat-icon>
                                    </div>
                                    <div class="service_info my-auto">
                                        <span>{{'homepage.raisrtic' | translate}}</span>
                                    </div>
                                    <div class="arrow" placement="top" ngbTooltip="Click Here" container="body">
                                        <mat-icon>
                                            double_arrow
                                        </mat-icon>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-xl-3 col-md-4 mobile_ordering-1 search_col_sect">
                    <div class="parents_title">
                        <h3 class="text-muted" *ngIf="lan==0">
                            <small>Validate</small> &nbsp;<span class="text-theme"><strong>Document</strong></span>
                        </h3>
                        <h3 class="text-muted" *ngIf="lan==1">
                            <small> मान्य</small> &nbsp;<span class="text-theme"><strong>दस्तावेज़</strong></span>
                        </h3>
                    </div>
                    <div class="search-box">
                        <div class="row align-items-end">
                            <div class="col-md-12">

                                <form #verifyEregistryHtml="ngForm" class="form-group mb-3">
                                    <label>
                                        {{'homepage.eRegisteredDocument' | translate}}
                                    </label>

                                    <div class="input-group">
                                        <input type="text" class="form-control" name="eregRegNo" [(ngModel)]="eregRegNo"
                                            oninput="this.value = this.value.toUpperCase()" minlength="14"
                                            maxlength="35" specialIsAlphaNumeric pattern="[a-zA-Z0-9]*"
                                            placeholder="e.g. MPXXXXXXAXXXXX">
                                        <!-- placeholder="{{'homepage.plzSearchValeregDoc' | translate}}" -->
                                        <div class="input-group-append">
                                            <button (click)="searchRedirectVerifyEregistry()" class="btn btn-primary"
                                                type="button">
                                                <mat-icon>search</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                    <!-- <div *ngIf="errMsgEregRegNo" class="error_mess">
                                        <div>{{'required' | translate}}</div>
                                    </div> -->
                                </form>
                            </div>
                            <div class="col-md-12">

                                <form #verifyEstampHtml="ngForm" class="form-group  mb-3">
                                    <label>
                                        {{'homepage.EStampDocument' | translate}}
                                    </label>

                                    <div class="input-group">
                                        <input type="text" class="form-control" name="estampRegNo"
                                            [(ngModel)]="estampRegNo" minlength="18" maxlength="35"
                                            oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                                            placeholder="e.g. 0101XXXXXXXXXXXXXX" required>
                                        <!-- placeholder="{{'homepage.plzSearchValestampDoc' | translate}}" -->
                                        <div class="input-group-append">
                                            <button (click)="searchRedirectVerifyEstamp()" class="btn btn-primary"
                                                type="button">
                                                <mat-icon>search</mat-icon>
                                            </button>
                                        </div>

                                    </div>
                                    <!-- <div *ngIf="errMsgEstampRegNo" class="error_mess">
                                        <div>{{'required' | translate}}</div>
                                    </div> -->

                                </form>
                            </div>




                            <div class="col-md-12">

                                <form #verifyCertifiedHtml="ngForm" class="form-group">
                                    <label>
                                        {{'Search-Document-Certified.noEntersearch_reg_no_certified' | translate}}
                                    </label>

                                    <div class="input-group">

                                        <input type="text" class="form-control" [(ngModel)]="registrationNumber"
                                            name="registrationNumber" id="registrationNumber" minlength="14"
                                            placeholder="e.g. CERTIFIEDCOPYXXXXXXX / MPXXXXXAx" value="null"
                                            oninput="this.value = this.value.replace(/[^0-9]/g, '')" maxlength="35" />


                                        <div class="input-group-append">
                                            <button (click)="searchRedirectVerifyCertifiedEregistryNo()"
                                                class="btn btn-primary" type="button">
                                                <mat-icon>search</mat-icon>
                                            </button>
                                        </div>

                                    </div>


                                </form>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- partners -->
<div class="partners">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="partnercarousel">
                    <ngx-slick-carousel class="carousel logo-slider" #slickModal="slick-carousel"
                        [config]="slideConfigPartners" (init)="slickInit($event)">
                        <div ngxSlickItem class="slide">
                            <a href="https://www.standupmitra.in/" target="_blank" title="Stand Up India">
                                <div class="img-box">
                                    <img src="./assets/images/partners/standup-india.png" alt="Stand Up India" />
                                </div>
                            </a>
                        </div>
                        <div ngxSlickItem class="slide">
                            <a href="http://www.digitalindia.gov.in/" target="_blank" title="Digital India">
                                <div class="img-box">
                                    <img src="./assets/images/partners/digital-india.png" alt="Digital India" />
                                </div>
                            </a>
                        </div>
                        <div ngxSlickItem class="slide">
                            <a href="https://www.mygov.in/" target="_blank"
                                title="MyGov: A Platform for Citizen Engagement towards Good Governance in India">
                                <div class="img-box">
                                    <img src="./assets/images/partners/my-gov.png" alt="My Gov" />
                                </div>
                            </a>
                        </div>
                        <div ngxSlickItem class="slide">
                            <a href="https://www.mponline.gov.in/portal/" target="_blank" title="MP Online">
                                <div class="img-box">
                                    <img src="./assets/images/partners/mp-online.png" alt="MP Online" />
                                </div>
                            </a>
                        </div>
                        <div ngxSlickItem class="slide">
                            <a href="https://www.india.gov.in/" target="_blank" title="National Portal of India">
                                <div class="img-box">
                                    <img src="./assets/images/partners/india-gov.png" alt="National Portal of India" />
                                </div>
                            </a>
                        </div>
                        <div ngxSlickItem class="slide">
                            <a href="https://data.gov.in/" target="_blank" title="Data Government">
                                <div class="img-box">
                                    <img src="./assets/images/partners/data-gov.png" alt="Data Government" />
                                </div>
                            </a>
                        </div>
                        <div ngxSlickItem class="slide">
                            <a href="https://mpsedc.mp.gov.in/" target="_blank" title="MPSEDC State IT Center">
                                <div class="img-box">
                                    <img src="./assets/images/partners/mpsedclogo.png" alt="My Gov" />
                                </div>
                            </a>
                        </div>
                        <div ngxSlickItem class="slide">
                            <a href="https://www.commercialtax.mp.gov.in/" target="_blank" title="commercialtax">
                                <div class="img-box">
                                    <img src="./assets/images/partners/commercialTax.jpg" alt="commercialtax"
                                        style="width: 79%;" />
                                </div>
                            </a>
                        </div>
                    </ngx-slick-carousel>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- partners end-->

<div class="pr py-3">
    <div class="container">
        <div class="row">
            <div class="col-lg-9 flinks mb-2">
                <h4 class="text-white ml-4 font-weight-bold">
                    {{ "homepage.Important-links" | translate }}
                </h4>
                <ul>
                    <!-- <li><a href="">{{'default-header-text-1' | translate}}</a></li> -->
                    <li><a href="https://www.mpigr.gov.in/#/about" target="_blank"
                            title="{{'proposalSection.aboutus' | translate}}">{{'proposalSection.aboutus' |
                            translate}}</a></li>
                    <li><a href="https://www.mpigr.gov.in/#/department-structure" target="_blank"
                            title="{{'homepage.Departmental-Structure' | translate}}">{{'homepage.Departmental-Structure'
                            | translate}}</a></li>
                    <li><a href="https://www.mpigr.gov.in/#/act" target="_blank" class="pr-0"
                            title="{{'homepage.Acts-and-Rules' | translate}}"> {{'homepage.Acts-and-Rules' |
                            translate}}</a><a href="https://www.mpigr.gov.in/#/rule" target="_blank" class="nocontent"
                            title="{{'homepage.rules' | translate}}">{{'homepage.rules' | translate}}</a></li>
                    <li><a href="https://www.mpigr.gov.in/#/service-provider" target="_blank"
                            title="{{'default-navigation.service-provider' | translate}}">{{'default-navigation.service-provider'
                            | translate}}</a></li>
                    <li><a href="https://www.mpigr.gov.in/#/model-deed" target="_blank"
                            title="{{'homepage.Model-Deeds' | translate}}"> {{'homepage.Model-Deeds' | translate}}</a>
                    </li>
                    <li><a href="https://www.mpigr.gov.in/#/official-and-documents-notification/4/20" target="_blank"
                            title="{{'proposalSection.NotificationOrdersCirculars' | translate}}">
                            {{'proposalSection.NotificationOrdersCirculars' | translate}}</a></li>
                    <li><a href="https://www.mpigr.gov.in/#/document-statistics" target="_blank"
                            title="{{'homepage.Statistics' | translate}}"> {{'homepage.Statistics' | translate}}</a>
                    </li>
                    <li><a href="https://www.mpigr.gov.in/#/gradation-list" target="_blank"
                            title="{{'homepage.Employee-Corner' | translate}}"> {{'homepage.Employee-Corner' |
                            translate}}</a></li>
                    <li><a href="https://www.mpigr.gov.in/#/head-office" target="_blank"
                            title="{{'homepage.Offices-and-Contacts' | translate}}"> {{'homepage.Offices-and-Contacts' |
                            translate}}</a></li>

                    <li>
                        <a href="/" routerLink="/Software_Download">

                            {{'softDownload.downloadSoft' | translate}}
                        </a>

                    </li>
                    <li>
                        <a href="/" routerLink="/privacyPolicy">
                            {{'privacyPolicy' |translate}}
                        </a>

                    </li>



                </ul>
            </div>
            <div class="col-lg-3 flinks mb-2">
                <h4 class="text-white ml-4 font-weight-bold">
                    <ng-container *ngIf="lan==0"> Scan QR Code to Download </ng-container> <br>
                    <ng-container *ngIf="lan==0"> SAMPADA 2.0 Mobile App </ng-container>
                    <ng-container *ngIf="lan==1"> संपदा 2.0 मोबाइल ऐप डाउनलोड करने </ng-container> <br>
                    <ng-container *ngIf="lan==1"> के लिए QR कोड स्कैन करें </ng-container>


                </h4>
                <div class="d-flex">
                    <div class="store_img">
                        <img src="assets/images/appstore.jpg" width="80px" />
                        <p>App Store</p>

                    </div>
                    <div class="store_img">
                        <img src="assets/images/playstore.jpg" width="80px" />
                        <p>Play Store</p>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>

<!-- <app-chatbot></app-chatbot> -->

<app-chatbot cdkDrag></app-chatbot>