import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[AlphaNumeric]'
})
export class AlphaNumericDirective {
  private regex: RegExp = new RegExp(/^[A-Z|a-z|0-9]+$/);
  // Define a set of allowed functional keys
  private allowedKeys: string[] = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];
  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {

    const key = event.key;
    // Allow functional keys like backspace, delete, arrow keys, and tab
    if (this.allowedKeys.includes(key)) {
      return; // Do nothing, allow the default behavior
    }
    
    let current: string = this.el.nativeElement.value;
    //const position = this.el.nativeElement.selectionStart;
    //const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    const position = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }


  // Listening for the paste event
  @HostListener('paste', ['$event']) 
  oonPaste(event: ClipboardEvent) {
    const pastedText = event.clipboardData?.getData('text');
      if (pastedText && !String(pastedText).match(this.regex)) {
        event.preventDefault();
        this.el.nativeElement.value = "";
      }
  }
}
