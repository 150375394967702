<div class="position-relative">
  <div class="fid-header"></div>
  <div class="breadcrumb-content">
    <div class="container">
      <div class="d-flex justify-content-between">
        <h2>
          {{'detailsPages.raisSupportTicket' | translate}}
        </h2>
        <button mat-button class="btn btn-primary" id="sticky_Div" routerLink="/raiseTicket" *ngIf="env=='prod'">

          {{'detailsPages.processForraisSupportTicket' | translate}}
        </button>
      </div>
    </div>
  </div>

  <div class="container mb-3">
    <mat-card class="example-card">
      <mat-card-title></mat-card-title>
      <mat-card-content class="mat-card-content">
        <div class="row">
          <div class="col-md-12">
            <mat-accordion class="example-headers-align">
              <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">
                      Mode of raising “Help Desk” Ticket
                    </div>
                    <div *ngIf="lan == 1">
                      "हेल्पडेस्क" टिकट दर्ज कराने का तरीका
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>
                      Registered and Unregistered users can both raise “Help
                      Desk” Tickets on Sampada 2.0 portal using the menu item
                      “Raise Ticket > Ticket Creation”
                    </p>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      पंजीकृत और अपंजीकृत दोनों उपयोगकर्ता सम्पदा 2.0 पोर्टल पर
                      मेनू आइटम "टिकट बनाए > टिकट निर्माण " का उपयोग करके "हेल्प
                      डेस्क" टिकट प्राप्त कर सकते हैं।
                    </p>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">
                      Type of problems can be raised by tickets.
                    </div>
                    <div *ngIf="lan == 1">
                      कारण जिनके अन्तर्गत टिकट बनाए जा सकते हैं|
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>
                      Property Valuation, E Registry- Completion, Interactive Video KYC, Non-Interactive
                      Video KYC, E Registry- Initiation, Duty Calculation, GIS
                      Enabled Guideline, QMS-Generate Token, Legacy Document
                      Request, Document Search and Certified Copy, E-Stamp,
                      IGRS-Payment, Service Provider, User Registration
                    </p>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      संपत्ति मूल्यांकन, ई रजिस्ट्री- समापन, इंटरैक्टिव वीडियो केवाईसी, गैर-इंटरएक्टिव वीडियो केवाईसी, ई रजिस्ट्री- पहल, शुल्क
                      गणना, जीआईएस सक्षम दिशानिर्देश, क्यूएमएस-जेनरेट टोकन,
                      लीगेसी दस्तावेज़ अनुरोध, दस्तावेज़ खोज और प्रमाणित
                      प्रतिलिपि, ई-स्टाम्प, आईजीआरएस-भुगतान, सेवा प्रदाता,
                      उपयोगकर्ता पंजीकरण
                    </p>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">Helpline number / contact number</div>
                    <div *ngIf="lan == 1">हेल्पलाइन नंबर/संपर्क नंबर</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <a href="tel:+0755-2780133"> Help Desk  : 0755-2780133</a>
                  </div>
                  <div *ngIf="lan == 1">
                    <a href="tel:+0755-2780133"> Help Desk  : 0755-2780133</a>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)" hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">Raise a Ticket</div>
                    <div *ngIf="lan == 1">एक टिकट बनाओ</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>
                      User can raise Ticket for “Help Desk” on Sampada 2.0
                      portal by entering email ID, User Name, selecting
                      District, Issue category and issue sub-category and issue
                      description and uploading supporting document (if any) &
                      submitting the ticket
                    </p>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      उपयोगकर्ता सम्पदा 2.0 पोर्टल पर ईमेल आईडी, उपयोगकर्ता का
                      नाम दर्ज करके, जिला,समस्या श्रेणी और समस्या उप-श्रेणी चयन
                      करके और सहायक दस्तावेज़ (यदि कोई हो) अपलोड करके और टिकट
                      सबमिट करके "हेल्प डेस्क" के लिए टिकट बना सकता है।
                    </p>
                  </div>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel [expanded]="step === 4" (opened)="setStep(4)" hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div *ngIf="lan == 0">Check Ticket Status</div>
                    <div *ngIf="lan == 1">टिकट की स्थिति जांचें</div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                  <div *ngIf="lan == 0">
                    <p>
                      User can check the Ticket Status on Sampada 2.0 portal
                      using the menu item “Raise Ticket > Ticket Creation”
                      section. User can also search a ticket by its Ticket
                      Number in the same section. User can View & check the
                      Ticket History by clicking on the view button of the
                      ticket.
                    </p>
                  </div>
                  <div *ngIf="lan == 1">
                    <p>
                      उपयोगकर्ता संपदा 2.0 पोर्टल पर मेनू आइटम "टिकट बनाए > टिकट
                      निर्माण" अनुभाग का उपयोग करके टिकट की स्थिति की जांच कर
                      सकता है। उपयोगकर्ता उसी अनुभाग में टिकट संख्या के आधार पर
                      भी टिकट खोज सकता है। उपयोगकर्ता “टिकट देखेँ” बटन पर क्लिक
                      करके टिकट इतिहास और स्थति देख सकता है।
                    </p>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>