<div class="position-relative">
    <div class="fid-header"></div>
    <div class="breadcrumb-content">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2 *ngIf="lan == 0">
                        Privacy Policy
                    </h2>
                    <h2 *ngIf="lan == 1">
                        Privacy Policy
                    </h2>

                </div>
            </div>
        </div>
    </div>

    <div class="container mb-3">
        <mat-card class="example-card">
            <mat-card-title></mat-card-title>
            <mat-card-content class="mat-card-content">

                <fieldset class="legend-section">

                    <div *ngIf="lan == 0">

                        <ol>
                            <li>
                                This website and mobile application do not automatically capture any specific
                                personal information from the user, unless user chooses to provide information
                                like name, phone number or e-mail address etc.
                            </li>

                            <li>
                                Website never collects information or creates personal profiles for commercial
                                marketing.
                            </li>
                            <li>
                                User can also delete their profile using the Sampada 2.0 website, however, data
                                already used in transaction cannot be deleted.
                            </li>
                            <li>
                                Users need to create “Profile” to avail Sampada 2.0 user specific services such
                                as Property Registration, E-stamp issuance, to search registered property
                                documents, to recharge Sampada Wallet, to check registry status and to book
                                slots for registry. All the user’s data is encrypted.
                            </li>
                            <li>
                                For seamless flow of Registration and E-Stamp processes, Sampada 2.0 website may
                                interact with certain government agencies to enhance the citizen services with
                                their consent.
                            </li>
                            <li>
                                Website Security: All the security measures are taken to ensure the users’ data
                                is safe.
                            </li>
                            <li>
                                If user chooses to provide the personal information by filling out a “Helpdesk
                                Form/Raise Ticket” through the website, such information will not be shared with
                                third parties. While user must provide an email address for a specific response
                                to any comments or suggestion. It is recommended NOT to include any other
                                personal information.
                            </li>

                            <li>
                                The Sampada mobile application does not access any private data of the user.
                                Following access, may require to provide the services, but not limited to:

                                <h3>
                                    Camera Permission:
                                </h3>
                                <ul>
                                    <li>The Sampada mobile application does not access any private data of the user.
                                        Following access, may require to provide the services, but not limited to:
                                    </li>
                                    <li>To scan the QR code of E-stamps to verify their authenticity.
                                    </li>
                                    <li>To generate the Token to perform Sub-Registrar Office based Document registry.</li>

                                </ul>
                                <h3>
                                    Location Permission:
                                </h3>
                                <p>
                                    Location permission is required to make sure that the user is taking the photo
                                    of correct property. The geo coordinates of the property and the user are
                                    captured and compared to ensure the same.
                                </p>
                                <h3>
                                    Photo Permission:
                                </h3>
                                <p>
                                    This permission is required to access photo gallery to upload profile photo of
                                    the user.
                                </p>
                                <h3>
                                    Microphone Permission:
                                </h3>
                                <p>
                                    To interact with registering authority during Online assisted & non assisted
                                    mode of registration.
                                </p>

                            </li>
                        </ol>
                    </div>
                    <div *ngIf="lan == 1">

                        <ol>
                            <li>
                                यह वेबसाइट और मोबाइल एप्लिकेशन उपयोगकर्ता से किसी भी विशिष्ट व्यक्तिगत जानकारी
                                को स्वचालित रूप से एकत्र नहीं करता है, जब तक कि उपयोगकर्ता नाम, फ़ोन नंबर या
                                ई-मेल पता आदि जैसी जानकारी प्रदान करना नहीं चाहता|
                            </li>

                            <li>
                                वेबसाइट कभी भी व्यावसायिक विपणन के लिए जानकारी एकत्र नहीं करता है या व्यक्तिगत
                                प्रोफ़ाइल नहीं बनाता है |
                            </li>
                            <li>
                                उपयोगकर्ता सम्पदा 2.0 वेबसाइट का उपयोग करके अपनी प्रोफ़ाइल भी हटा सकते हैं,
                                हालांकि, लेनदेन में पहले से उपयोग किए गए डेटा को हटाया नहीं जा सकता है।
                            </li>
                            <li>
                                उपयोगकर्ताओं को संपत्ति पंजीकरण, ई-स्टांप जारी करने, पंजीकृत संपत्ति दस्तावेजों
                                को खोजने, संपदा वॉलेट को रिचार्ज करने, रजिस्ट्री की स्थिति की जांच करने और
                                रजिस्ट्री के लिए स्लॉट बुक करने जैसी सम्पदा 2.0 उपयोगकर्ता विशिष्ट सेवाओं का लाभ
                                उठाने के लिए "प्रोफ़ाइल" बनाने की आवश्यकता है। यूजर का सारा डेटा एन्क्रिप्टेड
                                है।
                            </li>
                            <li>
                                पंजीकरण और ई-स्टांप प्रक्रियाओं के निर्बाध प्रवाह के लिए, सम्पदा 2.0 वेबसाइट
                                उनकी सहमति से नागरिक सेवाओं को बढ़ाने के लिए कुछ शासकीय एजेंसियों के साथ बातचीत
                                कर सकती है।
                            </li>
                            <li>
                                वेबसाइट सुरक्षा: उपयोगकर्ताओं का डेटा सुरक्षित है यह सुनिश्चित करने के लिए सभी
                                सुरक्षा उपाय किए जाते हैं।
                            </li>
                            <li>
                                यदि उपयोगकर्ता वेबसाइट के माध्यम से "हेल्पडेस्क फॉर्म/रेज़ टिकट" भरकर व्यक्तिगत
                                जानकारी प्रदान करना चुनता है, तो ऐसी जानकारी तीसरे पक्ष के साथ साझा नहीं की
                                जाएगी। जबकि उपयोगकर्ता को किसी भी टिप्पणी या सुझाव पर विशिष्ट प्रतिक्रिया के लिए
                                एक ईमेल पता प्रदान करना होगा। किसी अन्य व्यक्तिगत जानकारी को शामिल न करने की
                                अनुशंसा की जाती है।
                            </li>

                            <li>
                                सम्पदा मोबाइल एप्लिकेशन उपयोगकर्ता के किसी भी निजी डेटा तक नहीं पहुंचता है।
                                निम्नलिखित पहुंच के लिए सेवाएं प्रदान करने की आवश्यकता हो सकती है, लेकिन यह यहीं
                                तक सीमित नहीं है:

                                <h3>
                                    कैमरा अनुमति:
                                </h3>
                                <ul>
                                    <li>सम्पदा मोबाइल एप्लिकेशन उपयोगकर्ता के किसी भी निजी डेटा तक नहीं पहुंचता है।
                                        निम्नलिखित पहुंच के लिए सेवाएं प्रदान करने की आवश्यकता हो सकती है, लेकिन यह यहीं
                                        तक सीमित नहीं है:</li>
                                    <li>ई-स्टाम्प की प्रामाणिकता सत्यापित करने के लिए उनके क्यूआर कोड को स्कैन करना।</li>
                                    <li>उप-रजिस्ट्रार कार्यालय आधारित दस्तावेज़ रजिस्ट्री करने के लिए टोकन उत्पन्न करना।</li>

                                </ul>
                                <h3>
                                    स्थान अनुमति:
                                </h3>
                                <p>
                                    यह सुनिश्चित करने के लिए स्थान अनुमति आवश्यक है कि उपयोगकर्ता सही संपत्ति की
                                    तस्वीर ले रहा है। इसे सुनिश्चित करने के लिए संपत्ति और उपयोगकर्ता के भौगोलिक
                                    निर्देशांक लिए जाते हैं और उनकी तुलना की जाती है।
                                </p>
                                <h3>
                                    फोटो अनुमति:
                                </h3>
                                <p>
                                    उपयोगकर्ता की प्रोफ़ाइल फोटो अपलोड करने के लिए फोटो गैलरी तक पहुंचने के लिए यह
                                    अनुमति आवश्यक है।
                                </p>
                                <h3>
                                    माइक्रोफोन अनुमति:
                                </h3>
                                <p>
                                    पंजीकरण के ऑनलाइन इंटरएक्टिव मोड के दौरान पंजीकरण
                                    प्राधिकारी के साथ बातचीत करना।
                                </p>
                            </li>
                        </ol>
                    </div>
                </fieldset>
            </mat-card-content>
        </mat-card>
    </div>
</div>