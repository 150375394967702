import { Injectable } from '@angular/core';
import { AppSetting, AppSettingService } from 'projects/common-lib/src/lib/helper-lib/app.setting';
import { CustomHttpClient } from 'projects/common-lib/src/lib/helper-lib/httpclient/custom.httpclient';
import { ResultModel } from 'projects/common-lib/src/lib/models/api.result.model/result.model';
import { EstampDeedTemplateContent } from 'projects/common-lib/src/lib/models/estamp.model/estampDeedTemplateContent';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EstampDeedBean } from '../estamp-unsigned-template/estamp-unsigned-template.component';
import { AddEstamp, EstampPartyModel } from 'projects/common-lib/src/lib/models/addEstamp.model/add-estamp.model';
import { Citizen } from 'projects/common-lib/src/lib/models';
import { AESEncryptDecryptService } from 'projects/common-lib/src/lib/helper-lib/services/aesencrypt-decrypt.service';

@Injectable({
  providedIn: 'root'
})
export class EstampService {
  //[x: string]: any;

  private settings!: AppSetting;
  constructor(private appSettingsService: AppSettingService, private customeHttpClient: CustomHttpClient, private _AESEncryptDecryptService: AESEncryptDecryptService) {
    this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
      this.settings = settings;
    });
  }

  saveEstampDetail(estampDetail: any) {

    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/estamp/saveEstampDetail",
        estampDetail
      ).pipe(
        map((result: any) => {
          if (result) {
            return result;
          }
        },
          (error: any) => {

            return error;
          }
        )
      );
  }



  // saveOrEditEstampPartyDetails(estampPartyDetailsList: EstampPartyModel[]) {

  //   return this.customeHttpClient
  //     .Post<ResultModel<any>>(
  //       this.settings.igrs_services_url + "common/estamp/saveOrEditEstampPartyDetails",
  //       estampPartyDetailsList
  //     ).pipe(
  //       map((result: any) => {
  //         if (result) {
  //           return result;
  //         }
  //       },
  //         (error: any) => {

  //           return error;
  //         }
  //       )
  //     );
  // }

  saveOrEditEstampPartyDetail(estampPartyModel: EstampPartyModel) {

    return this.customeHttpClient
      .Post<ResultModel<any>>(
        this.settings.igrs_services_url + "common/estamp/saveOrEditEstampPartyDetail",
        estampPartyModel
      ).pipe(
        map((result: any) => {
          if (result) {
            return result;
          }
        },
          (error: any) => {

            return error;
          }
        )
      );
  }

  // editEstampPartyDetails(estampPartyDetailsList: any) {
  //     ;
  //     return this.customeHttpClient
  //         .Put<ResultModel<any>>(
  //             this.settings.igrs_services_url + "common/estamp/editEstampPartyDetails",
  //             estampPartyDetailsList
  //         ).pipe(
  //             map((result: any) => {
  //                 if (result) {
  //                     return result;
  //                 }
  //             },
  //                 (error: any) => {

  //                     return error;
  //                 }
  //             )
  //         );
  //   }


  getEstampTemplate() {
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/estamp/estampTemplate"
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  getDeedTemplateById(instrumentId: any) {
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/estamp/getDeedTemplateById?instrumentId=" + instrumentId
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  getDeedTemplateByTemplateId(templateId: any) {
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/estamp/getDeedTemplateByTemplateId?templateId=" + templateId
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  getFieldsByTemplateId(templateId: any) {
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/estamp/getFieldsByTemplateId?templateId=" + templateId
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  getTemplateFieldValuesDetails(templateId: any, estampId: any) {
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/estamp/getTemplateFieldValuesDetails?templateId=" + templateId + "&estampId=" + estampId
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  /* getEstampPartyDetailsByEstampId(estampId: string) { // ?deedTypeId="+deedTypeId
    // let param={estampId:estampId}
   return this.customeHttpClient
       .GetApi<ResultModel<any>>(
           this.settings.igrs_services_duty_url + "common/estamp/getEstampPartyDetailsByEstampId?estampid="+estampId
       ).pipe(
           map((result: any) => {
 
                   return result;
               // } else {
               //     return result.responseMessage;
               // }
 
           })
       );
 } */

  getMaxPageNo(estampId: any) {
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_duty_url + "common/estamp/getMaxPageNo?estampId=" + estampId
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  getEstampPartyDetailsByEstampId(estampId: number) {

    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_duty_url + "common/estamp/getEstampPartyDetailsByEstampId?estampId=" + estampId
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  getDeedAndPartyHistoryByEstampId(estampId: number) {

    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_duty_url + "common/estamp/getDeedAndPartyHistoryByEstampId?estampId=" + estampId
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  // getEstampPartyDetailsByEstampId(estampId:number) {

  //   return this.customeHttpClient
  //   .Get<ResultModel<any>>(
  //       this.settings.igrs_services_duty_url + "common/estamp/getEstampPartyDetailsByEstampId?estampId="+estampId
  //     ).pipe(
  //       map((result: any) => {
  //         return result;
  //       })
  //     );
  // }

  getEstampListByContactNumber(pageNo: number, pageSize: number, contactNumber: string, inputEstampId: any, isEstampIdEncrypt: any,partyId:any,userId:any) {
    let param = {
      "isEstampIdEncrypt": isEstampIdEncrypt,
      "partyId": partyId,
      "userId": userId,
    };
    return this.customeHttpClient
      .Post<ResultModel<any>>(
        this.settings.igrs_services_url + "common/estamp/getEstampListByContactNumber/" + pageNo + "/" + pageSize + "/" + contactNumber + "/" + inputEstampId, param
      ).pipe(
        map((result: any) => {
          return result;
        })
      );

  }

  getDeedByEstampId(estampId: number) {
    return this.customeHttpClient
      .Post<ResultModel<any>>(
        this.settings.igrs_services_url + "common/estamp/getDeedContentListByEstampId",
        estampId
      ).pipe(
        map((result: any) => {
          if (result) {
            return result;
          }
        },
          (error: any) => {

            return error;
          }
        )
      );
  }

  updateEstamp(estampDetail: any) {

    return this.customeHttpClient.PostApiToken<ResultModel<any>>(
      this.settings.igrs_services_url + "citizen/estamp/updateEstamp",
        estampDetail
    ).pipe(
      map((result: any) => {
        //if (result) {
        return result;
        //}
      },
        (error: any) => {
          console.log("error:-", error);
          return error;
        }
      )
    );
  }



/*
  updateEstamp(estampDetail: any) {

    return this.customeHttpClient
      .Put<ResultModel<any>>(
        this.settings.igrs_services_url + "common/estamp/updateEstamp",
        estampDetail
      ).pipe(
        map((result: any) => {
          if (result) {
            return result;
          }
        },
          (error: any) => {

            return error;
          }
        )
      );
  }
      */

  saveDeedContent(estampDeedContent: any) {

    return this.customeHttpClient
      .Post<ResultModel<any>>(
        this.settings.igrs_services_url + "common/estamp/saveDeedContent",
        estampDeedContent
      ).pipe(
        map((result: any) => {
          if (result) {
            return result;
          }
        },
          (error: any) => {

            return error;
          }
        )
      );
  }


  getListingEstamp(estampId: number) {

    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/estamp/listingEstamp?estampId=" + estampId
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }
  getAmountByEstampId(estampId: number) {

    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/estamp/getAmountByEstampId?estampId=" + estampId
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }



  getEstampDetailsById(estampId: number) {

    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/estamp/estampDetailsById?estampId=" + estampId
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }



  getStatusListByModuleType(moduleType: string) {

    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/estamp/getStatusListByModuleType?moduleType=" + moduleType
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  getRemarkHistoryByEstampId(estampId: string) {

    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/estamp/getRemarkHistoryByEstampId?estampId=" + estampId
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }


  savePartyStatusRemark(estampPartStatusRemark: EstampDeedTemplateContent) {

    return this.customeHttpClient
      .Post<ResultModel<any>>(
        this.settings.igrs_services_url + "common/estamp/savePartyStatusRemark",
        estampPartStatusRemark
      ).pipe(
        map((result: any) => {
          if (result) {
            return result;
          }
        },
          (error: any) => {

            return error;
          }
        )
      );
  }


  getListingEstampByUserId(userId: number) {

    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/estamp/listingEstampByUserId?userId=" + userId
      ).pipe(
        map((result: any) => {
          return result;
        })
      );

  }

  get_ListingEstampByUserId(pageNo: number, pageSize: number, param: any) {

    return this.customeHttpClient
      .Post<ResultModel<any>>(
        this.settings.igrs_services_url + "common/estamp/listingEstampByUserId/" + pageNo + "/" + pageSize, param
      ).pipe(
        map((result: any) => {
          return result;
        })
      );

  }

  getOtp(citizen: Citizen) {
    let encVal = this._AESEncryptDecryptService.encryptInAngular(JSON.stringify(citizen));
    return this.customeHttpClient.Post<any>(
      this.settings.igrs_services_url + "common/estamp/generate_otp_for_estamp_login", encVal
    ).pipe(
      map((result: any) => {
        ;

        if (result && result.responseStatus == 'true') {
          return result;
        } else {
          return result;
        }

      })
    );
  }

  generateOtpForUser(citizen: Citizen) {//citizen
    let encVal = this._AESEncryptDecryptService.encryptInAngular(JSON.stringify(citizen) );
    return this.customeHttpClient.Post<ResultModel<any>>(
        //  this.settings.igrs_gateway_url + "common/generate_otp_for_user?username="+mobile+"&usertype=citizen"
        this.settings.igrs_gateway_url + "common/generate_otp_for_estamp_login", encVal
    ).pipe(
        map((result: any) => {
            ;

            if (result && result.responseStatus == 'true') {
                return result;
            } else {
                return result;
            }

        })
    );
}

verifyOtpForUser(userId: number, otp: string, mobileNo: number, captchaId: string, captchaStr: string) {
  return this.customeHttpClient.Get<ResultModel<any>>(
      this.settings.igrs_gateway_url + "common/verify_otp_for_estamp_login?userId=" + userId + "&otp=" + otp + "&mobile_no=" + mobileNo + "&captchaid=" + captchaId + "&captchastr=" + captchaStr
  ).pipe(
      map((result: any) => {
          ;

          if (result && result.responseStatus == 'true') {
              return result;
          } else {
              return result;
          }

      })
  );
}
  verifyOtp(mobileNo: string, otp: string) {
    return this.customeHttpClient.Get<any>(
      this.settings.igrs_services_url + "common/estamp/verify_otp_for_estamp_login?mobile_no=" + mobileNo + "&otp=" + otp
    ).pipe(
      map((result: any) => {

        if (result && result.responseStatus == 'true') {
          return result;
        } else {
          return result;
        }

      })
    );
  }


  getEstampIdByPayReqPayRes(req: string, res: string) {

    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/estamp/getEstampByPayReqRes?req=" + req + "&res=" + res
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  getEstampIdByPayReq(req: string) {

    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/estamp/getEstampPaymentPayReqId?req=" + req
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  deletePartyById(partyId: number) {
    return this.customeHttpClient
      .Post<ResultModel<any>>(
        this.settings.igrs_services_duty_url + "common/estamp/deletePartyById?partyId=" + partyId,
        {}
      ).pipe(
        map((result: any) => {
          if (result) {
            return result;
          }
        },
          (error: any) => {

            return error;
          }
        )
      );
  }


  getQrCodeService(estampId: number) {

    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_duty_url + "common/estamp/getQRCodeByEstampId?req=" + estampId
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  // downloadPartyEsign(id?: number) {
  //   return this.customeHttpClient
  //     .Post<ResultModel<any>>(
  //       this.settings.igrs_gateway_url + "common/partyesign/downloadEsignPdfForEregCompletion/" + id,
  //       id
  //     ).pipe(
  //       map((result: any) => {
  //         if (result) {
  //           return result;
  //         }
  //       },
  //         (error: any) => {
  //           alert("not found");
  //           return error;
  //         }
  //       )
  //     );
  // }


  // downloadASPEsign(id: number) {
  //   return this.customeHttpClient
  //     .Post<ResultModel<any>>(
  //       this.settings.igrs_gateway_url + "common/partyesign/downloadASPEsignPdfForEregCompletion/" + id,
  //       id
  //     ).pipe(
  //       map((result: any) => {
  //         if (result) {
  //           return result;
  //         }
  //       },
  //         (error: any) => {
  //           alert("not found");
  //           return error;
  //         }
  //       )
  //     );
  // }



  downloadDMSEstamp(id: number) {
    return this.customeHttpClient
      .Post<ResultModel<any>>(
        this.settings.igrs_gateway_url + "common/partyesign/downloadDMSEstamp/" + id,
        id
      ).pipe(
        map((result: any) => {
          if (result) {
            return result;
          }
        },
          (error: any) => {
            alert("estamp DMS File not found");
            return error;
          }
        )
      );
  }

  saveTemplateFieldValuesDetails(templateFieldValuesDetailsList: any) {
    ;
    return this.customeHttpClient
      .Post<ResultModel<any>>(
        this.settings.igrs_services_url + "common/estamp/saveTemplateFieldValuesDetails",
        templateFieldValuesDetailsList
      ).pipe(
        map((result: any) => {
          if (result) {
            return result;
          }
        },
          (error: any) => {

            return error;
          }
        )
      );
  }


  getCitizenDetailsWithSp(citizenid: any) {
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_gateway_url + "common/getCitizenDetailsWithSp/" + citizenid,
      ).pipe(
        map((result: any) => {
          return result;

        })
      );
  }

  getGeneratedEstampCode(param: any) {

    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_gateway_url + "citizen/getGeneratedEstampCode", param
      ).pipe(
        map((result: any) => {

          //
          if (result && result.responseStatus == 'true') {
            return result;
          } else {
            return result;
          }

        })
      );
  }

  getGeneratedEstampCodeForEreg() {

    return this.customeHttpClient
      .GetApi<ResultModel<any>>(
        this.settings.igrs_gateway_url + "citizen/getGeneratedEstampCodeForEreg", "testing"
      ).pipe(
        map((result: any) => {

          //
          if (result && result.responseStatus == 'true') {
            return result;
          } else {
            return result;
          }

        })
      );
  }

  getMstStatusByModuleType(moduleType: string) {

    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/estamp/getMstStatusByModuleType/" + moduleType
      ).pipe(
        map((result: any) => {

          //
          if (result && result.responseStatus == 'true') {
            return result;
          } else {
            return result;
          }

        })
      );
  }
  getEstampWithoutSign(estampDeedBean: EstampDeedBean) {

    return this.customeHttpClient
      .Post<ResultModel<any>>(
        this.settings.igrs_services_url + "citizen/estamp/getEstampWithoutSign",
        estampDeedBean
      ).pipe(
        map((result: any) => {
          if (result) {
            return result;
          }
        },
          (error: any) => {

            return error;
          }
        )
      );
  }



  getEstampPdfDataAsByteArray(url: any) {
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        url,
      ).pipe(
        map((result: any) => {
          return result;

        })
      );
  }

  checkPartyEsignProgressStatus(param: any) {

    return this.customeHttpClient
      .Post<ResultModel<any>>(
        this.settings.igrs_services_url + "common/estamp/checkPartyEsignProgressStatus", param
      ).pipe(
        map((result: any) => {

          return result;
        })
      );
  }


  viewEstampDeedJrxmlToHtml(estampCommonBean: any) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_jasper_url + "citizen/jasper/estamp/exportJspReportForEstampDeed",
        estampCommonBean
      ).pipe(
        map((result: any) => {
          // if (result && result.responseStatus == 'true') {
          return result;
          // }
        },
          (error: any) => {

            return error;
          }
        )
      );
  }
  viewCommonEstampDeedJrxmlToHtml(estampCommonBean: any) {
    return this.customeHttpClient
      .Post<ResultModel<any>>(
        this.settings.igrs_services_jasper_url + "common/jasper/estamp/exportCommonJspReportForEstampDeed",
        estampCommonBean
      ).pipe(
        map((result: any) => {
          // if (result && result.responseStatus == 'true') {
          return result;
          // }
        },
          (error: any) => {
            return error;
          }
        )
      );
  }



  downloadEstampEsign(id?: number) {
    return this.customeHttpClient
      .Post<ResultModel<any>>(
        this.settings.igrs_gateway_url + "common/partyesign/downloadEsignPdf/" + id,
        id
        // filename
      ).pipe(
        map((result: any) => {
          if (result) {
            // var string1 = JSON.stringify(result);
            //   var data:any;
            //  filename: 'yourFileName.pdf',
            //   data= result.blob()
            return result;
          }
        },
          (error: any) => {
            alert("not found");
            return error;
          }
        )
      );
  }

  exportFinalEstampDeed(estampCommonBean: any) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_services_jasper_url + "citizen/jasper/estamp/exportFinalEstampDeed",
        estampCommonBean
      ).pipe(
        map((result: any) => {
          // if (result && result.responseStatus == 'true') {
          return result;
          // }
        },
          (error: any) => {

            return error;
          }
        )
      );
  }




  getEstampCommonDetailsForJasper(estampId: any) {
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/estamp/getCommonEstampDataByEstampId?estampId=" + estampId
      ).pipe(
        map((result: any) => {
          return result;

        })
      );
  }

  getEstampDetailsByEregId(eRegId: number) {
    let encrtid = this._AESEncryptDecryptService.encrypt("" + eRegId);
    return this.customeHttpClient
      .Get<ResultModel<any>>(
        this.settings.igrs_services_url + "common/estamp/estampDetailsByEregId?eRegId=" + encrtid
      ).pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  getRepresentativeListUserId(param:any) {
    return this.customeHttpClient
      .PostApiToken<ResultModel<any>>(
        this.settings.igrs_gateway_url + "citizen/getRepresentativeListUserId",
        param
      ).pipe(
        map((result: any) => {
          if (result) {
            return result;
          }
        },
          (error: any) => {

            return error;
          }
        )
      );
  }

}
