import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppSetting, AppSettingService } from 'projects/common-lib/src/lib/helper-lib/app.setting';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
@Component({
  selector: 'app-softw-download',
  templateUrl: './softw-download.component.html',
  styleUrls: ['./softw-download.component.scss']
})
export class SoftwDownloadComponent implements OnInit {
  private settings!: AppSetting;
  isExpanded = true;

  panelOpenState = true;
  lan: number;
  constructor(private appSettingsService: AppSettingService ,
 
         private translate: TranslateService,
        private translateServiceIns: TranslateService,
        private translateService: TranslateHEService,
  ) {
    this.appSettingsService.getSetting().subscribe((settings: AppSetting) => {
      this.settings = settings;
    });


try {
     
      let selectedLanguage = sessionStorage.getItem("selectedLanguage");
      if (sessionStorage.getItem("selectedLanguage") == null || sessionStorage.getItem("selectedLanguage") == '' || sessionStorage.getItem("selectedLanguage") == undefined) {
        this.lan = 1;
        selectedLanguage = 'hi';
      }

  
      this.translateService.currentApprovalStageMessage.subscribe(msg => {
       this.lan = msg
       this.translate.use(msg==1?'hi':'en')
      }
        );
    } catch (err) {
      console.log(err);
    }



   }

  ngOnInit(): void {
  }
  downloadZip(content:any): void {

    var url = this.settings.igrs_gateway_url + "common/dscsoftware/"+  content + ".zip"

    window.open(url, '_blank');

  }

}
