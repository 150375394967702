
import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TranslateHEService } from 'projects/common-lib/src/lib/helper-lib/services/common/translateHE.service';
import { DeedCategoryBean } from 'projects/common-lib/src/lib/models/dutyCalculation.model/deed-category-bean';
import { DeedInstrumentBean } from 'projects/common-lib/src/lib/models/dutyCalculation.model/deed-instrument-bean';
import { DeedTypeBean } from 'projects/common-lib/src/lib/models/dutyCalculation.model/deed-type-bean';
import { InstMappingBean } from 'projects/common-lib/src/lib/models/dutyCalculation.model/inst-mapping-bean';
import { Observable, Subject } from 'rxjs';
import { CommunicationServiceService } from '../duty-service/communication-service.service';
import { DutyService } from '../duty-service/duty.service';
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

import { CMSRegSearchDetails, IgrsEregPartyDetailsBean, IgrsEregPropIdentificationBean, IgrsEregTxnDetlsBean, PropertyIdentificationBySampadaId } from 'projects/common-lib/src/lib/models/e_registry/e-registry.model';
import { MineralSelectionComponent } from '../mineral-selection/mineral-selection.component';
import { MineralMstBean } from 'projects/common-lib/src/lib/models/dutyCalculation.model/mineral-mst-bean';
import { DutyConstants } from 'projects/common-lib/src/lib/models/dutyCalculation.model/duty-constants';
import { ERegCommunicationService } from 'projects/common-lib/src/lib/service/e-reg-communication.service';
//import { EregistryService } from '../../../service/eregistry.service';
//import { EregistryService } from '../e-reg-service/eregistry.service';
import { AlertmessageComponent } from 'projects/common-lib/src/lib/component/alertmessage/alertmessage.component';
import { EregistryPropertyIdentificationComponent } from 'projects/citizen-web/src/app/pages/e-registry/eregistry-property-identification/eregistry-property-identification.component';
import { EregistryService } from 'projects/citizen-web/src/app/pages/e-registry/e-reg-service/eregistry.service';
import { PropertyValuationService } from '../../property-valuation/property-valuation.service';
import { MstPropertyTypeBean } from 'projects/common-lib/src/lib/models/MstPropertyTypeBean';
import { ActivatedRoute } from '@angular/router';

import { MPenagarpalikaResponsModel } from 'projects/common-lib/src/lib/models/enagarpalikaModel';
import { PnrdModel } from 'projects/common-lib/src/lib/models/PnrdModel';
import moment from 'moment';
import Swal from 'sweetalert2';
import { MatInput } from '@angular/material/input';
import { Representative } from 'projects/common-lib/src/lib/models/representative/representative.model';
import { EstampService } from 'projects/citizen-web/src/app/pages/e-stamp/e-stamp-service/estamp.service';
import { CitizenUserProfileDetails, RepresentativeRoleList } from 'projects/common-lib/src/lib/models/citizen.login.model/citizen.profile.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WebGisResponseBeanData } from 'projects/common-lib/src/lib/models/WebGisResponseData';


@Component({
  selector: 'app-instrument-search',
  templateUrl: './instrument-search.component.html',
  styleUrls: ['./instrument-search.component.scss']
})
export class InstrumentSearchComponent implements AfterViewInit, OnInit, OnChanges {
  @ViewChild(AlertmessageComponent, { static: false }) alertmessage: AlertmessageComponent;
  @ViewChild("dutySearchForm", { static: false }) dutyform: NgForm;
  @ViewChild('input', { read: MatInput}) input: MatInput;
  lan: any;
  @Output() emitEregTxnDetlsToEregModule = new EventEmitter<IgrsEregTxnDetlsBean>();
  @Output() emitEregTxnDetlsToEregModuleImpound = new EventEmitter<IgrsEregTxnDetlsBean>();
  @Output() emitEregTxnDetlsToEregModuleCOSImpound = new EventEmitter<IgrsEregTxnDetlsBean>();
  @Output() emitEregTxnDetlsToEregModuleAdjudication = new EventEmitter<IgrsEregTxnDetlsBean>();
  @Output() instMappingEvent = new EventEmitter<any>();
  @Output() showDeedEvent = new EventEmitter<boolean>();
  @Input() fromEregistryModule: boolean;
  @Input() fromEregistryModuleImpound: boolean;
  @Input() fromEregistryModuleCOSImpound: boolean;
  @Input() fromCMSAdjudication: boolean;
  @Input() igrsEregTxnDetlsBean: IgrsEregTxnDetlsBean;
  date:any
  fromEregistryModuleImpoundORCOS: boolean = false;
  editable: boolean = true;
  deedCategoryBeanList: DeedCategoryBean[] = new Array();
  deedTypeCategoryBeanList: DeedTypeBean[] = new Array();
  deedInstrumentBeanList: DeedInstrumentBean[] = new Array();
  filterDeedInstrumentBeanList: DeedInstrumentBean[] = null;
  instMappingBeanList: InstMappingBean[] = new Array();
  deedCatSelected: DeedCategoryBean = new DeedCategoryBean();
  deedCatSelectedId: number;
  searchTextInstId: number;
  deedTypeSelectedId: number;
  deedInstrumentSelectedId: number;
  filtered: any;
  deedTypeSelected: DeedTypeBean = new DeedTypeBean();
  deedInstrumentSelected: DeedInstrumentBean = new DeedInstrumentBean();
  //igrsEregTxnDetlsBeanEQ: IgrsEregTxnDetlsBean = new IgrsEregTxnDetlsBean();
  // igrsEregTxnDetlsBean: IgrsEregTxnDetlsBean ;//= new IgrsEregTxnDetlsBean();
  showDeedFields: boolean = false;
  searchTextInst: DeedInstrumentBean = new DeedInstrumentBean();
  instumentSelectedFrmTop: DeedInstrumentBean = new DeedInstrumentBean();
  serachInstList: DeedInstrumentBean[] = new Array();
  serachInstListResult: DeedInstrumentBean[] = new Array();
  inputs$ = new Subject<any>();
  filterKey: string;
  isSearchInstrumentSelected: boolean = false;
  sales: boolean = false;
  mortgage: boolean = false;
  calculationduty: boolean = false;
  hideSearchFileds: boolean = false;
  willdeed: boolean = false;
  leasedeed: boolean = false;
  giftdeed: boolean = false;
  popUpValueSel: string;
  radioValSel: string;
  mineralTypeId: any;
  mstPropertyTypeBean: MstPropertyTypeBean[] = new Array();
  mineralType: any;
  allMineralList: MineralMstBean[] = new Array();
  selectedMineralList: MineralMstBean[] = new Array();
  filterMineralList: MineralMstBean[] = new Array();
  savedMineralList: MineralMstBean[] = new Array();

  showSubmitBttn: boolean = true
  //24-6 mendemet / Correction
    panelOpenState = false;
     isExpanded = true;
       checkSampadaId: string = "YES";
         sampadaPropertyId: string = "";
         manualSearchPropertyId: string = "";
         fromEregModule: boolean = true;
         registryInitiationDate:any;
         documentRegistrationDate:any;
         parentCategory:any = 111;
         parentDeedType:any = 222;
         parentInstrument:any = 333;


  //end-24-6 mendemet / Correction
  customErrorBean: any = { responseMessage: "", responseMessageEn: "", responseMessageHi: "" };
  propertyIdentificationBySampadaId: PropertyIdentificationBySampadaId[] = new Array();
  propertyIdentificationForSampadaRegistered: IgrsEregPropIdentificationBean[] = new Array();
  propertyIdentificationForSampadaRegisteredAfterExistanceCheck: IgrsEregPropIdentificationBean[] = new Array();
  propertyIdentificationForSampadaRegisteredAfterSelection: IgrsEregPropIdentificationBean[] = new Array();
  representativeList:Representative[] = new Array();
  userProfileModel: CitizenUserProfileDetails = new CitizenUserProfileDetails();

  cmsRegSearchDetails = new CMSRegSearchDetails();
  enablePropMorgageDiv = false;
  enablePropIdentifacationDiv: boolean = true;
  //toDate: Date = new Date();
  toDate = new Date().toJSON().split('T')[0];
  transactiononbehalf:boolean=false
  offSdata : any;
  repreInstrumentsAssignedList?: DeedInstrumentBean[] = [];
  representativeRoleList?:RepresentativeRoleList[];
  isBankRepresentative : boolean = false;
  ifsCodeFilterList?: DeedInstrumentBean[] = [];



  constructor(private pvService: PropertyValuationService, private eRegService: EregistryService, private dcService: DutyService, private ngxService: NgxUiLoaderService, private translateService: TranslateHEService, private translate: TranslateService,
    public dialog: MatDialog, private commServ: CommunicationServiceService, private elementRef: ElementRef,
    private eRegCommService: ERegCommunicationService, public dialogDuty: MatDialog,private estampService: EstampService,
    public propValuationDialogRef: MatDialogRef<EregistryPropertyIdentificationComponent>, private route: ActivatedRoute,  private modalService: NgbModal) {

    this.translateService.currentApprovalStageMessage.subscribe(msg => {
      this.lan = msg
      this.translate.use(msg == 1 ? 'hi' : 'en')
    })

    // //get param from EQ Vault
    // this.route.queryParams.subscribe(params => {
    //   //this.igrsEregTxnDetlsBeanEQ = params['igrsEregTxnDetlsBean'];
    //   console.log('regDocNo', params);
    //   // if (this.igrsEregTxnDetlsBeanEQ != undefined && this.igrsEregTxnDetlsBeanEQ != null) {
    //   //   this.igrsEregTxnDetlsBean = this.igrsEregTxnDetlsBeanEQ;
    //   // }
    // });
  }

  filterType: string = 'byname';
  myDropDown: string;
  myControl = new FormControl('');
  options: string[] = ['One', 'Two', 'Three'];
  selectedText: string;

  ngOnInit(): void {
    this.offSdata = sessionStorage.getItem('selectedOffice');
    if(sessionStorage.getItem("authData") != null && sessionStorage.getItem("authData") != undefined && this.offSdata == null){
       this.userProfileModel = JSON.parse(sessionStorage.getItem("authData") || "");
       this.representativeRoleList = this.userProfileModel?.representativeRoles;
       this.repreInstrumentsAssignedList = this.userProfileModel?.repreInstrumentsAssigned;
       if(this.igrsEregTxnDetlsBean && (this.igrsEregTxnDetlsBean?.userId == null || this.igrsEregTxnDetlsBean?.userId == undefined)){
        this.igrsEregTxnDetlsBean.userId = this.userProfileModel.id;
       }
       this.getRepresentativeListByUserId();
    }
   
    if(this.igrsEregTxnDetlsBean && (this.igrsEregTxnDetlsBean.parentSampadaIdFlag == null || this.igrsEregTxnDetlsBean.parentSampadaIdFlag == undefined))
      this.igrsEregTxnDetlsBean.parentSampadaIdFlag = 1; //default SAMPADA is YES
    
    this.getAllPropertyType()
    // console.log("instrument-search.component:ngOnInit() ");
    //console.log("instrument-search.component:ngOnInit() " + JSON.stringify(this.igrsEregTxnDetlsBean));
    this.mineralTypeId = this.igrsEregTxnDetlsBean?.mineralTypeId;
    this.getAllDeedCategoryList();
    this.getFilteredData(this.inputs$).subscribe((result) => {
      //console.log("instrument-search.component: getFilteredData()");
    });

    if (this.igrsEregTxnDetlsBean?.deedInstrumentSelected?.id == 81 || this.igrsEregTxnDetlsBean?.deedInstrumentSelected?.id == 83 || this.igrsEregTxnDetlsBean?.deedInstrumentSelected?.id == 84) {
      this.showSubmitBttn = false;
      this.getAllMineralList();

    }
    if (this.igrsEregTxnDetlsBean?.deedInstrumentSelectedForAmendmentCancellation?.id == 22 || this.igrsEregTxnDetlsBean?.deedInstrumentSelectedForAmendmentCancellation?.id == 37) {
      
      if(this.igrsEregTxnDetlsBean?.parentSampadaIdFlag != null && this.igrsEregTxnDetlsBean?.parentSampadaIdFlag != undefined){
        if(this.igrsEregTxnDetlsBean?.parentSampadaIdFlag == 1){
          this.checkSampadaId = "YES";
          this.sampadaPropertyId = this.igrsEregTxnDetlsBean?.parentRegNo;
        }
        else if(this.igrsEregTxnDetlsBean?.parentSampadaIdFlag == 0){
          this.checkSampadaId = "NO";
          this.manualSearchPropertyId = this.igrsEregTxnDetlsBean?.parentRegNo;
        }
         
      }
    }

    this.showSubmitBttnFn();

    if (this.fromEregistryModuleImpound || this.fromEregistryModuleCOSImpound || this.fromCMSAdjudication) {
      this.fromEregistryModuleImpoundORCOS = true;
    }

  }

  getRepresentativeListByUserId(){
    let param = {};
    this.ngxService.start();
    this.estampService.getRepresentativeListUserId(param).subscribe(dcdata => {
      if (dcdata.responseStatus == 'true') {
        this.ngxService.stop();
        this.representativeList = dcdata.responseData;
        this.representativeList?.forEach((repre : any) => {
                 if(repre.ifscCode != undefined){
                       this.isBankRepresentative = true;
                 }
        })
        //IFSCCODE papulated
        if (this.igrsEregTxnDetlsBean?.instrumentType == 'EQMortageVault') {
          //let representative = new Representative();
          let representative: Representative[] = new Array();
          if (this.igrsEregTxnDetlsBean?.ifscCode != null && this.igrsEregTxnDetlsBean?.ifscCode != undefined)
            representative = this.representativeList.filter(i => i.ifscCode == this.igrsEregTxnDetlsBean?.ifscCode);
          this.igrsEregTxnDetlsBean.representativeDetail = representative[0];
        }
      } else {
        this.ngxService.stop();
      }
    },
      (err) => {
        this.ngxService.stop();
        this.alertmessage.errorAlertMessage('tech_error_msg');
      });
  }

  getAllPropertyType() {
    this.pvService.getAllPropertyType().subscribe(dmodata => {
      this.mstPropertyTypeBean = dmodata.responseData;

    });

  }
  fetchViewEregistryDetails() {

    if (this.igrsEregTxnDetlsBean?.id != null && this.igrsEregTxnDetlsBean?.id != undefined && !this.igrsEregTxnDetlsBean.isCMSImpoundFlow) {
      //this.eRegService.getEregistryDetailById(this.regId).subscribe((res: any) => {

      // let igrsEregTxnDetlsBean = res.responseData;


      if (this.igrsEregTxnDetlsBean?.deedInstrumentSelectedForAmendmentCancellation?.id == 22 || this.igrsEregTxnDetlsBean?.deedInstrumentSelectedForAmendmentCancellation?.id == 37) {
        this.deedCatSelectedId = this.igrsEregTxnDetlsBean?.deedInstrumentSelectedForAmendmentCancellation.deedTypeId.deedCategoryId.id;
        this.getAllDeedTypeByCategoryId(this.deedCatSelectedId);
        this.deedTypeSelectedId = this.igrsEregTxnDetlsBean?.deedInstrumentSelectedForAmendmentCancellation.deedTypeId.id;
        this.getAllDeedInstrumentsByDeedTypeId(this.deedTypeSelectedId, '');
        this.deedInstrumentSelectedId = this.igrsEregTxnDetlsBean?.deedInstrumentSelectedForAmendmentCancellation.id;
      } else {
        this.deedCatSelectedId = this.igrsEregTxnDetlsBean.deedInstrumentSelected.deedTypeId.deedCategoryId.id;
        this.getAllDeedTypeByCategoryId(this.deedCatSelectedId);
        this.deedTypeSelectedId = this.igrsEregTxnDetlsBean.deedInstrumentSelected.deedTypeId.id;
        this.getAllDeedInstrumentsByDeedTypeId(this.deedTypeSelectedId, '');
        this.deedInstrumentSelectedId = this.igrsEregTxnDetlsBean.deedInstrumentSelected.id;
      }

      if (this.fromEregistryModule) {
        this.emitEregTxnDetlsToEregModule.emit(this.igrsEregTxnDetlsBean);
      } else if (this.fromEregistryModuleImpound) {
        this.emitEregTxnDetlsToEregModuleImpound.emit(this.igrsEregTxnDetlsBean);
      } else if (this.fromEregistryModuleCOSImpound) {
        this.emitEregTxnDetlsToEregModuleCOSImpound.emit(this.igrsEregTxnDetlsBean);
      }else if(this.fromCMSAdjudication){
        this.emitEregTxnDetlsToEregModuleAdjudication.emit(this.igrsEregTxnDetlsBean);
      }
      //})
    }
    else if (this.fromEregistryModuleImpound && this.igrsEregTxnDetlsBean.isCMSImpoundFlow) {

      this.deedCatSelectedId = this.igrsEregTxnDetlsBean.deedInstrumentSelectedImpound.deedTypeId.deedCategoryId.id;
      this.getAllDeedTypeByCategoryId(this.deedCatSelectedId);

      this.deedTypeSelectedId = this.igrsEregTxnDetlsBean.deedInstrumentSelectedImpound.deedTypeId.id;
      this.getAllDeedInstrumentsByDeedTypeId(this.deedTypeSelectedId, '');

      this.deedInstrumentSelectedId = this.igrsEregTxnDetlsBean.deedInstrumentSelectedImpound.id;

      this.emitEregTxnDetlsToEregModuleImpound.emit(this.igrsEregTxnDetlsBean);

    }
    else if (this.fromEregistryModuleCOSImpound && this.igrsEregTxnDetlsBean.isCMSImpoundFlow && this.igrsEregTxnDetlsBean.deedInstrumentSelectedCOSImpound != null) {

      this.deedCatSelectedId = this.igrsEregTxnDetlsBean.deedInstrumentSelectedCOSImpound.deedTypeId.deedCategoryId.id;
      this.getAllDeedTypeByCategoryId(this.deedCatSelectedId);

      this.deedTypeSelectedId = this.igrsEregTxnDetlsBean.deedInstrumentSelectedCOSImpound.deedTypeId.id;
      this.getAllDeedInstrumentsByDeedTypeId(this.deedTypeSelectedId, '');

      this.deedInstrumentSelectedId = this.igrsEregTxnDetlsBean.deedInstrumentSelectedCOSImpound.id;

      this.emitEregTxnDetlsToEregModuleCOSImpound.emit(this.igrsEregTxnDetlsBean);

    }else if(this.fromCMSAdjudication &&  this.igrsEregTxnDetlsBean.deedInstrumentSelectedForAdjudication != null){
  
      this.deedCatSelectedId = this.igrsEregTxnDetlsBean.deedInstrumentSelectedForAdjudication.deedTypeId.deedCategoryId.id;
      this.getAllDeedTypeByCategoryId(this.deedCatSelectedId);

      this.deedTypeSelectedId = this.igrsEregTxnDetlsBean.deedInstrumentSelectedForAdjudication.deedTypeId.id;
      this.getAllDeedInstrumentsByDeedTypeId(this.deedTypeSelectedId, '');

      this.deedInstrumentSelectedId = this.igrsEregTxnDetlsBean.deedInstrumentSelectedForAdjudication.id;

        this.emitEregTxnDetlsToEregModuleAdjudication.emit(this.igrsEregTxnDetlsBean);
      
    }
  }


  showSubmitBttnFn() {
    if (this.fromEregistryModule) {
      this.showSubmitBttn = false;
    } else if (this.fromEregistryModuleImpound || this.fromEregistryModuleCOSImpound) {
      this.showSubmitBttn = false;
    }
    else {
      this.showSubmitBttn = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    //console.log("InstrumentSearchComponent:ngOnChanges");
  }

  ngAfterViewInit() {
    // console.log("instrument-search.component:ngAfterViewInit() ");
    //console.log("instrument-search.component:ngAfterViewInit() " + JSON.stringify(this.igrsEregTxnDetlsBean));

    this.showSubmitBttnFn();
    //this.commServ.instSearchOrDeedChange.next(true);

    this.eRegCommService.resetDutyFields.subscribe(res => {
      if (res) {
        this.resetFields();
      }
    });

    this.eRegCommService.validateDutyFields.subscribe(res => {
      if (res) {
        this.validationForEregModule();
      }
    });

  }
  ngDoCheck() {
    //console.log("InstrumentSearchComponent:ngDoCheck");
  }


  onFilterKeyChange(key: any) {
    //this.resetHighlighterInParent();
    //this.clearDeedcategoryData();
    //this.clearDeedTypeData();
    // this.clearDeedInstrumentData();
    this.filterKey = key.searchTerm;
    //console.log("filterKey=", this.filterKey);
    if (this.filterKey !== undefined && this.filterKey != null) {
      if (this.filterKey.length > 3) {

        //this.deedInstrumentSelected =  new DeedInstrumentBean();
        this.inputs$.next({ filterKey: this.filterKey });
      }
    }
  }

  getInstrumentDtls(name: any) {
    this.serachInstList = null

    let text = name.searchTerm;
    if (text != null && text.length >= 3) {
      //this.ngxService.start();
      this.dcService.getInstrumentDetailsByText(this.lan, text).subscribe(dcdata => {

        if (dcdata.responseStatus === "true") {
          this.serachInstList = dcdata.responseData;
        } else {
          this.serachInstList = new Array();
        }
      });
    }
  }


  getFilteredData(inputs: Observable<any>) {
    //console.log("getFilteredData=");
    this.serachInstList = new Array();
    this.serachInstListResult = new Array();
    return inputs.pipe(

      filter(text => text.filterKey.length > 3),
      debounceTime(750),
      //distinctUntilChanged((p, q) => JSON.stringify(p.filterKey) === JSON.stringify(q.filterKey)),
      distinctUntilChanged((p, q) => p.filterKey === q.filterKey),
      //distinctUntilChanged(),

      switchMap((input) => {
        let key = input.filterKey.trim();
        //console.log("getFilteredData -key..... ", key);
        // Filter the data.
        this.dcService.getInstrumentDetailsByText(this.lan, key).subscribe(dcdata => {

          if (dcdata.responseStatus == "true") {
            this.serachInstList = dcdata.responseData;
            if (this.serachInstList === undefined || this.serachInstList == null) {
              this.serachInstList = new Array();
            }
          }
          else {
            this.resetFields();
          }
        });

        // Filter the data.
        if (this.serachInstList !== undefined || this.serachInstList !== null) {
          this.serachInstListResult = this.serachInstList.filter((item) => item.instrumentDescEn.toLowerCase().includes(key.toLowerCase()));
        }

        return this.serachInstListResult;

      })
    );
  }

  // onSearchInstrumentSelectedTest() {
  //   if (this.searchTextInstId !== undefined) {
  //     this.searchTextInst = this.serachInstList.find(i => i.id == this.searchTextInstId);
  //     this.deedInstrumentSelected = this.searchTextInst;
  //     this.deedInstrumentSelectedId = this.deedInstrumentSelected.id;
  //     this.deedTypeSelected = this.deedInstrumentSelected.deedTypeId;
  //     this.deedTypeSelectedId = this.deedTypeSelected.id;
  //     this.deedCatSelected = this.deedTypeSelected.deedCategoryId;
  //     this.deedCatSelectedId = this.deedCatSelected.id;

  //     let dTypeRet = this.getAllDeedTypeByCategoryId(this.deedCatSelectedId);
  //     this.getAllDeedInstrumentsByDeedTypeId(this.deedTypeSelectedId);
  //   }

  // }

  // onSearchInstrumentSelectedNew() {
  //   this.searchTextInst = this.serachInstList.find(i => i.id == this.searchTextInstId);
  //   this.deedInstrumentSelected = this.searchTextInst;


  //   this.deedTypeSelected = this.deedInstrumentSelected.deedTypeId;
  //   this.deedCatSelected = this.deedTypeSelected.deedCategoryId;
  //   this.deedTypeSelected = this.deedInstrumentSelected.deedTypeId;
  //   this.deedInstrumentSelected = this.searchTextInst;

  //   this.deedTypeCategoryBeanList = new Array();
  //   this.deedInstrumentBeanList = new Array();

  //   this.deedCatSelectedId = this.deedCatSelected.id;
  //   this.deedTypeCategoryBeanList.push(this.deedTypeSelected);
  //   this.deedTypeSelectedId = this.deedTypeSelected.id;
  //   this.deedTypeCategoryBeanList = this.deedTypeCategoryBeanList.map(item => item).filter((value, index, self) => self.indexOf(value) === index);

  //   this.deedInstrumentBeanList.push(this.deedInstrumentSelected);
  //   this.deedInstrumentSelectedId = this.deedInstrumentSelected.id;
  //   this.deedInstrumentBeanList = this.deedInstrumentBeanList.map(item => item).filter((value, index, self) => self.indexOf(value) === index);


  // }

  onSearchInstrumentSelected() {
    this.isSearchInstrumentSelected = true;
    this.deedTypeCategoryBeanList = new Array();
    this.deedInstrumentBeanList = new Array();
    if (this.searchTextInstId !== undefined && this.searchTextInstId != null && this.serachInstList != null) {
      if (this.serachInstList.length > 0) {

        this.searchTextInst = this.serachInstList.find(i => i.id == this.searchTextInstId);

        this.deedInstrumentSelected = this.searchTextInst;

        this.deedInstrumentBeanList.push(this.deedInstrumentSelected);
        this.serachInstList = this.serachInstList.filter(x => x.deedTypeId.id == this.deedInstrumentSelected.deedTypeId.id);
        this.deedInstrumentBeanList = this.serachInstList;
        this.clearSearchData();
        this.deedInstrumentBeanList = this.deedInstrumentBeanList.map(item => item).filter((value, index, self) => self.indexOf(value) === index);


        this.deedInstrumentSelectedId = this.deedInstrumentSelected.id;
        //this.deedInstrumentSelectedId =null;
        this.deedTypeSelected = this.deedInstrumentSelected.deedTypeId;
        this.deedTypeSelectedId = this.deedTypeSelected.id;

        this.deedCatSelected = this.deedTypeSelected.deedCategoryId;
        this.deedCatSelectedId = this.deedTypeSelected.deedCategoryId.id;

        this.deedTypeCategoryBeanList.push(this.deedTypeSelected);
        this.deedTypeCategoryBeanList = this.deedTypeCategoryBeanList.map(item => item).filter((value, index, self) => self.indexOf(value) === index);

        this.getAllDeedTypeByCategoryId(this.deedCatSelectedId);
        this.onDeedInstrumentSelected(true);

        // Show only avilable services
        // this.filterDeedInstrumentBeanList = new Array();
        // if (this.repreInstrumentsAssignedList != null && this.repreInstrumentsAssignedList.length > 0) {
        //   for (let i = 0; i < this.repreInstrumentsAssignedList.length; i++) {
        //     //const element = array[i];
        //     let insID = this.deedInstrumentBeanList.find(j => j.id == this.repreInstrumentsAssignedList[i]);
        //     if (insID != undefined) {
        //       this.filterDeedInstrumentBeanList.push(insID);
        //     }
        //   }

        //   if (this.filterDeedInstrumentBeanList != null && this.filterDeedInstrumentBeanList.length > 0) {
        //     this.deedInstrumentBeanList = null;
        //     this.deedInstrumentBeanList = [...this.filterDeedInstrumentBeanList];
        //   } else {
        //     this.deedInstrumentBeanList = [...this.filterDeedInstrumentBeanList];
        //   }
        // }

        if (this.fromEregistryModule) {
          this.igrsEregTxnDetlsBean.deedInstrumentSelected = this.deedInstrumentSelected;
          this.emitEregTxnDetlsToEregModule.emit(this.igrsEregTxnDetlsBean);
        } else if (this.fromEregistryModuleImpound) {
          this.igrsEregTxnDetlsBean.deedInstrumentSelectedImpound = this.deedInstrumentSelected;

          this.emitEregTxnDetlsToEregModuleImpound.emit(this.igrsEregTxnDetlsBean);
        } else if (this.fromEregistryModuleCOSImpound) {
          this.igrsEregTxnDetlsBean.deedInstrumentSelectedCOSImpound = this.deedInstrumentSelected;

          this.emitEregTxnDetlsToEregModuleCOSImpound.emit(this.igrsEregTxnDetlsBean);
        }
        else if (this.fromCMSAdjudication) {
          this.igrsEregTxnDetlsBean.deedInstrumentSelectedForAdjudication = this.deedInstrumentSelected;

          this.emitEregTxnDetlsToEregModuleAdjudication.emit(this.igrsEregTxnDetlsBean);
        }
      }
    }

  }

  onInstrumentSelectedFromTop(instrumentId: any, deedTypeId: any) {
    this.deedInstrumentSelected = null;
    this.searchTextInstId = null;
    this.deedTypeCategoryBeanList = new Array();
    this.deedInstrumentBeanList = new Array();
    // this.dcService.getAllDeedInstrumentsByDeedTypeId

    this.dcService.getAllDeedInstrumentsByDeedTypeId(deedTypeId).subscribe(dcdata => {
      // this.ngxService.stop();
      //console.log("getInstrumentDetailsById -subscribe..... ");

      if (dcdata.responseStatus === "true") {
        this.deedInstrumentBeanList = dcdata.responseData;

        this.instumentSelectedFrmTop = this.deedInstrumentBeanList.map(item => item).filter(f => f.id == instrumentId)[0];
        //console.log(this.instumentSelectedFrmTop);

        if (this.instumentSelectedFrmTop !== undefined && this.instumentSelectedFrmTop != null) {

          this.deedInstrumentSelected = this.instumentSelectedFrmTop;

          this.deedInstrumentBeanList = this.deedInstrumentBeanList.map(item => item).filter((value, index, self) => self.indexOf(value) === index);

          this.deedInstrumentSelectedId = null;
          this.deedTypeSelected = this.deedInstrumentSelected.deedTypeId;
          this.deedTypeSelectedId = this.deedTypeSelected.id;

          this.deedCatSelected = this.deedTypeSelected.deedCategoryId;
          this.deedCatSelectedId = this.deedTypeSelected.deedCategoryId.id;

          this.getAllDeedTypeByCategoryId(this.deedCatSelectedId);

          this.onDeedInstrumentSelected(false);

          // Show only avilable services
          // this.filterDeedInstrumentBeanList = new Array();
          // if (this.repreInstrumentsAssignedList != null && this.repreInstrumentsAssignedList.length > 0) {
          //   for (let i = 0; i < this.repreInstrumentsAssignedList.length; i++) {
          //     //const element = array[i];
          //     let insID = this.deedInstrumentBeanList.find(j => j.id == this.repreInstrumentsAssignedList[i]);
          //     if (insID != undefined) {
          //       this.filterDeedInstrumentBeanList.push(insID);
          //     }
          //   }
  
          //   if (this.filterDeedInstrumentBeanList != null && this.filterDeedInstrumentBeanList.length > 0) {
          //     this.deedInstrumentBeanList = null;
          //     this.deedInstrumentBeanList = [...this.filterDeedInstrumentBeanList];
          //   } else {
          //     this.deedInstrumentBeanList = [...this.filterDeedInstrumentBeanList];
          //   }
          // }

          if (this.fromEregistryModule) {
            this.igrsEregTxnDetlsBean.deedInstrumentSelected = null;
            this.emitEregTxnDetlsToEregModule.emit(this.igrsEregTxnDetlsBean);
          } else if (this.fromEregistryModuleImpound) {
            this.igrsEregTxnDetlsBean.deedInstrumentSelectedImpound = null;

            this.emitEregTxnDetlsToEregModuleImpound.emit(this.igrsEregTxnDetlsBean);
          }

        }
      }
    });
  }

  // onInstrumentSelectedFromTopOLD(instrumentId: any) {

  //   this.deedTypeCategoryBeanList = new Array();
  //   this.deedInstrumentBeanList = new Array();

  //   this.dcService.getInstrumentDetailsById(instrumentId).subscribe(dcdata => {

  //     if (dcdata.responseStatus === "true") {
  //       this.instumentSelectedFrmTop = dcdata.responseData;

  //       if (this.instumentSelectedFrmTop !== undefined && this.instumentSelectedFrmTop != null) {

  //         this.deedInstrumentSelected = this.instumentSelectedFrmTop;

  //         this.deedInstrumentBeanList.push(this.deedInstrumentSelected);
  //         this.deedInstrumentBeanList = this.deedInstrumentBeanList.map(item => item).filter((value, index, self) => self.indexOf(value) === index);

  //         this.deedInstrumentSelectedId = this.deedInstrumentSelected.id;

  //         this.deedTypeSelected = this.deedInstrumentSelected.deedTypeId;
  //         this.deedTypeSelectedId = this.deedTypeSelected.id;

  //         this.deedCatSelected = this.deedTypeSelected.deedCategoryId;
  //         this.deedCatSelectedId = this.deedTypeSelected.deedCategoryId.id;

  //         this.getAllDeedTypeByCategoryId(this.deedCatSelectedId);

  //         this.onDeedInstrumentSelected(false);

  //       }
  //     } 
  //   });
  // }

  getAllDeedCategoryList() {

    if (this.deedCategoryBeanList != null && this.deedCategoryBeanList.length == 0) {
      this.ngxService.start();
      this.dcService.getAllDeedCategory().subscribe(dcdata => {
        this.ngxService.stop();

        if (dcdata.responseStatus === "true") {
          this.deedCategoryBeanList = dcdata.responseData;
        }

        if (this.igrsEregTxnDetlsBean?.id) {
          this.fetchViewEregistryDetails();
        } else if (this.igrsEregTxnDetlsBean?.instrumentType == 'EQMortageVault') {
          this.deedCatSelectedId = 4;
          this.getAllDeedTypeByCategoryId(this.deedCatSelectedId);
          this.deedTypeSelectedId = 49;
          this.getAllDeedInstrumentsByDeedTypeId(this.deedTypeSelectedId, 'EQMortageVault');
          this.deedInstrumentSelectedId = 126;
        }

        //}

      });
    }
  }


  onDeedCategorySelected() {
    this.resetHighlighterInParent();
    this.clearSearchData();
    this.deedTypeSelected = new DeedTypeBean();
    this.deedInstrumentSelected = new DeedInstrumentBean();
    this.deedTypeSelectedId = null;
    this.deedInstrumentSelectedId = null;
    if (this.deedCatSelectedId !== undefined && this.deedCatSelectedId !== null) {
      this.deedCatSelected = this.deedCategoryBeanList.find(i => i.id == this.deedCatSelectedId);
      this.deedCatSelectedId = this.deedCatSelectedId;
      //console.log(this.deedCatSelected);
      //this.filtered = this.DeedCategoryBeanList.filter(t=>t.deedCategoryId ==this.deedCatSelected.deedCategoryId);
      //console.log(this.deedCatSelected !== undefined ? this.deedCatSelected.id : '');
      if (this.deedCatSelected !== undefined && this.deedCatSelected !== null) {
        this.getAllDeedTypeByCategoryId(this.deedCatSelected.id);
      }
      if (this.fromEregistryModule) {
        this.igrsEregTxnDetlsBean.deedInstrumentSelected = null;
        this.emitEregTxnDetlsToEregModule.emit(this.igrsEregTxnDetlsBean);
      } else if (this.fromEregistryModuleImpound) {
        this.igrsEregTxnDetlsBean.deedInstrumentSelectedImpound = null;

        this.emitEregTxnDetlsToEregModuleImpound.emit(this.igrsEregTxnDetlsBean);
      }
    }
  }


  getAllDeedTypeByCategoryId(deedCategoryIdSelcted: any) {

    this.ngxService.start();
    this.dcService.getAllDeedTypeByCategoryId(deedCategoryIdSelcted).subscribe(dcdata => {
      this.ngxService.stop();
      if (dcdata.responseStatus === "true") {
        this.deedTypeCategoryBeanList = dcdata.responseData;
      }
    });
  }


  onDeedTypeSelected() {
    this.resetHighlighterInParent();
    this.clearSearchData();
    this.deedInstrumentSelected = new DeedInstrumentBean();
    this.deedInstrumentSelectedId = null;
    if (this.deedTypeSelectedId !== undefined && this.deedTypeSelectedId !== null) {
      this.deedTypeSelected = this.deedTypeCategoryBeanList.find(i => i.id == this.deedTypeSelectedId);
      this.deedTypeSelectedId = this.deedTypeSelectedId;


      if (this.deedTypeSelected !== undefined && this.deedTypeSelected !== null) {
        this.getAllDeedInstrumentsByDeedTypeId(this.deedTypeSelected.id, '');
      }
      if (this.fromEregistryModule) {
        this.igrsEregTxnDetlsBean.deedInstrumentSelected = null;
        this.emitEregTxnDetlsToEregModule.emit(this.igrsEregTxnDetlsBean);
      } else if (this.fromEregistryModuleImpound) {
        this.igrsEregTxnDetlsBean.deedInstrumentSelectedImpound = null;

        this.emitEregTxnDetlsToEregModuleImpound.emit(this.igrsEregTxnDetlsBean);
      }
    }

  }



  getAllDeedInstrumentsByDeedTypeId(deedTypeIdSelected: any, from: string): boolean {
    let ret: boolean;
    this.dcService.getAllDeedInstrumentsByDeedTypeId(deedTypeIdSelected).subscribe(dcdata => {

      if (dcdata.responseStatus === "true") {
        this.deedInstrumentBeanList = dcdata.responseData;

        //if (this.filterDeedInstrumentBeanList != null && this.filterDeedInstrumentBeanList.length > 0)
        // Show only avilable services
        //   this.filterDeedInstrumentBeanList = new Array();

        // if (this.repreInstrumentsAssignedList != null && this.repreInstrumentsAssignedList.length > 0) {
        //   for (let i = 0; i < this.repreInstrumentsAssignedList.length; i++) {
        //     //const element = array[i];
        //     let insID = this.deedInstrumentBeanList.find(j => j.id == this.repreInstrumentsAssignedList[i]);
        //     if (insID != undefined) {
        //       this.filterDeedInstrumentBeanList.push(insID);
        //     }
        //   }

        //   if (this.filterDeedInstrumentBeanList != null && this.filterDeedInstrumentBeanList.length > 0) {
        //     this.deedInstrumentBeanList = null;
        //     this.deedInstrumentBeanList = [...this.filterDeedInstrumentBeanList];
        //   } else {
        //     this.deedInstrumentBeanList = [...this.filterDeedInstrumentBeanList];
        //   }
        // }

        if (from == 'EQMortageVault')
          this.onDeedInstrumentSelected(true);
      }
    });

    return ret;
  }


  onDeedInstrumentSelected(isInstFromTop: boolean) {
    //isInstFromTop == true ? this.resetHighlighterInParent() : '';
    this.clearSearchData();
    // this.isInstrumentSelected = true;
    if (this.deedInstrumentSelectedId !== undefined && this.deedInstrumentSelectedId !== null) {
      if (this.deedInstrumentBeanList.length > 0) {
        this.deedInstrumentSelected = this.deedInstrumentBeanList.find(i => i.id == this.deedInstrumentSelectedId);
        this.getAllInstMappingById(this.deedInstrumentSelected?.id);
        if (this.fromEregistryModule && isInstFromTop) {
           //Validation for selected instrument for representative
          if (this.repreInstrumentsAssignedList != null && this.repreInstrumentsAssignedList.length > 0) {

            //Other Bank branch selection (Go ahead as representative)
            if (this.igrsEregTxnDetlsBean?.representativeDetail?.citizenId != null && this.igrsEregTxnDetlsBean?.representativeDetail?.citizenId != undefined)
              this.igrsEregTxnDetlsBean.userId = this.igrsEregTxnDetlsBean?.representativeDetail?.citizenId

            //Filter assigned instrument by Bank branch(By IFSC code)
            if (this.igrsEregTxnDetlsBean?.representativeDetail?.ifscCode) {
              this.ifsCodeFilterList = this.repreInstrumentsAssignedList.filter(i => i.ifscCode == this.igrsEregTxnDetlsBean?.representativeDetail?.ifscCode);

              let insID = this.ifsCodeFilterList.find(i => i.instrumentId == this.deedInstrumentSelectedId);
              console.log(insID);
              if (insID == undefined && this.igrsEregTxnDetlsBean.userId != this.userProfileModel?.id) {
                this.deedInstrumentSelectedId = undefined;
                this.alertmessage.errorAlertMessage('e-stamp.not-authorized-to-process');
                return;
              }
            } else {
              let insID = this.repreInstrumentsAssignedList.find(i => i.instrumentId == this.deedInstrumentSelectedId);
              console.log(insID);
              if (insID == undefined && this.igrsEregTxnDetlsBean.userId != this.userProfileModel?.id) {
                this.deedInstrumentSelectedId = undefined;
                this.alertmessage.errorAlertMessage('e-stamp.not-authorized-to-process');
                return;
              }
            }
          }
          this.igrsEregTxnDetlsBean.deedInstrumentSelected = this.deedInstrumentSelected;
          this.emitEregTxnDetlsToEregModule.emit(this.igrsEregTxnDetlsBean);

          if (this.igrsEregTxnDetlsBean.deedInstrumentSelected.id == 81 || this.igrsEregTxnDetlsBean.deedInstrumentSelected.id == 83 || this.igrsEregTxnDetlsBean.deedInstrumentSelected.id == 84) {
            this.getAllMineralList();
          }
        } else if (this.fromEregistryModuleImpound) {
          this.igrsEregTxnDetlsBean.deedInstrumentSelectedImpound = this.deedInstrumentSelected;

          this.emitEregTxnDetlsToEregModuleImpound.emit(this.igrsEregTxnDetlsBean);
        } else if (this.fromEregistryModuleCOSImpound) {
          this.igrsEregTxnDetlsBean.deedInstrumentSelectedCOSImpound = this.deedInstrumentSelected;

          this.emitEregTxnDetlsToEregModuleCOSImpound.emit(this.igrsEregTxnDetlsBean);
        }
        else if (this.fromCMSAdjudication) {
          this.igrsEregTxnDetlsBean.deedInstrumentSelectedForAdjudication= this.deedInstrumentSelected;

          this.emitEregTxnDetlsToEregModuleAdjudication.emit(this.igrsEregTxnDetlsBean);
        }
      }
    }
  }

  getAllMineralList() {
    this.dcService.getAllMinerals().subscribe(dcdata => {
      //console.log("getAllMineralList -subscribe..... ");
      this.allMineralList = dcdata.responseData;
      if (this.allMineralList === undefined) {
        //console.log(" getAllMineralList  undefined");
      } else {
        //console.log("getAllMineralList -: ",this.allMineralList);
      }
    });
  }


  getAllInstMappingById(instrumentId: number) {
    // console.log("---- getAllInstMappingById ---------");
    // console.log("getAllInstMappingById:instrumentId:- ", instrumentId);
    //this.ngxService.start();
    this.ngxService.startLoader('instrument-search');
    if (instrumentId !== undefined && instrumentId !== null) {
      this.dcService.getAllInstMappingById(instrumentId).subscribe(dcdata => {
        //this.ngxService.stop();
        this.ngxService.stopLoader('instrument-search');
        //console.log("getAllInstMappingById -subscribe..... ");
        this.instMappingBeanList = null;
        if (dcdata.responseStatus === "true") {
          // console.log("getAllInstMappingById dcdata.responseData ",dcdata.responseData);
          this.instMappingBeanList = dcdata.responseData;
          // console.log("getAllInstMappingById instMappingBeanList ",this.instMappingBeanList.length);
        } else {
          // console.log(" instMappingBeanList  undefined");

        }
      });
    }
  }


  validationForEregModule() {
    //console.log("validationForEregModule");
    //this.dutyform.ngSubmit.emit();
    this.dutyform.onSubmit(undefined);
  }

  onSubmitDeedDetails(dutySearchform: NgForm, showDeedEventParam: boolean) {
    this.ngxService.startLoader('instrument-search');
    // this.getAllInstMappingById(this.deedInstrumentSelected.id);
    if (this.deedInstrumentSelected !== undefined && this.deedInstrumentSelected !== null) {
      this.getAllInstMappingById(this.deedInstrumentSelected.id);
    }
    if (this.mineralTypeId == null && this.mineralTypeId == undefined) {
      dutySearchform.invalid;
    }
    this.commServ.raiseEvent(this.deedInstrumentSelected);
    this.instMappingEvent.emit(this.instMappingBeanList);
    this.showDeedEvent.emit(showDeedEventParam);
    this.commServ.raiseClearEvent();
    this.ngxService.stopLoader('instrument-search');

  }

  onSubmitDeedDetailsOld() {
    //this.getAllInstMappingById(this.deedInstrumentSelected.id);
    this.commServ.raiseEvent(this.deedInstrumentSelected);
    this.instMappingEvent.emit(this.instMappingBeanList);
    this.showDeedEvent.emit(true);

    this.commServ.raiseClearEvent();
  }

  close_Nav() {
    this.elementRef.nativeElement.querySelector('.navbar-collapse').classList.remove('show');
  }

  changeDutyCalculate(duties: any): void {

    if (!(this.igrsEregTxnDetlsBean?.statusBean?.id >= 45) || this.fromEregistryModuleImpound || this.fromEregistryModuleCOSImpound || this.fromCMSAdjudication) {

      this.hideSearchFileds = true;
      this.setDefaultHighliter();

      if (duties == 'calculationduty') {

        this.calculationduty = true;

      }
      if (duties == 'sales') {


        let instId: Number = 59;
        this.onInstrumentSelectedFromTop(instId, 60);
        this.sales = true;
      }
      if (duties == 'mortgage') {

        //this.mortgage = true;
        this.onInstrumentSelectedFromTop(18, 16);

        this.mortgage = true;
      }
      if (duties == 'willdeed') {
        // this.willdeed = true;
        this.onInstrumentSelectedFromTop(144, 8);
        this.willdeed = true;
      }
      if (duties == 'leasedeed') {
        //this.leasedeed = true;
        this.onInstrumentSelectedFromTop(81, 1);
        this.leasedeed = true;
      }
      if (duties == 'giftdeed') {
        //this.giftdeed = true;
        this.onInstrumentSelectedFromTop(72, 63);
        this.giftdeed = true;
        //73
      }
    }
  }

  setDefaultHighliter() {
    this.sales = false;
    this.mortgage = false;
    this.calculationduty = false;
    this.willdeed = false;
    this.leasedeed = false;
    this.giftdeed = false;
    this.showDeedEvent.emit(false);
  }

  clearDeedcategoryData() {
    this.deedCatSelectedId = null;
    this.deedCatSelected = new DeedCategoryBean();
    this.igrsEregTxnDetlsBean?.deedInstrumentSelected == null ? null : this.igrsEregTxnDetlsBean?.deedInstrumentSelected;
    //this.igrsEregTxnDetlsBean.instrumentId = null;
    this.emitEregTxnDetlsToEregModule.emit(this.igrsEregTxnDetlsBean);
  }


  clearDeedTypeData() {
    this.deedTypeSelectedId = null;
    this.deedTypeSelected = new DeedTypeBean();
    this.deedTypeCategoryBeanList = new Array();

    this.igrsEregTxnDetlsBean?.deedInstrumentSelected == null ? null : this.igrsEregTxnDetlsBean?.deedInstrumentSelected;
    //this.igrsEregTxnDetlsBean.instrumentId = null;
    this.emitEregTxnDetlsToEregModule.emit(this.igrsEregTxnDetlsBean);
  }

  clearDeedInstrumentData() {
    this.deedInstrumentSelectedId = null;
    this.deedInstrumentSelected = new DeedInstrumentBean();
    this.deedInstrumentBeanList = new Array();
    this.igrsEregTxnDetlsBean?.deedInstrumentSelected == null ? null : this.igrsEregTxnDetlsBean?.deedInstrumentSelected;
    //this.igrsEregTxnDetlsBean.instrumentId = null;
    this.emitEregTxnDetlsToEregModule.emit(this.igrsEregTxnDetlsBean);
  }


  clearSearchData() {

    this.serachInstList = new Array();
    this.searchTextInst = new DeedInstrumentBean();
    this.searchTextInstId = null;
    this.serachInstListResult = new Array();
    this.igrsEregTxnDetlsBean?.deedInstrumentSelected == null ? null : this.igrsEregTxnDetlsBean?.deedInstrumentSelected;
    //this.igrsEregTxnDetlsBean.instrumentId = null;
    this.emitEregTxnDetlsToEregModule.emit(this.igrsEregTxnDetlsBean);
  }

  resetHighlighterInParent() {
    //console.log("InstrumentSearchComponent:resetHighlighterInParent");
    if (this.isSearchInstrumentSelected == false) { this.searchTextInstId = null; }
    // this.commServ.instSearchOrDeedChange.next(true);
    this.setDefaultHighliter();
  }

  resetFields() {
    //console.log(" reset instrument search fields");
    if (this.fromEregistryModule) {
      //console.log(" From ereg module on deed selection change");
      // this.eRegCommService.emitInstDetlsToEregModule.emit(this.deedInstrumentSelected);
      //this.igrsEregTxnDetlsBean.id = this.igrsEregTxnDetlsBeanTemp?.id
      this.igrsEregTxnDetlsBean.deedInstrumentSelected = null;
      //this.igrsEregTxnDetlsBean.instrumentId = null;
      this.emitEregTxnDetlsToEregModule.emit(this.igrsEregTxnDetlsBean);
    } else if (this.fromEregistryModuleImpound) {
      this.igrsEregTxnDetlsBean.deedInstrumentSelectedImpound = this.igrsEregTxnDetlsBean.deedInstrumentSelected;

      this.emitEregTxnDetlsToEregModuleImpound.emit(this.igrsEregTxnDetlsBean);
    }
    this.dutyform.resetForm();
    this.deedCategoryBeanList = new Array();
    this.instMappingBeanList = new Array();
    this.deedCatSelected = new DeedCategoryBean();
    this.isSearchInstrumentSelected = false;
    this.deedTypeSelected = new DeedTypeBean();
    this.deedInstrumentSelected = new DeedInstrumentBean();
    this.hideSearchFileds = false;
    this.checkSampadaId = "YES";
    this.clearSearchData();
    this.getAllDeedCategoryList();
    this.onSubmitDeedDetails(this.dutyform, false);

  }

  openMineralDialog(event: any, selectId: any) {
    if (this.igrsEregTxnDetlsBean.mineralTypeId != null && this.igrsEregTxnDetlsBean.mineralTypeId != undefined && this.igrsEregTxnDetlsBean.mineralTypeId == selectId) {
      this.savedMineralList = this.igrsEregTxnDetlsBean.selectedMineralList;
    } else {
      this.savedMineralList = new Array();
    }
    //this.mineralTypeId = selectId;
    if (selectId == 1) {
      this.radioValSel = DutyConstants.major_min;
    } else if (selectId == 2) {
      this.radioValSel = DutyConstants.minor_min;
    }
    //this.radioValSel = event.target.value.toUpperCase();
    this.mineralType = this.radioValSel
    if (this.radioValSel === DutyConstants.major_min) {
      //this.igrsEregTxnDetlsBean.mineralTypeId = 1;
      this.filterMineralList = this.allMineralList.map(item => item).filter((item) => item.mineralType.toUpperCase() === DutyConstants.major_min);
    } else if (this.radioValSel === DutyConstants.minor_min) {
      //this.igrsEregTxnDetlsBean.mineralTypeId = 2;
      this.filterMineralList = this.allMineralList.map(item => item).filter((item) => item.mineralType.toUpperCase() === DutyConstants.minor_min);
    }

    let obj = { filterMineralList: this.filterMineralList, popUpValueSel: this.radioValSel, savedMineralList: this.savedMineralList };
    this.dialogDuty.open(MineralSelectionComponent, {
      data: obj,
      maxWidth: '60vw',
      disableClose: true,
      width: '60%'
    }).afterClosed().subscribe(res => {
      this.selectedMineralList = res.selectedMineralList;
      if (this.selectedMineralList != null && this.selectedMineralList != undefined && this.selectedMineralList.length > 0) {
        this.igrsEregTxnDetlsBean.mineralTypeId = selectId;
        this.igrsEregTxnDetlsBean.selectedMineralList = this.selectedMineralList;
        this.emitEregTxnDetlsToEregModule.emit(this.igrsEregTxnDetlsBean);
      } else {
        this.radioValSel = null;
        this.mineralType = null;
      }
    });
  }




  /*************************Amendemet / Correction*************** */
  checkSampadaIdStatus(stats: string, content:any) {
    if (stats === "YES") {
      this.igrsEregTxnDetlsBean.parentSampadaIdFlag = 1;
    }
    else if (stats === "NO") {
      this.igrsEregTxnDetlsBean.parentSampadaIdFlag = 0;
        this.modalService.open(content,
      {  size: 'md', centered: true, backdrop:true })
       
     
    }
  }

  getUnit(element: any) {
    if (element == 'HA') {
      return this.lan == 0 ? 'HA' : 'हे.'
    } else if (element == 'SQMT') {
      return this.lan == 0 ? 'SQMT' : 'व.मी.'
    } else if (element == 'SQFT') {
      return this.lan == 0 ? 'SQFT' : 'व.फु.'
    } else {
      return ''
    }
  }

  // addDemography(propIdentiObj: PropertyIdentificationBySampadaId, igrsEregTxnDetlsBean: IgrsEregTxnDetlsBean, indexh: number) {
  //   let obj = { fromEregModule: this.fromEregModule, propIdentiObj: igrsEregTxnDetlsBean, ismannual: true, getDemographyForSampada1: true, districtId: propIdentiObj.districtId };
  //   this.propValuationDialogRef = this.dialog.open(EregistryPropertyIdentificationComponent, {
  //     data: obj, maxWidth: '90vw',
  //     //maxHeight: '80vh',
  //     height: 'auto',
  //     width: '100%',
  //     panelClass: 'full-screen-modal', disableClose: true
  //   });

  //   this.propValuationDialogRef.afterClosed().subscribe(res => {
  //     if (res != "" && res) {
  //       this.propertyIdentificationForSampadaRegisteredAfterExistanceCheck.forEach((identiObj: any, index: number) => {
  //         console.log(identiObj)
  //         if (identiObj.propertyId == propIdentiObj.propertyId) {
  //           if (identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr[indexh - 1]?.demography && identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr[indexh - 1]?.demography.id != res.demography.id) {
  //             this.alertmessage.errorAlertMessage({
  //               responseMessage: 'The guildline location of both properties is not the same',
  //               responseMessageEn: 'The guildline location of both properties is not the same',
  //               responseMessageHi: 'दोनों संपत्तियों का गिल्डलाइन स्थान समान नहीं है'
  //             })
  //             return
  //           }
  //           if (identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr[indexh - 1]?.landParcelId && identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr[indexh - 1]?.landParcelId == res.webGisResponseBeanDataArr[index].landParcelId) {
  //             this.alertmessage.errorAlertMessage({
  //               responseMessage: 'Duplicate property ID not added',
  //               responseMessageEn: 'Duplicate property ID not added',
  //               responseMessageHi: 'डुप्लिकेट प्रॉपर्टी आईडी नहीं जोड़ी गई'
  //             })
  //             return
  //           }

  //          // propIdentiObj.demography = res.demography;
  //          // identiObj.demography = res.demography;
  //           //identiObj.propertyValuationRequestBean.demography = res.demography;
  //           if (Array.isArray(identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr)) {
  //             if(identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData&&res.webGisResponseBeanDataArr){
  //               this.alertmessage.errorAlertMessage({responseMessage:"responseMessage",responseMessageHi:"समतामूलक बंधक में संपत्ति (p) को संपत्ति (S) में न बदलें",responseMessageEn:"Not change property (p) to property (s) in equitable mortgage"})
  //               return
  //             }
  //             identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr
  //             if (res.webGisResponseBeanDataArr) {
  //               propIdentiObj.demography = res.demography;
  //               identiObj.demography = res.demography;
  //               identiObj.propertyValuationRequestBean.demography = res.demography;
  //               identiObj.demographyId=res.demography.id
  //               propIdentiObj.transactingArea = res.webGisResponseBeanDataArr[0].totalArea
  //               identiObj.propertyValuationRequestBean.isPlotOnAgriLand = res.isPlotOnAgriLand??false
 
  //               identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr[indexh - index].id = res.webGisResponseBeanDataArr[0]?.id
  //               identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr[indexh - index].totalArea = res.webGisResponseBeanDataArr[0].totalArea;
  //               identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr[indexh - index].partialTransactArea = res.webGisResponseBeanDataArr[0].partialTransactArea;
  //               identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr[indexh - index].ULPin = res.webGisResponseBeanDataArr[0].ulpin;
  //               identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr[indexh - index].unit = res.webGisResponseBeanDataArr[0].unit;
  //               identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr[indexh - index].landParcelId = res.webGisResponseBeanDataArr[0].landParcelId;
  //               identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr[indexh - index].ULPin = res.webGisResponseBeanDataArr[0].ULPin;
  //               identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr[indexh - index].latitude = res.webGisResponseBeanDataArr[0].latitude
  //               identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr[indexh - index].longitude = res.webGisResponseBeanDataArr[0].longitude
  //               identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr[indexh - index].khasraType = res.webGisResponseBeanDataArr[0].khasraType
  //               identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr[indexh - index].remarks = res.webGisResponseBeanDataArr[0].remarks
  //               identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr[indexh - index].isMortgage = res.webGisResponseBeanDataArr[0].isMortgage
  //               identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr[indexh - index].isHypothecation = res.webGisResponseBeanDataArr[0].isHypothecation
  //               identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr[indexh - index].isCourtCase = res.webGisResponseBeanDataArr[0].isCourtCase
  //               identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr[indexh - index].landUseType = res.webGisResponseBeanDataArr[0].landUseType
  //               identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr[indexh - index].landTypeName = res.webGisResponseBeanDataArr[0].landTypeName
  //               identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr[indexh - index].usage = res.usage
  //               identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr[indexh - index].propertyOwner = res.webGisResponseBeanDataArr[0].propertyOwner
  //               identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr[indexh - index].demography = res.demography
  //               identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr[indexh - index].igrsEregPropAdditionalDetlsBean.transactingArea = res.webGisResponseBeanDataArr[0].partialTransactArea;
  //               identiObj.propertyValuationRequestBean.partialTransactArea = res.webGisResponseBeanDataArr[0].partialTransactArea;
  //               identiObj.propertyValuationRequestBean.usage = res.usage
  //               let obj = this.propertyIdentificationBySampadaId.find(x => x.khasraplotNo == identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr[indexh - index].surveyNo);
  //               if (obj) {
  //                 obj.landParcelId = res.webGisResponseBeanDataArr[0].landParcelId;
  //               }
  //             }

  //           }
  //           if (identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData) {
  //             identiObj.propertyValuationRequestBean.isPlotOnAgriLand = res.isPlotOnAgriLand??false
  //             propIdentiObj.demography = res.demography;
  //             identiObj.demography = res.demography;
  //             identiObj.propertyValuationRequestBean.demography = res.demography;
  //             identiObj.propertyValuationRequestBean.subClause=[]
  //             identiObj.demographyId=res.demography.id
  //             identiObj.demography = res.demography;
  //             identiObj.propertyValuationRequestBean.demography = res.demography;
  //             identiObj.propertySource = 'ENP'
  //             identiObj.propertyValuationRequestBean.propertySource = 'ENP'
  //             identiObj.propertyValuationRequestBean.houseFlatNo = res.mPenagarpalikaResponseData.houseFlatNo
  //             identiObj.propTypeId = res.mPenagarpalikaResponseData.propertyTypeId
  //             propIdentiObj.transactingArea = res.mPenagarpalikaResponseData.totalArea
  //             propIdentiObj.unit = res.mPenagarpalikaResponseData?.unit;
  //             let souDirection=identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.directionSouth
  //             let easDirection=identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.directionEast
  //             let wesDirection=identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.directionWest
  //             let norDirection=identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.directionNorth
  //             identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData = new MPenagarpalikaResponsModel();
  //             identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.demography=res.demography
  //             identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.id = res.mPenagarpalikaResponseData.id
  //             identiObj.totalArea = res.mPenagarpalikaResponseData.totalArea
  //             propIdentiObj.propertyType = res.mPenagarpalikaResponseData.areaType
  //             propIdentiObj.propertyNo = res.mPenagarpalikaResponseData.propertyNo
  //             identiObj.propertyType = res.mPenagarpalikaResponseData.areaType
  //             identiObj.propertyId = res.mPenagarpalikaResponseData.propertyNo
  //             this.mstPropertyTypeBean.filter(pdata => {
  //               if (pdata.id == res.mPenagarpalikaResponseData.propertyTypeId) {
  //                 identiObj.propertyValuationRequestBean.propertyType = pdata
  //                 propIdentiObj.propertyType = this.lan == 0 ? pdata.nameEn : pdata.nameHi
  //               }
  //             })
  //             identiObj.propertyValuationRequestBean.latitude=res.mPenagarpalikaResponseData.latitude
  //             identiObj.propertyValuationRequestBean.longitude=res.mPenagarpalikaResponseData.longitude
  //             identiObj.propertyValuationRequestBean.buildingType=res.mPenagarpalikaResponseData.buildingType
  //             identiObj.propertyValuationRequestBean.buildingSubType=res.mPenagarpalikaResponseData.buildingSubType
  //             identiObj.propertyValuationRequestBean.isResidensial=res.mPenagarpalikaResponseData.isResidensial
  //             identiObj.propertyValuationRequestBean.isCommercial=res.mPenagarpalikaResponseData.isCommercial
  //             identiObj.propertyValuationRequestBean.isHealth=res.mPenagarpalikaResponseData.isHealth
  //             identiObj.propertyValuationRequestBean.isOthers=res.mPenagarpalikaResponseData.isOthers
  //             identiObj.propertyValuationRequestBean.isIndustrial=res.mPenagarpalikaResponseData.isIndustrial
  //             identiObj.propertyValuationRequestBean.isEduArea=res.mPenagarpalikaResponseData.isEduArea
  //             identiObj.propertyValuationRequestBean.propertyTypeId = res.mPenagarpalikaResponseData.propertyTypeId
  //             identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.propertyTypeId = res.mPenagarpalikaResponseData.propertyTypeId
  //             identiObj.partialTransactArea = res.mPenagarpalikaResponseData.totalArea
  //             identiObj.propertyValuationRequestBean.overAllArea = res.mPenagarpalikaResponseData.totalArea
  //             identiObj.propertyValuationRequestBean.totalArea = res.mPenagarpalikaResponseData.totalArea
  //             identiObj.propertyValuationRequestBean.buildingAreaType=identiObj.propertyValuationRequestBeanbuildingAreaType??"no"
  //             identiObj.propertyValuationRequestBean.isResidentialCumCommercial= identiObj.propertyValuationRequestBean.isResidentialCumCommercial??false
  //             identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.propertyId = res.mPenagarpalikaResponseData.propertyNo
  //             identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.propertyNo = res.mPenagarpalikaResponseData.propertyNo
  //             identiObj.propertyValuationRequestBean.partialTransactArea = res.mPenagarpalikaResponseData.partialTransactArea ?? res.mPenagarpalikaResponseData.totalArea
  //             identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.overAllArea = res.mPenagarpalikaResponseData.overAllArea ?? res.mPenagarpalikaResponseData.totalArea
  //             identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.totalArea = res.mPenagarpalikaResponseData.totalArea
  //             identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.partialTransactArea = res.mPenagarpalikaResponseData.partialTransactArea ?? res.mPenagarpalikaResponseData.totalArea
  //             identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.latitude = res.mPenagarpalikaResponseData.latitude
  //             identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.longitude = res.mPenagarpalikaResponseData.longitude
  //             identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.houseFlatNo = res.mPenagarpalikaResponseData.houseFlatNo
  //             identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.demography = res.demography
  //             identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.igrsEregPropAdditionalDetlsBean.eastBoundary= easDirection//??res.mPenagarpalikaResponseData.directionEast
  //             identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.igrsEregPropAdditionalDetlsBean.northBoundary= norDirection//??res.mPenagarpalikaResponseData.directionNorth
  //             identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.igrsEregPropAdditionalDetlsBean.southBoundary= souDirection//??res.mPenagarpalikaResponseData.directionWest
  //             identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.igrsEregPropAdditionalDetlsBean.westBoundary= wesDirection//??res.mPenagarpalikaResponseData.directionWest
  //             identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.igrsEregPropAdditionalDetlsBean.transactingArea = res.mPenagarpalikaResponseData.partialTransactArea ?? res.mPenagarpalikaResponseData.totalArea;
  //             identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.propertyOwner = res.mPenagarpalikaResponseData.propertyOwner
  //             identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr = []
  //             identiObj.propertyValuationRequestBean.pNRDResponseData = null
  //           } if (identiObj.propertyValuationRequestBean.pNRDResponseData) {
  //             identiObj.propertyValuationRequestBean.isPlotOnAgriLand = res.isPlotOnAgriLand??false
  //             propIdentiObj.demography = res.demography;
  //             identiObj.demography = res.demography;
  //             identiObj.demographyId=res.demography.id
  //             identiObj.propertyValuationRequestBean.demography = res.demography;
  //             identiObj.propertySource = 'PNRD'
  //             identiObj.propertyValuationRequestBean.subClause=[]
  //             identiObj.propertyValuationRequestBean.propertySource = 'PNRD'
  //             identiObj.propTypeId = res.pNRDResponseData.propertyTypeId
  //             identiObj.propertyValuationRequestBean.houseFlatNo = res.pNRDResponseData.houseFlatNo
  //             propIdentiObj.transactingArea = res.pNRDResponseData.totalArea
  //             propIdentiObj.unit = res.pNRDResponseData?.unit;
  //             identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData = null
  //             identiObj.propertyValuationRequestBean.pNRDResponseData = new PnrdModel();
  //             identiObj.propertyValuationRequestBean.pNRDResponseData.id = res.pNRDResponseData.id
  //             identiObj.propertyValuationRequestBean.pNRDResponseData.demography=res.demography
  //             identiObj.totalArea = res.pNRDResponseData.totalArea
  //             propIdentiObj.propertyNo = res.pNRDResponseData.propertyNo
  //             identiObj.propertyType = res.pNRDResponseData.areaType
  //             identiObj.propertyId = res.pNRDResponseData.propertyNo
  //             this.mstPropertyTypeBean.filter(pdata => {
  //               if (pdata.id == res.pNRDResponseData.propertyTypeId) {
  //                 identiObj.propertyValuationRequestBean.propertyType = pdata
  //                 identiObj.propertyValuationRequestBean.pNRDResponseData.propertyTypeId = pdata.id
  //                 propIdentiObj.propertyType = this.lan == 0 ? pdata.nameEn : pdata.nameHi
  //               }
  //             })
  //             identiObj.propertyValuationRequestBean.latitude=res.pNRDResponseData.latitude
  //             identiObj.propertyValuationRequestBean.longitude=res.pNRDResponseData.longitude
  //             identiObj.propertyValuationRequestBean.buildingType=res.pNRDResponseData.buildingType
  //             identiObj.propertyValuationRequestBean.buildingSubType=res.pNRDResponseData.buildingSubType
  //             identiObj.propertyValuationRequestBean.isResidensial=res.pNRDResponseData.isResidensial
  //             identiObj.propertyValuationRequestBean.isCommercial=res.pNRDResponseData.isCommercial
  //             identiObj.propertyValuationRequestBean.isHealth=res.pNRDResponseData.isHealth
  //             identiObj.propertyValuationRequestBean.isOthers=res.pNRDResponseData.isOthers
  //             identiObj.propertyValuationRequestBean.isIndustrial=res.pNRDResponseData.isIndustrial
  //             identiObj.propertyValuationRequestBean.isEduArea=res.pNRDResponseData.isEduArea
  //             identiObj.partialTransactArea = res.pNRDResponseData.totalArea
  //             identiObj.partialTransactArea = res.pNRDResponseData.totalArea
  //             identiObj.propertyValuationRequestBean.overAllArea = res.pNRDResponseData.totalArea
  //             identiObj.propertyValuationRequestBean.totalArea = res.pNRDResponseData.totalArea
  //             identiObj.propertyValuationRequestBean.partialTransactArea = res.pNRDResponseData.partialTransactArea ?? res.pNRDResponseData.totalArea
  //             identiObj.propertyValuationRequestBean.pNRDResponseData.overAllArea = res.pNRDResponseData.totalArea
  //             identiObj.propertyValuationRequestBean.pNRDResponseData.totalArea = res.pNRDResponseData.totalArea
  //             identiObj.propertyValuationRequestBean.pNRDResponseData.propertyNo = res.pNRDResponseData.propertyNo
  //             identiObj.propertyValuationRequestBean.pNRDResponseData.propertyId = res.pNRDResponseData.propertyNo
  //             identiObj.propertyValuationRequestBean.pNRDResponseData.partialTransactArea = res.pNRDResponseData.partialTransactArea ?? res.pNRDResponseData.totalArea
  //             identiObj.propertyValuationRequestBean.pNRDResponseData.houseFlatNo = res.pNRDResponseData.houseFlatNo
  //             identiObj.propertyValuationRequestBean.pNRDResponseData.latitude = res.pNRDResponseData.latitude
  //             identiObj.propertyValuationRequestBean.pNRDResponseData.longitude = res.pNRDResponseData.longitude
  //             identiObj.propertyValuationRequestBean.pNRDResponseData.demography = res.demography
  //             identiObj.propertyValuationRequestBean.buildingAreaType=identiObj.propertyValuationRequestBean.buildingAreaType??"no"
  //             identiObj.propertyValuationRequestBean.isResidentialCumCommercial= identiObj.propertyValuationRequestBean.isResidentialCumCommercial??false
  //             identiObj.propertyValuationRequestBean.pNRDResponseData.unit = res.pNRDResponseData.unit
  //             identiObj.propertyValuationRequestBean.pNRDResponseData.igrsEregPropAdditionalDetlsBean.transactingArea = res.pNRDResponseData.partialTransactArea ?? res.pNRDResponseData.totalArea;
  //             identiObj.propertyValuationRequestBean.pNRDResponseData.propertyOwner = res.pNRDResponseData.propertyOwner
  //             identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr = []
  //           }
  //           //  console.log(this.propertyIdentificationBySampadaId );
  //           // console.log(this.igrsEregTxnDetlsBean );

  //         }
  //       })
  //     }
  //   });
  // }
  addDemography(propIdentiObj: PropertyIdentificationBySampadaId, igrsEregTxnDetlsBean: IgrsEregTxnDetlsBean, indexh: number) {
    let obj = { fromEregModule: this.fromEregModule, propIdentiObj: igrsEregTxnDetlsBean, ismannual: true, getDemographyForSampada1: true, districtId: propIdentiObj.districtId };
    this.propValuationDialogRef = this.dialog.open(EregistryPropertyIdentificationComponent, {
      data: obj, maxWidth: '90vw',
      //maxHeight: '80vh',
      height: 'auto',
      width: '100%',
      panelClass: 'full-screen-modal', disableClose: true
    });

    this.propValuationDialogRef.afterClosed().subscribe(res => {
      if (res != "" && res) {
        this.propertyIdentificationForSampadaRegisteredAfterExistanceCheck.forEach((identiObj: any, propindex: number) => {
          console.log(identiObj)
          if (identiObj.propertyId == propIdentiObj.propertyId) {
            if (identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr[indexh - 1]?.demography && identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr[indexh - 1]?.demography.id != res.demography.id) {
              this.alertmessage.errorAlertMessage({
                responseMessage: 'The guildline location of both properties is not the same',
                responseMessageEn: 'The guildline location of both properties is not the same',
                responseMessageHi: 'दोनों संपत्तियों का गिल्डलाइन स्थान समान नहीं है'
              })
              return
            }
            if (identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr[indexh - 1]?.landParcelId && identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr[indexh - 1]?.landParcelId == res.webGisResponseBeanDataArr[propindex].landParcelId) {
              this.alertmessage.errorAlertMessage({
                responseMessage: 'Duplicate property ID not added',
                responseMessageEn: 'Duplicate property ID not added',
                responseMessageHi: 'डुप्लिकेट प्रॉपर्टी आईडी नहीं जोड़ी गई'
              })
              return
            }
            let executed = false;
            if (res.propertySource == 'CLR' && res.webGisResponseBeanDataArr[0]) {
              if (Array.isArray(res.webGisResponseBeanDataArr) && res.webGisResponseBeanDataArr.length > 0) {
                if(identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData&&(res.webGisResponseBeanDataArr&&res.webGisResponseBeanDataArr[0].unit=='HA')){
                  this.alertmessage.errorAlertMessage({
                    responseMessage:"The system fetched the property type as a plot. You cannot identify the same property as agricultural land now. Kindly select the correct property type.",
                    responseMessageHi:"सिस्टम ने संपत्ति के प्रकार को एक प्लॉट के रूप में प्राप्त किया हैं। अब आप उसी संपत्ति की कृषि भूमि के रूप में पहचान नहीं कर सकते हैं  कृपया संपत्ति के सही प्रकार का चयन करें। ",
                    responseMessageEn:"The system fetched the property type as a plot. You cannot identify the same property as agricultural land now. Kindly select the correct property type."})
                  return
                }
                if(identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr.length>0){
                identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr.forEach((web: WebGisResponseBeanData, index: number) => {

                  if (!executed) {
                    if (res.webGisResponseBeanDataArr[0] && this.ifMultiplekhashra(identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr.length,index,indexh) ) {
                      propIdentiObj.transactingArea = res.webGisResponseBeanDataArr[0].totalArea
                      propIdentiObj.demography = res.demography;
                      identiObj.demography = res.demography;
                      identiObj.propertySource = 'CLR'
                      identiObj.demographyId=res.demography.id
                      identiObj.propertyValuationRequestBean.propertySource = 'CLR'
                      identiObj.propertyValuationRequestBean.isPlotOnAgriLand = res.isPlotOnAgriLand
                      identiObj.propertyValuationRequestBean.demography = res.demography;
                      propIdentiObj.unit = res.webGisResponseBeanDataArr[0].unit;
                      web.id = res.webGisResponseBeanDataArr[0]?.id
                      web.totalArea = res.webGisResponseBeanDataArr[0].totalArea;
                      web.partialTransactArea = res.webGisResponseBeanDataArr[0].partialTransactArea;
                      web.isCheked = true
                      web.unit = res.webGisResponseBeanDataArr[0].unit;
                      web.landParcelId = res.webGisResponseBeanDataArr[0].landParcelId;
                      web.ULPin = res.webGisResponseBeanDataArr[0].ULPin;
                      web.latitude = res.webGisResponseBeanDataArr[0].latitude
                      web.longitude = res.webGisResponseBeanDataArr[0].longitude
                      web.khasraType = res.webGisResponseBeanDataArr[0].khasraType
                      web.remarks = res.webGisResponseBeanDataArr[0].remarks
                      web.mortageRemarks = res.webGisResponseBeanDataArr[0].mortageRemarks
                      web.isMortgage = res.webGisResponseBeanDataArr[0].isMortgage
                      web.isHypothecation = res.webGisResponseBeanDataArr[0].isHypothecation
                      web.isCourtCase = res.webGisResponseBeanDataArr[0].isCourtCase
                      web.landUseType = res.webGisResponseBeanDataArr[0].landUseType
                      web.landTypeName = res.webGisResponseBeanDataArr[0].landTypeName
                      web.usage = res.usage
                      web.propertyOwner = res.webGisResponseBeanDataArr[0].propertyOwner
                      web.demography = res.webGisResponseBeanDataArr[0].demography
                      web.surveyNo = res.webGisResponseBeanDataArr[0].surveyNo
                      web.igrsEregPropAdditionalDetlsBean.transactingArea = res.webGisResponseBeanDataArr[0].partialTransactArea;
                      identiObj.propertyValuationRequestBean.partialTransactArea = res.webGisResponseBeanDataArr[0].partialTransactArea;
                      identiObj.propertyValuationRequestBean.overAllArea = res.webGisResponseBeanDataArr[0].totalArea;
                      identiObj.totalArea = res.webGisResponseBeanDataArr[0].totalArea
                      propIdentiObj.propertyType = propIdentiObj.propertyType == undefined || null ? "AGRICULTURAL LAND" : propIdentiObj.propertyType
                      identiObj.partialTransactArea = res.webGisResponseBeanDataArr[0].partialTransactArea;
                      identiObj.propertyValuationRequestBean.usage = res.usage
                      identiObj.propertyValuationRequestBean.unit = res.webGisResponseBeanDataArr[0].unit;
                      propIdentiObj.landParcelId = res.webGisResponseBeanDataArr[0].landParcelId;
                      web.surveyNo = res.webGisResponseBeanDataArr[0].surveyNo
                      identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData = null
                    }
                  }
                })
                }
              else{
                propIdentiObj.transactingArea = res.webGisResponseBeanDataArr[0].totalArea
                propIdentiObj.demography = res.demography;
                identiObj.demography = res.demography;
                identiObj.totalArea = res.webGisResponseBeanDataArr[0].totalArea
                propIdentiObj.propertyType = propIdentiObj.propertyType == undefined || null ? "AGRICULTURAL LAND" : propIdentiObj.propertyType
                identiObj.partialTransactArea = res.webGisResponseBeanDataArr[0].partialTransactArea;
                identiObj.propertySource = 'CLR'
                identiObj.demographyId=res.demography.id
                identiObj.propertyValuationRequestBean=res
                identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr=res.webGisResponseBeanDataArr
                identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr[0].isCheked=true
                identiObj.propertyValuationRequestBean.agriculturalWithPlot.webGisResponseBeanDataArr[0].isCheked=true
                identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr[0].igrsEregPropAdditionalDetlsBean.transactingArea=res.webGisResponseBeanDataArr[0].totalArea
                identiObj.propertyValuationRequestBean.agriculturalWithPlot.webGisResponseBeanDataArr[0].igrsEregPropAdditionalDetlsBean.transactingArea=res.agriculturalWithPlot.webGisResponseBeanDataArr[0].totalArea
                identiObj.propertyValuationRequestBean.propertySource = 'CLR'
                identiObj.propertyValuationRequestBean.isPartialTransact=false
                identiObj.propertyValuationRequestBean.isPlotOnAgriLand = res.isPlotOnAgriLand
                identiObj.propertyValuationRequestBean.demography = res.demography;
               // identiObj.propertyValuationRequestBean.agriculturalWithPlot=res.agriculturalWithPlot
                identiObj.propertyValuationRequestBean.propertyType={id: 3, nameEn: "AGRICULTURAL LAND", nameHi: "कृषि भूमि", parentId: 0, status: 1}
                identiObj.propTypeId=3
                propIdentiObj.unit = res.webGisResponseBeanDataArr[0].unit;
                //let web=new WebGisResponseBeanData()
                // web.propertyType={id: 3, nameEn: "AGRICULTURAL LAND", nameHi: "कृषि भूमि", parentId: 0, status: 1}
                // web.id = res.webGisResponseBeanDataArr[0]?.id
                // web.totalArea = res.webGisResponseBeanDataArr[0].totalArea;
                // web.partialTransactArea = res.webGisResponseBeanDataArr[0].partialTransactArea;
                
                // web.propertyTypeId=3
                // web.unit = res.webGisResponseBeanDataArr[0].unit;
                // web.landParcelId = res.webGisResponseBeanDataArr[0].landParcelId;
                // web.ULPin = res.webGisResponseBeanDataArr[0].ULPin;
                // web.latitude = res.webGisResponseBeanDataArr[0].latitude
                // web.longitude = res.webGisResponseBeanDataArr[0].longitude
                // web.khasraType = res.webGisResponseBeanDataArr[0].khasraType
                // web.remarks = res.webGisResponseBeanDataArr[0].remarks
                // web.mortageRemarks = res.webGisResponseBeanDataArr[0].mortageRemarks
                // web.isMortgage = res.webGisResponseBeanDataArr[0].isMortgage
                // web.isHypothecation = res.webGisResponseBeanDataArr[0].isHypothecation
                // web.isCourtCase = res.webGisResponseBeanDataArr[0].isCourtCase
                // web.landUseType = res.webGisResponseBeanDataArr[0].landUseType
                // web.landTypeName = res.webGisResponseBeanDataArr[0].landTypeName
                // web.usage = res.usage
                // web.propertyOwner = res.webGisResponseBeanDataArr[0].propertyOwner
                // web.demography = res.webGisResponseBeanDataArr[0].demography
                // web.surveyNo = res.webGisResponseBeanDataArr[0].surveyNo
                // web.igrsEregPropAdditionalDetlsBean.transactingArea = res.webGisResponseBeanDataArr[0].partialTransactArea;
               
                //identiObj.propertyValuationRequestBean.partialTransactArea = res.webGisResponseBeanDataArr[0].partialTransactArea;
                //identiObj.propertyValuationRequestBean.overAllArea = res.webGisResponseBeanDataArr[0].totalArea;
               // identiObj.propertyValuationRequestBean.totalArea=res.webGisResponseBeanDataArr[0].totalArea
              
               // identiObj.propertyValuationRequestBean.usage = res.usage
                //identiObj.propertyValuationRequestBean.unit = res.webGisResponseBeanDataArr[0].unit;
                propIdentiObj.landParcelId = res.webGisResponseBeanDataArr[0].landParcelId;
                identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData = null
               // identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr.push(web)
                
              }
            }
            }
            if (res.mPenagarpalikaResponseData && res.propertySource == 'ENP') {
              propIdentiObj.demography = res.demography;
              identiObj.demographyId=res.demography.id
              identiObj.propertyValuationRequestBean.demography = res.demography;
              identiObj.demography = res.demography;
              identiObj.propertyValuationRequestBean.demography = res.demography;
              identiObj.propertySource = 'ENP'
              identiObj.propertyValuationRequestBean.propertySource = 'ENP'
              identiObj.propertyValuationRequestBean.houseFlatNo = res.mPenagarpalikaResponseData.houseFlatNo
              identiObj.propTypeId = res.mPenagarpalikaResponseData.propertyTypeId
              propIdentiObj.transactingArea = res.mPenagarpalikaResponseData.totalArea
              propIdentiObj.unit = res.mPenagarpalikaResponseData?.unit;
              identiObj.propertyValuationRequestBean.unit = res.mPenagarpalikaResponseData?.unit??'SQMT';
              identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData = new MPenagarpalikaResponsModel();
              identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.id = res.mPenagarpalikaResponseData.id
              identiObj.totalArea = res.mPenagarpalikaResponseData.totalArea
              propIdentiObj.propertyType = res.mPenagarpalikaResponseData.areaType
              propIdentiObj.propertyNo = res.mPenagarpalikaResponseData.propertyNo
              identiObj.propertyType = res.mPenagarpalikaResponseData.areaType
              identiObj.propertyId = res.mPenagarpalikaResponseData.propertyNo
              this.mstPropertyTypeBean.filter(pdata => {
                if (pdata.id == res.mPenagarpalikaResponseData.propertyTypeId) {
                  identiObj.propertyValuationRequestBean.propertyType = pdata
                  propIdentiObj.propertyType = this.lan == 0 ? pdata.nameEn : pdata.nameHi
                }
              })
              identiObj.propertyValuationRequestBean.subClause=new Array()
              identiObj.propertyValuationRequestBean.latitude=res.mPenagarpalikaResponseData.latitude
              identiObj.propertyValuationRequestBean.longitude=res.mPenagarpalikaResponseData.longitude
              identiObj.propertyValuationRequestBean.buildingType=res.mPenagarpalikaResponseData.buildingType
              identiObj.propertyValuationRequestBean.buildingSubType=res.mPenagarpalikaResponseData.buildingSubType
              identiObj.propertyValuationRequestBean.isResidensial=res.mPenagarpalikaResponseData.isResidensial
              identiObj.propertyValuationRequestBean.isCommercial=res.mPenagarpalikaResponseData.isCommercial
              identiObj.propertyValuationRequestBean.isHealth=res.mPenagarpalikaResponseData.isHealth
              identiObj.propertyValuationRequestBean.isOthers=res.mPenagarpalikaResponseData.isOthers
              identiObj.propertyValuationRequestBean.isIndustrial=res.mPenagarpalikaResponseData.isIndustrial
              identiObj.propertyValuationRequestBean.isEduArea=res.mPenagarpalikaResponseData.isEduArea
              identiObj.propertyValuationRequestBean.propertyTypeId = res.mPenagarpalikaResponseData.propertyTypeId
              identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.propertyTypeId = res.mPenagarpalikaResponseData.propertyTypeId
              identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.unit= res.mPenagarpalikaResponseData?.unit??'SQMT';
              identiObj.propertyValuationRequestBean.buildingType = res.mPenagarpalikaResponseData.areaType
              identiObj.partialTransactArea = res.mPenagarpalikaResponseData.totalArea
              identiObj.propertyValuationRequestBean.overAllArea = res.mPenagarpalikaResponseData.totalArea
              identiObj.propertyValuationRequestBean.totalArea = res.mPenagarpalikaResponseData.totalArea
              identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.propertyId = res.mPenagarpalikaResponseData.propertyNo
              identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.propertyNo = res.mPenagarpalikaResponseData.propertyNo
              identiObj.propertyValuationRequestBean.partialTransactArea = res.mPenagarpalikaResponseData.partialTransactArea ?? res.mPenagarpalikaResponseData.totalArea
              identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.overAllArea = res.mPenagarpalikaResponseData.overAllArea ?? res.mPenagarpalikaResponseData.totalArea
              identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.totalArea = res.mPenagarpalikaResponseData.totalArea
              identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.partialTransactArea = res.mPenagarpalikaResponseData.partialTransactArea ?? res.mPenagarpalikaResponseData.totalArea
              identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.latitude = res.mPenagarpalikaResponseData.latitude
              identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.longitude = res.mPenagarpalikaResponseData.longitude
              identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.houseFlatNo = res.mPenagarpalikaResponseData.houseFlatNo
              identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.demography = res.demography
              identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.igrsEregPropAdditionalDetlsBean.transactingArea = res.mPenagarpalikaResponseData.partialTransactArea ?? res.mPenagarpalikaResponseData.totalArea;
              identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData.propertyOwner = res.mPenagarpalikaResponseData.propertyOwner
              identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr = []
              identiObj.propertyValuationRequestBean.pNRDResponseData = null
            } if (res.propertySource == 'PNRD') {
              propIdentiObj.demography = res.demography;
              identiObj.propertySource = 'PNRD'
              identiObj.demography = res.demography;
              identiObj.demographyId=res.demography.id
              identiObj.propertyValuationRequestBean.propertySource = 'PNRD'
              identiObj.propTypeId = res.pNRDResponseData.propertyTypeId
              identiObj.propertyValuationRequestBean.demography = res.demography;
              identiObj.propertyValuationRequestBean.houseFlatNo = res.pNRDResponseData.houseFlatNo
              propIdentiObj.transactingArea = res.pNRDResponseData.totalArea
              identiObj.propertyValuationRequestBean.mPenagarpalikaResponseData = null
              identiObj.propertyValuationRequestBean.pNRDResponseData = new PnrdModel();
              identiObj.propertyValuationRequestBean.pNRDResponseData.id = res.pNRDResponseData.id
              identiObj.totalArea = res.pNRDResponseData.totalArea
              propIdentiObj.propertyNo = res.pNRDResponseData.propertyNo
              propIdentiObj.unit = res.pNRDResponseData?.unit;
              identiObj.propertyValuationRequestBean.unit = res.pNRDResponseData?.unit??'SQMT';
              identiObj.propertyValuationRequestBean.pNRDResponseData.unit = res.pNRDResponseData?.unit??'SQMT';
              identiObj.propertyType = res.pNRDResponseData.areaType
              identiObj.propertyId = res.pNRDResponseData.propertyNo
              this.mstPropertyTypeBean.filter(pdata => {
                if (pdata.id == res.pNRDResponseData.propertyTypeId) {
                  identiObj.propertyValuationRequestBean.propertyType = pdata
                  identiObj.propertyValuationRequestBean.pNRDResponseData.propertyTypeId = pdata.id
                  propIdentiObj.propertyType = this.lan == 0 ? pdata.nameEn : pdata.nameHi
                }
              })
              identiObj.propertyValuationRequestBean.subClause=new Array()
              identiObj.propertyValuationRequestBean.latitude=res.pNRDResponseData.latitude
              identiObj.propertyValuationRequestBean.longitude=res.pNRDResponseData.longitude
              identiObj.propertyValuationRequestBean.buildingType=res.pNRDResponseData.buildingType
              identiObj.propertyValuationRequestBean.buildingSubType=res.pNRDResponseData.buildingSubType
              identiObj.propertyValuationRequestBean.isResidensial=res.pNRDResponseData.isResidensial
              identiObj.propertyValuationRequestBean.isCommercial=res.pNRDResponseData.isCommercial
              identiObj.propertyValuationRequestBean.isHealth=res.pNRDResponseData.isHealth
              identiObj.propertyValuationRequestBean.isOthers=res.pNRDResponseData.isOthers
              identiObj.propertyValuationRequestBean.isIndustrial=res.pNRDResponseData.isIndustrial
              identiObj.propertyValuationRequestBean.isEduArea=res.pNRDResponseData.isEduArea
              identiObj.partialTransactArea = res.pNRDResponseData.totalArea
              identiObj.partialTransactArea = res.pNRDResponseData.totalArea
              identiObj.propertyValuationRequestBean.overAllArea = res.pNRDResponseData.totalArea
              identiObj.propertyValuationRequestBean.totalArea = res.pNRDResponseData.totalArea
              identiObj.propertyValuationRequestBean.partialTransactArea = res.pNRDResponseData.partialTransactArea ?? res.pNRDResponseData.totalArea
              identiObj.propertyValuationRequestBean.pNRDResponseData.overAllArea = res.pNRDResponseData.totalArea
              identiObj.propertyValuationRequestBean.pNRDResponseData.totalArea = res.pNRDResponseData.totalArea
              identiObj.propertyValuationRequestBean.pNRDResponseData.propertyNo = res.pNRDResponseData.propertyNo
              identiObj.propertyValuationRequestBean.pNRDResponseData.propertyId = res.pNRDResponseData.propertyNo
              identiObj.propertyValuationRequestBean.pNRDResponseData.partialTransactArea = res.pNRDResponseData.partialTransactArea ?? res.pNRDResponseData.totalArea
              identiObj.propertyValuationRequestBean.pNRDResponseData.houseFlatNo = res.pNRDResponseData.houseFlatNo
              identiObj.propertyValuationRequestBean.pNRDResponseData.latitude = res.pNRDResponseData.latitude
              identiObj.propertyValuationRequestBean.pNRDResponseData.longitude = res.pNRDResponseData.longitude
              identiObj.propertyValuationRequestBean.pNRDResponseData.demography = res.demography
              identiObj.propertyValuationRequestBean.pNRDResponseData.unit = res.pNRDResponseData.unit
              identiObj.propertyValuationRequestBean.pNRDResponseData.igrsEregPropAdditionalDetlsBean.transactingArea = res.pNRDResponseData.partialTransactArea ?? res.pNRDResponseData.totalArea;
              identiObj.propertyValuationRequestBean.pNRDResponseData.propertyOwner = res.pNRDResponseData.propertyOwner
              identiObj.propertyValuationRequestBean.webGisResponseBeanDataArr = []
            }
          }
        }
        )
      }
    });
  }
  ifMultiplekhashra(webGisArrayLength:number,index:number,propIndex:number):boolean{
    if(webGisArrayLength>1){
      return index==propIndex
    }
    else if(webGisArrayLength==1){
      return true
    }
    return false
   }
  searchPropertyByPropertyId() {
    console.log("search by SAMPADA property id:-");
    this.propertyIdentificationForSampadaRegisteredAfterExistanceCheck = [];
    this.ngxService.startLoader('property-identification');
    if (this.sampadaPropertyId && (this.sampadaPropertyId.length == 18 || this.sampadaPropertyId.length == 19 || this.sampadaPropertyId.length == 20 || this.sampadaPropertyId.length == 24 || this.sampadaPropertyId.length == 25)) {// || this.sampadaPropertyId.length==25
      let type: string = "all";
      // if (this.igrsEregTxnDetlsBean.deedInstrumentSelected.id == 22 || this.igrsEregTxnDetlsBean.deedInstrumentSelected.id == 37) {
      //   type = "Mortgage"
      // } else if (this.igrsEregTxnDetlsBean.deedInstrumentSelected.deedTypeId.id == 49) {
      //   type = "DeMortgage"
      // }
      this.eRegService.getForCMSSampadaPropertyListByPropertyId(this.sampadaPropertyId, type).subscribe((res: any) => {
        if (res.responseStatus == "true") {
          this.propertyIdentificationForSampadaRegisteredAfterSelection = [];
          this.propertyIdentificationForSampadaRegistered = [];
          this.propertyIdentificationBySampadaId = [];

          this.propertyIdentificationForSampadaRegistered = res.responseData.propIdentificationList;
          this.cmsRegSearchDetails = res.responseData.cmsRegSearchDetails;

          this.igrsEregTxnDetlsBean.deedInstrumentSelected.propReqFlag = res.responseData.propReqFlag;
          this.igrsEregTxnDetlsBean.deedInstrumentSelected.propValReqFlag = res.responseData.propValReqFlag;
          this.igrsEregTxnDetlsBean.cmsRegSearchDetails = this.cmsRegSearchDetails;
          //this.igrsEregTxnDetlsBean.docNo = this.sampadaPropertyId;

          this.igrsEregTxnDetlsBean.parentRegNo = this.sampadaPropertyId;
          this.igrsEregTxnDetlsBean.parentDeedTypeId = this.cmsRegSearchDetails.deedInstrumentSelected.deedTypeId.id;
          this.igrsEregTxnDetlsBean.parentDeedCategoryId = this.cmsRegSearchDetails.deedInstrumentSelected.deedTypeId.deedCategoryId.id;
          this.igrsEregTxnDetlsBean.parentDeedInstrumentId = this.cmsRegSearchDetails.deedInstrumentSelected.id;
          this.igrsEregTxnDetlsBean.parentDateOfExecution = this.cmsRegSearchDetails.registryInitiationDate;
          this.igrsEregTxnDetlsBean.parentDateOfRegistration = this.cmsRegSearchDetails.documentRegistrationDate;
          this.igrsEregTxnDetlsBean.numberOfPartition = res.responseData?.noOfPartition;

          if(res.responseData.partyBeanList){
            this.igrsEregTxnDetlsBean.igrsEregPartyDetailsBeanList =  res.responseData.partyBeanList;
          }
          if(res.responseData.partyBeanListSam1){
            this.igrsEregTxnDetlsBean.igrsEregPartyDetailsBeanListSam1 =  res.responseData.partyBeanListSam1;
          }
            
        //  this.igrsEregTxnDetlsBean.deedInstrumentSelectedForAmendmentCancellation  = this.igrsEregTxnDetlsBean.deedInstrumentSelected;
        //  this.igrsEregTxnDetlsBean.deedInstrumentSelected = this.cmsRegSearchDetails.deedInstrumentSelected;
        this.igrsEregTxnDetlsBean.deedInstrumentSelectedForAmendmentCancellation = this.cmsRegSearchDetails.deedInstrumentSelected;//this is dynamic amendment/cancellation deed instrument object
          
         let  newPartyBeanList: IgrsEregPartyDetailsBean[] = new Array();
          this.igrsEregTxnDetlsBean?.igrsEregPartyDetailsBeanList?.forEach((item) => {
            if (item.partySubTypeId == 0 || item.partySubTypeId == 1 || item.partySubTypeId == 2 || item.partySubTypeId == 3) {
              newPartyBeanList.push(item);
            }
          });

          this.igrsEregTxnDetlsBean.igrsEregPartyDetailsBeanList = newPartyBeanList;

          this.emitEregTxnDetlsToEregModule.emit(this.igrsEregTxnDetlsBean);

         // console.log("search amedment igrsEregTxnDetlsBean ::"+JSON.stringify(this.igrsEregTxnDetlsBean));
          console.log("search amedment reg data ::"+JSON.stringify(res.responseData));

          this.propertyIdentificationForSampadaRegistered.forEach(propIdentificationObject => {
            propIdentificationObject.sam1RegNo = this.sampadaPropertyId;

            let tempIdentificationTocheck: IgrsEregPropIdentificationBean = new IgrsEregPropIdentificationBean();
            //Agriculture Land 
            if (propIdentificationObject?.propertyValuationRequestBean?.propertyType?.id == 3) {
              tempIdentificationTocheck = JSON.parse(JSON.stringify(propIdentificationObject));
              tempIdentificationTocheck.propertyValuationRequestBean.webGisResponseBeanDataArr = []

              propIdentificationObject?.propertyValuationRequestBean?.webGisResponseBeanDataArr.forEach(webGisData => {
                let propertyIdentificationBySampadaId = new PropertyIdentificationBySampadaId();
                propertyIdentificationBySampadaId.id = propIdentificationObject.id;
                propertyIdentificationBySampadaId.propertyTypeId = propIdentificationObject.propertyValuationRequestBean.propertyType.id;
                propertyIdentificationBySampadaId.propertyType = propIdentificationObject.propertyValuationRequestBean.propertyType.nameEn;
                propertyIdentificationBySampadaId.propertyId = propIdentificationObject.propertyId;
                propertyIdentificationBySampadaId.landParcelId = webGisData.landParcelId;
                propertyIdentificationBySampadaId.khasraplotNo = webGisData.surveyNo;
                propertyIdentificationBySampadaId.addressOfProperty = propIdentificationObject.propertyValuationRequestBean.address;
                propertyIdentificationBySampadaId.transactingArea = webGisData?.partialTransactArea;
                propertyIdentificationBySampadaId.unit = propIdentificationObject?.propertyValuationRequestBean?.unit;

                if (this.igrsEregTxnDetlsBean.igrsEregPropIdentificationBean) {
                  if (this.igrsEregTxnDetlsBean.igrsEregPropIdentificationBean.length > 0) {
                    let isExists = false;
                    for (let i = 0; i < this.igrsEregTxnDetlsBean.igrsEregPropIdentificationBean.length; i++) {

                      let property = this.igrsEregTxnDetlsBean.igrsEregPropIdentificationBean[i];
                      if (property.propertyValuationRequestBean.propertyType.id == 3) {

                        let index = property.propertyValuationRequestBean.webGisResponseBeanDataArr.findIndex(khasra => {
                          return khasra.landParcelId == webGisData.landParcelId
                        });

                        if (index != -1) {
                          isExists = true;
                          break;
                        }
                      }
                    }
                    if (isExists) {
                      propertyIdentificationBySampadaId.alreadyAdded = true;
                    }
                    else {
                      propertyIdentificationBySampadaId.alreadyAdded = false;
                      tempIdentificationTocheck.propertyValuationRequestBean.webGisResponseBeanDataArr.push(webGisData);
                    }

                  }
                  else {
                    tempIdentificationTocheck.propertyValuationRequestBean.webGisResponseBeanDataArr.push(webGisData);
                  }
                }
                else {
                  this.igrsEregTxnDetlsBean.igrsEregPropIdentificationBean = [];
                  this.propertyIdentificationForSampadaRegisteredAfterExistanceCheck.push(propIdentificationObject);
                }
                this.propertyIdentificationBySampadaId.push(propertyIdentificationBySampadaId);
              })
              if (tempIdentificationTocheck.propertyValuationRequestBean.webGisResponseBeanDataArr.length > 0) {
                this.propertyIdentificationForSampadaRegisteredAfterExistanceCheck.push(tempIdentificationTocheck);
              }

            }
            else if (propIdentificationObject?.propertyValuationRequestBean?.propertyType?.id == 2 && propIdentificationObject?.propertyValuationRequestBean?.propertySource === "ENP") {

              let propertyIdentificationBySampadaId = new PropertyIdentificationBySampadaId();
              propertyIdentificationBySampadaId.id = propIdentificationObject.id;
              propertyIdentificationBySampadaId.propertyTypeId = propIdentificationObject.propertyValuationRequestBean.propertyType.id;
              propertyIdentificationBySampadaId.propertyType = propIdentificationObject.propertyValuationRequestBean.propertyType.nameEn;
              propertyIdentificationBySampadaId.propertyId = propIdentificationObject.propertyId;
              propertyIdentificationBySampadaId.landParcelId = propIdentificationObject.propertyValuationRequestBean?.mPenagarpalikaResponseData?.propertyNo.toString();
              propertyIdentificationBySampadaId.khasraplotNo = propIdentificationObject.propertyValuationRequestBean.houseFlatNo;
              propertyIdentificationBySampadaId.addressOfProperty = propIdentificationObject.propertyValuationRequestBean.address;
              propertyIdentificationBySampadaId.transactingArea = Number(propIdentificationObject?.propertyValuationRequestBean?.partialTransactArea)//.propertyValuationRequestBean?.mPenagarpalikaResponseData?.igrsEregPropAdditionalDetlsBean?.transactingArea;
              propertyIdentificationBySampadaId.share = propIdentificationObject.propertyValuationRequestBean?.mPenagarpalikaResponseData?.totalArea.toString();
              // propertyIdentificationBySampadaId.unit = propIdentificationObject.propertyValuationRequestBean?.unit;
              propertyIdentificationBySampadaId.unit =propIdentificationObject?.propertyValuationRequestBean.unit??'SQMT';


              if (this.igrsEregTxnDetlsBean.igrsEregPropIdentificationBean) {
                if (this.igrsEregTxnDetlsBean.igrsEregPropIdentificationBean.length > 0) {

                  let isExists = false;

                  for (let i = 0; i < this.igrsEregTxnDetlsBean.igrsEregPropIdentificationBean.length; i++) {

                    let property = this.igrsEregTxnDetlsBean.igrsEregPropIdentificationBean[i];
                    // property.propertyValuationRequestBean.marketValue=null
                    // property.propertyValuationRequestBean.valTxnId=null
                    if (property?.propertyValuationRequestBean?.mPenagarpalikaResponseData?.propertyNo?.toString() === propertyIdentificationBySampadaId?.landParcelId) {
                      isExists = true;
                      break;
                    }
                  }
                  if (isExists) {
                    propertyIdentificationBySampadaId.alreadyAdded = true;
                  }
                  else {
                    propertyIdentificationBySampadaId.alreadyAdded = false;
                    this.propertyIdentificationForSampadaRegisteredAfterExistanceCheck.push(propIdentificationObject);
                  }

                }
                else {
                  this.propertyIdentificationForSampadaRegisteredAfterExistanceCheck.push(propIdentificationObject);
                }
              }
              else {
                this.igrsEregTxnDetlsBean.igrsEregPropIdentificationBean = [];
                this.propertyIdentificationForSampadaRegisteredAfterExistanceCheck.push(propIdentificationObject);
              }
              this.propertyIdentificationBySampadaId.push(propertyIdentificationBySampadaId);
            }
            else if (propIdentificationObject?.propertyValuationRequestBean?.propertyType?.id == 2 && propIdentificationObject?.propertyValuationRequestBean?.propertySource === "PNRD") {

              let propertyIdentificationBySampadaId = new PropertyIdentificationBySampadaId();
              propertyIdentificationBySampadaId.id = propIdentificationObject.id;
              propertyIdentificationBySampadaId.propertyTypeId = propIdentificationObject.propertyValuationRequestBean.propertyType.id;
              propertyIdentificationBySampadaId.propertyType = propIdentificationObject.propertyValuationRequestBean.propertyType.nameEn;
              propertyIdentificationBySampadaId.propertyId = propIdentificationObject.propertyId;
              propertyIdentificationBySampadaId.landParcelId = propIdentificationObject.propertyValuationRequestBean?.pNRDResponseData.propertyId.toString();
              propertyIdentificationBySampadaId.khasraplotNo = propIdentificationObject.propertyValuationRequestBean.houseFlatNo;
              propertyIdentificationBySampadaId.addressOfProperty = propIdentificationObject.propertyValuationRequestBean.address;
              propertyIdentificationBySampadaId.transactingArea =Number(propIdentificationObject?.propertyValuationRequestBean?.partialTransactArea) //propIdentificationObject.propertyValuationRequestBean.totalArea
              propertyIdentificationBySampadaId.share = propIdentificationObject.propertyValuationRequestBean?.pNRDResponseData.totalArea.toString();
              propertyIdentificationBySampadaId.unit = propIdentificationObject.propertyValuationRequestBean?.unit;

              if (this.igrsEregTxnDetlsBean.igrsEregPropIdentificationBean) {
                if (this.igrsEregTxnDetlsBean.igrsEregPropIdentificationBean.length > 0) {

                  let isExists = false;

                  for (let i = 0; i < this.igrsEregTxnDetlsBean.igrsEregPropIdentificationBean.length; i++) {

                    let property = this.igrsEregTxnDetlsBean.igrsEregPropIdentificationBean[i];
                    
                    if (property.propertyValuationRequestBean?.pNRDResponseData?.propertyId.toString() === propertyIdentificationBySampadaId.landParcelId) {
                      isExists = true;
                      break;
                    }
                  }
                  if (isExists) {
                    propertyIdentificationBySampadaId.alreadyAdded = true;
                  }
                  else {
                    propertyIdentificationBySampadaId.alreadyAdded = false;
                    this.propertyIdentificationForSampadaRegisteredAfterExistanceCheck.push(propIdentificationObject);
                  }

                }
                else {
                  this.propertyIdentificationForSampadaRegisteredAfterExistanceCheck.push(propIdentificationObject);
                }
              }
              else {
                this.igrsEregTxnDetlsBean.igrsEregPropIdentificationBean = [];
                this.propertyIdentificationForSampadaRegisteredAfterExistanceCheck.push(propIdentificationObject);
              }
              this.propertyIdentificationBySampadaId.push(propertyIdentificationBySampadaId);
            }
            
            else if (propIdentificationObject?.propertyValuationRequestBean?.propertyType?.id == 1 && propIdentificationObject?.propertyValuationRequestBean?.propertySource === "PNRD") {

              let propertyIdentificationBySampadaId = new PropertyIdentificationBySampadaId();
              propertyIdentificationBySampadaId.id = propIdentificationObject.id;
              propertyIdentificationBySampadaId.propertyTypeId = propIdentificationObject.propertyValuationRequestBean.propertyType.id;
              propertyIdentificationBySampadaId.propertyType = propIdentificationObject.propertyValuationRequestBean.propertyType.nameEn;
              propertyIdentificationBySampadaId.propertyId = propIdentificationObject.propertyId;
              propertyIdentificationBySampadaId.landParcelId = propIdentificationObject.propertyValuationRequestBean?.pNRDResponseData?.propertyId.toString();
              propertyIdentificationBySampadaId.khasraplotNo = propIdentificationObject.propertyValuationRequestBean.houseFlatNo;
              propertyIdentificationBySampadaId.addressOfProperty = propIdentificationObject.propertyValuationRequestBean.address;
             
                propertyIdentificationBySampadaId.transactingArea =Number(propIdentificationObject?.propertyValuationRequestBean?.partialTransactArea) // propIdentificationObject.propertyValuationRequestBean.totalArea
                propertyIdentificationBySampadaId.khasraplotNo = propIdentificationObject.propertyValuationRequestBean.houseFlatNo;
              

              //propertyIdentificationBySampadaId.share = propIdentificationObject.propertyValuationRequestBean?.pNRDResponseData?.totalArea.toString();
              propertyIdentificationBySampadaId.unit = propIdentificationObject.propertyValuationRequestBean?.unit??'SQMT';

              if (this.igrsEregTxnDetlsBean.igrsEregPropIdentificationBean) {
                if (this.igrsEregTxnDetlsBean.igrsEregPropIdentificationBean.length > 0) {

                  let isExists = false;

                  for (let i = 0; i < this.igrsEregTxnDetlsBean.igrsEregPropIdentificationBean.length; i++) {
                    let property = this.igrsEregTxnDetlsBean.igrsEregPropIdentificationBean[i];
                    if (property.propertyValuationRequestBean?.pNRDResponseData?.propertyId.toString() === propertyIdentificationBySampadaId.landParcelId) {
                      isExists = true;
                      break;
                    }
                  }
                  if (isExists) {
                    propertyIdentificationBySampadaId.alreadyAdded = true;
                  }
                  else {
                    propertyIdentificationBySampadaId.alreadyAdded = false;
                    this.propertyIdentificationForSampadaRegisteredAfterExistanceCheck.push(propIdentificationObject);
                  }

                }
                else {
                  this.propertyIdentificationForSampadaRegisteredAfterExistanceCheck.push(propIdentificationObject);
                }
              }
              else {
                this.igrsEregTxnDetlsBean.igrsEregPropIdentificationBean = [];
                this.propertyIdentificationForSampadaRegisteredAfterExistanceCheck.push(propIdentificationObject);
              }
              this.propertyIdentificationBySampadaId.push(propertyIdentificationBySampadaId);
            }
            else if (propIdentificationObject?.propertyValuationRequestBean?.propertyType?.id == 1 && propIdentificationObject?.propertyValuationRequestBean?.propertySource === "ENP") {

              let propertyIdentificationBySampadaId = new PropertyIdentificationBySampadaId();
              propertyIdentificationBySampadaId.id = propIdentificationObject.id;
              propertyIdentificationBySampadaId.propertyTypeId = propIdentificationObject.propertyValuationRequestBean.propertyType.id;
              propertyIdentificationBySampadaId.propertyType = propIdentificationObject.propertyValuationRequestBean.propertyType.nameEn;
              propertyIdentificationBySampadaId.propertyId = propIdentificationObject.propertyId;
              propertyIdentificationBySampadaId.landParcelId = propIdentificationObject.propertyValuationRequestBean?.mPenagarpalikaResponseData?.propertyNo.toString();
              propertyIdentificationBySampadaId.khasraplotNo = propIdentificationObject.propertyValuationRequestBean.houseFlatNo;
              propertyIdentificationBySampadaId.addressOfProperty = propIdentificationObject.propertyValuationRequestBean.address;
              propertyIdentificationBySampadaId.transactingArea = Number(propIdentificationObject?.propertyValuationRequestBean?.partialTransactArea) //propIdentificationObject.propertyValuationRequestBean.totalArea
              propertyIdentificationBySampadaId.khasraplotNo = propIdentificationObject.propertyValuationRequestBean.houseFlatNo;
              

              //propertyIdentificationBySampadaId.share = propIdentificationObject.propertyValuationRequestBean?.mPenagarpalikaResponseData?.totalArea.toString();
              propertyIdentificationBySampadaId.unit = propIdentificationObject.propertyValuationRequestBean?.unit??'SQMT';

              if (this.igrsEregTxnDetlsBean.igrsEregPropIdentificationBean) {
                if (this.igrsEregTxnDetlsBean.igrsEregPropIdentificationBean.length > 0) {

                  let isExists = false;

                  for (let i = 0; i < this.igrsEregTxnDetlsBean.igrsEregPropIdentificationBean.length; i++) {

                    let property = this.igrsEregTxnDetlsBean.igrsEregPropIdentificationBean[i];
                  
                    if (property.propertyValuationRequestBean?.mPenagarpalikaResponseData?.propertyId.toString() === propertyIdentificationBySampadaId?.landParcelId) {
                      isExists = true;
                      break;
                    }
                  }
                  if (isExists) {
                    propertyIdentificationBySampadaId.alreadyAdded = true;
                  }
                  else {
                    propertyIdentificationBySampadaId.alreadyAdded = false;
                    this.propertyIdentificationForSampadaRegisteredAfterExistanceCheck.push(propIdentificationObject);
                  }

                }
                else {
                  this.propertyIdentificationForSampadaRegisteredAfterExistanceCheck.push(propIdentificationObject);
                }
              }
              else {
                this.igrsEregTxnDetlsBean.igrsEregPropIdentificationBean = [];
                this.propertyIdentificationForSampadaRegisteredAfterExistanceCheck.push(propIdentificationObject);
              }
              this.propertyIdentificationBySampadaId.push(propertyIdentificationBySampadaId);
            }
          })
          this.ngxService.stopLoader('property-identification');
        } else {
          this.alertmessage.errorAlertMessage(res);
          this.ngxService.stopLoader('property-identification');
        }
      });
    }
    else {
      this.customErrorBean.responseMessage = "Please enter SAMPADA property id.";
      this.customErrorBean.responseMessageEn = "Please enter SAMPADA property id.";
      this.customErrorBean.responseMessageHi = "कृपया उचित सम्पदा प्रॉपर्टी आईडी दर्ज़ करें |"
      this.alertmessage.errorAlertMessage(this.customErrorBean);
      this.ngxService.stopLoader('property-identification');
    }

  }

  getSelectedPropertyDetails() {
    // this.ngxService.startLoader('property-identification');
    let isDemography: boolean = true;
    this.propertyIdentificationForSampadaRegisteredAfterExistanceCheck.forEach(selectedPropertyObj => {
      if (selectedPropertyObj.propertyValuationRequestBean.demography == null || selectedPropertyObj.propertyValuationRequestBean.demography == undefined) {
        isDemography = false;
      }
    })

    if (!isDemography) {
      this.customErrorBean.responseMessage = "Please Identify Guideline Location First.";
      this.customErrorBean.responseMessageEn = "Please Identify Guideline Location First.";
      this.customErrorBean.responseMessageHi = "कृपया पहले दिशानिर्देश स्थान की पहचान करें।";
      this.alertmessage.errorAlertMessage(this.customErrorBean);
      return;
    }

   
      // not add duplicate
      this.propertyIdentificationForSampadaRegisteredAfterSelection.push(...this.propertyIdentificationForSampadaRegisteredAfterExistanceCheck);
      this.propertyIdentificationForSampadaRegisteredAfterSelection = Array.from(
        this.propertyIdentificationForSampadaRegisteredAfterSelection.reduce((map, obj) => map.set(obj.id, obj), new Map()).values()
      );
   

     
    
    if (this.propertyIdentificationForSampadaRegisteredAfterSelection.length === this.propertyIdentificationForSampadaRegisteredAfterExistanceCheck.length) {
      if (!this.igrsEregTxnDetlsBean.igrsEregPropIdentificationBean) {
        this.igrsEregTxnDetlsBean.igrsEregPropIdentificationBean = [];
      }
      this.saveEregInitDetails();
    }
    else {
      this.ngxService.stopLoader('property-identification');
      if (this.igrsEregTxnDetlsBean.deedInstrumentSelected.deedTypeId.id == 16) {//Equitable Mortgage
        this.customErrorBean.responseMessage = "Property(s) searched is/are already added below. Please check.";
        this.customErrorBean.responseMessageEn = "Property(s) searched is/are already added below. Please check.";
        this.customErrorBean.responseMessageHi = "खोजी गई संपत्ति पहले से ही नीचे जोड़ी गई है। कृपया जांचें।"
      }
      else {
        this.customErrorBean.responseMessage = "Please select all property from list.";
        this.customErrorBean.responseMessageEn = "Please select all property from list.";
        this.customErrorBean.responseMessageHi = "कृपया सूची से सभी प्रॉपर्टी का चयन करें।"
      }
      this.alertmessage.errorAlertMessage(this.customErrorBean);
    }
  }

  saveEregInitDetails() {
    this.eRegService.saveEregInitDetails(this.igrsEregTxnDetlsBean).subscribe(result => {
      if (result.responseStatus == "true") {
        //  this.igrsEregTxnDetlsBean = result.responseData;
        this.igrsEregTxnDetlsBean.deedInstrumentSelected = result.responseData?.deedInstrumentSelected;
        this.igrsEregTxnDetlsBean.deedInstrumentSelectedForAmendmentCancellation = result.responseData?.deedInstrumentSelectedForAmendmentCancellation;
        this.igrsEregTxnDetlsBean.regId = result.responseData.regId
        this.igrsEregTxnDetlsBean.dutyId = result.responseData.dutyId;
        this.igrsEregTxnDetlsBean.tempEregId = result.responseData.tempEregId
        this.igrsEregTxnDetlsBean.statusBean = result.responseData.statusBean
        this.igrsEregTxnDetlsBean.statusId = result.responseData.statusId
        this.igrsEregTxnDetlsBean.igrsEregParticularDetailBeanList=result.responseData.igrsEregParticularDetailBeanList
        this.igrsEregTxnDetlsBean.inputFields = result.responseData?.inputFields
        this.igrsEregTxnDetlsBean.selectedMineralList = result.responseData?.selectedMineralList;
        this.igrsEregTxnDetlsBean.mineralTypeId = result.responseData?.mineralTypeId;
        this.igrsEregTxnDetlsBean.cyberTehsilCheck = result.responseData?.cyberTehsilCheck;
        if(this.igrsEregTxnDetlsBean.cyberTehsilCheck){
        this.igrsEregTxnDetlsBean.cyberTehsilFormA1List = result.responseData?.cyberTehsilFormA1List;
        this.igrsEregTxnDetlsBean.cyberTehsilFormA2List = result.responseData?.cyberTehsilFormA2List;
        }

        let inputFields = JSON.parse(JSON.stringify(this.igrsEregTxnDetlsBean.inputFields));

        if (Array.isArray(inputFields)) {
          this.igrsEregTxnDetlsBean.inputFields = {};
          this.igrsEregTxnDetlsBean.inputFields = inputFields.reduce(function (map: any, obj: any) {
            map[obj.id] = obj.value;
            return map;
          }, {});
        }
        
        this.igrsEregTxnDetlsBean.igrsEregistryConsdAmtDetailsArray=result.responseData.igrsEregistryConsdAmtDetailsArray
        this.emitEregTxnDetlsToEregModule.emit(this.igrsEregTxnDetlsBean)
        if (result.responseData.id != undefined && result.responseData.id != "")
          this.igrsEregTxnDetlsBean.id = result.responseData.id
        this.genrateMortgageProperty(this.propertyIdentificationForSampadaRegisteredAfterSelection);
      }
      else {
        this.alertmessage.errorAlertMessage(result)
      }
    });
  }

  genrateMortgageProperty(igrsEregPropIdentificationBeanList: IgrsEregPropIdentificationBean[]) {

    this.ngxService.startLoader('property-identification');
    igrsEregPropIdentificationBeanList.forEach(tmp => {
      tmp.eregId = this.igrsEregTxnDetlsBean.tempEregId;
      tmp.fkEregId = this.igrsEregTxnDetlsBean.id;
    })
    console.log("igrsEregPropIdentificationBeanList mortgage::" + JSON.stringify(igrsEregPropIdentificationBeanList))
    if (igrsEregPropIdentificationBeanList != null && igrsEregPropIdentificationBeanList[0].source == 1) {//from sampada1 property mortgage
      this.eRegService.insertSampada1DataForMortgage(igrsEregPropIdentificationBeanList).subscribe(response => {
        this.ngxService.stopLoader('property-identification');
        if (response.responseStatus == "true") {
          console.log("response data after save prop:" + JSON.stringify(response.responseData));
          this.propertyIdentificationForSampadaRegisteredAfterSelection = response.responseData;
          this.propertyIdentificationForSampadaRegisteredAfterSelection.forEach((identiObj, i) => {
            identiObj.eregId = this.igrsEregTxnDetlsBean.regId;
            identiObj.fkEregId = this.igrsEregTxnDetlsBean.id;
            identiObj.expanded = true;
            identiObj.sam1EregCompletionDate=response.responseData[i].sam1EregCompletionDate
            // identiObj.propertyValuationRequestBean.demography=this.propertyIdentificationBySampadaId[i].demography
            this.igrsEregTxnDetlsBean.igrsEregPropIdentificationBean.push(identiObj);
          })

          console.log("propertyIdentificationFinal:" + JSON.stringify(this.igrsEregTxnDetlsBean));
          this.propertyIdentificationForSampadaRegisteredAfterSelection = [];
          this.propertyIdentificationForSampadaRegistered = [];
          this.propertyIdentificationBySampadaId = [];
          // this.enablePropMorgageDiv = false;
          // this.enablePropIdentifacationDiv = false;
          // this.sampadaPropertyId = '';
        }
        else {
          this.alertmessage.errorAlertMessage(response);
        }
      })
    } else {//from sampada2 property mortgage
      igrsEregPropIdentificationBeanList.forEach(tmp=>{
        tmp.currentInstrumentId=this.igrsEregTxnDetlsBean.deedInstrumentSelectedForAmendmentCancellation.id
      })
      this.eRegService.updateDataForMortgage(igrsEregPropIdentificationBeanList).subscribe(response => {
        this.ngxService.stopLoader('property-identification');
        if (response.responseStatus == "true") {
          console.log("response data after save prop:" + JSON.stringify(response.responseData));
          this.propertyIdentificationForSampadaRegisteredAfterSelection = response.responseData;
          this.propertyIdentificationForSampadaRegisteredAfterSelection.forEach((identiObj, i) => {
            identiObj.eregId = this.igrsEregTxnDetlsBean.regId;
            identiObj.fkEregId = this.igrsEregTxnDetlsBean.id;
            identiObj.expanded = true
            identiObj.sam1EregCompletionDate=response.responseData[i].sam1EregCompletionDate
            this.igrsEregTxnDetlsBean.igrsEregPropIdentificationBean.push(identiObj);

          })
          console.log("propertyIdentificationFinal:" + JSON.stringify(this.igrsEregTxnDetlsBean));
          this.callAmendmentCancellationCloningPartyDetails();
          this.propertyIdentificationForSampadaRegisteredAfterSelection = [];
          this.propertyIdentificationForSampadaRegistered = [];
          this.propertyIdentificationBySampadaId = [];
          this.enablePropMorgageDiv = false;
          this.enablePropIdentifacationDiv = false;
          this.sampadaPropertyId = '';
        }
        else {
          this.alertmessage.errorAlertMessage(response);
        }
      })
    }

  }

  callAmendmentCancellationCloningPartyDetails() {
    this.eRegService.amendmentCancellationCloningPartyDetails(this.igrsEregTxnDetlsBean).subscribe(result => {
      if (result.responseStatus == "true") {
        this.igrsEregTxnDetlsBean.igrsEregPartyDetailsBeanList  = result.responseData?.igrsEregPartyDetailsBeanList;
        this.igrsEregTxnDetlsBean.igrsEregistryConsdAmtDetailsArray=result.responseData.igrsEregistryConsdAmtDetailsArray
        this.igrsEregTxnDetlsBean.igrsBankFinancialInstituteDetailsBean=result.responseData.igrsBankFinancialInstituteDetailsBean
        console.log("amendment/cancellation party cloning sucessfully updated bean list ::"+JSON.stringify(this.igrsEregTxnDetlsBean.igrsEregPartyDetailsBeanList));
      }
    });
  }

  onPropertyCheckBoxChange(propIdentificationId: number, landParcelId: string, propertyTypeId: number, isChecked: any) {

    this.propertyIdentificationForSampadaRegisteredAfterExistanceCheck.forEach(propObject => {

      if (propObject.id == propIdentificationId) {
        if (propObject.propertyValuationRequestBean.propertyType.id == 3) {
          let tempIdentificationTocheck: IgrsEregPropIdentificationBean = new IgrsEregPropIdentificationBean();
          tempIdentificationTocheck = JSON.parse(JSON.stringify(propObject));
          tempIdentificationTocheck.propertyValuationRequestBean.webGisResponseBeanDataArr = []

          propObject.propertyValuationRequestBean.webGisResponseBeanDataArr.forEach(wbGis => {
            if (wbGis.landParcelId == landParcelId) {
              if (isChecked.target.checked) {
                tempIdentificationTocheck.propertyValuationRequestBean.webGisResponseBeanDataArr.push(wbGis)
                this.propertyIdentificationForSampadaRegisteredAfterSelection.push(tempIdentificationTocheck);
              }
              else {
                let index = tempIdentificationTocheck.propertyValuationRequestBean.webGisResponseBeanDataArr.indexOf(wbGis);
                tempIdentificationTocheck.propertyValuationRequestBean.webGisResponseBeanDataArr.splice(index, 1);
                if (tempIdentificationTocheck.propertyValuationRequestBean.webGisResponseBeanDataArr.length == 0) {
                  let index1 = this.propertyIdentificationForSampadaRegisteredAfterSelection.indexOf(tempIdentificationTocheck);
                  this.propertyIdentificationForSampadaRegisteredAfterSelection.splice(index1, 1);
                }
                else {
                  let index1 = this.propertyIdentificationForSampadaRegisteredAfterSelection.indexOf(tempIdentificationTocheck);
                  this.propertyIdentificationForSampadaRegisteredAfterSelection.splice(index1, 1);
                  this.propertyIdentificationForSampadaRegisteredAfterSelection.push(tempIdentificationTocheck);
                }
              }
              // this.propertyIdentificationForSampadaRegisteredAfterSelection.push(tempIdentificationTocheck);
            }
          })
        }
        else {
          if (isChecked.target.checked) {
            this.propertyIdentificationForSampadaRegisteredAfterSelection.push(propObject);
          }
          else {
            let index = this.propertyIdentificationForSampadaRegisteredAfterSelection.indexOf(propObject);
            this.propertyIdentificationForSampadaRegisteredAfterSelection.splice(index, 1);
          }
        }
      }
    })
    if (this.propertyIdentificationForSampadaRegisteredAfterSelection.length > 0) {
      console.log("Selected prop:" + JSON.stringify(this.propertyIdentificationForSampadaRegisteredAfterSelection));
    }
  }

  getParentSelectedInstrumentObj(parentSelectedInstrumentObj:any){
    console.log("final parent inst obj ::"+JSON.stringify(parentSelectedInstrumentObj))
    let setCMSRegSearchDetails : CMSRegSearchDetails = new CMSRegSearchDetails();
    setCMSRegSearchDetails.deedTypeNameEn = parentSelectedInstrumentObj?.deedTypeId?.deedTypeNameEn;
    setCMSRegSearchDetails.deedTypeNameHi = parentSelectedInstrumentObj?.deedTypeId?.deedTypeNameHi;
    setCMSRegSearchDetails.deedCategoryNameEn = parentSelectedInstrumentObj?.deedTypeId?.deedCategoryId?.deedCategoryNameEn;
    setCMSRegSearchDetails.deedCategoryNameHi = parentSelectedInstrumentObj?.deedTypeId?.deedCategoryId?.deedCategoryNameHi;
    setCMSRegSearchDetails.instrumentNameEn = parentSelectedInstrumentObj?.instrumentNameEn;
    setCMSRegSearchDetails.instrumentNameHi = parentSelectedInstrumentObj?.instrumentNameHi;
    setCMSRegSearchDetails.registryInitiationDate = this.registryInitiationDate;
    setCMSRegSearchDetails.documentRegistrationDate = this.documentRegistrationDate;
    
    setCMSRegSearchDetails.deedInstrumentSelected = parentSelectedInstrumentObj;
    

    this.igrsEregTxnDetlsBean.cmsRegSearchDetails = setCMSRegSearchDetails;
    this.igrsEregTxnDetlsBean.parentRegNo = this.manualSearchPropertyId;
    this.igrsEregTxnDetlsBean.parentDeedTypeId = parentSelectedInstrumentObj?.deedTypeId?.id;
    this.igrsEregTxnDetlsBean.parentDeedCategoryId = parentSelectedInstrumentObj?.deedTypeId?.deedCategoryId?.id;
    this.igrsEregTxnDetlsBean.parentDeedInstrumentId = parentSelectedInstrumentObj?.id;
    this.igrsEregTxnDetlsBean.deedInstrumentSelected.propReqFlag = parentSelectedInstrumentObj?.propReqFlag;
    this.igrsEregTxnDetlsBean.deedInstrumentSelected.propValReqFlag = parentSelectedInstrumentObj?.propValReqFlag;
    this.igrsEregTxnDetlsBean.deedInstrumentSelectedForAmendmentCancellation = parentSelectedInstrumentObj;
  }

  setDates(val:string,event: any){
    if(val == "iniDate"){
      this.registryInitiationDate = event.value;
      this.documentRegistrationDate = null;
      this.input.value = '';

      this.igrsEregTxnDetlsBean.cmsRegSearchDetails.documentRegistrationDate = null;
      this.igrsEregTxnDetlsBean.parentDateOfRegistration = null;

      this.igrsEregTxnDetlsBean.cmsRegSearchDetails.registryInitiationDate = this.registryInitiationDate;
      this.igrsEregTxnDetlsBean.parentDateOfExecution = this.registryInitiationDate;
    }else if(val == "regDate"){
      this.documentRegistrationDate = event.value;
      if(this.registryInitiationDate == null || this.registryInitiationDate == undefined){
        this.documentRegistrationDate = null;
        this.input.value = '';
        Swal.fire((this.lan == 0) ? "first insert the Date Of Execution":"सबसे पहले निष्पादन की तारीख डालें");
      }else if(moment(this.documentRegistrationDate).format("YYYY-MM-DD") < moment(this.registryInitiationDate).format("YYYY-MM-DD")){
        this.documentRegistrationDate = null;
        this.input.value = '';
        Swal.fire((this.lan == 0) ? "Date of Registration should be greater or equal to Date Of Execution":"पंजीकरण की तारीख निष्पादन की तारीख से अधिक या उसके बराबर होनी चाहिए");
      }else{
        this.igrsEregTxnDetlsBean.cmsRegSearchDetails.documentRegistrationDate = this.documentRegistrationDate;
        this.igrsEregTxnDetlsBean.parentDateOfRegistration = this.documentRegistrationDate;
      }
      
    }
  }
  


}
