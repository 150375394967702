import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CsvDownloadService {

  
  downloadFile(data:any, filename='data',columnname:any,columnname_key?:any) {
    let csvData = this.ConvertToCSV(data,columnname,columnname_key);
  //  
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
}

ConvertToCSV(objArray:any, headerList:any,rowList?:any) {
     let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
     let str = '';
     let row = 'S.No,';

     for (let index in headerList) {
         row += headerList[index] + ',';
     }
     row = row.slice(0, -1);
     str += row + '\r\n';

     //In case of Header name index have some diffrent value
     if(typeof(rowList) !== 'undefined'){

          for (let i = 0; i < array.length-1; i++) {
            let line = (i+1)+'';
            for (let index in rowList) {
              let head = rowList[index];

                line += ',' + array[i][head];
            }
            str += line + '\r\n';
        }
     }else{
      
          for (let i = 0; i < array.length; i++) {
            let line = (i+1)+'';
            for (let index in headerList) {
              let head = headerList[index];

                line += ',' + array[i][head];
            }
            str += line + '\r\n';
        }
     }
     
    
     return str;
 }

 downloadFileNew(data:any, filename='data',columnnameEn:any,columnnameHi?:any, lan?: number) {
  let csvData = this.ConvertToCSVNew(data,columnnameEn,columnnameHi, lan);
//  
  let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
  let dwldLink = document.createElement("a");
  let url = URL.createObjectURL(blob);
  let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
  if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
  }
  dwldLink.setAttribute("href", url);
  dwldLink.setAttribute("download", filename + ".csv");
  dwldLink.style.visibility = "hidden";
  document.body.appendChild(dwldLink);
  dwldLink.click();
  document.body.removeChild(dwldLink);
}

 ConvertToCSVNew(objArray:any, headerList:any,columnnameHi?:any, lan?: number) {
  let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  let str = '';
  let row = 'S.No,';

   if (lan == 0) {
     for (let index in headerList) {
       row += headerList[index] + ',';
     }
   } else {
  for (let index in columnnameHi) {
    row += columnnameHi[index] + ',';
     }
}
  row = row.slice(0, -1);
  str += row + '\r\n';

  //In case of Header name index have some diffrent value
  // if(typeof(rowList) !== 'undefined'){

  //      for (let i = 0; i < array.length-1; i++) {
  //        let line = (i+1)+'';
  //        for (let index in rowList) {
  //          let head = rowList[index];

  //            line += ',' + array[i][head];
  //        }
  //        str += line + '\r\n';
  //    }
  // }else{
   
       for (let i = 0; i < array.length; i++) {
         let line = (i+1)+'';
         for (let index in headerList) {
           let head = headerList[index];

       if (array[i][head] != null && array[i][head] != undefined) {
         if (array[i][head].includes(',')) {
           const item = array[i][head].split(',');
           let value: string = '';
           for (let index in item) {
             value += ' ' + item[index]
           }
           line += ',' + value;
         } else {
           let curStatus;
           if ((head === 'status' || head === 'स्थिति') && array[i][head] == 1) {
             curStatus = (lan == 0) ? 'Active' : 'सक्रीय'
             line += ',' + curStatus;
           } else if ((head === 'status' || head == 'स्थिति') && array[i][head] == 0) {
             let curStatus = (lan == 0) ? 'Active' : 'सक्रीय'
             line += ',' + curStatus;
           } else if (head === 'requestDate' || head == 'अनुरोध की तिथि') {
            const item = array[i][head].split(' ');
            line += ',' + item[0];
          } else
             line += ',' + array[i][head];
         }
       } else {
         line += ',' + 'N/A';
       }
         }
         str += line + '\r\n';
     }
  //}
  
 
  return str;
}

//  // For Department List
// downloadDepartmentFile(data:any, filename='data') {
//     let csvData = this.ConvertCSV(data, ['department', 'department_type', 'parent_department', 'status']);
//     
//     let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
//     let dwldLink = document.createElement("a");
//     let url = URL.createObjectURL(blob);
//     let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
//     if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
//         dwldLink.setAttribute("target", "_blank");
//     }
//     dwldLink.setAttribute("href", url);
//     dwldLink.setAttribute("download", filename + ".csv");
//     dwldLink.style.visibility = "hidden";
//     document.body.appendChild(dwldLink);
//     dwldLink.click();
//     document.body.removeChild(dwldLink);
// }

// ConvertCSV(objArray:any, headerList:any) {
//     let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
//     let str = '';
//     let row = 'S.No,';

//     for (let index in headerList) {
//         row += headerList[index] + ',';
//     }
//     row = row.slice(0, -1);
//     str += row + '\r\n';
//     for (let i = 0; i < array.length; i++) {
//         let line = (i+1)+'';
//         for (let index in headerList) {
//            let head = headerList[index];

//             line += ',' + array[i][head];
//         }
//         str += line + '\r\n';
//     }
//     return str;
// }

}
