<div class="position-relative">
  <div class="fid-header"></div>
  <div class="breadcrumb-content">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
             <h2>
             {{'softDownload.soft_docu_download' | translate}}
            </h2>

        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <mat-card class="example-card">
      <mat-card-title></mat-card-title>
      <mat-card-content class="mat-card-content">

          <fieldset class="legend-section">
            <legend class="sidebar__widget-title">

             <mat-icon>fingerprint</mat-icon> {{'softDownload.aadhar_Bio_devi' | translate}}
            </legend>

            <mat-expansion-panel
            (opened)="panelOpenState = true" (closed)="panelOpenState = false" [expanded]="isExpanded"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{'softDownload.thumb_devi_driv' | translate}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="p-3">
                <!-- <div class="d-flex justify-content-between mb-2">

                  <p>Mantra Devices - MFS 100 - Windows</p>
                  <button  mat-button class="btn btn-primary" (click)="downloadZip('MIS100V2')">
                    <mat-icon>download</mat-icon>

                  </button>
                </div> -->
                <div class="d-flex justify-content-between mb-2">

                  <p>
                    {{'softDownload.mfs100' | translate}}
                  </p>
                  <button  mat-button class="btn btn-primary" (click)="downloadZip('MFS110_L1')">
                    <mat-icon>download</mat-icon>

                  </button>
                </div>



                <div class="d-flex justify-content-between mb-2">
                  <p>
                    {{'softDownload.FM220U' | translate}}
                  </p>
                  <button  mat-button class="btn btn-primary" (click)="downloadZip('FM220U_L1')">
                    <!-- {{'citizen-profile-details.citizen-contact-details.download' | translate}} -->
                    <mat-icon>download</mat-icon>
                  </button>
                </div>
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel
            (opened)="panelOpenState = true" (closed)="panelOpenState = false" [expanded]="isExpanded"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{'softDownload.Iris_devi_driv' | translate}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="p-3">
                <div class="d-flex justify-content-between mb-2">
                  <p>
                    {{'softDownload.MIS100V2' | translate}}
                  </p>
                  <button  mat-button class="btn btn-primary" (click)="downloadZip('MIS100V2')">
                    <!-- {{'citizen-profile-details.citizen-contact-details.download' | translate}} -->
                    <mat-icon>download</mat-icon>
                  </button>
                </div>
              </div>
            </mat-expansion-panel>
          </fieldset>
          <fieldset class="legend-section">
            <legend class="sidebar__widget-title">
           <mat-icon>important_devices</mat-icon>   {{'softDownload.biomet_driv' | translate}}
             </legend>

            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [expanded]="isExpanded"
            >
              <mat-expansion-panel-header>
                <mat-panel-title> {{'softDownload.thumb_devi_driv' | translate}} </mat-panel-title>
              </mat-expansion-panel-header>
                 <div class="p-3">
                <div class="d-flex justify-content-between mb-2">
                  <p>
                    {{(lan==0)?'Mantra Devices - MFS 500 - Windows New Drivers':'मंत्रा डिवाइसेस - एमएफएस 500 - विंडोज़ नए ड्राइवर'}}
                  </p>
                  <button  mat-button class="btn btn-primary" (click)="downloadZip('MFS500-new')">
                    <!-- {{'citizen-profile-details.citizen-contact-details.download' | translate}} -->
                    <mat-icon>download</mat-icon>
                  </button>
                </div>
              </div>
              <div class="p-3">
                <div class="d-flex justify-content-between mb-2">
                  <p>
                    <!-- {{'softDownload.MFS500' | translate}} -->
                    {{(lan==0)?'Mantra Devices - MFS 500 - Windows Old Drivers':'मंत्रा डिवाइस - एमएफएस 500 - विंडोज़ पुराने ड्राइवर'}}
                  </p>
                  <button  mat-button class="btn btn-primary" (click)="downloadZip('MFS500')">
                    <!-- {{'citizen-profile-details.citizen-contact-details.download' | translate}} -->
                    <mat-icon>download</mat-icon>
                  </button>
                </div>
              </div>
           
            </mat-expansion-panel>
           
          </fieldset>



        <fieldset class="legend-section">
            <legend class="sidebar__widget-title">
              <mat-icon>perm_data_setting</mat-icon>  {{(lan==0)?'DSC Software':'डीएससी सॉफ़्टवेयर'}}
             </legend>
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [expanded]="isExpanded"
            >
              <mat-expansion-panel-header>
                <mat-panel-title> {{(lan==0)?'DSC':'डीएससी '}} </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="p-3">
                <div class="d-flex justify-content-between mb-2">
                  <p>
                {{(lan==0)?'DSC Drivers':'डीएससी  ड्राइवर'}}
                  </p>
                  <button  mat-button class="btn btn-primary" (click)="downloadZip('unipay_service')">
                    <!-- {{'citizen-profile-details.citizen-contact-details.download' | translate}} -->
                    <mat-icon>download</mat-icon>
                  </button>
                </div>
              </div>
            </mat-expansion-panel>
             </fieldset>




      </mat-card-content>
    </mat-card>
  </div>
</div>
